var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-2 white-card loan-process-height-249 d-flex flex-column"},[_c('div',{staticClass:"loan-process-heading"},[_c('div',{staticClass:"col-lg-12 d-flex justify-content-between"},[_c('p',{staticClass:"heading mb-0"},[_vm._v("Hazard Binder Received ")]),_c('div',{staticClass:"d-flex align-items-center"},[_vm._m(0),_c('div',{staticClass:"d-flex align-items-center border_solid_radius"},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],class:[
            _vm.isCompleted
            ? 'btn-upload cursor-pointer cursor-not-allow'
            : 'btn-upload cursor-pointer' ],attrs:{"type":"button","title":"Send Follow Up Mail to Agent"},on:{"click":function($event){!_vm.isCompleted ? _vm.sendFollowUpMailToHazardAgent():''}}},[_c('i',{staticClass:"fa fa-plus font__size12 text__blue mx-2 cursor-pointer",class:[
            _vm.isCompleted
            ? 'btn-upload cursor-pointer cursor-not-allow'
            : 'btn-upload cursor-pointer' ]})]),_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"fa fa-eye text__blue mx-2 font__size12 cursor-pointer",attrs:{"title":"View"},on:{"click":function($event){return _vm.viewFollowUpHistory()}}})])])])]),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.saveHazardRecievedTask)}}},[_c('div',{staticClass:"col-lg-12 pt-3 pb-3 flex-1 height_100"},[_c('div',{staticClass:"form-row m-0 flex-1 flex-column min_293 col-12 px-1"},[_c('div',{staticClass:"flex-1"},[_c('div',{staticClass:"col-lg-12 p-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 col-xl-6 col-md-12 col-sm-12 mb-2"},[_c('div',{staticClass:"flex-1"},[_c('p',{staticClass:"content-text m-0"},[_c('b',[_vm._v("Reports")])]),_c('hr',{staticClass:"loan-process-border-top mb-2 mt-1"}),_c('div',{staticClass:"custom-checkbox d-flex align-items-center mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hazardBinderRecieved.isHazardRecieved),expression:"hazardBinderRecieved.isHazardRecieved"}],staticClass:"custom-checkbox-input",attrs:{"type":"checkbox","id":"a","disabled":_vm.isCompleted},domProps:{"checked":Array.isArray(_vm.hazardBinderRecieved.isHazardRecieved)?_vm._i(_vm.hazardBinderRecieved.isHazardRecieved,null)>-1:(_vm.hazardBinderRecieved.isHazardRecieved)},on:{"change":[function($event){var $$a=_vm.hazardBinderRecieved.isHazardRecieved,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.hazardBinderRecieved, "isHazardRecieved", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.hazardBinderRecieved, "isHazardRecieved", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.hazardBinderRecieved, "isHazardRecieved", $$c)}},function($event){_vm.error = { show: false, msg: null };_vm.isComplete = false;
                      _vm.handleChange(_vm.hazardBinderRecieved.isHazardRecieved, _vm.hazardBinderRecievedData.isHazardRecieved, 'Hazard Binder Received'); _vm.saveHazardRecievedTask(false)}]}}),_c('label',{staticClass:"label",attrs:{"for":"a"}},[_vm._v("Hazard Binder Received")]),_c('div',{staticClass:"indicator"})]),_c('div',[_c('div',{staticClass:"col-lg-12 d_flex_704 align-items-center p-0"},[_c('div',{staticClass:"form-group d-flex align-items-center"},[(!_vm.hazardBinderRecieved.loanChecklistData)?_c('div',{staticClass:"file-upload mt-1 overflow_hidden"},[_c('label',{staticClass:"file-upload-label mb-0",attrs:{"for":"upload"}},[_c('i',{staticClass:"fa fa-upload mr-1",attrs:{"aria-hidden":"true"}}),_vm._v(_vm._s(_vm.docLoader ? "Uploading..." : "Upload Hazard Binder Report"))]),_c('input',{staticClass:"file-upload-input",class:[
                              _vm.isCompleted || _vm.hazardBinderRecieved.loanChecklistData
                              ? 'btn-upload cursor-pointer cursor-not-allow'
                              : 'btn-upload cursor-pointer' ],attrs:{"id":"upload","type":"file","name":"file-upload","disabled":_vm.isCompleted || _vm.hazardBinderRecieved.loanChecklistData,"multiple":""},on:{"change":function($event){_vm.uploadReport($event);
                              _vm.error = { show: false, msg: null };}}})]):_c('div',[_c('label',{staticClass:"label",attrs:{"for":"a"}},[_vm._v("Uploaded Hazard Binder Report")])]),_c('div',{staticClass:"d-flex align-items-center"},[( _vm.hazardBinderRecieved.loanChecklistData && _vm.hazardBinderRecieved.loanChecklistData.docLength>0)?_c('p',{staticClass:"mb-0 card-content-title ml-2 app-count"},[_c('b',[_vm._v(_vm._s(_vm.hazardBinderRecieved.loanChecklistData ? _vm.hazardBinderRecieved.loanChecklistData.docLength : ''))])]):_vm._e(),( _vm.hazardBinderRecieved.loanChecklistData && _vm.hazardBinderRecieved.loanChecklistData.docLength>0)?_c('i',{staticClass:"ri-file-text-line ml-2 mr-2 text__blue cursor-pointer",on:{"click":function($event){return _vm.viewDocument(_vm.hazardBinderRecieved.loanChecklistData)}}}):_vm._e()])]),(_vm.hazardBinderRecieved.loanChecklistData && _vm.showUploadRevisedReportDiv())?_c('div',{staticClass:"d-flex top-2"},[_c('label',{staticClass:"file-upload-label mb-0",attrs:{"for":"upload1"}},[_vm._v(" "+_vm._s(_vm.revisedUploading ? 'Uploading..' : 'Upload Updated Report')+" ")]),_c('div',{staticClass:"file-upload mt-1 overflow_hidden"},[_c('input',{staticClass:"file-upload-input",attrs:{"id":"upload1","disabled":_vm.isCompleted,"type":"file","name":"file-upload","multiple":""},on:{"change":function($event){return _vm.uploadReport($event,'Revised')}}})])]):_vm._e()])])])]),_c('div',{staticClass:"col-lg-6 col-xl-6 col-md-12 col-sm-12 mb-2 border_left_991"},[_c('p',{staticClass:"content-text m-0"},[_c('b',[_vm._v("Checklist")])]),_c('hr',{staticClass:"loan-process-border-top mb-2 mt-1"}),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 p-0"},[_c('p',{staticClass:"content-text-black mt-1 mb-1"},[_c('span',[_vm._v("1.")]),_vm._v(" Are the Borrowers name accurately reflected? ")]),_c('div',{staticClass:"mb-2"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0 ml-3"},[_c('span',{staticClass:"radio-text"},[_vm._v("Yes")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hazardBinderRecieved.isBorrowerNameAccuratelyReflected.mode),expression:"hazardBinderRecieved.isBorrowerNameAccuratelyReflected.mode"}],attrs:{"type":"radio","value":"Yes","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.hazardBinderRecieved.isBorrowerNameAccuratelyReflected.mode,"Yes")},on:{"change":[function($event){return _vm.$set(_vm.hazardBinderRecieved.isBorrowerNameAccuratelyReflected, "mode", "Yes")},function($event){_vm.isComplete = false;
                              _vm.handleChange(_vm.hazardBinderRecieved.isBorrowerNameAccuratelyReflected.mode, _vm.hazardBinderRecievedData.isBorrowerNameAccuratelyReflected.mode, 'Are the Borrowers name accurately reflected?'); _vm.saveHazardRecievedTask(false)}],"click":function($event){_vm.hazardBinderRecieved.isBorrowerNameAccuratelyReflected.revisionRequestedOn = null;
                              _vm.hazardBinderRecieved.isBorrowerNameAccuratelyReflected.revisionCompletedOn = null;_vm.hazardBinderRecieved.isBorrowerNameAccuratelyReflected.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("No")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hazardBinderRecieved.isBorrowerNameAccuratelyReflected.mode),expression:"hazardBinderRecieved.isBorrowerNameAccuratelyReflected.mode"}],attrs:{"type":"radio","value":"No","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.hazardBinderRecieved.isBorrowerNameAccuratelyReflected.mode,"No")},on:{"change":[function($event){return _vm.$set(_vm.hazardBinderRecieved.isBorrowerNameAccuratelyReflected, "mode", "No")},function($event){_vm.isComplete = false;
                              _vm.handleChange(_vm.hazardBinderRecieved.isBorrowerNameAccuratelyReflected.mode, _vm.hazardBinderRecievedData.isBorrowerNameAccuratelyReflected.mode, 'Are the Borrowers name accurately reflected?'); _vm.saveHazardRecievedTask(false)}],"click":function($event){_vm.hazardBinderRecieved.isBorrowerNameAccuratelyReflected.revisionRequestedOn = null;
                              _vm.hazardBinderRecieved.isBorrowerNameAccuratelyReflected.revisionCompletedOn = null;_vm.hazardBinderRecieved.isBorrowerNameAccuratelyReflected.nARemark = null;}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("NA")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hazardBinderRecieved.isBorrowerNameAccuratelyReflected.mode),expression:"hazardBinderRecieved.isBorrowerNameAccuratelyReflected.mode"}],attrs:{"type":"radio","value":"NA","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.hazardBinderRecieved.isBorrowerNameAccuratelyReflected.mode,"NA")},on:{"change":[function($event){return _vm.$set(_vm.hazardBinderRecieved.isBorrowerNameAccuratelyReflected, "mode", "NA")},function($event){_vm.isComplete = false;
                              _vm.handleChange(_vm.hazardBinderRecieved.isBorrowerNameAccuratelyReflected.mode, _vm.hazardBinderRecievedData.isBorrowerNameAccuratelyReflected.mode, 'Are the Borrowers name accurately reflected?'); _vm.saveHazardRecievedTask(false)}],"click":function($event){_vm.hazardBinderRecieved.isBorrowerNameAccuratelyReflected.revisionRequestedOn = null;
                              _vm.hazardBinderRecieved.isBorrowerNameAccuratelyReflected.revisionCompletedOn = null; _vm.hazardBinderRecieved.isBorrowerNameAccuratelyReflected.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)})],1),_c('div',{staticClass:"row m-0"},[(_vm.hazardBinderRecieved.isBorrowerNameAccuratelyReflected.mode == 'No')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('label',{staticClass:"label ml-3",attrs:{"for":"initial"}},[_vm._v("Revision Requested")]),_c('div',{staticClass:"d-flex align-items-center ml-3"},[_c('datepicker',{class:[errors.length>0?'mx-input-error':''],attrs:{"disabled":_vm.isCompleted,"valueType":"format"},on:{"change":function($event){_vm.isComplete = false;
                              _vm.handleChange(_vm.hazardBinderRecieved.isBorrowerNameAccuratelyReflected.revisionRequestedOn, _vm.hazardBinderRecievedData.isBorrowerNameAccuratelyReflected.revisionRequestedOn, 'Are the Borrowers name accurately reflected? Revision Requested'); _vm.saveHazardRecievedTask(false)}},model:{value:(_vm.hazardBinderRecieved.isBorrowerNameAccuratelyReflected.revisionRequestedOn),callback:function ($$v) {_vm.$set(_vm.hazardBinderRecieved.isBorrowerNameAccuratelyReflected, "revisionRequestedOn", $$v)},expression:"hazardBinderRecieved.isBorrowerNameAccuratelyReflected.revisionRequestedOn"}})],1)]}}],null,true)})],1):_vm._e(),(_vm.hazardBinderRecieved.isBorrowerNameAccuratelyReflected.mode == 'No')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('label',{staticClass:"label",attrs:{"for":"initial"}},[_vm._v("Revision Completed")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('datepicker',{class:[errors.length>0?'mx-input-error':''],attrs:{"disabled-date":function (date) { return date  <= _vm.$moment(new Date (_vm.hazardBinderRecieved.isBorrowerNameAccuratelyReflected.revisionRequestedOn).setDate(new Date(_vm.hazardBinderRecieved.isBorrowerNameAccuratelyReflected.revisionRequestedOn).getDate() -1 )); },"disabled":_vm.isCompleted,"valueType":"format"},on:{"change":function($event){_vm.isComplete = false;
                            _vm.handleChange(_vm.hazardBinderRecieved.isBorrowerNameAccuratelyReflected.revisionCompletedOn, _vm.hazardBinderRecievedData.isBorrowerNameAccuratelyReflected.revisionCompletedOn, 'Are the Borrowers name accurately reflected? Revision Completed'); _vm.saveHazardRecievedTask(false)}},model:{value:(_vm.hazardBinderRecieved.isBorrowerNameAccuratelyReflected.revisionCompletedOn),callback:function ($$v) {_vm.$set(_vm.hazardBinderRecieved.isBorrowerNameAccuratelyReflected, "revisionCompletedOn", $$v)},expression:"hazardBinderRecieved.isBorrowerNameAccuratelyReflected.revisionCompletedOn"}})],1)]}}],null,true)})],1):_vm._e()]),(_vm.hazardBinderRecieved.isBorrowerNameAccuratelyReflected.mode == 'NA')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 p-0"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label ml-3",attrs:{"for":"creditorName"}},[_vm._v("Add remarks")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hazardBinderRecieved.isBorrowerNameAccuratelyReflected.nARemark),expression:"hazardBinderRecieved.isBorrowerNameAccuratelyReflected.nARemark"}],staticClass:"login-input line_height_40 ml-3",attrs:{"type":"text","disabled":_vm.isCompleted,"name":"Add remarks"},domProps:{"value":(_vm.hazardBinderRecieved.isBorrowerNameAccuratelyReflected.nARemark)},on:{"change":function($event){_vm.isComplete = false;
                          _vm.handleChange(_vm.hazardBinderRecieved.isBorrowerNameAccuratelyReflected.nARemark, _vm.hazardBinderRecievedData.isBorrowerNameAccuratelyReflected.nARemark, 'Are the Borrowers name accurately reflected? Remarks'); _vm.saveHazardRecievedTask(false)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.hazardBinderRecieved.isBorrowerNameAccuratelyReflected, "nARemark", $event.target.value)}}})])]):_vm._e()]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 p-0"},[_c('p',{staticClass:"content-text-black mt-1 mb-1"},[_c('span',[_vm._v("2.")]),_vm._v(" Is the property address accurately reflected? ")]),_c('div',{staticClass:"mb-2"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0 ml-3"},[_c('span',{staticClass:"radio-text"},[_vm._v("Yes")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hazardBinderRecieved.isPropertyAddressAccuratelyReflected.mode),expression:"hazardBinderRecieved.isPropertyAddressAccuratelyReflected.mode"}],attrs:{"type":"radio","value":"Yes","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.hazardBinderRecieved.isPropertyAddressAccuratelyReflected.mode,"Yes")},on:{"change":[function($event){return _vm.$set(_vm.hazardBinderRecieved.isPropertyAddressAccuratelyReflected, "mode", "Yes")},function($event){_vm.isComplete = false;
                          _vm.handleChange(_vm.hazardBinderRecieved.isPropertyAddressAccuratelyReflected.mode, _vm.hazardBinderRecievedData.isPropertyAddressAccuratelyReflected.mode, 'Is the property address accurately reflected?'); _vm.saveHazardRecievedTask(false)}],"click":function($event){_vm.hazardBinderRecieved.isPropertyAddressAccuratelyReflected.revisionRequestedOn =null;
                          _vm.hazardBinderRecieved.isPropertyAddressAccuratelyReflected.revisionCompletedOn = null; _vm.hazardBinderRecieved.isPropertyAddressAccuratelyReflected.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("No")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hazardBinderRecieved.isPropertyAddressAccuratelyReflected.mode),expression:"hazardBinderRecieved.isPropertyAddressAccuratelyReflected.mode"}],attrs:{"type":"radio","value":"No","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.hazardBinderRecieved.isPropertyAddressAccuratelyReflected.mode,"No")},on:{"change":[function($event){return _vm.$set(_vm.hazardBinderRecieved.isPropertyAddressAccuratelyReflected, "mode", "No")},function($event){_vm.isComplete = false;
                          _vm.handleChange(_vm.hazardBinderRecieved.isPropertyAddressAccuratelyReflected.mode, _vm.hazardBinderRecievedData.isPropertyAddressAccuratelyReflected.mode, 'Is the property address accurately reflected?'); _vm.saveHazardRecievedTask(false)}],"click":function($event){_vm.hazardBinderRecieved.isPropertyAddressAccuratelyReflected.revisionRequestedOn =null;
                          _vm.hazardBinderRecieved.isPropertyAddressAccuratelyReflected.revisionCompletedOn = null;_vm.hazardBinderRecieved.isPropertyAddressAccuratelyReflected.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("NA")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hazardBinderRecieved.isPropertyAddressAccuratelyReflected.mode),expression:"hazardBinderRecieved.isPropertyAddressAccuratelyReflected.mode"}],attrs:{"type":"radio","value":"NA","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.hazardBinderRecieved.isPropertyAddressAccuratelyReflected.mode,"NA")},on:{"change":[function($event){return _vm.$set(_vm.hazardBinderRecieved.isPropertyAddressAccuratelyReflected, "mode", "NA")},function($event){_vm.isComplete = false;
                          _vm.handleChange(_vm.hazardBinderRecieved.isPropertyAddressAccuratelyReflected.mode, _vm.hazardBinderRecievedData.isPropertyAddressAccuratelyReflected.mode, 'Is the property address accurately reflected?'); _vm.saveHazardRecievedTask(false)}],"click":function($event){_vm.hazardBinderRecieved.isPropertyAddressAccuratelyReflected.revisionRequestedOn =null;
                          _vm.hazardBinderRecieved.isPropertyAddressAccuratelyReflected.revisionCompletedOn = null; _vm.hazardBinderRecieved.isPropertyAddressAccuratelyReflected.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)})],1),_c('div',{staticClass:"row m-0"},[(_vm.hazardBinderRecieved.isPropertyAddressAccuratelyReflected.mode == 'No')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('label',{staticClass:"label ml-3",attrs:{"for":"initial"}},[_vm._v("Revision Requested")]),_c('div',{staticClass:"d-flex align-items-center ml-3"},[_c('datepicker',{class:[errors.length>0?'mx-input-error':''],attrs:{"disabled":_vm.isCompleted,"value-type":"format"},on:{"change":function($event){_vm.isComplete = false;
                          _vm.handleChange(_vm.hazardBinderRecieved.isPropertyAddressAccuratelyReflected.revisionRequestedOn, _vm.hazardBinderRecievedData.isPropertyAddressAccuratelyReflected.revisionRequestedOn, 'Is the property address accurately reflected? Revision Requested'); _vm.saveHazardRecievedTask(false)}},model:{value:(_vm.hazardBinderRecieved.isPropertyAddressAccuratelyReflected.revisionRequestedOn),callback:function ($$v) {_vm.$set(_vm.hazardBinderRecieved.isPropertyAddressAccuratelyReflected, "revisionRequestedOn", $$v)},expression:"hazardBinderRecieved.isPropertyAddressAccuratelyReflected.revisionRequestedOn"}})],1)]}}],null,true)})],1):_vm._e(),(_vm.hazardBinderRecieved.isPropertyAddressAccuratelyReflected.mode == 'No')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('label',{staticClass:"label",attrs:{"for":"initial"}},[_vm._v("Revision Completed")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('datepicker',{class:[errors.length>0?'mx-input-error':''],attrs:{"disabled":_vm.isCompleted,"value-type":"format","disabled-date":function (date) { return date <= _vm.$moment(new Date(_vm.hazardBinderRecieved.isPropertyAddressAccuratelyReflected.revisionRequestedOn).setDate(new Date(_vm.hazardBinderRecieved.isPropertyAddressAccuratelyReflected.revisionRequestedOn).getDate() -1)); }},on:{"change":function($event){_vm.isComplete = false;
                        _vm.handleChange(_vm.hazardBinderRecieved.isPropertyAddressAccuratelyReflected.revisionCompletedOn, _vm.hazardBinderRecievedData.isPropertyAddressAccuratelyReflected.revisionCompletedOn, 'Is the property address accurately reflected? Revision Completed'); _vm.saveHazardRecievedTask(false)}},model:{value:(_vm.hazardBinderRecieved.isPropertyAddressAccuratelyReflected.revisionCompletedOn),callback:function ($$v) {_vm.$set(_vm.hazardBinderRecieved.isPropertyAddressAccuratelyReflected, "revisionCompletedOn", $$v)},expression:"hazardBinderRecieved.isPropertyAddressAccuratelyReflected.revisionCompletedOn"}})],1)]}}],null,true)})],1):_vm._e()]),(_vm.hazardBinderRecieved.isPropertyAddressAccuratelyReflected.mode == 'NA')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 p-0"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label ml-3",attrs:{"for":"creditorName"}},[_vm._v("Add remarks")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hazardBinderRecieved.isPropertyAddressAccuratelyReflected.nARemark),expression:"hazardBinderRecieved.isPropertyAddressAccuratelyReflected.nARemark"}],staticClass:"login-input line_height_40 ml-3",attrs:{"type":"text","disabled":_vm.isCompleted,"name":"Add remarks"},domProps:{"value":(_vm.hazardBinderRecieved.isPropertyAddressAccuratelyReflected.nARemark)},on:{"change":function($event){_vm.isComplete = false;
                      _vm.handleChange(_vm.hazardBinderRecieved.isPropertyAddressAccuratelyReflected.nARemark, _vm.hazardBinderRecievedData.isPropertyAddressAccuratelyReflected.nARemark, 'Is the property address accurately reflected? Remarks'); _vm.saveHazardRecievedTask(false)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.hazardBinderRecieved.isPropertyAddressAccuratelyReflected, "nARemark", $event.target.value)}}})])]):_vm._e()]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 p-0"},[_c('p',{staticClass:"content-text-black mt-1 mb-1"},[_c('span',[_vm._v("3.")]),_vm._v(" Is the Effective Date on or no more than 7 days after the Closing Date (Purchase)? ")]),_c('div',{staticClass:"mb-2"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0 ml-3"},[_c('span',{staticClass:"radio-text"},[_vm._v("Yes")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hazardBinderRecieved.isEffectiveDateOnOrNoMoreThan7Days.mode),expression:"hazardBinderRecieved.isEffectiveDateOnOrNoMoreThan7Days.mode"}],attrs:{"type":"radio","value":"Yes","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.hazardBinderRecieved.isEffectiveDateOnOrNoMoreThan7Days.mode,"Yes")},on:{"change":[function($event){return _vm.$set(_vm.hazardBinderRecieved.isEffectiveDateOnOrNoMoreThan7Days, "mode", "Yes")},function($event){_vm.isComplete = false;
                      _vm.handleChange(_vm.hazardBinderRecieved.isEffectiveDateOnOrNoMoreThan7Days.mode, _vm.hazardBinderRecievedData.isEffectiveDateOnOrNoMoreThan7Days.mode, 'Is the Effective Date on or no more than 7 days after the Closing Date (Purchase)?'); _vm.saveHazardRecievedTask(false)}],"click":function($event){_vm.hazardBinderRecieved.isEffectiveDateOnOrNoMoreThan7Days.revisionRequestedOn = null;
                      _vm.hazardBinderRecieved.isEffectiveDateOnOrNoMoreThan7Days.revisionCompletedOn = null; _vm.hazardBinderRecieved.isEffectiveDateOnOrNoMoreThan7Days.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("No")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hazardBinderRecieved.isEffectiveDateOnOrNoMoreThan7Days.mode),expression:"hazardBinderRecieved.isEffectiveDateOnOrNoMoreThan7Days.mode"}],attrs:{"type":"radio","value":"No","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.hazardBinderRecieved.isEffectiveDateOnOrNoMoreThan7Days.mode,"No")},on:{"change":[function($event){return _vm.$set(_vm.hazardBinderRecieved.isEffectiveDateOnOrNoMoreThan7Days, "mode", "No")},function($event){_vm.isComplete = false;
                      _vm.handleChange(_vm.hazardBinderRecieved.isEffectiveDateOnOrNoMoreThan7Days.mode, _vm.hazardBinderRecievedData.isEffectiveDateOnOrNoMoreThan7Days.mode, 'Is the Effective Date on or no more than 7 days after the Closing Date (Purchase)?'); _vm.saveHazardRecievedTask(false)}],"click":function($event){_vm.hazardBinderRecieved.isEffectiveDateOnOrNoMoreThan7Days.revisionRequestedOn = null;
                      _vm.hazardBinderRecieved.isEffectiveDateOnOrNoMoreThan7Days.revisionCompletedOn = null;_vm.hazardBinderRecieved.isEffectiveDateOnOrNoMoreThan7Days.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("NA")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hazardBinderRecieved.isEffectiveDateOnOrNoMoreThan7Days.mode),expression:"hazardBinderRecieved.isEffectiveDateOnOrNoMoreThan7Days.mode"}],attrs:{"type":"radio","value":"NA","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.hazardBinderRecieved.isEffectiveDateOnOrNoMoreThan7Days.mode,"NA")},on:{"change":[function($event){return _vm.$set(_vm.hazardBinderRecieved.isEffectiveDateOnOrNoMoreThan7Days, "mode", "NA")},function($event){_vm.isComplete = false;
                      _vm.handleChange(_vm.hazardBinderRecieved.isEffectiveDateOnOrNoMoreThan7Days.mode, _vm.hazardBinderRecievedData.isEffectiveDateOnOrNoMoreThan7Days.mode, 'Is the Effective Date on or no more than 7 days after the Closing Date (Purchase)?'); _vm.saveHazardRecievedTask(false)}],"click":function($event){_vm.hazardBinderRecieved.isEffectiveDateOnOrNoMoreThan7Days.revisionRequestedOn = null;
                      _vm.hazardBinderRecieved.isEffectiveDateOnOrNoMoreThan7Days.revisionCompletedOn = null; _vm.hazardBinderRecieved.isEffectiveDateOnOrNoMoreThan7Days.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)})],1),_c('div',{staticClass:"row m-0"},[(_vm.hazardBinderRecieved.isEffectiveDateOnOrNoMoreThan7Days.mode == 'No')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('label',{staticClass:"label ml-3",attrs:{"for":"initial"}},[_vm._v("Revision Requested")]),_c('div',{staticClass:"d-flex align-items-center ml-3"},[_c('datepicker',{class:[errors.length>0?'mx-input-error':''],attrs:{"disabled":_vm.isCompleted,"value-type":"format"},on:{"change":function($event){_vm.isComplete = false;
                      _vm.handleChange(_vm.hazardBinderRecieved.isEffectiveDateOnOrNoMoreThan7Days.revisionRequestedOn, _vm.hazardBinderRecievedData.isEffectiveDateOnOrNoMoreThan7Days.revisionRequestedOn, 'Is the Effective Date on or no more than 7 days after the Closing Date (Purchase)? Revision Requested'); _vm.saveHazardRecievedTask(false)}},model:{value:(_vm.hazardBinderRecieved.isEffectiveDateOnOrNoMoreThan7Days.revisionRequestedOn),callback:function ($$v) {_vm.$set(_vm.hazardBinderRecieved.isEffectiveDateOnOrNoMoreThan7Days, "revisionRequestedOn", $$v)},expression:"hazardBinderRecieved.isEffectiveDateOnOrNoMoreThan7Days.revisionRequestedOn"}})],1)]}}],null,true)})],1):_vm._e(),(_vm.hazardBinderRecieved.isEffectiveDateOnOrNoMoreThan7Days.mode == 'No')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('label',{staticClass:"label",attrs:{"for":"initial"}},[_vm._v("Revision Completed")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('datepicker',{class:[errors.length>0?'mx-input-error':''],attrs:{"disabled":_vm.isCompleted,"value-type":"format","disabled-date":function (date) { return date <= _vm.$moment(new Date(_vm.hazardBinderRecieved.isEffectiveDateOnOrNoMoreThan7Days.revisionRequestedOn).setDate(new Date(_vm.hazardBinderRecieved.isEffectiveDateOnOrNoMoreThan7Days.revisionRequestedOn).getDate() - 1)); }},on:{"change":function($event){_vm.isComplete = false;
                    _vm.handleChange(_vm.hazardBinderRecieved.isEffectiveDateOnOrNoMoreThan7Days.revisionCompletedOn, _vm.hazardBinderRecievedData.isEffectiveDateOnOrNoMoreThan7Days.revisionCompletedOn, 'Is the Effective Date on or no more than 7 days after the Closing Date (Purchase)? Revision Completed'); _vm.saveHazardRecievedTask(false)}},model:{value:(_vm.hazardBinderRecieved.isEffectiveDateOnOrNoMoreThan7Days.revisionCompletedOn),callback:function ($$v) {_vm.$set(_vm.hazardBinderRecieved.isEffectiveDateOnOrNoMoreThan7Days, "revisionCompletedOn", $$v)},expression:"hazardBinderRecieved.isEffectiveDateOnOrNoMoreThan7Days.revisionCompletedOn"}})],1)]}}],null,true)})],1):_vm._e()]),(_vm.hazardBinderRecieved.isEffectiveDateOnOrNoMoreThan7Days.mode == 'NA')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 p-0"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label ml-3",attrs:{"for":"creditorName"}},[_vm._v("Add remarks")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hazardBinderRecieved.isEffectiveDateOnOrNoMoreThan7Days.nARemark),expression:"hazardBinderRecieved.isEffectiveDateOnOrNoMoreThan7Days.nARemark"}],staticClass:"login-input line_height_40 ml-3",attrs:{"type":"text","disabled":_vm.isCompleted,"name":"Add remarks"},domProps:{"value":(_vm.hazardBinderRecieved.isEffectiveDateOnOrNoMoreThan7Days.nARemark)},on:{"change":function($event){_vm.isComplete = false;
                  _vm.handleChange(_vm.hazardBinderRecieved.isEffectiveDateOnOrNoMoreThan7Days.nARemark, _vm.hazardBinderRecievedData.isEffectiveDateOnOrNoMoreThan7Days.nARemark, 'Is the Effective Date on or no more than 7 days after the Closing Date (Purchase)? Remarks'); _vm.saveHazardRecievedTask(false)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.hazardBinderRecieved.isEffectiveDateOnOrNoMoreThan7Days, "nARemark", $event.target.value)}}})])]):_vm._e()]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 p-0"},[_c('p',{staticClass:"content-text-black mt-1 mb-1"},[_c('span',[_vm._v("4.")]),_vm._v(" Is there at least 12 months of coverage reflected for Purchases? ")]),_c('div',{staticClass:"mb-2"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0 ml-3"},[_c('span',{staticClass:"radio-text"},[_vm._v("Yes")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hazardBinderRecieved.hasAtLeast12MonthsOfCoverageReflectedForPurchase.mode),expression:"hazardBinderRecieved.hasAtLeast12MonthsOfCoverageReflectedForPurchase.mode"}],attrs:{"type":"radio","value":"Yes","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.hazardBinderRecieved.hasAtLeast12MonthsOfCoverageReflectedForPurchase.mode,"Yes")},on:{"change":[function($event){return _vm.$set(_vm.hazardBinderRecieved.hasAtLeast12MonthsOfCoverageReflectedForPurchase, "mode", "Yes")},function($event){_vm.isComplete = false;
                  _vm.handleChange(_vm.hazardBinderRecieved.hasAtLeast12MonthsOfCoverageReflectedForPurchase.mode, _vm.hazardBinderRecievedData.hasAtLeast12MonthsOfCoverageReflectedForPurchase.mode, 'Is there at least 12 months of coverage reflected for Purchases?'); _vm.saveHazardRecievedTask(false)}],"click":function($event){_vm.hazardBinderRecieved.hasAtLeast12MonthsOfCoverageReflectedForPurchase.revisionRequestedOn = null;
                  _vm.hazardBinderRecieved.hasAtLeast12MonthsOfCoverageReflectedForPurchase.revisionCompletedOn = null;_vm.hazardBinderRecieved.hasAtLeast12MonthsOfCoverageReflectedForPurchase.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("No")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hazardBinderRecieved.hasAtLeast12MonthsOfCoverageReflectedForPurchase.mode),expression:"hazardBinderRecieved.hasAtLeast12MonthsOfCoverageReflectedForPurchase.mode"}],attrs:{"type":"radio","value":"No","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.hazardBinderRecieved.hasAtLeast12MonthsOfCoverageReflectedForPurchase.mode,"No")},on:{"change":[function($event){return _vm.$set(_vm.hazardBinderRecieved.hasAtLeast12MonthsOfCoverageReflectedForPurchase, "mode", "No")},function($event){_vm.isComplete = false;
                  _vm.handleChange(_vm.hazardBinderRecieved.hasAtLeast12MonthsOfCoverageReflectedForPurchase.mode, _vm.hazardBinderRecievedData.hasAtLeast12MonthsOfCoverageReflectedForPurchase.mode, 'Is there at least 12 months of coverage reflected for Purchases?'); _vm.saveHazardRecievedTask(false)}],"click":function($event){_vm.hazardBinderRecieved.hasAtLeast12MonthsOfCoverageReflectedForPurchase.revisionRequestedOn = null;
                  _vm.hazardBinderRecieved.hasAtLeast12MonthsOfCoverageReflectedForPurchase.revisionCompletedOn = null;_vm.hazardBinderRecieved.hasAtLeast12MonthsOfCoverageReflectedForPurchase.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("NA")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hazardBinderRecieved.hasAtLeast12MonthsOfCoverageReflectedForPurchase.mode),expression:"hazardBinderRecieved.hasAtLeast12MonthsOfCoverageReflectedForPurchase.mode"}],attrs:{"type":"radio","value":"NA","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.hazardBinderRecieved.hasAtLeast12MonthsOfCoverageReflectedForPurchase.mode,"NA")},on:{"change":[function($event){return _vm.$set(_vm.hazardBinderRecieved.hasAtLeast12MonthsOfCoverageReflectedForPurchase, "mode", "NA")},function($event){_vm.isComplete = false;
                  _vm.handleChange(_vm.hazardBinderRecieved.hasAtLeast12MonthsOfCoverageReflectedForPurchase.mode, _vm.hazardBinderRecievedData.hasAtLeast12MonthsOfCoverageReflectedForPurchase.mode, 'Is there at least 12 months of coverage reflected for Purchases?'); _vm.saveHazardRecievedTask(false)}],"click":function($event){_vm.hazardBinderRecieved.hasAtLeast12MonthsOfCoverageReflectedForPurchase.revisionRequestedOn = null;
                  _vm.hazardBinderRecieved.hasAtLeast12MonthsOfCoverageReflectedForPurchase.revisionCompletedOn = null;_vm.hazardBinderRecieved.hasAtLeast12MonthsOfCoverageReflectedForPurchase.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)})],1),_c('div',{staticClass:"row m-0"},[(_vm.hazardBinderRecieved.hasAtLeast12MonthsOfCoverageReflectedForPurchase.mode == 'No')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('label',{staticClass:"label ml-3",attrs:{"for":"initial"}},[_vm._v("Revision Requested")]),_c('div',{staticClass:"d-flex align-items-center ml-3"},[_c('datepicker',{class:[errors.length>0?'mx-input-error':''],attrs:{"disabled":_vm.isCompleted,"value-type":"format"},on:{"change":function($event){_vm.isComplete = false;
                  _vm.handleChange(_vm.hazardBinderRecieved.hasAtLeast12MonthsOfCoverageReflectedForPurchase.revisionRequestedOn, _vm.hazardBinderRecievedData.hasAtLeast12MonthsOfCoverageReflectedForPurchase.revisionRequestedOn, 'Is there at least 12 months of coverage reflected for Purchases? Revision Requested'); _vm.saveHazardRecievedTask(false)}},model:{value:(_vm.hazardBinderRecieved.hasAtLeast12MonthsOfCoverageReflectedForPurchase.revisionRequestedOn),callback:function ($$v) {_vm.$set(_vm.hazardBinderRecieved.hasAtLeast12MonthsOfCoverageReflectedForPurchase, "revisionRequestedOn", $$v)},expression:"hazardBinderRecieved.hasAtLeast12MonthsOfCoverageReflectedForPurchase.revisionRequestedOn"}})],1)]}}],null,true)})],1):_vm._e(),(_vm.hazardBinderRecieved.hasAtLeast12MonthsOfCoverageReflectedForPurchase.mode == 'No')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('label',{staticClass:"label",attrs:{"for":"initial"}},[_vm._v("Revision Completed")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('datepicker',{class:[errors.length>0?'mx-input-error':''],attrs:{"disabled":_vm.isCompleted,"value-type":"format","disabled-date":function (date) { return date <= _vm.$moment(new Date(_vm.hazardBinderRecieved.hasAtLeast12MonthsOfCoverageReflectedForPurchase.revisionRequestedOn).setDate(new Date(_vm.hazardBinderRecieved.hasAtLeast12MonthsOfCoverageReflectedForPurchase.revisionRequestedOn).getDate() -1)); }},on:{"change":function($event){_vm.isComplete = false;
                _vm.handleChange(_vm.hazardBinderRecieved.hasAtLeast12MonthsOfCoverageReflectedForPurchase.revisionCompletedOn, _vm.hazardBinderRecievedData.hasAtLeast12MonthsOfCoverageReflectedForPurchase.revisionCompletedOn, 'Is there at least 12 months of coverage reflected for Purchases? Revision Completed'); _vm.saveHazardRecievedTask(false)}},model:{value:(_vm.hazardBinderRecieved.hasAtLeast12MonthsOfCoverageReflectedForPurchase.revisionCompletedOn),callback:function ($$v) {_vm.$set(_vm.hazardBinderRecieved.hasAtLeast12MonthsOfCoverageReflectedForPurchase, "revisionCompletedOn", $$v)},expression:"hazardBinderRecieved.hasAtLeast12MonthsOfCoverageReflectedForPurchase.revisionCompletedOn"}})],1)]}}],null,true)})],1):_vm._e()]),(_vm.hazardBinderRecieved.hasAtLeast12MonthsOfCoverageReflectedForPurchase.mode == 'NA')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 p-0"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label ml-3",attrs:{"for":"creditorName"}},[_vm._v("Add remarks")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hazardBinderRecieved.hasAtLeast12MonthsOfCoverageReflectedForPurchase.nARemark),expression:"hazardBinderRecieved.hasAtLeast12MonthsOfCoverageReflectedForPurchase.nARemark"}],staticClass:"login-input line_height_40 ml-3",attrs:{"type":"text","disabled":_vm.isCompleted,"name":"Add remarks"},domProps:{"value":(_vm.hazardBinderRecieved.hasAtLeast12MonthsOfCoverageReflectedForPurchase.nARemark)},on:{"change":function($event){_vm.isComplete = false;
              _vm.handleChange(_vm.hazardBinderRecieved.hasAtLeast12MonthsOfCoverageReflectedForPurchase.nARemark, _vm.hazardBinderRecievedData.hasAtLeast12MonthsOfCoverageReflectedForPurchase.nARemark, 'Is there at least 12 months of coverage reflected for Purchases? Remarks'); _vm.saveHazardRecievedTask(false)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.hazardBinderRecieved.hasAtLeast12MonthsOfCoverageReflectedForPurchase, "nARemark", $event.target.value)}}})])]):_vm._e()]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 p-0"},[_c('p',{staticClass:"content-text-black mt-1 mb-1"},[_c('span',[_vm._v("5.")]),_vm._v(" Is there at least 60 days of coverage remaining for Refinances? ")]),_c('div',{staticClass:"mb-2"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0 ml-3"},[_c('span',{staticClass:"radio-text"},[_vm._v("Yes")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hazardBinderRecieved.atLeast60DaysOfCoverageRemainingForRefinance.mode),expression:"hazardBinderRecieved.atLeast60DaysOfCoverageRemainingForRefinance.mode"}],attrs:{"type":"radio","value":"yes","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.hazardBinderRecieved.atLeast60DaysOfCoverageRemainingForRefinance.mode,"yes")},on:{"change":[function($event){return _vm.$set(_vm.hazardBinderRecieved.atLeast60DaysOfCoverageRemainingForRefinance, "mode", "yes")},function($event){_vm.isComplete = false;
              _vm.handleChange(_vm.hazardBinderRecieved.atLeast60DaysOfCoverageRemainingForRefinance.mode, _vm.hazardBinderRecievedData.atLeast60DaysOfCoverageRemainingForRefinance.mode, 'Is there at least 60 days of coverage remaining for Refinances?'); _vm.saveHazardRecievedTask(false)}],"click":function($event){_vm.hazardBinderRecieved.atLeast60DaysOfCoverageRemainingForRefinance.revisionRequestedOn = null;
              _vm.hazardBinderRecieved.atLeast60DaysOfCoverageRemainingForRefinance.revisionCompletedOn = null;_vm.hazardBinderRecieved.atLeast60DaysOfCoverageRemainingForRefinance.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("No")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hazardBinderRecieved.atLeast60DaysOfCoverageRemainingForRefinance.mode),expression:"hazardBinderRecieved.atLeast60DaysOfCoverageRemainingForRefinance.mode"}],attrs:{"type":"radio","value":"No","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.hazardBinderRecieved.atLeast60DaysOfCoverageRemainingForRefinance.mode,"No")},on:{"change":[function($event){return _vm.$set(_vm.hazardBinderRecieved.atLeast60DaysOfCoverageRemainingForRefinance, "mode", "No")},function($event){_vm.isComplete = false;
              _vm.handleChange(_vm.hazardBinderRecieved.atLeast60DaysOfCoverageRemainingForRefinance.mode, _vm.hazardBinderRecievedData.atLeast60DaysOfCoverageRemainingForRefinance.mode, 'Is there at least 60 days of coverage remaining for Refinances?'); _vm.saveHazardRecievedTask(false)}],"click":function($event){_vm.hazardBinderRecieved.atLeast60DaysOfCoverageRemainingForRefinance.revisionRequestedOn = null;
              _vm.hazardBinderRecieved.atLeast60DaysOfCoverageRemainingForRefinance.revisionCompletedOn = null; _vm.hazardBinderRecieved.atLeast60DaysOfCoverageRemainingForRefinance.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("NA")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hazardBinderRecieved.atLeast60DaysOfCoverageRemainingForRefinance.mode),expression:"hazardBinderRecieved.atLeast60DaysOfCoverageRemainingForRefinance.mode"}],attrs:{"type":"radio","value":"NA","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.hazardBinderRecieved.atLeast60DaysOfCoverageRemainingForRefinance.mode,"NA")},on:{"change":[function($event){return _vm.$set(_vm.hazardBinderRecieved.atLeast60DaysOfCoverageRemainingForRefinance, "mode", "NA")},function($event){_vm.isComplete = false;
              _vm.handleChange(_vm.hazardBinderRecieved.atLeast60DaysOfCoverageRemainingForRefinance.mode, _vm.hazardBinderRecievedData.atLeast60DaysOfCoverageRemainingForRefinance.mode, 'Is there at least 60 days of coverage remaining for Refinances?'); _vm.saveHazardRecievedTask(false)}],"click":function($event){_vm.hazardBinderRecieved.atLeast60DaysOfCoverageRemainingForRefinance.revisionRequestedOn = null;
              _vm.hazardBinderRecieved.atLeast60DaysOfCoverageRemainingForRefinance.revisionCompletedOn = null;_vm.hazardBinderRecieved.atLeast60DaysOfCoverageRemainingForRefinance.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)})],1),_c('div',{staticClass:"row m-0"},[(_vm.hazardBinderRecieved.atLeast60DaysOfCoverageRemainingForRefinance.mode == 'No')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('label',{staticClass:"label ml-3",attrs:{"for":"initial"}},[_vm._v("Revision Requested")]),_c('div',{staticClass:"d-flex align-items-center ml-3"},[_c('datepicker',{class:[errors.length>0?'mx-input-error':''],attrs:{"disabled":_vm.isCompleted,"value-type":"format"},on:{"change":function($event){_vm.isComplete = false;
              _vm.handleChange(_vm.hazardBinderRecieved.atLeast60DaysOfCoverageRemainingForRefinance.revisionRequestedOn, _vm.hazardBinderRecievedData.atLeast60DaysOfCoverageRemainingForRefinance.revisionRequestedOn, 'Is there at least 60 days of coverage remaining for Refinances? Revision Requested'); _vm.saveHazardRecievedTask(false)}},model:{value:(_vm.hazardBinderRecieved.atLeast60DaysOfCoverageRemainingForRefinance.revisionRequestedOn),callback:function ($$v) {_vm.$set(_vm.hazardBinderRecieved.atLeast60DaysOfCoverageRemainingForRefinance, "revisionRequestedOn", $$v)},expression:"hazardBinderRecieved.atLeast60DaysOfCoverageRemainingForRefinance.revisionRequestedOn"}})],1)]}}],null,true)})],1):_vm._e(),(_vm.hazardBinderRecieved.atLeast60DaysOfCoverageRemainingForRefinance.mode == 'No')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('label',{staticClass:"label ml-3",attrs:{"for":"initial"}},[_vm._v("Revision Completed")]),_c('div',{staticClass:"d-flex align-items-center ml-3"},[_c('datepicker',{class:[errors.length>0?'mx-input-error':''],attrs:{"disabled":_vm.isCompleted,"value-type":"format","disabled-date":function (date) { return date <= _vm.$moment(new Date(_vm.hazardBinderRecieved.atLeast60DaysOfCoverageRemainingForRefinance.revisionRequestedOn).setDate(new Date(_vm.hazardBinderRecieved.atLeast60DaysOfCoverageRemainingForRefinance.revisionRequestedOn).getDate() -1)); }},on:{"change":function($event){_vm.isComplete = false;
            _vm.handleChange(_vm.hazardBinderRecieved.atLeast60DaysOfCoverageRemainingForRefinance.revisionCompletedOn, _vm.hazardBinderRecievedData.atLeast60DaysOfCoverageRemainingForRefinance.revisionCompletedOn, 'Is there at least 60 days of coverage remaining for Refinances? Revision Completed'); _vm.saveHazardRecievedTask(false)}},model:{value:(_vm.hazardBinderRecieved.atLeast60DaysOfCoverageRemainingForRefinance.revisionCompletedOn),callback:function ($$v) {_vm.$set(_vm.hazardBinderRecieved.atLeast60DaysOfCoverageRemainingForRefinance, "revisionCompletedOn", $$v)},expression:"hazardBinderRecieved.atLeast60DaysOfCoverageRemainingForRefinance.revisionCompletedOn"}})],1)]}}],null,true)})],1):_vm._e()]),(_vm.hazardBinderRecieved.atLeast60DaysOfCoverageRemainingForRefinance.mode == 'NA')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 p-0"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label ml-3",attrs:{"for":"creditorName"}},[_vm._v("Add remarks")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hazardBinderRecieved.atLeast60DaysOfCoverageRemainingForRefinance.nARemark),expression:"hazardBinderRecieved.atLeast60DaysOfCoverageRemainingForRefinance.nARemark"}],staticClass:"login-input line_height_40 ml-3",attrs:{"type":"text","disabled":_vm.isCompleted,"name":"Add remarks"},domProps:{"value":(_vm.hazardBinderRecieved.atLeast60DaysOfCoverageRemainingForRefinance.nARemark)},on:{"change":function($event){_vm.isComplete = false;
          _vm.handleChange(_vm.hazardBinderRecieved.atLeast60DaysOfCoverageRemainingForRefinance.nARemark, _vm.hazardBinderRecievedData.atLeast60DaysOfCoverageRemainingForRefinance.nARemark, 'Is there at least 60 days of coverage remaining for Refinances? Remarks'); _vm.saveHazardRecievedTask(false)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.hazardBinderRecieved.atLeast60DaysOfCoverageRemainingForRefinance, "nARemark", $event.target.value)}}})])]):_vm._e()]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 p-0"},[_c('p',{staticClass:"content-text-black mt-1 mb-1"},[_c('span',[_vm._v("6.")]),_vm._v(" Is the Lender’s Mortgagee Clause and Loan Number accurately reflected? ")]),_c('div',{staticClass:"mb-2"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0 ml-3"},[_c('span',{staticClass:"radio-text"},[_vm._v("Yes")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hazardBinderRecieved.isMortgageClauseAndLoanNumberAccuratelyReflected.mode),expression:"hazardBinderRecieved.isMortgageClauseAndLoanNumberAccuratelyReflected.mode"}],attrs:{"type":"radio","value":"Yes","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.hazardBinderRecieved.isMortgageClauseAndLoanNumberAccuratelyReflected.mode,"Yes")},on:{"change":[function($event){return _vm.$set(_vm.hazardBinderRecieved.isMortgageClauseAndLoanNumberAccuratelyReflected, "mode", "Yes")},function($event){_vm.isComplete = false;
          _vm.handleChange(_vm.hazardBinderRecieved.isMortgageClauseAndLoanNumberAccuratelyReflected.mode, _vm.hazardBinderRecievedData.isMortgageClauseAndLoanNumberAccuratelyReflected.mode, 'Is the Lender’s Mortgagee Clause and Loan Number accurately reflected?'); _vm.saveHazardRecievedTask(false)}],"click":function($event){_vm.hazardBinderRecieved.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionRequestedOn = null;
          _vm.hazardBinderRecieved.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionCompletedOn = null; _vm.hazardBinderRecieved.isMortgageClauseAndLoanNumberAccuratelyReflected.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("No")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hazardBinderRecieved.isMortgageClauseAndLoanNumberAccuratelyReflected.mode),expression:"hazardBinderRecieved.isMortgageClauseAndLoanNumberAccuratelyReflected.mode"}],attrs:{"type":"radio","value":"No","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.hazardBinderRecieved.isMortgageClauseAndLoanNumberAccuratelyReflected.mode,"No")},on:{"change":[function($event){return _vm.$set(_vm.hazardBinderRecieved.isMortgageClauseAndLoanNumberAccuratelyReflected, "mode", "No")},function($event){_vm.isComplete = false;
          _vm.handleChange(_vm.hazardBinderRecieved.isMortgageClauseAndLoanNumberAccuratelyReflected.mode, _vm.hazardBinderRecievedData.isMortgageClauseAndLoanNumberAccuratelyReflected.mode, 'Is the Lender’s Mortgagee Clause and Loan Number accurately reflected?'); _vm.saveHazardRecievedTask(false)}],"click":function($event){_vm.hazardBinderRecieved.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionRequestedOn = null;
          _vm.hazardBinderRecieved.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionCompletedOn = null; _vm.hazardBinderRecieved.isMortgageClauseAndLoanNumberAccuratelyReflected.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("NA")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hazardBinderRecieved.isMortgageClauseAndLoanNumberAccuratelyReflected.mode),expression:"hazardBinderRecieved.isMortgageClauseAndLoanNumberAccuratelyReflected.mode"}],attrs:{"type":"radio","value":"NA","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.hazardBinderRecieved.isMortgageClauseAndLoanNumberAccuratelyReflected.mode,"NA")},on:{"change":[function($event){return _vm.$set(_vm.hazardBinderRecieved.isMortgageClauseAndLoanNumberAccuratelyReflected, "mode", "NA")},function($event){_vm.isComplete = false;
          _vm.handleChange(_vm.hazardBinderRecieved.isMortgageClauseAndLoanNumberAccuratelyReflected.mode, _vm.hazardBinderRecievedData.isMortgageClauseAndLoanNumberAccuratelyReflected.mode, 'Is the Lender’s Mortgagee Clause and Loan Number accurately reflected?'); _vm.saveHazardRecievedTask(false)}],"click":function($event){_vm.hazardBinderRecieved.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionRequestedOn = null;
          _vm.hazardBinderRecieved.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionCompletedOn = null; _vm.hazardBinderRecieved.isMortgageClauseAndLoanNumberAccuratelyReflected.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)})],1),_c('div',{staticClass:"row m-0"},[(_vm.hazardBinderRecieved.isMortgageClauseAndLoanNumberAccuratelyReflected.mode == 'No')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('label',{staticClass:"label ml-3",attrs:{"for":"initial"}},[_vm._v("Revision Requested")]),_c('div',{staticClass:"d-flex align-items-center ml-3"},[_c('datepicker',{class:[errors.length>0?'mx-input-error':''],attrs:{"disabled":_vm.isCompleted,"value-type":"format"},on:{"change":function($event){_vm.isComplete = false;
          _vm.handleChange(_vm.hazardBinderRecieved.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionRequestedOn, _vm.hazardBinderRecievedData.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionRequestedOn, 'Is the Lender’s Mortgagee Clause and Loan Number accurately reflected? Revision Requested'); _vm.saveHazardRecievedTask(false)}},model:{value:(_vm.hazardBinderRecieved.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionRequestedOn),callback:function ($$v) {_vm.$set(_vm.hazardBinderRecieved.isMortgageClauseAndLoanNumberAccuratelyReflected, "revisionRequestedOn", $$v)},expression:"hazardBinderRecieved.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionRequestedOn"}})],1)]}}],null,true)})],1):_vm._e(),(_vm.hazardBinderRecieved.isMortgageClauseAndLoanNumberAccuratelyReflected.mode == 'No')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('label',{staticClass:"label",attrs:{"for":"initial"}},[_vm._v("Revision Completed")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('datepicker',{class:[errors.length>0?'mx-input-error':''],attrs:{"disabled":_vm.isCompleted,"value-type":"format","disabled-date":function (date) { return date <= _vm.$moment(new Date(_vm.hazardBinderRecieved.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionRequestedOn).setDate(new Date(_vm.hazardBinderRecieved.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionRequestedOn).getDate() -1 )); }},on:{"change":function($event){_vm.isComplete = false;
        _vm.handleChange(_vm.hazardBinderRecieved.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionCompletedOn, _vm.hazardBinderRecievedData.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionCompletedOn, 'Is the Lender’s Mortgagee Clause and Loan Number accurately reflected? Revision Completed'); _vm.saveHazardRecievedTask(false)}},model:{value:(_vm.hazardBinderRecieved.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionCompletedOn),callback:function ($$v) {_vm.$set(_vm.hazardBinderRecieved.isMortgageClauseAndLoanNumberAccuratelyReflected, "revisionCompletedOn", $$v)},expression:"hazardBinderRecieved.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionCompletedOn"}})],1)]}}],null,true)})],1):_vm._e()]),(_vm.hazardBinderRecieved.isMortgageClauseAndLoanNumberAccuratelyReflected.mode == 'NA')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 p-0"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label ml-3",attrs:{"for":"creditorName"}},[_vm._v("Add remarks")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hazardBinderRecieved.isMortgageClauseAndLoanNumberAccuratelyReflected.nARemark),expression:"hazardBinderRecieved.isMortgageClauseAndLoanNumberAccuratelyReflected.nARemark"}],staticClass:"login-input line_height_40 ml-3",attrs:{"type":"text","disabled":_vm.isCompleted,"name":"Add remarks"},domProps:{"value":(_vm.hazardBinderRecieved.isMortgageClauseAndLoanNumberAccuratelyReflected.nARemark)},on:{"change":function($event){_vm.isComplete = false;
      _vm.handleChange(_vm.hazardBinderRecieved.isMortgageClauseAndLoanNumberAccuratelyReflected.nARemark, _vm.hazardBinderRecievedData.isMortgageClauseAndLoanNumberAccuratelyReflected.nARemark, 'Is the Lender’s Mortgagee Clause and Loan Number accurately reflected? Remarks'); _vm.saveHazardRecievedTask(false)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.hazardBinderRecieved.isMortgageClauseAndLoanNumberAccuratelyReflected, "nARemark", $event.target.value)}}})])]):_vm._e()]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 p-0"},[_c('p',{staticClass:"content-text-black mt-1 mb-1"},[_c('span',[_vm._v("7.")]),_vm._v(" Is the Dwelling Coverage sufficient to cover the Loan Amount? ")]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0 ml-3"},[_c('span',{staticClass:"radio-text"},[_vm._v("Yes")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hazardBinderRecieved.isDwellingCoverageSufficientToCoverLoanAmountI.mode),expression:"hazardBinderRecieved.isDwellingCoverageSufficientToCoverLoanAmountI.mode"}],attrs:{"type":"radio","value":"Yes","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.hazardBinderRecieved.isDwellingCoverageSufficientToCoverLoanAmountI.mode,"Yes")},on:{"change":[function($event){return _vm.$set(_vm.hazardBinderRecieved.isDwellingCoverageSufficientToCoverLoanAmountI, "mode", "Yes")},function($event){_vm.isComplete = false;
      _vm.handleChange(_vm.hazardBinderRecieved.isDwellingCoverageSufficientToCoverLoanAmountI.mode, _vm.hazardBinderRecievedData.isDwellingCoverageSufficientToCoverLoanAmountI.mode, 'Is the Dwelling Coverage sufficient to cover the Loan Amount?'); _vm.saveHazardRecievedTask(false)}],"click":function($event){_vm.hazardBinderRecieved.isDwellingCoverageSufficientToCoverLoanAmountI.nARemark = null;
      _vm.hazardBinderRecieved.condoProperty.mode = null;_vm.hazardBinderRecieved.isCoverageSufficientToCoverAmountReflectedOnRCE.mode = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("No ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hazardBinderRecieved.isDwellingCoverageSufficientToCoverLoanAmountI.mode),expression:"hazardBinderRecieved.isDwellingCoverageSufficientToCoverLoanAmountI.mode"}],attrs:{"type":"radio","value":"No","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.hazardBinderRecieved.isDwellingCoverageSufficientToCoverLoanAmountI.mode,"No")},on:{"change":[function($event){return _vm.$set(_vm.hazardBinderRecieved.isDwellingCoverageSufficientToCoverLoanAmountI, "mode", "No")},function($event){_vm.isComplete = false;
      _vm.handleChange(_vm.hazardBinderRecieved.isDwellingCoverageSufficientToCoverLoanAmountI.mode, _vm.hazardBinderRecievedData.isDwellingCoverageSufficientToCoverLoanAmountI.mode, 'Is the Dwelling Coverage sufficient to cover the Loan Amount?'); _vm.saveHazardRecievedTask(false)}],"click":function($event){_vm.hazardBinderRecieved.isDwellingCoverageSufficientToCoverLoanAmountI.nARemark = null;
      _vm.hazardBinderRecieved.condoProperty.mode = null;_vm.hazardBinderRecieved.isCoverageSufficientToCoverAmountReflectedOnRCE.mode = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("NA")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hazardBinderRecieved.isDwellingCoverageSufficientToCoverLoanAmountI.mode),expression:"hazardBinderRecieved.isDwellingCoverageSufficientToCoverLoanAmountI.mode"}],attrs:{"type":"radio","value":"NA","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.hazardBinderRecieved.isDwellingCoverageSufficientToCoverLoanAmountI.mode,"NA")},on:{"change":[function($event){return _vm.$set(_vm.hazardBinderRecieved.isDwellingCoverageSufficientToCoverLoanAmountI, "mode", "NA")},function($event){_vm.isComplete = false;
      _vm.handleChange(_vm.hazardBinderRecieved.isDwellingCoverageSufficientToCoverLoanAmountI.mode, _vm.hazardBinderRecievedData.isDwellingCoverageSufficientToCoverLoanAmountI.mode, 'Is the Dwelling Coverage sufficient to cover the Loan Amount?'); _vm.saveHazardRecievedTask(false)}],"click":function($event){_vm.hazardBinderRecieved.isDwellingCoverageSufficientToCoverLoanAmountI.nARemark = null;
      _vm.hazardBinderRecieved.condoProperty.mode = null;_vm.hazardBinderRecieved.isCoverageSufficientToCoverAmountReflectedOnRCE.mode = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),(_vm.hazardBinderRecieved.isDwellingCoverageSufficientToCoverLoanAmountI.mode == 'No')?_c('div',[_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 p-0"},[_c('p',{staticClass:"content-text-black mt-1 mb-1 ml-2"},[_vm._v("Is property a Condo? ")]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0 ml-3"},[_c('span',{staticClass:"radio-text"},[_vm._v(" Yes")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hazardBinderRecieved.condoProperty.mode),expression:"hazardBinderRecieved.condoProperty.mode"}],attrs:{"type":"radio","value":"Yes","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.hazardBinderRecieved.condoProperty.mode,"Yes")},on:{"change":[function($event){return _vm.$set(_vm.hazardBinderRecieved.condoProperty, "mode", "Yes")},function($event){_vm.isComplete = false;
          _vm.handleChange(_vm.hazardBinderRecieved.condoProperty.mode, _vm.hazardBinderRecievedData.condoProperty.mode, 'Is property a Condo?'); _vm.saveHazardRecievedTask(false)}],"click":function($event){_vm.hazardBinderRecieved.condoProperty.revisionRequestedOn = null;
          _vm.hazardBinderRecieved.condoProperty.revisionCompletedOn = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v(" No")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hazardBinderRecieved.condoProperty.mode),expression:"hazardBinderRecieved.condoProperty.mode"}],attrs:{"type":"radio","value":"No","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.hazardBinderRecieved.condoProperty.mode,"No")},on:{"change":[function($event){return _vm.$set(_vm.hazardBinderRecieved.condoProperty, "mode", "No")},function($event){_vm.isComplete = false;
          _vm.handleChange(_vm.hazardBinderRecieved.condoProperty.mode, _vm.hazardBinderRecievedData.condoProperty.mode, 'Is property a Condo?'); _vm.saveHazardRecievedTask(false)}]}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)})],1),_c('div',{staticClass:"row m-0"},[(_vm.hazardBinderRecieved.condoProperty.mode == 'Yes')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('label',{staticClass:"label ml-3",attrs:{"for":"initial"}},[_vm._v("Revision Requested")]),_c('div',{staticClass:"d-flex align-items-center ml-3"},[_c('datepicker',{class:[errors.length>0?'mx-input-error':''],attrs:{"disabled":_vm.isCompleted,"value-type":"format"},on:{"change":function($event){_vm.isComplete = false;
          _vm.handleChange(_vm.hazardBinderRecieved.condoProperty.revisionRequestedOn, _vm.hazardBinderRecievedData.condoProperty.revisionRequestedOn, 'Is property a Condo? Revision Requested'); _vm.saveHazardRecievedTask(false)}},model:{value:(_vm.hazardBinderRecieved.condoProperty.revisionRequestedOn),callback:function ($$v) {_vm.$set(_vm.hazardBinderRecieved.condoProperty, "revisionRequestedOn", $$v)},expression:"hazardBinderRecieved.condoProperty.revisionRequestedOn"}})],1)]}}],null,true)})],1):_vm._e(),(_vm.hazardBinderRecieved.condoProperty.mode == 'Yes')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('label',{staticClass:"label",attrs:{"for":"initial"}},[_vm._v("Revision Completed")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('datepicker',{class:[errors.length>0?'mx-input-error':''],attrs:{"disabled":_vm.isCompleted,"value-type":"format","disabled-date":function (date) { return date <= _vm.$moment(new Date(_vm.hazardBinderRecieved.condoProperty.revisionRequestedOn).setDate(new Date(_vm.hazardBinderRecieved.condoProperty.revisionRequestedOn).getDate() -1 )); }},on:{"change":function($event){_vm.isComplete = false;
        _vm.handleChange(_vm.hazardBinderRecieved.condoProperty.revisionCompletedOn, _vm.hazardBinderRecievedData.condoProperty.revisionCompletedOn, 'Is property a Condo? Revision Completed'); _vm.saveHazardRecievedTask(false)}},model:{value:(_vm.hazardBinderRecieved.condoProperty.revisionCompletedOn),callback:function ($$v) {_vm.$set(_vm.hazardBinderRecieved.condoProperty, "revisionCompletedOn", $$v)},expression:"hazardBinderRecieved.condoProperty.revisionCompletedOn"}})],1)]}}],null,true)})],1):_vm._e()]),(_vm.hazardBinderRecieved.condoProperty.mode == 'No')?_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 p-0"},[_c('p',{staticClass:"content-text-black mt-1 mb-1 ml-2"},[_vm._v("Is Dwelling Coverage sufficient to cover the amount reflected on the Replacement Cost Estimator (RCE)? ")]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0 ml-3"},[_c('span',{staticClass:"radio-text"},[_vm._v("Yes")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hazardBinderRecieved.isCoverageSufficientToCoverAmountReflectedOnRCE.mode),expression:"hazardBinderRecieved.isCoverageSufficientToCoverAmountReflectedOnRCE.mode"}],attrs:{"type":"radio","value":"Yes","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.hazardBinderRecieved.isCoverageSufficientToCoverAmountReflectedOnRCE.mode,"Yes")},on:{"change":[function($event){return _vm.$set(_vm.hazardBinderRecieved.isCoverageSufficientToCoverAmountReflectedOnRCE, "mode", "Yes")},function($event){_vm.isComplete = false;
      _vm.handleChange(_vm.hazardBinderRecieved.isCoverageSufficientToCoverAmountReflectedOnRCE.mode, _vm.hazardBinderRecievedData.isCoverageSufficientToCoverAmountReflectedOnRCE.mode, 'is Dwelling Coverage sufficient to cover the amount reflected on the Replacement Cost Estimator (RCE)?'); _vm.saveHazardRecievedTask(false)}]}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("No ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hazardBinderRecieved.isCoverageSufficientToCoverAmountReflectedOnRCE.mode),expression:"hazardBinderRecieved.isCoverageSufficientToCoverAmountReflectedOnRCE.mode"}],attrs:{"type":"radio","value":"No","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.hazardBinderRecieved.isCoverageSufficientToCoverAmountReflectedOnRCE.mode,"No")},on:{"change":[function($event){return _vm.$set(_vm.hazardBinderRecieved.isCoverageSufficientToCoverAmountReflectedOnRCE, "mode", "No")},function($event){_vm.isComplete = false;
      _vm.handleChange(_vm.hazardBinderRecieved.isCoverageSufficientToCoverAmountReflectedOnRCE.mode, _vm.hazardBinderRecievedData.isCoverageSufficientToCoverAmountReflectedOnRCE.mode, 'is Dwelling Coverage sufficient to cover the amount reflected on the Replacement Cost Estimator (RCE)?'); _vm.saveHazardRecievedTask(false)}],"click":function($event){_vm.hazardBinderRecieved.isCoverageSufficientToCoverAmountReflectedOnRCE.revisionRequestedOn = null;
      _vm.hazardBinderRecieved.isCoverageSufficientToCoverAmountReflectedOnRCE.revisionCompletedOn = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('div',{staticClass:"row m-0"},[(_vm.hazardBinderRecieved.isCoverageSufficientToCoverAmountReflectedOnRCE.mode == 'No')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('label',{staticClass:"label ml-3",attrs:{"for":"initial"}},[_vm._v("Revision Requested")]),_c('div',{staticClass:"d-flex align-items-center ml-3"},[_c('datepicker',{class:[errors.length>0?'mx-input-error':''],attrs:{"disabled":_vm.isCompleted,"value-type":"format"},on:{"change":function($event){_vm.isComplete = false;
        _vm.handleChange(_vm.hazardBinderRecieved.isCoverageSufficientToCoverAmountReflectedOnRCE.revisionRequestedOn, _vm.hazardBinderRecievedData.isCoverageSufficientToCoverAmountReflectedOnRCE.revisionRequestedOn, 'is Dwelling Coverage sufficient to cover the amount reflected on the Replacement Cost Estimator (RCE)? Revision Requested'); _vm.saveHazardRecievedTask(false)}},model:{value:(_vm.hazardBinderRecieved.isCoverageSufficientToCoverAmountReflectedOnRCE.revisionRequestedOn),callback:function ($$v) {_vm.$set(_vm.hazardBinderRecieved.isCoverageSufficientToCoverAmountReflectedOnRCE, "revisionRequestedOn", $$v)},expression:"hazardBinderRecieved.isCoverageSufficientToCoverAmountReflectedOnRCE.revisionRequestedOn"}})],1)]}}],null,true)})],1):_vm._e(),(_vm.hazardBinderRecieved.isCoverageSufficientToCoverAmountReflectedOnRCE.mode == 'No')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('label',{staticClass:"label",attrs:{"for":"initial"}},[_vm._v("Revision Completed")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('datepicker',{class:[errors.length>0?'mx-input-error':''],attrs:{"disabled":_vm.isCompleted,"value-type":"format","disabled-date":function (date) { return date <= _vm.$moment(new Date(_vm.hazardBinderRecieved.isCoverageSufficientToCoverAmountReflectedOnRCE.revisionRequestedOn).setDate(new Date(_vm.hazardBinderRecieved.isCoverageSufficientToCoverAmountReflectedOnRCE.revisionRequestedOn).getDate() -1 )); }},on:{"change":function($event){_vm.isComplete = false;
      _vm.handleChange(_vm.hazardBinderRecieved.isCoverageSufficientToCoverAmountReflectedOnRCE.revisionCompletedOn, _vm.hazardBinderRecievedData.isCoverageSufficientToCoverAmountReflectedOnRCE.revisionCompletedOn, 'is Dwelling Coverage sufficient to cover the amount reflected on the Replacement Cost Estimator (RCE)? Revision Completed'); _vm.saveHazardRecievedTask(false)}},model:{value:(_vm.hazardBinderRecieved.isCoverageSufficientToCoverAmountReflectedOnRCE.revisionCompletedOn),callback:function ($$v) {_vm.$set(_vm.hazardBinderRecieved.isCoverageSufficientToCoverAmountReflectedOnRCE, "revisionCompletedOn", $$v)},expression:"hazardBinderRecieved.isCoverageSufficientToCoverAmountReflectedOnRCE.revisionCompletedOn"}})],1)]}}],null,true)})],1):_vm._e()])],1):_vm._e()]):_vm._e(),(_vm.hazardBinderRecieved.isDwellingCoverageSufficientToCoverLoanAmountI.mode == 'NA')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 p-0"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label ml-3",attrs:{"for":"creditorName"}},[_vm._v("Add remarks")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hazardBinderRecieved.isDwellingCoverageSufficientToCoverLoanAmountI.nARemark),expression:"hazardBinderRecieved.isDwellingCoverageSufficientToCoverLoanAmountI.nARemark"}],staticClass:"login-input line_height_40 ml-3",attrs:{"type":"text","disabled":_vm.isCompleted,"name":"Add remarks"},domProps:{"value":(_vm.hazardBinderRecieved.isDwellingCoverageSufficientToCoverLoanAmountI.nARemark)},on:{"change":function($event){_vm.isComplete = false;
      _vm.handleChange(_vm.hazardBinderRecieved.isDwellingCoverageSufficientToCoverLoanAmountI.nARemark, _vm.hazardBinderRecievedData.isDwellingCoverageSufficientToCoverLoanAmountI.nARemark, 'Is the Dwelling Coverage sufficient to cover the Loan Amount? Remarks'); _vm.saveHazardRecievedTask(false)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.hazardBinderRecieved.isDwellingCoverageSufficientToCoverLoanAmountI, "nARemark", $event.target.value)}}})])]):_vm._e()],1),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 p-0"},[_c('p',{staticClass:"content-text-black mt-1 mb-1"},[_c('span',[_vm._v("8.")]),_vm._v(" Is the monthly payment correctly reflected on the 1003? ")]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0 ml-3"},[_c('span',{staticClass:"radio-text"},[_vm._v("Yes")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hazardBinderRecieved.monthlyPaymentOn1003CorrectlyReflected.mode),expression:"hazardBinderRecieved.monthlyPaymentOn1003CorrectlyReflected.mode"}],attrs:{"type":"radio","value":"Yes","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.hazardBinderRecieved.monthlyPaymentOn1003CorrectlyReflected.mode,"Yes")},on:{"change":[function($event){return _vm.$set(_vm.hazardBinderRecieved.monthlyPaymentOn1003CorrectlyReflected, "mode", "Yes")},function($event){_vm.isComplete = false;
      _vm.handleChange(_vm.hazardBinderRecieved.monthlyPaymentOn1003CorrectlyReflected.mode, _vm.hazardBinderRecievedData.monthlyPaymentOn1003CorrectlyReflected.mode, 'Is the monthly payment correctly reflected on the 1003?'); _vm.saveHazardRecievedTask(false)}],"click":function($event){_vm.hazardBinderRecieved.monthlyPaymentOn1003CorrectlyReflected.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("No")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hazardBinderRecieved.monthlyPaymentOn1003CorrectlyReflected.mode),expression:"hazardBinderRecieved.monthlyPaymentOn1003CorrectlyReflected.mode"}],attrs:{"type":"radio","value":"No","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.hazardBinderRecieved.monthlyPaymentOn1003CorrectlyReflected.mode,"No")},on:{"change":[function($event){return _vm.$set(_vm.hazardBinderRecieved.monthlyPaymentOn1003CorrectlyReflected, "mode", "No")},function($event){_vm.isComplete = false;
      _vm.handleChange(_vm.hazardBinderRecieved.monthlyPaymentOn1003CorrectlyReflected.mode, _vm.hazardBinderRecievedData.monthlyPaymentOn1003CorrectlyReflected.mode, 'Is the monthly payment correctly reflected on the 1003?'); _vm.saveHazardRecievedTask(false)}],"click":function($event){_vm.hazardBinderRecieved.monthlyPaymentOn1003CorrectlyReflected.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("NA")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hazardBinderRecieved.monthlyPaymentOn1003CorrectlyReflected.mode),expression:"hazardBinderRecieved.monthlyPaymentOn1003CorrectlyReflected.mode"}],attrs:{"type":"radio","value":"NA","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.hazardBinderRecieved.monthlyPaymentOn1003CorrectlyReflected.mode,"NA")},on:{"change":[function($event){return _vm.$set(_vm.hazardBinderRecieved.monthlyPaymentOn1003CorrectlyReflected, "mode", "NA")},function($event){_vm.isComplete = false;
      _vm.handleChange(_vm.hazardBinderRecieved.monthlyPaymentOn1003CorrectlyReflected.mode, _vm.hazardBinderRecievedData.monthlyPaymentOn1003CorrectlyReflected.mode, 'Is the monthly payment correctly reflected on the 1003?'); _vm.saveHazardRecievedTask(false)}],"click":function($event){_vm.hazardBinderRecieved.monthlyPaymentOn1003CorrectlyReflected.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)})],1),(_vm.hazardBinderRecieved.monthlyPaymentOn1003CorrectlyReflected.mode == 'NA')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 p-0"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label ml-3",attrs:{"for":"creditorName"}},[_vm._v("Add remarks")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hazardBinderRecieved.monthlyPaymentOn1003CorrectlyReflected.nARemark),expression:"hazardBinderRecieved.monthlyPaymentOn1003CorrectlyReflected.nARemark"}],staticClass:"login-input line_height_40 ml-3",attrs:{"type":"text","disabled":_vm.isCompleted,"name":"Add remarks"},domProps:{"value":(_vm.hazardBinderRecieved.monthlyPaymentOn1003CorrectlyReflected.nARemark)},on:{"change":function($event){_vm.isComplete = false;
      _vm.handleChange(_vm.hazardBinderRecieved.monthlyPaymentOn1003CorrectlyReflected.nARemark, _vm.hazardBinderRecievedData.monthlyPaymentOn1003CorrectlyReflected.nARemark, 'Is the monthly payment correctly reflected on the 1003? Remarks'); _vm.saveHazardRecievedTask(false)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.hazardBinderRecieved.monthlyPaymentOn1003CorrectlyReflected, "nARemark", $event.target.value)}}})])]):_vm._e(),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 p-0"},[_c('p',{staticClass:"content-text-black mt-1 mb-1"},[_c('span',[_vm._v("9.")]),_vm._v(" Has the Invoice been provided? ")]),_c('div',{staticClass:"mb-2"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0 ml-3"},[_c('span',{staticClass:"radio-text"},[_vm._v("Yes")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hazardBinderRecieved.hasInvoiceBeenProvided.mode),expression:"hazardBinderRecieved.hasInvoiceBeenProvided.mode"}],attrs:{"type":"radio","value":"Yes","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.hazardBinderRecieved.hasInvoiceBeenProvided.mode,"Yes")},on:{"change":[function($event){return _vm.$set(_vm.hazardBinderRecieved.hasInvoiceBeenProvided, "mode", "Yes")},function($event){_vm.isComplete = false;
        _vm.handleChange(_vm.hazardBinderRecieved.hasInvoiceBeenProvided.mode, _vm.hazardBinderRecievedData.hasInvoiceBeenProvided.mode, 'Has the Invoice been provided?'); _vm.saveHazardRecievedTask(false)}],"click":function($event){_vm.hazardBinderRecieved.hasInvoiceBeenProvided.revisionRequestedOn = null;
        _vm.hazardBinderRecieved.hasInvoiceBeenProvided.revisionCompletedOn = null; _vm.hazardBinderRecieved.hasInvoiceBeenProvided.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("No")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hazardBinderRecieved.hasInvoiceBeenProvided.mode),expression:"hazardBinderRecieved.hasInvoiceBeenProvided.mode"}],attrs:{"type":"radio","value":"No","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.hazardBinderRecieved.hasInvoiceBeenProvided.mode,"No")},on:{"change":[function($event){return _vm.$set(_vm.hazardBinderRecieved.hasInvoiceBeenProvided, "mode", "No")},function($event){_vm.isComplete = false;
        _vm.handleChange(_vm.hazardBinderRecieved.hasInvoiceBeenProvided.mode, _vm.hazardBinderRecievedData.hasInvoiceBeenProvided.mode, 'Has the Invoice been provided?'); _vm.saveHazardRecievedTask(false)}],"click":function($event){_vm.hazardBinderRecieved.hasInvoiceBeenProvided.revisionRequestedOn = null;
        _vm.hazardBinderRecieved.hasInvoiceBeenProvided.revisionCompletedOn = null; _vm.hazardBinderRecieved.hasInvoiceBeenProvided.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("NA")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hazardBinderRecieved.hasInvoiceBeenProvided.mode),expression:"hazardBinderRecieved.hasInvoiceBeenProvided.mode"}],attrs:{"type":"radio","value":"NA","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.hazardBinderRecieved.hasInvoiceBeenProvided.mode,"NA")},on:{"change":[function($event){return _vm.$set(_vm.hazardBinderRecieved.hasInvoiceBeenProvided, "mode", "NA")},function($event){_vm.isComplete = false;
        _vm.handleChange(_vm.hazardBinderRecieved.hasInvoiceBeenProvided.mode, _vm.hazardBinderRecievedData.hasInvoiceBeenProvided.mode, 'Has the Invoice been provided?'); _vm.saveHazardRecievedTask(false)}],"click":function($event){_vm.hazardBinderRecieved.hasInvoiceBeenProvided.revisionRequestedOn = null;
        _vm.hazardBinderRecieved.hasInvoiceBeenProvided.revisionCompletedOn = null; _vm.hazardBinderRecieved.hasInvoiceBeenProvided.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)})],1),_c('div',{staticClass:"row m-0"},[(_vm.hazardBinderRecieved.hasInvoiceBeenProvided.mode == 'No')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('label',{staticClass:"label ml-3",attrs:{"for":"initial"}},[_vm._v("Revision Requested")]),_c('div',{staticClass:"d-flex align-items-center ml-3"},[_c('datepicker',{class:[errors.length>0?'mx-input-error':''],attrs:{"disabled":_vm.isCompleted,"value-type":"format"},on:{"change":function($event){_vm.isComplete = false;
        _vm.handleChange(_vm.hazardBinderRecieved.hasInvoiceBeenProvided.revisionRequestedOn, _vm.hazardBinderRecievedData.hasInvoiceBeenProvided.revisionRequestedOn, 'Has the Invoice been provided? Revision Requested'); _vm.saveHazardRecievedTask(false)}},model:{value:(_vm.hazardBinderRecieved.hasInvoiceBeenProvided.revisionRequestedOn),callback:function ($$v) {_vm.$set(_vm.hazardBinderRecieved.hasInvoiceBeenProvided, "revisionRequestedOn", $$v)},expression:"hazardBinderRecieved.hasInvoiceBeenProvided.revisionRequestedOn"}})],1)]}}],null,true)})],1):_vm._e(),(_vm.hazardBinderRecieved.hasInvoiceBeenProvided.mode == 'No')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('label',{staticClass:"label",attrs:{"for":"initial"}},[_vm._v("Revision Completed")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('datepicker',{class:[errors.length>0?'mx-input-error':''],attrs:{"disabled":_vm.isCompleted,"value-type":"format","disabled-date":function (date) { return date <= _vm.$moment(new Date(_vm.hazardBinderRecieved.hasInvoiceBeenProvided.revisionRequestedOn).setDate(new Date(_vm.hazardBinderRecieved.hasInvoiceBeenProvided.revisionRequestedOn).getDate() -1)); }},on:{"change":function($event){_vm.isComplete = false;
      _vm.handleChange(_vm.hazardBinderRecieved.hasInvoiceBeenProvided.revisionCompletedOn, _vm.hazardBinderRecievedData.hasInvoiceBeenProvided.revisionCompletedOn, 'Has the Invoice been provided? Revision Completed'); _vm.saveHazardRecievedTask(false)}},model:{value:(_vm.hazardBinderRecieved.hasInvoiceBeenProvided.revisionCompletedOn),callback:function ($$v) {_vm.$set(_vm.hazardBinderRecieved.hasInvoiceBeenProvided, "revisionCompletedOn", $$v)},expression:"hazardBinderRecieved.hasInvoiceBeenProvided.revisionCompletedOn"}})],1)]}}],null,true)})],1):_vm._e()]),(_vm.hazardBinderRecieved.hasInvoiceBeenProvided.mode == 'NA')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 p-0"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label ml-3",attrs:{"for":"creditorName"}},[_vm._v("Add remarks")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hazardBinderRecieved.hasInvoiceBeenProvided.nARemark),expression:"hazardBinderRecieved.hasInvoiceBeenProvided.nARemark"}],staticClass:"login-input line_height_40 ml-3",attrs:{"type":"text","disabled":_vm.isCompleted,"name":"Add remarks"},domProps:{"value":(_vm.hazardBinderRecieved.hasInvoiceBeenProvided.nARemark)},on:{"change":function($event){_vm.isComplete = false;
      _vm.handleChange(_vm.hazardBinderRecieved.hasInvoiceBeenProvided.nARemark, _vm.hazardBinderRecievedData.hasInvoiceBeenProvided.nARemark, 'Has the Invoice been provided? Remarks'); _vm.saveHazardRecievedTask(false)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.hazardBinderRecieved.hasInvoiceBeenProvided, "nARemark", $event.target.value)}}})])]):_vm._e()])])])])]),_c('hr',{staticClass:"loan-process-border-top"}),_c('div',{staticClass:"d-flex align-items-center justify-content-end form-group"},[(_vm.error.show)?_c('span',{staticClass:"login-error show mr-2"},[_vm._v(_vm._s(_vm.error.msg))]):_vm._e(),_c('button',{staticClass:"tmpco-btn tmpco-btn__theme",class:[ _vm.isCompleted ? 'btn-upload cursor-pointer cursor-not-allow'
  : 'btn-upload cursor-pointer' ],attrs:{"type":"submit","disabled":_vm.isCompleted},on:{"click":function($event){return _vm.$modal.show('hazardReceivedConfirmationModal')}}},[_vm._v(" "+_vm._s(_vm.isCompleted ? "Completed" : "Complete")+" ")])]),_c('modal',{staticClass:"modal",attrs:{"centered":"","name":"hazardReceivedConfirmationModal","hide-footer":true}},[_c('div',{staticClass:"modal--md-regular"},[_c('div',{staticClass:"tmpco-modal__content"},[_c('div',{staticClass:"tmpco-modal__header"},[_c('p',{staticClass:"tmpco-modal__title"},[_vm._v(" Hazard Binder Received")]),_c('button',{staticClass:"tmpco-modal__close",attrs:{"type":"button","data-dismiss":"modal"},on:{"click":function($event){return _vm.$modal.hide('hazardReceivedConfirmationModal');}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"tmpco-modal__body"},[_c('div',{staticClass:"col-lg-12 col-xl-12"},[_c('p',{staticClass:"mt-3 mb-3 content-modal"},[_vm._v(" Are you sure you want to complete? ")])])]),_c('div',{staticClass:"tmpco-modal__footer"},[_c('button',{staticClass:"tmpco-btn tmpco-btn__sm-default mr-2",on:{"click":function($event){return _vm.$modal.hide('hazardReceivedConfirmationModal');}}},[_vm._v("No")]),_c('button',{staticClass:"tmpco-btn tmpco-btn__sm-theme",attrs:{"type":"submit"},on:{"click":function($event){_vm.isComplete = true;_vm.$modal.hide('hazardReceivedConfirmationModal')}}},[_vm._v("Yes")])])])])])],1)])])]}}])}),_c('ViewDocument',{ref:"ref",on:{"call-mount-funtion":function($event){return _vm.getHazardBinderReceived()}}}),_c('ReOrderTask',{ref:"refReOrder",on:{"refresh-task-after-reorder":function($event){_vm.getHazardBinderReceived();
      _vm.isOrderPlacedOnce = false;
      _vm.$emit('refresh-task-list', true);}}}),_c('follow-up-task',{ref:"refFollowUp",on:{"refresh-task-after-sening-mail":function($event){return _vm.getHazardBinderReceived()}}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"card-content-title mr-2 mb-0"},[_c('b',[_vm._v("Follow Up")])])}]

export { render, staticRenderFns }