
































































import { Component, Vue, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "../config";
import { authHeader } from "../services/auth";

@Component
export default class CommonNotifyOnRejection extends Vue {
  @Prop()
  public loanTxnId: any;
  @Prop()
  public activeStep: any;
  @Prop()
  public isForAgent: any;
  public isNotifiedOnce = false;

  public cancel() {
    try {
      this.$modal.hide("notifyBroker");
    } catch (error) {
      console.log(error);
    }
  }

  public async notifyBrokerAboutRejectedDoc() {
    try {
      if (this.isNotifiedOnce) return;
      const res = await Axios.get(
        BASE_API_URL +
          "loanChecklist/notifyBrokerAboutRejectedDoc/" +
          this.loanTxnId +
          "/" +
          this.activeStep,
        {
          headers: authHeader(),
        }
      );

      if (res.status == 201) {
        if (this.isForAgent) {
          this.$snotify.success("Agent has been Notified");
        } else {
          this.$snotify.success(res.data.message);
        }
        this.cancel();
        this.$emit("refresh-task");
      }
    } catch (error) {
      console.log(error);
    }
  }
}
