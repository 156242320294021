


import { Component, Vue, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL, EventBus } from "../../config";
import { authHeader } from "../../services/auth";
import ViewDocument from "../ViewDocument.vue";
import UploadedDocs from "./UploadedDocs.vue";
import MinimumCredit from "./MinimumCreditDocs.vue";
import ThirdParty from "./ThirdPartyDocs.vue";
import Conditions from "./ConditionDocs.vue";
import CommonNotifyOnRejection from "../../common-components/CommonNotifyOnRejection.vue";
// import Rejected from "./RejectedDocs.vue";
import DeletedDoc from "./DeletedDoc.vue";
import { DOCUMENT_CATEGORY } from "../../constants/constants";
@Component({
  components: {
    ViewDocument,
    UploadedDocs,
    MinimumCredit,
    ThirdParty,
    Conditions,
    // Rejected,
    DeletedDoc,
    CommonNotifyOnRejection,
  },
})
export default class Documents extends Vue {
  /* eslint-disable */
  @Prop()
  public documentValidation;
  public documents = [];
  public loanId: any = this.$route.query.id;
  public files = [];
  public isValid: any = false;
  public loanDetails: any = null;
  public hasSubmit = false;
  public isUploading = false;
  public docId = null;
  public documentCategory = DOCUMENT_CATEGORY;
  public document: any = {};
  public step =
    this.$route.name == "Loan" ||
    this.$route.name == "DisclosureSign" ||
    (this.$route.name == "LoanProcess" &&
      this.$store.state.sessionObj.userType === 2)
      ? 1
      : this.$route.name == "BorrowerLoanDetails"
      ? 2
      : 0;
  public alreadyNotified = true;
  public notifyBroker = false;
  public formData: any = new FormData();
  public inProcess = false;
  public isAdding = false;
  public isAdd: any = false;
  public addAttachment: any = false;
  public attachedDocDetails: any = null;
  public attachedDoc: any = null;
  public fileData: any = null;
  public fileToDisplay: any = null;
  public originalFilename: any = null;
  public fileType: any = null;
  public tabIndex = this.$route.query.conditionDoc
    ? this.$store.state.sessionObj.userType === 2
      ? 1
      : 0
    : 0;
  public isProcessorNotifiedOnce = false;

  public async addDocument() {
    try {
      this.fileData = null;
      this.callLoader(true, null);
      this.isAdding = true;
      if (this.isAdd) {
        this.$modal.show("attachment");
      }
      let body = {
        loanId: this.loanId,
        category: this.document.category,
        name: this.document.name,
        remark: this.document.remark,
        uploadedBy: this.document.uploadedBy,
      };

      this.inProcess = true;
      let res = await Axios.post(
        BASE_API_URL + "loanChecklist/addDocument",
        body,

        {
          headers: authHeader(),
        }
      );
      if ((res.status = 201)) {
        this.attachedDocDetails = res.data;
        this.isAdding = false;
        this.$snotify.success("Document has been Added.");
        this.inProcess = false;
        EventBus.$emit("refreshDocs");
        // await this.getAllDocuments();
        this.cancel();
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public cancel() {
    this.$modal.hide("addDocument");
    this.$modal.hide("notifyProcessor");
    this.document = {};
  }

  public async notifyProcessor() {
    try {
      if (this.isProcessorNotifiedOnce) return;
      let res = await Axios.get(
        BASE_API_URL +
          "loanChecklist/notifyProcessorAboutUploadedDocs/" +
          this.loanId +
          "/" +
          this.step,
        {
          headers: authHeader(),
        }
      );

      this.isProcessorNotifiedOnce = true;
      if (res.status == 201 || res.status == 202) {
        this.$snotify.success(res.data.message);
        this.cancel();
        await this.isProcessorNotifiedForAllDocs();
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async isProcessorNotifiedForAllDocs() {
    try {
      let res = await Axios.get(
        BASE_API_URL +
          "loanChecklist/isProcessorNotifiedForAllDocs/" +
          this.loanId +
          "/" +
          this.step,
        {
          headers: authHeader(),
        }
      );
      if (res.status == 201) {
        this.alreadyNotified = res.data.alreadyNotified;

        if (!this.alreadyNotified) this.isProcessorNotifiedOnce = false;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async isBrokerNotifiedForRejectedDocs() {
    try {
      let res = await Axios.get(
        BASE_API_URL +
          "loanChecklist/isBrokerNotifiedForRejectedDocs/" +
          this.loanId +
          "/" +
          null +
          "/" +
          this.step,
        {
          headers: authHeader(),
        }
      );
      if (res.status == 201) {
        this.notifyBroker = res.data.notifyBorker;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async uploadDocument(event) {
    try {
      this.isUploading = true;
      this.callLoader(true, null);
      let files = event.target.files;
      Array.from(files).forEach((f) => {
        this.formData.append("file", f);
      });

      this.formData.append("loanId", this.loanId);
      this.formData.append("uploadedBy", this.document.uploadedBy);
      this.formData.append("documentId", this.attachedDocDetails._id);

      let response = await Axios.post(
        BASE_API_URL + "loanChecklist/uploadAdditionalDocument",
        this.formData,
        {
          headers: authHeader(),
        }
      );
      if (response.data) {
        this.isUploading = false;
        //this.$snotify.success('Document Uploaded successfully')
        this.fileData = response.data.checklist;
        this.$modal.hide("upload-attachment");
        this.$snotify.success("Document Created successfully");
        this.formData = new FormData();
        this.tabIndex = 2;
        this.step = 2;
        EventBus.$emit("refreshDocs");
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async getFile() {
    try {
      this.fileToDisplay =
        this.fileData.files.length > 0 ? this.fileData.files[0].image : null;
      this.originalFilename =
        this.fileData.files.length > 0
          ? this.fileData.files[0].originalName
          : null;
      this.fileType =
        this.fileData.files.length > 0
          ? this.fileData.files[0].path.split(".").pop()
          : null;
      this.$modal.show("showAttachFileModal");
    } catch (error) {
      console.log(error);
    }
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  async mounted() {
    if (
      this.$route.name == "LoanProcess" &&
      this.$store.state.sessionObj.userType === 3
    )
      this.step = 0;

    if (
      this.$route.query.conditionDoc == "Yes" ||
      this.$route.name == "BorrowerDashboard"
    ) {
      this.step = 2;
    }

    if (this.$store.state.sessionObj.userType === 3) {
      if (this.step == 2 || this.step == 1)
        await this.isBrokerNotifiedForRejectedDocs();
      EventBus.$on("brokerNotifed", async () =>
        this.isBrokerNotifiedForRejectedDocs()
      );
    } else {
      await this.isProcessorNotifiedForAllDocs();
      EventBus.$on("processorNotifed", async () =>
        this.isProcessorNotifiedForAllDocs()
      );
    }
  }

  /* eslint-disable */
}
