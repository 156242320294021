import _ from "lodash";

export const sumObjectValues = (object, arrkey: string[])=> {
    let total = 0;
    try {
      if (!_.isEmpty(object))
        arrkey.length > 0
          ? arrkey.forEach(key =>
              Number(object[key]) ? (total += Number(object[key])) : ""
            )
          : Object.keys(object).forEach(key =>
              Number(object[key]) ? (total += Number(object[key])) : ""
            );
    } catch (err) {
      console.error(err);
    }
    return total.toFixed(2);
  }