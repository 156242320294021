


























































































import { Component, Vue, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "../../../config";
import { authHeader } from "../../../services/auth";
@Component
export default class CDRequest extends Vue {
  /* eslint-disable */
  public cdRequest: any = {};
  @Prop()
  public taskId: any;
  public loanId: any = this.$route.query.id;
  public error = false;
  @Prop()
  public isLoanInactive: any;
  public isTaskCompleted = false;
  @Prop()
  public isTaskDisabled: any;
  /**
   * Taking dsData variable for checking form data is changed or not only for comaprison
   */
  public dsData: any = {};
  public isOrderPlacedOnce = false
  /**************************************************************** */

  async getCDRequest() {
    this.callLoader(true, 1);
    try {
      let response = await Axios.get(
        BASE_API_URL +
          "processor/getCDRequest/" +
          this.loanId +
          "/" +
          this.taskId,
        { headers: authHeader() }
      );
      if (response.status === 201) {
        // this.cdRequest = response.data.cdRequest;
        this.isTaskCompleted = response.data.isCompleted;
        this.cdRequest = JSON.parse(JSON.stringify(response.data.cdRequest));
        this.dsData = JSON.parse(JSON.stringify(response.data.cdRequest));
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async checkSaveValidation() {
    try {
      if (
        !this.cdRequest.isCdOrdered &&
        this.cdRequest.isCdOrdered == this.dsData.isCdOrdered
      )
        return true;
      else return false;
    } catch (error) {
      console.log(error);
    }
  }

  public async saveCDRequest(isComplete) {
    if(this.isOrderPlacedOnce) return
    this.callLoader(true, 2);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getCDRequest();
        return;
      }
      if (!isComplete) {
        let notValid1 = await this.checkSaveValidation();
        if (notValid1) {
          this.callLoader(false, null);
          this.$snotify.info("No Changes");
          return;
        }
      }
      if (isComplete && !this.cdRequest.isCdOrdered) {
        this.error = true;
        this.callLoader(false, null);
        return;
      }
         if(isComplete) this.isOrderPlacedOnce = true;
      let response = await Axios.post(
        BASE_API_URL + "processor/saveCDRequest",
        {
          loanId: this.loanId,
          taskId: this.taskId,
          cdRequest: this.cdRequest,
          isComplete: isComplete,
          formData: this.dsData,
        },
        { headers: authHeader() }
      );
      if (!isComplete) {
        this.$snotify.success("CD Request Information has been saved");
      }
      if (response.status === 201 && isComplete)
        this.$snotify.success("CD Request Completed successfully.");
      if (isComplete) this.$emit("refresh-task-list", true);
      await this.getCDRequest();
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  public mounted() {
    this.getCDRequest();
  }
}
