

import Axios from "axios";
import { BASE_API_URL, EventBus } from "../../../config";
import { Component, Vue, Prop } from "vue-property-decorator";
import Address from "../urlaAddress.vue";
import { authHeader } from "../../../services/auth";
import { AppraisalReceivedChecklist } from "../../../models/workflow/appraisalReceived.model";
import CommonUserChoiceModal from "../../CommonUserChoiceModal.vue";
import {
  LoanAndPropertyInfo,
  OtherMortgage,
  Gift,
} from "../../../models/urla/loan-and-property.model";
import {
  LOAN_PURPOSE,
  PROPERTY_USAGE_TYPE,
  LOAN_TYPE,
  LIEN_PRIORITY_TYPE,
  RELATIONSHIP_VESTINGS,
  GIFT_ASSET_TYPE_SOURCE,
  GIFT_TYPE,
  LOAN_TERM,
  ESTATE_HELD,
  MORTGAGE_LIEN_TYPE,
  REFINANCE_TYPE,
  REO_INTENDED_OCCUPANCY,
  VA_TYPE,
  ATTACHMENT_TYPE,
} from "../../../constants/constants";
@Component({
  components: {
    Address,
    CommonUserChoiceModal,
  },
})
export default class LoanAndProperty extends Vue {
  /* eslint-disable */
  public loanPurpose = LOAN_PURPOSE;
  public propertyUsageType = PROPERTY_USAGE_TYPE;
  public mortgageAppliedFor = LOAN_TYPE;
  public giftAsset = GIFT_ASSET_TYPE_SOURCE;
  public asset = GIFT_TYPE;
  public loanTerm = LOAN_TERM;
  public mortgageLienType = MORTGAGE_LIEN_TYPE;
  public refinanceType = REFINANCE_TYPE;
  public vaType = VA_TYPE;
  public loanAndPropertyInfo: any = new LoanAndPropertyInfo();
  public loanAndPropertyData: any = new LoanAndPropertyInfo();
  public otherMortgage: any = new OtherMortgage();
  public gifts: any = new Gift();
  public loanType = null;
  public occupancy = REO_INTENDED_OCCUPANCY;
  public lenderPropertyInfo: any = null;
  public projectType: any = null;
  public pptVal: any = null;
  public downPaymentAmt: any = null;
  public downPaymentPercentage: any = null;
  public fundingFee: any = null;
  public count = 0;
  public changedValues: any = [];
  public attachmentType = ATTACHMENT_TYPE;
  @Prop()
  public validationData: any;
  @Prop()
  public isLoanInactive: any;
  public lastData: any;
  public propertyManufacturedHomePreviousData: any = null;
  // @Prop()
  // public isUpdating: any

  public addInputField(identifier, type, index) {
    if (identifier === "lien") {
      if (type === "add") {
        this.otherMortgage = new OtherMortgage();
        this.loanAndPropertyInfo.otherMortgage.push(this.otherMortgage);
      } else if (type === "del") {
        this.loanAndPropertyInfo.otherMortgage.splice(index, 1);
      }
    } else if (identifier === "gift") {
     // console.log("coming");
      if (type === "add") {
        this.gifts = new Gift();
        this.loanAndPropertyInfo.gifts.push(this.gifts);
      } else if (type === "del") {
        this.loanAndPropertyInfo.gifts.splice(index, 1);
      }
    }
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  public initializeError() {
    if (this.validationData) {
      this.validationData.loanNumberDuplicacy = false;
    }
  }

  public compareData() {
    if (
      this.propertyManufacturedHomePreviousData !=
      this.loanAndPropertyInfo.isPropertyManufacturedHome
    )
      this.openModal();
  }

  public hasAnychangedValues() {
    return this.changedValues;
  }

  public async getLoanAndPropertyInfo() {
    try {
    //  console.log("112......*******");
      this.callLoader(true, null);
      this.changedValues = [];
      let response = await Axios.post(
        BASE_API_URL + "urla/getLoanAndPropertyInfo",
        {
          loanId: this.$route.query.id,
          lastData: this.lastData,
        },
        {
          headers: authHeader(),
        }
      );
      if (response.status === 201) {
        EventBus.$emit("refesh-loaninfo");
        this.lastData = response.data;
        await this.assignData(response.data);

        this.count = 0;
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async assignData(data) {
    this.callLoader(true, null);
    try {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null || data[key] !== undefined) {
          this.loanAndPropertyInfo[key] = data[key];
        }
        if (key == "gifts" && data[key].length == 0) {
          this.loanAndPropertyInfo["gifts"].push(this.gifts);
        }
      });
      this.loanAndPropertyInfo = JSON.parse(
        JSON.stringify(this.loanAndPropertyInfo)
      );
      this.loanAndPropertyInfo.loanAmount = this.loanAndPropertyInfo.loanAmount
        ? Number(this.loanAndPropertyInfo.loanAmount)
        : 0;
      this.loanAndPropertyInfo.propertyValue = this.loanAndPropertyInfo
        .propertyValue
        ? Number(this.loanAndPropertyInfo.propertyValue)
        : 0;
      this.loanAndPropertyInfo.interestRate = this.loanAndPropertyInfo
        .interestRate
        ? Number(this.loanAndPropertyInfo.interestRate)
        : 0;
      this.loanAndPropertyInfo.expectedMonthlyRentalIncome = this
        .loanAndPropertyInfo.expectedMonthlyRentalIncome
        ? Number(this.loanAndPropertyInfo.expectedMonthlyRentalIncome)
        : 0;
      if (this.loanAndPropertyInfo.expectedMonthlyRentalIncome > 0) {
        this.loanAndPropertyInfo.expectedNetMonthlyRentalIncome = this
          .loanAndPropertyInfo.expectedNetMonthlyRentalIncome
          ? Number(this.loanAndPropertyInfo.expectedNetMonthlyRentalIncome)
          : 0;
      } else {
        this.loanAndPropertyInfo.expectedNetMonthlyRentalIncome = 0;
      }
      this.propertyManufacturedHomePreviousData =
        this.loanAndPropertyInfo.isPropertyManufacturedHome;

      this.loanAndPropertyInfo.otherMort34, 500.0;
      this.loanAndPropertyInfo.gifts.forEach((e) => {
        e.cashOrMarketValue = e.cashOrMarketValue
          ? Number(e.cashOrMarketValue)
          : 0;
      });
      this.loanAndPropertyData = JSON.parse(
        JSON.stringify(this.loanAndPropertyInfo)
      );
      this.loanAndPropertyInfo.amountInCash = this.loanAndPropertyInfo
        .amountInCash
        ? this.loanAndPropertyInfo.amountInCash
        : 0;
      console.log(
        "this.loanAndPropertyData.amountInCash",
        this.loanAndPropertyData.amountInCash
      );
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async updateLoanAndProperty() {
    try {
      //this.loanAndPropertyInfo.interestRate = this.loanAndPropertyInfo.interestRate.toFixed(3);
      // console.log('this.loanAndPropertyInfo.interestRate ',this.loanAndPropertyInfo.interestRate )

      if (this.$store.state.sessionObj.userType != 2) this.compareData();
      let response = await Axios.post(
        BASE_API_URL + "urla/updateLoanAndProperty",
        {
          loanAndPropertyInfo: this.loanAndPropertyInfo,
          changedValues: this.changedValues,
          loanId: this.$route.query.id,
          lastData: this.lastData,
        },
        {
          headers: authHeader(),
        }
      );

      if (response.status === 201 && this.count > 0) {
        this.$snotify.success(response.data);
      }
      await this.getMipforVA();
      await this.getLoanAndPropertyInfo();
      //this.getAppraisedvalue();
      this.$emit("referesh-tab", true);
      EventBus.$emit("refesh-loaninfo");
      EventBus.$emit("refreshLenderInfoTab");
    } catch (error) {
      console.log(error);
    }
  }

  public async getLenderInfo() {
    try {
      let res = await Axios.get(
        BASE_API_URL + "urla/getLenderInfo/" + this.$route.query.id,
        {
          headers: authHeader(),
        }
      );
      this.projectType = res.data.lenderInfo.projectType;
    } catch (error) {
      console.log(error);
    }
  }

  public async openModal() {
    await this.$refs.refrence["openModal"]({
      taskId: 4,
      messgae: "You made some changes, send notification to broker",
      data: new AppraisalReceivedChecklist(),
    });
  }

  public async handleChange(currentVal, previousVal, fieldName) {
    let index = this.changedValues.findIndex((c) => c.name === fieldName);
    if (index !== -1) {
      if (currentVal === previousVal) {
        this.changedValues.splice(index, 1);
      } else {
        let data = this.changedValues.find((v) => v.name === fieldName);
        data.from = previousVal;
        data.to = currentVal;
      }
    } else {
      this.changedValues.push({
        name: fieldName,
        from: previousVal,
        to: currentVal,
      });
    }
  }

  public checkLoanPurpose() {
    try {
      if (
        this.loanAndPropertyInfo.loanPurpose &&
        this.loanAndPropertyInfo.loanPurpose.name === "Purchase"
      ) {
        this.loanAndPropertyInfo.refinanceType = null;
        this.loanAndPropertyInfo.loanPurpose.description = null;
      } else if (
        this.loanAndPropertyInfo.loanPurpose &&
        this.loanAndPropertyInfo.loanPurpose.name === "Other"
      ) {
        this.loanAndPropertyInfo.refinanceType = null;
      } else if (
        this.loanAndPropertyInfo.loanPurpose &&
        this.loanAndPropertyInfo.loanPurpose.name === "Refinance"
      ) {
        this.loanAndPropertyInfo.loanPurpose.description = null;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public removeMortgageOrGifts(identifier) {
    try {
      if (identifier === "gifts") this.loanAndPropertyInfo.gifts = [new Gift()];
      if (identifier === "mortgage")
        this.loanAndPropertyInfo.otherMortgage = [new OtherMortgage()];
    } catch (error) {
      console.log(error);
    }
  }

  public async changeMortgageType() {
    try {
      if (this.loanAndPropertyInfo.loanType.type != "Other") {
        this.loanAndPropertyInfo.loanType.description = null;
      }
      // this.loanAndPropertyInfo.vaType = null;
      let response = await Axios.post(
        BASE_API_URL + "urla/updateFirstMortgage",
        {
          loanId: this.$route.query.id,
          loanType: this.loanAndPropertyInfo.loanType.type,
        },
        {
          headers: authHeader(),
        }
      );
    } catch (error) {
      console.log(error);
    }
  }

  public getDownPayment() {
    try {
      let loanAmt = this.loanAndPropertyInfo.loanAmount
        ? Number(this.loanAndPropertyInfo.loanAmount)
        : 0;
      this.pptVal = this.loanAndPropertyInfo.propertyValue
        ? Number(this.loanAndPropertyInfo.propertyValue)
        : 0;
      this.downPaymentAmt = this.pptVal - loanAmt;
      return this.downPaymentAmt;
    } catch (error) {
      console.log(error);
    }
  }

  public getPercentage() {
    let percentage = (this.downPaymentAmt / this.pptVal) * 100;
    isNaN(percentage) || !isFinite(percentage) ? (percentage = 0) : percentage;
    this.downPaymentPercentage = percentage;
    return percentage ? percentage.toFixed(2) : 0;
  }
  public getMipPercentage() {
    let percentage =
      (this.loanAndPropertyInfo.mip / this.loanAndPropertyInfo.loanAmount) *
      100;
    isNaN(percentage) || !isFinite(percentage) ? (percentage = 0) : percentage;
    return percentage ? percentage.toFixed(2) : 0;
  }

  public getMip() {
    let percentage =
      (this.loanAndPropertyInfo.mipPercent /
        this.loanAndPropertyInfo.loanAmount) *
      100;
    isNaN(percentage) || !isFinite(percentage) ? (percentage = 0) : percentage;
    return percentage ? percentage.toFixed(2) : 0;
  }

  public getMipforVA() {
    if (
      this.loanAndPropertyInfo.loanType.type == "VA" &&
      this.loanAndPropertyInfo.vaType == null
    ) {
      this.loanAndPropertyInfo.mipPercent = 0;
      this.loanAndPropertyInfo.monthlyMIPercent = 0;
    }
    // this.loanAndPropertyInfo.monthlyMIPercent = 0;
  }

  public getMIforFirstAndSecond() {
    let percentage = (this.downPaymentAmt / this.pptVal) * 100;
    isNaN(percentage) || !isFinite(percentage) ? (percentage = 0) : percentage;
    this.downPaymentPercentage = percentage;
    return percentage ? percentage.toFixed(2) : 0;
  }

  public checkForValidation() {
    this.$emit("call-mount", true);
  }

  public checkValidationForMobile() {
    EventBus.$emit("check-validation", true);
  }

  public updateDTI() {
    EventBus.$emit("refreshLenderInfoTab");
    EventBus.$emit("refesh-loaninfo");
  }

  async mounted() {
    this.callLoader(true, null);
    await this.getLoanAndPropertyInfo();
    await this.getMipforVA();
    await this.getLenderInfo();
    await this.updateDTI();
    this.validationData ? await this.checkForValidation() : "";
    this.callLoader(false, null);
  }
}
