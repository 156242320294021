/* eslint-disable  @typescript-eslint/no-explicit-any */
export class ChangOfCircumstances {
  constructor() {
    this.interestRate = 0;
    this.propertyType = null;
    this.occupancy = null;
    this.productProgramType = null;
    this.appraisedValue = 0;
    this.salesPrice = 0;
    this.baseLoanAmount = 0;
    this.escrowImpounds = 0;
    this.addressChange = null;
    this.other = null;
  }
  public interestRate: any;
  public propertyType: any;
  public occupancy: any;
  public productProgramType: any;
  public appraisedValue: any;
  public salesPrice: any;
  public baseLoanAmount: any;
  public escrowImpounds: any;
  public addressChange: any;
  public other: any;
}
