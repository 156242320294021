






























































































import { Component, Prop, Vue } from "vue-property-decorator";
import ApplicationReview from "./ApplicationReview.vue";
import InitialDisclosure from "./InitialDisclosure.vue";
import InitialDisclosureReceived from "./InitialDisclosureReceived.vue";
import LenderResponse from "./LenderResponse.vue";
import TitleOrder from "./TitleOrder.vue";
import TitleReceived from "./TitleReceived.vue";
import ConditionalApproval from "./ConditionalApproval.vue";
import Suspended from "./Suspended.vue";
import DocsOut from "./DocsOut.vue";
import Funded from "./Funded.vue";
import AppraisalOrder from "./AppraisalOrder.vue";
import AppraisalReceived from "./AppraisalReceived.vue";
import VoeOrder from "./VoeOrder.vue";
import VoeReceived from "./VoeReceived.vue";
import VomOrder from "./VomOrder.vue";
import VodOrder from "./VodOrder.vue";
import VorOrder from "./VorOrder.vue";
import VorReceived from "./VorReceived.vue";
import VodReceived from "./VodReceived.vue";
import VomReceived from "./VomReceived.vue";
import HazardBinderOrder from "./HazardBinderOrder.vue";
import HazardBinderReceived from "./HazardBinderReceived.vue";
import FloodBinderOrder from "./FloodBinderOrder.vue";
import FloodBinderReceived from "./FloodBinderReceived.vue";
import PudQuestionnaireReceived from "./PudQuestionnaireReceived.vue";
import PudQuestionnaireOrder from "./PudQuestionnaireOrder.vue";
import ReInspectionSubjectToRepairOrder from "./ReInspectionSubjectToRepairOrder.vue";
import ReInspectionSubjectToCompletionOrder from "./ReInspectionSubjectToCompletionOrder.vue";
import ReInspectionSubjectToRepairReceived from "./ReInspectionSubjectToRepairReceived.vue";
import ReInspectionSubjectToCompletionReceived from "./ReInspectionSubjectToCompletionReceived.vue";
import CondoQuestionnaireOrder from "./CondoQuestionnaireOrder.vue";
import CondoQuestionnaireReceived from "./CondoQuestionnaireReceived.vue";
import CreateTask from "./CreateTask.vue";
import CDRequest from "./CDRequest.vue";
import CDReceived from "./CDReceived.vue";
import PayOffOrder from "./PayOffOrder.vue";
import PayOffReceived from "./PayOffReceived.vue";
import ThankYou from "./ThankYou.vue";
import ChangeOfCircumstance from "./ChangeOfCircumstance.vue";
import ChangeOfCircumstanceLow from "./ChangeOfCircumstanceLow.vue";
import ChangeOfCircumstanceSubjectToRepair from "./ChangeOfCircumstanceSubjectToRepair.vue";
import CircumstanceOfChangeSubjectToCompletion from "./CircumstanceOfChangeSubjectToCompletion.vue";
import ReInspectionLendersRequestOrder from "./ReInspectionLendersRequestOrder.vue";
import ReInspectionLendersRequestReceived from "./ReInspectionLendersRequestReceived.vue";

import UpdatedTitle from "./UpdatedTitle.vue";
import MasterInsuranceCertificateOrder from "./MasterInsuranceCertificateOrder.vue";
import MasterInsuranceCertificateReceived from "./MasterInsuranceCertificateReceived.vue";
import CreditSupplementOrder from "./CreditSupplementOrder.vue";
import CreditSupplementReceived from "./CreditSupplementReceived.vue";
import IbtsOrder from "./IbtsOrder.vue";
import IbtsRecieved from "./IbtsRecieved.vue";
import EngineerCertificateOrder from "./EngineerCertification.vue";
import EngineerCertificateRecieved from "./EngineerCertificationRecieved.vue";
import CocManufacturedHome from "./CocManufacturedHome.vue";
import Cancelled from "./CancelledLoan.vue";

import Axios from "axios";
import { BASE_API_URL, EventBus } from "../../../config";
import { authHeader } from "../../../services/auth";
@Component({
  components: {
    PayOffOrder,
    PayOffReceived,
    ApplicationReview,
    InitialDisclosure,
    InitialDisclosureReceived,
    ChangeOfCircumstanceLow,
    LenderResponse,
    ChangeOfCircumstanceSubjectToRepair,
    TitleOrder,
    TitleReceived,
    ConditionalApproval,
    Suspended,
    DocsOut,
    Funded,
    AppraisalOrder,
    AppraisalReceived,
    VoeOrder,
    VoeReceived,
    HazardBinderOrder,
    HazardBinderReceived,
    FloodBinderOrder,
    FloodBinderReceived,
    PudQuestionnaireOrder,
    PudQuestionnaireReceived,
    VomOrder,
    VorOrder,
    VodOrder,
    VodReceived,
    VorReceived,
    VomReceived,
    ReInspectionSubjectToRepairOrder,
    ReInspectionSubjectToRepairReceived,
    CondoQuestionnaireReceived,
    CondoQuestionnaireOrder,
    CreateTask,
    CDRequest,
    CDReceived,
    ChangeOfCircumstance,
    UpdatedTitle,
    MasterInsuranceCertificateOrder,
    MasterInsuranceCertificateReceived,
    CreditSupplementOrder,
    CreditSupplementReceived,
    IbtsOrder,
    IbtsRecieved,
    EngineerCertificateOrder,
    EngineerCertificateRecieved,
    CocManufacturedHome,
    CircumstanceOfChangeSubjectToCompletion,
    ReInspectionLendersRequestOrder,
    ReInspectionLendersRequestReceived,
    Cancelled
  },
})
export default class Workflow extends Vue {
  /* eslint-disable */
  public component = null;
  public componentRef: any = null;
  public loanId = this.$route.query.id;
  public idsHandledByTMPCO = false;
  public taskId = null;
  public reOrderCount: any = null;
  public lenderResponseTask: any = [];
  public expandLenderResponse = false;
  public sequenceNumber = null;
  public workflow: any = [];
  public secondAppraisalCount = null;
  public appraisalRefCount = null;
  public orderSequence = null;
  public totalTaskCount = null;
  public lenderResponseCount = null;
  public isVodTriggered = false;
  public isFloodBinderTaskTriggered = false;
  public documents = [];
  public loanDetails = {};
  public previousTaskId: any = null;
  public isTaskDisabled: any = null;

  @Prop()
  public isLoanInactive: any;

  public async showCurrentComponent(
    component,
    taskId,
    lenderResponseSequence,
    reOrderCount,
    secondAppraisalCount,
    totalTaskCount,
    appraisalRefCount,
    orderSequence,
    isTaskDisabled
  ) {
    try {
      this.isTaskDisabled = isTaskDisabled;
      this.taskId = taskId;
      this.component = component;
      this.secondAppraisalCount = secondAppraisalCount;
      this.lenderResponseCount = lenderResponseSequence;
      this.totalTaskCount = totalTaskCount;
      this.reOrderCount = reOrderCount;
      this.appraisalRefCount = appraisalRefCount;
      this.orderSequence = orderSequence;

      if (this.previousTaskId == taskId) {
        if (
          taskId == 5 ||
          taskId == 6 ||
          taskId == 9 ||
          taskId == 10 ||
          taskId == 11 ||
          taskId == 7 ||
          taskId == 8 ||
          taskId == 12 ||
          taskId == 47 ||
          taskId == 45 ||
          taskId == 46 ||
          taskId == 41 ||
          taskId == 21 ||
          taskId == 42 ||
          taskId == 36 
        )
          this.$refs.taskref["allMountFunction"](this.reOrderCount);
        if (taskId == 27 || taskId == 30 || taskId == 29)
          this.$refs.taskref["allMountFunction"](this.lenderResponseCount);
        if (
          taskId == 39 ||
          taskId == 40 ||
          taskId == 50 ||
          taskId == 51 ||
          taskId == 48 ||
          taskId == 49 ||
          taskId == 38 ||
          taskId == 13 ||
          taskId == 14 ||
          taskId == 53 ||
          taskId == 57 ||
          taskId == 52 ||
          taskId == 54
        )
          this.$refs.taskref["allMountFunction"](
            this.totalTaskCount,
            this.secondAppraisalCount,
            this.appraisalRefCount,
            this.reOrderCount
          );
        if (taskId == 55 || taskId == 56 || taskId == 3 || taskId == 4)
          this.$refs.taskref["allMountFunction"](
            this.reOrderCount,
            this.secondAppraisalCount,
            taskId,
            this.appraisalRefCount
          );
      } else if (
        (this.previousTaskId == 55 && taskId == 3) ||
        (this.previousTaskId == 3 && taskId == 55) ||
        (this.previousTaskId == 56 && taskId == 4) ||
        (this.previousTaskId == 4 && taskId == 56)
      )
        await this.$refs.taskref["allMountFunction"](
          this.reOrderCount,
          this.secondAppraisalCount,
          taskId,
          this.appraisalRefCount
        );

      await this.updateCurrentActiveTask(orderSequence);

      if (this.component == "Create Task") this.componentRef = CreateTask;
      else if (this.component == "application-review")
        this.componentRef = ApplicationReview;
      else if (this.component == "initial-disclosure-received")
        this.componentRef = InitialDisclosureReceived;
      else if (this.component == "initial-disclosure")
        this.componentRef = InitialDisclosure;
      else if (this.component == "lender-response")
        this.componentRef = LenderResponse;
      else if (this.component == "conditional-approval")
        this.componentRef = ConditionalApproval;
      else if (this.component == "suspended") this.componentRef = Suspended;
      else if (this.component == "docs-out") this.componentRef = DocsOut;
      else if (this.component == "funded") this.componentRef = Funded;
      else if (this.component == "appraisal-order")
        this.componentRef = AppraisalOrder;
      else if (this.component == "appraisal-received")
        this.componentRef = AppraisalReceived;
      else if (this.component == "second-appraisal-order")
        this.componentRef = AppraisalOrder;
      else if (this.component == "second-appraisal-received")
        this.componentRef = AppraisalReceived;
      else if (this.component == "title-order") this.componentRef = TitleOrder;
      else if (this.component == "title-received")
        this.componentRef = TitleReceived;
      else if (this.component == "wvoe-order") this.componentRef = VoeOrder;
      else if (this.component == "wvoe-received")
        this.componentRef = VoeReceived;
      else if (this.component == "hazard-binder-order")
        this.componentRef = HazardBinderOrder;
      else if (this.component == "hazard-binder-received")
        this.componentRef = HazardBinderReceived;
      else if (this.component == "flood-binder")
        this.componentRef = FloodBinderOrder;
      else if (this.component == "flood-binder-received")
        this.componentRef = FloodBinderReceived;
      else if (this.component == "master-insurance-certificate-order")
        this.componentRef = MasterInsuranceCertificateOrder;
      else if (this.component == "master-insurance-certificate-received")
        this.componentRef = MasterInsuranceCertificateReceived;
      else if (this.component == "pud-questionnaire-order")
        this.componentRef = PudQuestionnaireOrder;
      else if (this.component == "pud-questionnaire-received")
        this.componentRef = PudQuestionnaireReceived;
      else if (this.component == "credit-supplement-order")
        this.componentRef = CreditSupplementOrder;
      else if (this.component == "credit-supplement-received")
        this.componentRef = CreditSupplementReceived;
      else if (this.component == "vor-order") this.componentRef = VorOrder;
      else if (this.component == "vom-order") this.componentRef = VomOrder;
      else if (this.component == "vod-order") this.componentRef = VodOrder;
      else if (this.component == "vod-received")
        this.componentRef = VodReceived;
      else if (this.component == "vor-received")
        this.componentRef = VorReceived;
      else if (this.component == "vom-received")
        this.componentRef = VomReceived;
      else if (this.component == "reinspection-order-subject-to-repair")
        this.componentRef = ReInspectionSubjectToRepairOrder;
      else if (this.component == "reinspection-received-subject-to-repair")
        this.componentRef = ReInspectionSubjectToRepairReceived;
      else if (this.component == "reinspection-order-subject-to-completion")
        this.componentRef = ReInspectionSubjectToCompletionOrder;
      else if (this.component == "reinspection-received-subject-to-completion")
        this.componentRef = ReInspectionSubjectToCompletionReceived;
      else if (this.component == "condo-questionnaire-received")
        this.componentRef = CondoQuestionnaireReceived;
      else if (this.component == "condo-questionnaire-order")
        this.componentRef = CondoQuestionnaireOrder;
      else if (this.component == "cd-order") this.componentRef = CDRequest;
      else if (this.component == "cd-received") this.componentRef = CDReceived;
      else if (this.component == "payoff-order")
        this.componentRef = PayOffOrder;
      else if (this.component == "payoff-received")
        this.componentRef = PayOffReceived;
      else if (this.component == "circumstance-of-change")
        this.componentRef = ChangeOfCircumstance;
      if (this.component == "circumstance-of-change-subject-to-repair")
        this.componentRef = ChangeOfCircumstanceSubjectToRepair;
      if (this.component == "circumstance-of-change-low")
        this.componentRef = ChangeOfCircumstanceLow;
      if (this.component == "circumstance-of-change-subject-to-completion")
        this.componentRef = CircumstanceOfChangeSubjectToCompletion;
      else if (this.component == "updated-title")
        this.componentRef = UpdatedTitle;
      else if (this.component == "IBTS-order") this.componentRef = IbtsOrder;
      else if (this.component == "IBTS-received")
        this.componentRef = IbtsRecieved;
      else if (this.component == "engineer-certification-order")
        this.componentRef = EngineerCertificateOrder;
      else if (this.component == "engineer-certification-received")
        this.componentRef = EngineerCertificateRecieved;
      else if (this.component == "circumstance-of-change-manufactured-home")
        this.componentRef = CocManufacturedHome;
      else if (this.component == "reinspection-lenders-request-order")
        this.componentRef = ReInspectionLendersRequestOrder;
         else if (this.component == "cancelled-loan")
        this.componentRef = Cancelled;
      else if (this.component == "reinspection-lenders-request-received")
        this.componentRef = ReInspectionLendersRequestReceived;

      this.previousTaskId = taskId;
    } catch (error) {
      console.log(error);
    }
  }

  public async updateCurrentActiveTask(orderSequence) {
    try {
      let response = await Axios.post(
        BASE_API_URL + "processor/updateCurrentActiveTask",
        {
          loanId: this.loanId,
          orderSequence: orderSequence,
        },
        {
          headers: authHeader(),
        }
      );
    } catch (error) {
      console.log(error);
    }
  }

  public async getWorkflow() {
    try {
      this.callLoader(true, 1);
      let response = await Axios.post(
        BASE_API_URL + "processor/getWorkflow",
        {
          loanId: this.loanId,
        },
        {
          headers: authHeader(),
        }
      );
      let currentActiveTask = 0;
      if (response.status === 201) {
        this.workflow = response.data.workflow;
        currentActiveTask = response.data.currentActiveTask
          ? response.data.currentActiveTask
          : currentActiveTask;
      }
      this.$emit("refreshMileStone");
      let task = this.workflow.find(
        (e) => e.orderSequence === currentActiveTask
      );

      if (task)
        await this.showCurrentComponent(
          task.component,
          task.workflowTaskId,
          task.lenderResponseSequence,
          task.reOrderCount,
          task.secondAppraisalCount,
          task.totalTaskCount,
          task.appraisalRefCount,
          task.orderSequence,
          task.isTaskDisabled
        );
      else {
        this.componentRef = ThankYou;
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public returnCount(task) {
    if (
      task.workflowTaskId == 39 ||
      task.workflowTaskId == 40 ||
      task.workflowTaskId == 50 ||
      task.workflowTaskId == 51 ||
      task.workflowTaskId == 48 ||
      task.workflowTaskId == 49 ||
      task.workflowTaskId == 38 ||
      task.workflowTaskId == 13 ||
      task.workflowTaskId == 14 ||
      task.workflowTaskId == 53 ||
      task.workflowTaskId == 57 ||
      task.workflowTaskId == 52 ||
      task.workflowTaskId == 55 ||
      task.workflowTaskId == 56 ||
      task.workflowTaskId == 54
    ) {
      if (task.secondAppraisalCount) {
        let sAC = task.secondAppraisalCount - 1;
        return sAC ? sAC : "";
      } else {
        return task.totalTaskCount ? task.totalTaskCount + 1 : "";
      }
    } else return task.reOrderCount ? task.reOrderCount + 1 : "";
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  /***********To call all document in Title order with docstatus requred****************/

  public async getConditionDocuments() {
    try {
      let response = await Axios.get(
        BASE_API_URL +
          "loanChecklist/getConditionDocuments/" +
          this.$route.query.id,
        {
          headers: authHeader(),
        }
      );
      this.documents = response.data.documents;
      this.loanDetails = response.data.loanDetails;
    } catch (error) {
      console.log(error);
    }
  }
  /************************************************************************************/

  public async refreshList() {
    await this.getWorkflow();
  }

  public refreshOnlyMilestone() {
    this.$emit("refreshMileStone");
  }

  public async getWorkflowForMount() {
    this.callLoader(true, 1);
    try {
      // await this.updateCurrentActiveTask(orderSequence);

      let response = await Axios.post(
        BASE_API_URL + "processor/getWorkflow",
        {
          loanId: this.loanId,
        },
        {
          headers: authHeader(),
        }
      );
      // let currentActiveTask = 0;
      if (response.status === 201) {
        this.workflow = response.data.workflow;
        // currentActiveTask = response.data.currentActiveTask
        //   ? response.data.currentActiveTask
        //   : currentActiveTask;
      }
      let task = this.workflow.find((e) => !e.completedOn && !e.isDisabled);
      this.$emit("refreshMileStone");
      // let task = this.workflow.find(
      //   (e) => e.orderSequence === currentActiveTask
      // );

      if (task) {
        await this.showCurrentComponent(
          task.component,
          task.workflowTaskId,
          task.lenderResponseSequence,
          task.reOrderCount,
          task.secondAppraisalCount,
          task.totalTaskCount,
          task.appraisalRefCount,
          task.orderSequence,
          task.isTaskDisabled
        );
      } else {
        this.componentRef = ThankYou;
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }
  public async mounted() {
    await this.getWorkflowForMount();
    await this.getConditionDocuments();
  }
  /* eslint-disable */
}
