var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('modal',{staticClass:"modal",attrs:{"size":"lg","centered":"","name":"AddOtherVendor","hide-footer":true}},[_c('div',{staticClass:"modal--md-large"},[_c('div',{staticClass:"tmpco-modal__content"},[_c('div',{staticClass:"tmpco-modal__header"},[_c('h4',{staticClass:"tmpco-modal__title"},[_vm._v("Add Other Vendor")]),_c('button',{staticClass:"tmpco-modal__close",attrs:{"type":"button","aria-label":"Close"},on:{"click":function($event){return _vm.cancel()}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.saveOtherVendor)}}},[_c('div',{staticClass:"tmpco-modal__body"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"rules":"required|url_format"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("WebSite"),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.vendorDetails.companyUrl),expression:"vendorDetails.companyUrl"}],staticClass:"login-input line_height_40",attrs:{"type":"text","name":"WebSite"},domProps:{"value":(_vm.vendorDetails.companyUrl)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.vendorDetails, "companyUrl", $event.target.value)}}}),(errors)?_c('span',{staticClass:"login-error show"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Company Name"),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.vendorDetails.companyName),expression:"vendorDetails.companyName"}],staticClass:"login-input line_height_40",attrs:{"type":"text","name":"Company Name"},domProps:{"value":(_vm.vendorDetails.companyName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.vendorDetails, "companyName", $event.target.value)}}}),(errors)?_c('span',{staticClass:"login-error show"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("User Name"),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.vendorDetails.userName),expression:"vendorDetails.userName"}],staticClass:"login-input line_height_40",attrs:{"type":"text","name":"User Name"},domProps:{"value":(_vm.vendorDetails.userName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.vendorDetails, "userName", $event.target.value)}}}),(errors)?_c('span',{staticClass:"login-error show"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Password"),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.vendorDetails.password),expression:"vendorDetails.password"}],staticClass:"login-input line_height_40",attrs:{"type":"password","name":"Password"},domProps:{"value":(_vm.vendorDetails.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.vendorDetails, "password", $event.target.value)}}}),(errors)?_c('span',{staticClass:"login-error show"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)])])])]),_c('div',{staticClass:"tmpco-modal__footer"},[_c('button',{staticClass:"tmpco-btn tmpco-btn__sm-default mr-2",attrs:{"type":"button"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" Cancel ")]),_c('button',{staticClass:"tmpco-btn tmpco-btn__sm-theme",attrs:{"type":"submit"}},[_vm._v(" Save ")])])])]}}])})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }