










































































































































































  import { Component, Vue, Prop } from "vue-property-decorator";
  import Axios from "axios";
  import { BASE_API_URL } from "../../../config";
  import { authHeader } from "../../../services/auth";
  import CommonNotifyOnRejection from "../../../common-components/CommonNotifyOnRejection.vue";
  import ViewDocument from "../../../common-components/ViewDocument.vue";
  @Component({
    components: {
      ViewDocument,
      CommonNotifyOnRejection,
    },
  })
  export default class VorOrder extends Vue {
    /* eslint-disable */
    @Prop()
    public taskId: any;
    public bImgLoader = false;
    public vorData: any = {};
    public error: any = {
      msg: null,
      show: false,
    };
    public docsDetails: any = [];

    public docLoader = false;
    public docStatus1 = false;
    public docStatus2 = false;
    public isOrderPlacedOnce = false;
    public isRejected = false;
  public isAccepted = false;
  public isChecklist = true;

    @Prop()
    public isLoanInactive: any;
    public isComplete = false;
    public notifyBroker = false;
  /**
   * Taking dsData variable for checking form data is changed or not only for comaprison
   */
   public dsData: any = {};
   /**************************************************************** */
   @Prop()
   public isTaskDisabled: any;


   public async checkSaveValidation() {
    try {
      let hasNoData = true;
      let valueIsNotSame = false;
      for (let i = 0; i < this.vorData.landLordInfo.length; i++) {
        const e = this.vorData.landLordInfo[i];
        if (
          e.landLordName != this.dsData.landLordInfo[i].landLordName ||
          e.email != this.dsData.landLordInfo[i].email
          )
          valueIsNotSame = true;
        else if (e.landLordName || e.email) hasNoData = false;
      }
      if (!valueIsNotSame && hasNoData) return true;
      else return false;
    } catch (error) {
      console.log(error);
    }
  }
  /**
   * Saving VOR ORDER Task Info.
   */
   public async saveVorOrderData() {
       if(this.isOrderPlacedOnce) return
    this.callLoader(true, null);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getVorData();
        return;
      }
      if (!this.isComplete) {
        let notValid1 = await this.checkSaveValidation();
        if (notValid1) {
          this.callLoader(false, null);
          this.$snotify.info("No Changes");
          return;
        }
      }

      if (this.isComplete) {
        this.error = { msg: null, show: false };
        let noInfo = this.vorData.landLordInfo.filter(
          (e) => e.email && e.landLordName
          );
        if (noInfo.length == 0) {
          this.error.show = true;
          this.error.msg = "Please provide atleast 1 landlord information";
          this.callLoader(false, null);
          return;
        } else {
          let emptyData = this.vorData.landLordInfo.filter(
            (e) => e.email || e.landLordName
            );
          let emptyOptionData = emptyData.filter(
            (m) => (m.email && !m.landLordName) || (!m.email && m.landLordName)
            );
          if (emptyOptionData.length > 0) {
            this.error.show = true;
            this.error.msg = "Please fill empty field";
            this.callLoader(false, null);
            return;
          }
        } 
      }
let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      let checkvalidEmailData = this.vorData.landLordInfo.filter(
        (e) => e.email && !e.email.match(mailformat)
      );

  if (this.isComplete && checkvalidEmailData.length > 0) {
        this.error.show = true;
        this.error.msg = "Please Enter Valid Email ";
        this.callLoader(false, null);
        return;
      }
      if (this.isComplete && !this.docsDetails) {
        this.error.show = true;
        this.error.msg = "Please Upload Borrower Authorization.";
        this.callLoader(false, null);
        return;
      }
      if ( this.isComplete && this.docsDetails &&
        !this.docsDetails.docApproved) {
        this.error.show = true;
      this.error.msg = "Borrower Authorization is not approved.";
      this.callLoader(false, null);
      return;
    }

    if(this.isComplete) this.isOrderPlacedOnce=true;

    let response = await Axios.post(
      BASE_API_URL + "processor/saveVorOrderData",
      {
        loanId: this.$route.query.id,
        taskId: this.taskId,
        isComplete: this.isComplete,
        vorData: this.vorData,
      },
      { headers: authHeader() }
      );
    if (!this.isComplete) {
      this.$snotify.success("VOR Order Information has been saved");
    }

    if (response.status === 201 && this.isComplete)
      this.$snotify.success(response.data.message);
    if (this.isComplete) this.$emit("refresh-task-list", true);
    await this.getVorData();
  } catch (error) {
    console.log(error);
  }
  this.callLoader(false, null);
}

public async getVorData() {
  this.callLoader(true, null);
  try {
    const response = await Axios.get(
      BASE_API_URL +
      "processor/getVorData/" +
      this.$route.query.id +
      "/" +
      this.taskId,
      { headers: authHeader() }
      );

    if (response.status === 201) {
      this.vorData = JSON.parse(JSON.stringify(response.data));
      this.dsData = JSON.parse(JSON.stringify(response.data));
    }
  } catch (error) {
    console.log(error);
  }
  this.callLoader(false, null);
}

public async uploadFile(event) {
  if (this.isTaskDisabled) {
    this.callLoader(false, null);
    this.$snotify.info("Task is not assigned to you.");
    this.getVorData();
    return;
  }
  this.docLoader = true;
  try {
    if (event.target.files.length <= 0) return;
    const formData: any = new FormData();
    Array.from(event.target.files).forEach((element) => {
      formData.append("file", element);
    });
    let loanId: any = this.$route.query.id;
    formData.append("loanId", loanId);
    formData.append("taskId", this.taskId);
    formData.append("docName", "Borrower Authorization");
    const response = await Axios.post(
      BASE_API_URL + "processor/uploadBorrowerAuthorization",
      formData,
      { headers: authHeader() }
      );
    if (response.status === 201) this.$snotify.success(response.data.message);
    this.docsDetails = response.data.doc;
      //this.getVorData();
      await this.getBorrowerAuthorizationrDocument();

      await this.isBrokerNotifiedForRejectedDocs();
    } catch (error) {
      console.log(error);
    }
    this.docLoader = false;
  }

  public async viewDocument(document) {
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getVorData();
        return;
      }
     document.loanId = this.$route.query.id;
          document.isRejected = this.isRejected,
        document.isAccepted = this.isAccepted,
       document.isChecklist = this.isChecklist,
      this.$refs.ref["showFile"](document);
    } catch (error) {
      console.log(error);
    }
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  public async isBrokerNotifiedForRejectedDocs() {
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getVorData();
        return;
      }
      let res = await Axios.get(
        BASE_API_URL +
        "loanChecklist/isBrokerNotifiedForRejectedDocs/" +
        this.$route.query.id +
        "/" +
        this.taskId+'/'+null,
        {
          headers: authHeader(),
        }
        );
      if (res.status == 201) {
        this.notifyBroker = res.data.notifyBorker;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async getBorrowerAuthorizationrDocument() {
    this.callLoader(true, 1);
    try {
      let response = await Axios.get(
        BASE_API_URL +
        "processor/getBorrowerAuthorizationrDocument/" +
        this.$route.query.id,
        {
          headers: authHeader(),
        }
        );

      if (response.status === 201) {
        this.docsDetails = response.data;
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, 1);
  }

  mounted() {
    this.getVorData();
    this.getBorrowerAuthorizationrDocument();
    this.isBrokerNotifiedForRejectedDocs();
  }
}
