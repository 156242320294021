export class IsAppraisalOrderedOnCorrectForm {
    constructor() {
        this.mode = null;
        this.revisionRequestedOn = new Date();
        this.revisionCompletedOn = new Date();
        this.nARemark = null;
    }
    public revisionRequestedOn: any;
    public revisionCompletedOn: any;
    public nARemark: any
    public mode: any
}
export class IsBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003 {
    constructor() {
        this.mode = null;
        this.revisionRequestedOn = new Date();
        this.revisionCompletedOn = new Date();
        this.nARemark = null;
    }
    public revisionRequestedOn: any;
    public revisionCompletedOn: any;
    public nARemark: any
    public mode: any
}
export class IsPropertyAddressCoincideSalesContractOrSalesAddendum {
    constructor() {
        this.mode = null;
        this.revisionRequestedOn = new Date();
        this.revisionCompletedOn = new Date();
        this.nARemark = null;
    }
    public revisionRequestedOn: any;
    public revisionCompletedOn: any;
    public nARemark: any
    public mode: any
}
export class IsSalesPriceAccuratelyReflected {
    constructor() {
        this.mode = null;
        this.revisionRequestedOn = new Date();
        this.revisionCompletedOn = new Date();
        this.nARemark = null;
    }
    public revisionRequestedOn: any;
    public revisionCompletedOn: any;
    public nARemark: any
    public mode: any
}

export class IsOwnerOfRecordSameAsSellerReflectedOnSalesContract {
    constructor() {
        this.mode = null;
        this.revisionRequestedOn = new Date();
        this.revisionCompletedOn = new Date();
        this.nARemark = null;
    }
    public revisionRequestedOn: any;
    public revisionCompletedOn: any;
    public nARemark: any
    public mode: any
}
export class IsLenderNameAccuratelyReflected {
    constructor() {
        this.mode = null;
        this.revisionRequestedOn = new Date();
        this.revisionCompletedOn = new Date();
        this.nARemark = null;
    }
    public revisionRequestedOn: any;
    public revisionCompletedOn: any;
    public nARemark: any
    public mode: any
}
export class IsMonthlyHOAPaymentUpdatedOn1003 {
    constructor() {
        this.mode = null;
        this.revisionRequestedOn = new Date();
        this.revisionCompletedOn = new Date();
        this.nARemark = null;
    }
    public revisionRequestedOn: any;
    public revisionCompletedOn: any;
    public nARemark: any
    public mode: any
}
export class IsPropertyLocatedInFloodZone {
    constructor() {
        this.mode = null;
        this.revisionRequestedOn = new Date();
        this.revisionCompletedOn = new Date();
        this.nARemark = null;
    }
    public revisionRequestedOn: any;
    public revisionCompletedOn: any;
    public nARemark: any
    public mode: any
}
export class IsPropertyManufacturedHome {
    constructor() {
        this.remarks = [];
        this.mode = null;
        this.revisionRequestedOn = new Date();
        this.revisionCompletedOn = new Date();
        this.nARemark = null;
    }
    public revisionRequestedOn: any;
    public revisionCompletedOn: any;
    public nARemark: any
    public mode: any
    public remarks: any
}
export class IsFHAOrVACaseNumberAccuratelyReflected {
    constructor() {
        this.mode = null;
        this.revisionRequestedOn = new Date();
        this.revisionCompletedOn = new Date();
        this.nARemark = null;
    }
    public revisionRequestedOn: any;
    public revisionCompletedOn: any;
    public nARemark: any
    public mode: any
}
export class IsAppraisalCompletedAsIs {
    constructor() {
        this.isSubjectToRepair = null;
        this.isSubjectToCompletion = null;
        this.mode = null;
        this.revisionRequestedOn = new Date();
        this.revisionCompletedOn = new Date();
        this.nARemark = null;
    }
    public revisionRequestedOn: any;
    public revisionCompletedOn: any;
    public nARemark: any
    public mode: any
    public isSubjectToRepair: any;
    public isSubjectToCompletion: any;
}
export class IsAppraisedValueLowerThanSalesPrice {
    constructor() {
        this.mode = null;
        this.revisionRequestedOn = new Date();
        this.revisionCompletedOn = new Date();
        this.nARemark = null;
    }
    public revisionRequestedOn: any;
    public revisionCompletedOn: any;
    public nARemark: any
    public mode: any
}
export class HasPropertyOwnedForAtLeast91Days {
    constructor() {
        this.mode = null;
        this.revisionRequestedOn = new Date();
        this.revisionCompletedOn = new Date();
        this.nARemark = null;
        this.onHoldMailSent = false;
    }
    public revisionRequestedOn: any;
    public revisionCompletedOn: any;
    public nARemark: any
    public mode: any
    public onHoldMailSent: any
}
export class IsOwnerOwnPropertLessThan180Days {
    constructor() {
        this.mode = null;
        this.revisionRequestedOn = new Date();
        this.revisionCompletedOn = new Date();
        this.nARemark = null;
    }
    public revisionRequestedOn: any;
    public revisionCompletedOn: any;
    public nARemark: any
    public mode: any
}
export class AppraisalReceivedChecklist {
    constructor() {
        this.isAppraisalOrderedOnCorrectForm = new IsAppraisalOrderedOnCorrectForm();
        this.isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003 = new IsBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003();
        this.isPropertyAddressCoincideSalesContractOrSalesAddendum = new IsPropertyAddressCoincideSalesContractOrSalesAddendum();
        this.isSalesPriceAccuratelyReflected = new IsSalesPriceAccuratelyReflected();
        this.isOwnerOfRecordSameAsSellerReflectedOnSalesContract = new IsOwnerOfRecordSameAsSellerReflectedOnSalesContract();
        this.isLenderNameAccuratelyReflected = new IsLenderNameAccuratelyReflected();
        this.isMonthlyHOAPaymentUpdatedOn1003 = new IsMonthlyHOAPaymentUpdatedOn1003();
        this.isPropertyLocatedInFloodZone = new IsPropertyLocatedInFloodZone();
        this.isPropertyManufacturedHome = new IsPropertyManufacturedHome();
        this.isFHAOrVACaseNumberAccuratelyReflected = new IsFHAOrVACaseNumberAccuratelyReflected();
        this.isAppraisalCompletedAsIs = new IsAppraisalCompletedAsIs();
        this.isAppraisedValueLowerThanSalesPrice = new IsAppraisedValueLowerThanSalesPrice();
        this.isOwnerOwnPropertLessThan180Days = new IsOwnerOwnPropertLessThan180Days();
        this.hasPropertyOwnedForAtLeast91Days = new HasPropertyOwnedForAtLeast91Days();
        this.appraisalPrice = 0;
        this.isLow = null;
        this.isSubjectToRepair = null;

    }
    public isAppraisalOrderedOnCorrectForm: IsAppraisalOrderedOnCorrectForm;
    public isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003: IsBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003;
    public isPropertyAddressCoincideSalesContractOrSalesAddendum: IsPropertyAddressCoincideSalesContractOrSalesAddendum;
    public isSalesPriceAccuratelyReflected: IsSalesPriceAccuratelyReflected;
    public isOwnerOfRecordSameAsSellerReflectedOnSalesContract: IsOwnerOfRecordSameAsSellerReflectedOnSalesContract;
    public isLenderNameAccuratelyReflected: IsLenderNameAccuratelyReflected;
    public isMonthlyHOAPaymentUpdatedOn1003: IsMonthlyHOAPaymentUpdatedOn1003;
    public isPropertyLocatedInFloodZone: IsPropertyLocatedInFloodZone;
    public isPropertyManufacturedHome: IsPropertyManufacturedHome;
    public isFHAOrVACaseNumberAccuratelyReflected: IsFHAOrVACaseNumberAccuratelyReflected;
    public isAppraisalCompletedAsIs: IsAppraisalCompletedAsIs;
    public isAppraisedValueLowerThanSalesPrice: IsAppraisedValueLowerThanSalesPrice;
    public isOwnerOwnPropertLessThan180Days: IsOwnerOwnPropertLessThan180Days;
    public hasPropertyOwnedForAtLeast91Days: HasPropertyOwnedForAtLeast91Days;
    public appraisalPrice: any;
    public isLow: any;
    public isSubjectToRepair: any

}