





























































import { Component, Vue, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "../../../config";
import { authHeader } from "../../../services/auth";
@Component
export default class DocsOut extends Vue {
  /* eslint-disable */

  @Prop()
  public taskId: any;
  public docsOut: any = {};
  public loanId = this.$route.query.id;
  public error = false;
  @Prop()
  public isLoanInactive: any;
  public isTaskCompleted = false;
  @Prop()
  public isTaskDisabled: any;
  public isOrderPlacedOnce = false;
  /**
   * Taking dsData variable for checking form data is changed or not only for comaprison
   */
  public dsData: any = {};
  /**************************************************************** */

  public async checkSaveValidation() {
    try {
      if (
        !this.docsOut.isClosingDocsSentToTitleCompany &&
        this.docsOut.isClosingDocsSentToTitleCompany ==
          this.dsData.isClosingDocsSentToTitleCompany
      )
        return true;
      else return false;
    } catch (error) {
      console.log(error);
    }
  }

  public async saveDocsOutInfo(isComplete) {
            if(this.isOrderPlacedOnce) return
    try {
      if (this.isTaskDisabled) {
        this.$snotify.info("Task is not assigned to you.");
        await this.getDocOutDetails();
        return;
      }
      if (!isComplete) {
        let notValid1 = await this.checkSaveValidation();
        if (notValid1) {
          //this.callLoader(false, null);
          this.$snotify.info("No Changes");
          return;
        }
      }
      if (isComplete && !this.docsOut.isClosingDocsSentToTitleCompany) {
        this.error = true;
        return;
      }
         if(isComplete) this.isOrderPlacedOnce=true;

      let response = await Axios.post(
        BASE_API_URL + "processor/saveDocsOutInfo",
        {
          loanId: this.loanId,
          docsOut: this.docsOut,
          taskId: this.taskId,
          isComplete: isComplete,
        },
        {
          headers: authHeader(),
        }
      );
      if (!isComplete) {
        this.$snotify.success("Docs Out Information has been saved");
      }

      if (response.status === 201 && isComplete)
        this.$snotify.success("Docs Out Information Completed Successfully.");
      if (isComplete) this.$emit("refresh-task-list", true);
      await this.getDocOutDetails();
    } catch (error) {
      console.log(error);
    }
  }

  public async getDocOutDetails() {
    try {
      const response = await Axios.get(
        BASE_API_URL +
          "processor/getDocOutDetails/" +
          this.$route.query.id +
          "/" +
          this.taskId,
        {
          headers: authHeader(),
        }
      );
      if (response.status === 201) {
        // this.docsOut = response.data.docsOut;
        this.isTaskCompleted = response.data.isCompleted;
        this.docsOut = JSON.parse(JSON.stringify(response.data.docsOut));
        this.dsData = JSON.parse(JSON.stringify(response.data.docsOut));
      }
    } catch (error) {
      console.log(error);
    }
  }

  mounted() {
    this.getDocOutDetails();
  }
}
