
export class  IsBorrowerNameAccuratelyReflected{
    constructor() {
    this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    }
public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}

export class IsPropertyAddressAccuratelyReflected{
    constructor() {
    this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    }
public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}
export class HasInvoiceBeenProvided{
    constructor() {
    this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    }
public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}
export class IsMortgageClauseAndLoanNumberAccuratelyReflected{
    constructor() {
    this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    }
public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}
export class AtLeast60DaysOfCoverageRemainingForRefinance{
    constructor() {
    this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    }
public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}
export class HasAtLeast12MonthsOfCoverageReflectedForPurchase {
    constructor() {
    this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    }
public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}
export class MonthlyPaymentOn1003CorrectlyReflected{
    constructor() {
    this.mode = null;
    this.nARemark =  null;
    }
public nARemark: any
public mode: any
}
export class IsEffectiveDateOnOrNoMoreThan7Days{
    constructor() {
    this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    }
public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}

export class CondoProperty {
    constructor() {
    this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    }
public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}
export class IsCoverageSufficientToCoverAmountReflectedOnRCE  {
    constructor() {
    this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    }
public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}
export class IsDwellingCoverageSufficientToCoverLoanAmountI {
    constructor() {
    this.mode = null;
    this.nARemark =  null;
    }
public nARemark: any
public mode: any
}


export class HazardBinderReceivedChecklist {
    constructor() {
        this.isBorrowerNameAccuratelyReflected = new IsBorrowerNameAccuratelyReflected();
        this.isPropertyAddressAccuratelyReflected= new IsPropertyAddressAccuratelyReflected();
        this.hasInvoiceBeenProvided = new HasInvoiceBeenProvided();
        this.isMortgageClauseAndLoanNumberAccuratelyReflected= new IsMortgageClauseAndLoanNumberAccuratelyReflected();
        this.atLeast60DaysOfCoverageRemainingForRefinance = new AtLeast60DaysOfCoverageRemainingForRefinance();
        this.hasAtLeast12MonthsOfCoverageReflectedForPurchase = new HasAtLeast12MonthsOfCoverageReflectedForPurchase();
        this.monthlyPaymentOn1003CorrectlyReflected= new MonthlyPaymentOn1003CorrectlyReflected();
        this.isEffectiveDateOnOrNoMoreThan7Days = new IsEffectiveDateOnOrNoMoreThan7Days();
        this.isDwellingCoverageSufficientToCoverLoanAmountI = new IsDwellingCoverageSufficientToCoverLoanAmountI();
        this.condoProperty = new CondoProperty();
        this.isCoverageSufficientToCoverAmountReflectedOnRCE = new IsCoverageSufficientToCoverAmountReflectedOnRCE();
        this.isHazardRecieved = false;

     }
     public isBorrowerNameAccuratelyReflected: IsBorrowerNameAccuratelyReflected;
     public isPropertyAddressAccuratelyReflected: IsPropertyAddressAccuratelyReflected;
     public hasInvoiceBeenProvided: HasInvoiceBeenProvided;
     public isMortgageClauseAndLoanNumberAccuratelyReflected: IsMortgageClauseAndLoanNumberAccuratelyReflected;
     public atLeast60DaysOfCoverageRemainingForRefinance: AtLeast60DaysOfCoverageRemainingForRefinance;
     public hasAtLeast12MonthsOfCoverageReflectedForPurchase:  HasAtLeast12MonthsOfCoverageReflectedForPurchase;
     public monthlyPaymentOn1003CorrectlyReflected: MonthlyPaymentOn1003CorrectlyReflected;
     public isEffectiveDateOnOrNoMoreThan7Days: IsEffectiveDateOnOrNoMoreThan7Days;
     public isDwellingCoverageSufficientToCoverLoanAmountI: IsDwellingCoverageSufficientToCoverLoanAmountI;
     public condoProperty: CondoProperty;
     public isCoverageSufficientToCoverAmountReflectedOnRCE: IsCoverageSufficientToCoverAmountReflectedOnRCE;

     public isHazardRecieved: boolean;
     
}