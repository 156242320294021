import { BPopover } from 'bootstrap-vue';

/* eslint-disable  @typescript-eslint/no-explicit-any */

export class EstateHeld {
    constructor() {
        this.type = null;
        this.expirationDate = null;
    }
    public type: any;
    public expirationDate: any;
}

export class TitleInformation {
    constructor() {
        this.titleMannerHeld = {
            type: null,
            description: null
        };
        this.estateHeld = new EstateHeld();
        this.trustInfo = null;
        this.indianLandTenure = {
            type: null,
            description: null
        };
    }
    public titleHolderName: any;
    public estateHeld: EstateHeld;
    public titleMannerHeld: any;
    public trustInfo: any;
    public indianLandTenure: any;
}

export class BallonTerm {
    constructor() {
        this.isBallonTerm = null;
        this.term = 0;
    }
    public isBallonTerm: any;
    public term: number;
}

export class InterestTerm {
    constructor() {
        this.isInterestTerm = null;
        this.term = 0;
    }
    public isInterestTerm: any;
    public term: number;
}

export class PrePaymentPenaltyTerm {
    constructor() {
        this.iPrePaymentPenaltyTerm = null;
        this.term = 0;
    }
    public iPrePaymentPenaltyTerm: any;
    public term: number;
}

export class BuyDownInterestrate {
    constructor() {
        this.isBuyDownInterestrate = null;
        this.rate = 0;
    }
    public isBuyDownInterestrate: any;
    public rate: number;
}

export class OtherLoanFeature {
    constructor() {
        this.isOther = null;
        this.description = null;
    }
    public isOther: any;
    public description: any;
}

export class LoanFeature {
    constructor() {
        this.ballonTerm = new BallonTerm();
        this.interestTerm = new InterestTerm();
        this.isNegativeAmortization = false;
        this.prePaymentPenaltyTerm = new PrePaymentPenaltyTerm();
        this.buyDownInterestrate = new BuyDownInterestrate();
        this.other = new OtherLoanFeature;
    }
    public ballonTerm: BallonTerm;
    public interestTerm: InterestTerm;
    public isNegativeAmortization: boolean;
    public prePaymentPenaltyTerm: PrePaymentPenaltyTerm;
    public buyDownInterestrate: BuyDownInterestrate;
    public other: OtherLoanFeature;
}

export class AmortizationType {
    constructor() {
        this.type = null;
        this.priorAdjustmentPeriod = null;
        this.subsequentAdjustmentPeriod = null;
        this.description = null;
    }
    public type: any;
    public priorAdjustmentPeriod: any;
    public subsequentAdjustmentPeriod: any;
    public description: any;
}

export class CommunityPropertyState {
    constructor() {
        this.borrowerLivesPropertyState = null;
        this.isInCommunityPropertyState = null;
    }
    public borrowerLivesPropertyState: any;
    public isInCommunityPropertyState: any;
}

export class ConstructionToPermanent {
    constructor() {
        this.isConstructionToPermanent = null;
        this.typeOfConstruction = null;
        this.improvementCost = 0;
        this.lotAcquiredDate = null;
        this.originalCost = 0;
    }
    public isConstructionToPermanent: any;
    public typeOfConstruction: any;
    public improvementCost: number;
    public lotAcquiredDate: any;
    public originalCost: number;
}



export class TransactionDetail {
    constructor() {
        this.isContractDeedOrLandContract = null;
        this.isRenovation = null;
        this.constructionToPermanent = new ConstructionToPermanent();
    }
    public isContractDeedOrLandContract: any;
    public isRenovation: any;
    public constructionToPermanent: ConstructionToPermanent;
}

export class EnergyImprovement {
    constructor() {
        this.isMortgageLoanFinance = null;
        this.isPropertySubjectToLein = null;
    }

    public isMortgageLoanFinance: any;
    public isPropertySubjectToLein: any;
}

export class ProjectType {
    constructor() {
        this.condominium = null;
        this.cooperative = null;
        this.plannedUnitDevelopment = null;
        this.propertyNotLoacatedInProject = null;
    }
    public condominium: any;
    public cooperative: any;
    public plannedUnitDevelopment: any;
    public propertyNotLoacatedInProject: any;
}

export class RefinanceProgramme {
    constructor() {
        this.type = null;
        this.description = null
    }
    public type: any;
    public description: any;
}

export class ProposedMonthlyPaymentForProperty {
    constructor() {
        this.firstMortgage = 0;
        this.subordinateLien = 0;
        this.homeOwnerInsurance = 0;
        this.supplementalPropertyInsurance = 0;
        this.propertyTaxes = 0;
        this.mortgageInsurance = 0;
        this.projectDues = 0;
        this.other = 0;
    }
    public firstMortgage: number;
    public subordinateLien: number;
    public homeOwnerInsurance: number;
    public supplementalPropertyInsurance: number;
    public propertyTaxes: number;
    public mortgageInsurance: number;
    public projectDues: number;
    public other: number;
}

export class LenderInfo {
    constructor() {
        this.salesContractPrice = 0;
        this.improvementRepair = 0;
        this.land = 0;
        this.loanForRefinance = 0;
        this.creditCardAndOther = 0;
        this.estimatedClosingCost = 0;
        this.prepaidItemsEstimatedAmount = 0;
        this.miFundingFeeTotal = 0;
        this.closingCosts = 0;
        this.discount = 0;
        this.loanExcludingFinanced = 0;
        this.financedMortgage = 0;
        this.otherNewMortgage = 0;
        this.sellerCredit = 0;
        this.otherCredit = 0;
        this.titleInformation = new TitleInformation();
        this.loanFeatures = new LoanFeature();
        this.amortizationType = new AmortizationType();
        this.communityPropertyState = new CommunityPropertyState();
        this.transactionDetail = new TransactionDetail();
        this.energyImprovement = new EnergyImprovement();
        this.projectType = new ProjectType();
        this.refinanceProgramme = new RefinanceProgramme();
        this.proposedMonthlyPaymentForProperty = new ProposedMonthlyPaymentForProperty();

    }

    public salesContractPrice: number;
    public improvementRepair: number;
    public land: number;
    public loanForRefinance: number;
    public creditCardAndOther: number;
    public estimatedClosingCost: number;
    public miFundingFeeTotal: number;
    public prepaidItemsEstimatedAmount: number
    public closingCosts: number;
    public discount: number;
    public loanExcludingFinanced: number;
    public financedMortgage: number;
    public otherNewMortgage: number;
    public sellerCredit: number;
    public otherCredit: number;
    public titleInformation: TitleInformation;
    public loanFeatures: LoanFeature;
    public amortizationType: AmortizationType;
    public communityPropertyState: CommunityPropertyState;
    public transactionDetail: TransactionDetail;
    public energyImprovement: EnergyImprovement;
    public projectType: ProjectType;
    public refinanceProgramme: RefinanceProgramme;
    public proposedMonthlyPaymentForProperty: ProposedMonthlyPaymentForProperty

}