



















































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "../../../config";
import { authHeader } from "../../../services/auth";
import ViewDocument from "../../../common-components/ViewDocument.vue";
import CommonNotifyOnRejection from "../../../common-components/CommonNotifyOnRejection.vue";

@Component({
  components: {
    ViewDocument,
    CommonNotifyOnRejection,
  },
})
export default class VomOrder extends Vue {
  /* eslint-disable */
  @Prop()
  public taskId: any;
  public documents: any = [];
  public accountType = [
    { id: 5, name: "Mortgage Loan", value: "MortgageLoan" },
    { id: 6, name: "Heloc", value: "HELOC" },
  ];
  public vomData: any = {};
  public error: any = {
    show: false,
    msg: null,
  };
  public notifyBroker = false;
  public docLoader = false;
  public docsDetails: any = [];
  public docStatus1 = false;
  public docStatus2 = false;
  public isOrderPlacedOnce = false;
  @Prop()
  public isLoanInactive: any;
  public isComplete = false;
  public isRejected = false;
  public isAccepted = false;
  public isChecklist = true;
  /**documents
   * Taking dsData variable for checking form data is changed or not only for comaprison
   */
  public dsData: any = {};
  /**************************************************************** */
  @Prop()
  public isTaskDisabled: any;

  // public vomOptionError(vomOption, vomOptionValue) {
  //   if (vomOption == "email" && !vomOptionValue) return "Please provide email";
  //   else return "Please provide fax";
  // }

  public async checkSaveValidation() {
    try {
      let hasNoData = true;
      let valueIsNotSame = false;
      for (let i = 0; i < this.vomData.creditorInfo.length; i++) {
        const e = this.vomData.creditorInfo[i];
        if (e.option != this.dsData.creditorInfo[i].option)
          valueIsNotSame = true;
        else if (e.option) hasNoData = false;
      }
      if (!valueIsNotSame && hasNoData) return true;
      else return false;
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Saving VOR ORDER Task Info.
   */
  public async saveVomOrderData() {
    if(this.isOrderPlacedOnce) return
    this.callLoader(true, null);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getVomOrderData();
        return;
      }
      if (!this.isComplete) {
        let notValid1 = await this.checkSaveValidation();
        if (notValid1) {
          this.callLoader(false, null);
          this.$snotify.info("No Changes");
          return;
        }
      }

      if (this.isComplete && !this.docsDetails) {
        this.error.show = true;
        this.error.msg = "Please Upload Borrower Authorization.";
        this.callLoader(false, null);
        return;
      }
      if (
        this.isComplete &&
        this.docsDetails &&
        !this.docsDetails.docApproved
      ) {
        this.error.show = true;
        this.error.msg = "Borrower Authorization is not approved.";
        this.callLoader(false, null);
        return;
      }

      if (this.isComplete) {
        this.error = { msg: null, show: false };
        let noInfo = this.vomData.creditorInfo.filter((e) => e.email || e.fax);
        if (noInfo.length == 0) {
          this.error.show = true;
          this.error.msg = "Please provide atleast 1 creditor information";
          this.callLoader(false, null);
          return;
        } else {
          let emptyData = this.vomData.creditorInfo.filter((e) => e.option);
          let emptyOptionData = emptyData.filter(
            (m) =>
              (m.option == "email" && !m.email) || (m.option == "fax" && !m.fax)
          );

          if (emptyOptionData.length > 0) {
            this.error.show = true;
            this.error.msg = "Please fill empty field";
            this.callLoader(false, null);
            return;
          }
        }
      }

      
      let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      let checkvalidEmailData = this.vomData.creditorInfo.filter(
        (e) => e.option == "email" && !e.email.match(mailformat)
      );

 let faxformat =/^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;

      let checkvalidFaxData = this.vomData.creditorInfo.filter(
        (e) => e.option == "fax" && !e.fax.match(faxformat)
      );

   let emptyOptionData = this.vomData.creditorInfo.filter((e) => e.option == null);


if (
        this.isComplete &&
        (emptyOptionData.length > 0 )
      ) {
        this.error.show = true;
        this.error.msg = "Please Choose atleast 1 option";
        this.callLoader(false, null);
        return;
      }
       if (this.isComplete && checkvalidEmailData.length > 0) {
        this.error.show = true;
        this.error.msg = "Please Enter Valid Email ";

        this.callLoader(false, null);
        return;
      } else if (this.isComplete && checkvalidFaxData.length > 0) {
        this.error.show = true;
        this.error.msg = "Please Enter Valid Fax ";

        this.callLoader(false, null);
        return;
      }


      if(this.isComplete) this.isOrderPlacedOnce=true;
      let response = await Axios.post(
        BASE_API_URL + "processor/saveVomOrder",
        {
          loanId: this.$route.query.id,
          taskId: this.taskId,
          isComplete: this.isComplete,
          vomData: this.vomData,
        },
        { headers: authHeader() }
      );
      if (!this.isComplete) {
        this.$snotify.success("VOM Order Information has been saved");
      }

      if (response.status === 201 && this.isComplete)
        this.$snotify.success(response.data.msg);
      if (this.isComplete) this.$emit("refresh-task-list", true);
      await this.getVomOrderData();
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async getVomOrderData() {
    this.callLoader(true, null);
    try {
      const response = await Axios.get(
        BASE_API_URL +
          "processor/getVomOrderData/" +
          this.$route.query.id +
          "/" +
          this.taskId,
        { headers: authHeader() }
      );

      if (response.status === 201) {
        this.vomData = JSON.parse(JSON.stringify(response.data));
        this.dsData = JSON.parse(JSON.stringify(response.data));
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async uploadFile(event) {
    if (this.isTaskDisabled) {
      this.callLoader(false, null);
      this.$snotify.info("Task is not assigned to you.");
      this.getVomOrderData();
      return;
    }
    this.docLoader = true;
    try {
      if (event.target.files.length <= 0) return;
      const formData: any = new FormData();
      Array.from(event.target.files).forEach((element) => {
        formData.append("file", element);
      });
      let loanId: any = this.$route.query.id;
      formData.append("loanId", loanId);
      formData.append("taskId", this.taskId);
      formData.append("docName", "Borrower Authorization");
      const response = await Axios.post(
        BASE_API_URL + "processor/uploadBorrowerAuthorization",
        formData,
        { headers: authHeader() }
      );
      if (response.status === 201) this.$snotify.success(response.data.message);
      this.docsDetails = response.data.doc;
      await this.getBorrowerAuthorizationrDocument();
      await this.isBrokerNotifiedForRejectedDocs();
    } catch (error) {
      console.log(error);
    }
    this.docLoader = false;
  }

  public async viewDocument(document) {
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getVomOrderData();
        return;
      }
     document.loanId = this.$route.query.id;
          document.isRejected = this.isRejected,
        document.isAccepted = this.isAccepted,
       document.isChecklist = this.isChecklist,
        this.$refs.ref["showFile"](document);
    } catch (error) {
      console.log(error);
    }
  }

  public async isBrokerNotifiedForRejectedDocs() {
    try {
      let res = await Axios.get(
        BASE_API_URL +
          "loanChecklist/isBrokerNotifiedForRejectedDocs/" +
          this.$route.query.id +
          "/" +
          this.taskId+'/'+null,
        {
          headers: authHeader(),
        }
      );
      if (res.status == 201) {
        this.notifyBroker = res.data.notifyBorker;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public displayVal(data) {
    let datas: any = {};
    datas = this.accountType.find((e) => e.value == data);

    return datas.name;
  }

  public async getBorrowerAuthorizationrDocument() {
    this.callLoader(true, 1);
    try {
      let response = await Axios.get(
        BASE_API_URL +
          "processor/getBorrowerAuthorizationrDocument/" +
          this.$route.query.id,
        {
          headers: authHeader(),
        }
      );

      if (response.status === 201) {
        this.docsDetails = response.data;
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, 1);
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  async mounted() {
    await this.getBorrowerAuthorizationrDocument();
    await this.isBrokerNotifiedForRejectedDocs();
    await this.getVomOrderData();
  }
}
