/* eslint-disable  @typescript-eslint/no-explicit-any */
import Address from '../common/address.model';

export class OtherMortgage {
  constructor() {
    this.creditorName = null;
    this.mortgageLienType = null;
    this.monthlyPayment = 0;
    this.loanAmount = 0;
    this.creditLimit = 0;
  }

  public creditorName: any;
  public mortgageLienType: any;
  public monthlyPayment: number;
  public loanAmount: number;
  public creditLimit: number;
}
export class Gift {
  constructor() {
    this.assetType = null;
    this.isDeposited = null;
    this.source =
    {name:  null,
    description: null}
    this.cashOrMarketValue = 0;
  }

  public assetType: any;
  public isDeposited: any;
  public source: any;
  public cashOrMarketValue: number;
}
export class LoanPurpose {
  constructor() {
    this.name = null;
    this.description = null;
  }

  public name: any;
  public description: any;
}

export class LoanType {
  constructor() {
    this.type = null;
    this.description = null;
  }

  public type: any;
  public description: any;
}




export class LoanAndPropertyInfo {
  constructor() {
    this.loanId = null;
    this.isMixedUsedProperty = null;
    this.propertyAddress = new Address();
    this.otherMortgage = [new OtherMortgage()];
    this.gifts = [new Gift()];
    this.isPropertyManufacturedHome = null;
    this.loanTerm = 0;
    this.loanAmount = 0;
    this.loanNumber = null;
    this.refinanceType = null;
    this.interestRate = 0;
    this.propertyValue = 0;
    this.occupancy = null
    this.loanPurpose = new LoanPurpose();
    this.loanType = new LoanType();
    this.vaType = null;
    this.isFhaSecondaryResidence = null;
    this.hasOtherMortgage = null;
    this.hasGiftsOrGrants = null;
    this.expectedMonthlyRentalIncome = 0
    this.expectedNetMonthlyRentalIncome = 0
    this.attachmentType = null;
    this.totalLoanAmount = 0;
    this.mipPercent = 0;
    this.mip = 0;
    this.amountPaidCase = 0;
    this.monthlyMIPercent = 0;
    this.appraisedvalue = 0;

  }

  public loanId: any;
  public loanAmount: any;
  public loanNumber: any;
  public loanType: LoanType;
  public loanPurpose: LoanPurpose;
  public loanTerm: any;
  public refinanceType: any;
  public propertyAddress: Address;
  public propertyValue: any;
  public occupancy: any;
  public isMixedUsedProperty: any;
  public interestRate: number;
  public mortgageLienType: any;
  public otherMortgage: Array<OtherMortgage>;
  public expectedMonthlyRentalIncome: any;
  public expectedNetMonthlyRentalIncome: any;
  public gifts: Array<Gift>;
  public isPropertyManufacturedHome: any;
  public isFhaSecondaryResidence: any;
  public hasOtherMortgage: any;
  public hasGiftsOrGrants: any;
  public vaType: any;
  public attachmentType: any;
  public totalLoanAmount: any;
  public mipPercent: any;
  public mip: any;
  public amountPaidCase: any;
  public monthlyMIPercent: any;
  public appraisedvalue: any;
}
