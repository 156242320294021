
















































































































































































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "../../../config";
import { authHeader } from "../../../services/auth";
import ReOrderTask from "../../../common-components/ReOrderTask.vue";
import {
  VerificationOfAgent,
  //VendorData,
} from "../../../models/workflow/floodBinderOrder.model";
import PdfPreview from "../../../common-components/PDFPreview.vue";
@Component({
  components: {
    ReOrderTask,
    PdfPreview,
  },
})
export default class FloodBinderOrder extends Vue {
  /* eslint-disable */
  @Prop()
  public taskId: any;
  public floodBinderOrder: any = new VerificationOfAgent();
  // public error: any = { show: false, msg: null };
  public agent: any = {};
  // @Prop()
  // public isLoanInactive: any;
  @Prop()
  public reOrderCount: any;
  public countToSendOnServer: any = null;
  public allAgents: any = [];
  public selectedAgent: any = null;
  public isTaskCompleted = false;
  public mortgageClause: any = {};
  public agentType: any = null;
  public showReOrderBtn = false;
  public showHistoryBtn = false;
  public loanNumber: any = {};
  public isComplete = false;
  public error = false;
  public msg = "";
  public checkEmail = false;
  public checkFax = false;
  public isOrderPlacedOnce = false;
  /**
   * Taking dsData variable for checking form data is changed or not only for comaprison
   */
  public dsData: any = {};
  public tempSelectedAgent: any = null;
  /**************************************************************** */
  @Prop()
  public isTaskDisabled: any;

  public floodOrder: any = {};
  public pathForSaving: any = `./uploads/${this.$route.query.id}/requestforfloodbinder.pdf`;

  public async checkSaveValidation() {
    try {
      let returnV = false;
      if (
        !this.floodBinderOrder.isFloodBinderOrder &&
        this.floodBinderOrder.isFloodBinderOrder ==
          this.dsData.isFloodBinderOrder &&
        !this.floodBinderOrder.isInsuranceEscrowed &&
        this.floodBinderOrder.isInsuranceEscrowed ==
          this.dsData.isInsuranceEscrowed &&
        !this.floodBinderOrder.agentInfo.option &&
        this.dsData.agentInfo.option == this.dsData.agentInfo.option
      ) {
        if (!this.selectedAgent && this.selectedAgent == this.tempSelectedAgent)
          returnV = true;
        if (this.allAgents.length == 1 && this.selectedAgent) returnV = true;
      }
      return returnV;
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Saving Flood Binding Task Information
   */
  public async saveFloodBinderOrderTask() {
    if (this.isOrderPlacedOnce) return;
    this.callLoader(true, null);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getFloodBinderOrder();
        return;
      }
      if (!this.isComplete) {
        let notValid1 = await this.checkSaveValidation();
        if (notValid1) {
          this.callLoader(false, null);
          this.$snotify.info("No Changes");
          return;
        }
      }
      if (
        (!this.agent.agentContactData || !this.agent.agentData) &&
        this.isComplete
      ) {
        this.error = true;
        this.msg = "Agent Not Added yet.";
        this.callLoader(false, null);
        return;
      }
      if (
        this.isComplete &&
        (!this.floodBinderOrder.isFloodBinderOrder || !this.selectedAgent)
      ) {
        this.error = true;
        this.msg = "All fields are Required.";
        this.callLoader(false, null);
        return;
      }

      if (
        this.isComplete &&
        (this.floodBinderOrder.agentInfo.option == null ||
          (this.floodBinderOrder.agentInfo.option == "email" &&
            (this.floodBinderOrder.agentInfo.email == null ||
              this.floodBinderOrder.agentInfo.email == "")) ||
          (this.floodBinderOrder.agentInfo.option == "fax" &&
            (this.floodBinderOrder.agentInfo.fax == null ||
              this.floodBinderOrder.agentInfo.fax == "")))
      ) {
        this.error = true;
        this.msg = "All fields are Required.";
        this.callLoader(false, null);
        return;
      }

      let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      let checkvalidEmailData =
        this.floodBinderOrder.agentInfo.option == "email" &&
        !this.floodBinderOrder.agentInfo.email.match(mailformat);

      let faxformat = /^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
      let checkvalidFaxData =
        this.floodBinderOrder.agentInfo.option == "fax" &&
        !this.floodBinderOrder.agentInfo.fax.match(faxformat);
      if (this.isComplete && checkvalidFaxData) {
        this.error = true;
        this.msg = "Enter a valid fax.";
        this.callLoader(false, null);
        return;
      }
      if (this.isComplete && !this.floodBinderOrder.lenderId) {
        this.error = true;
        this.msg = "Lender Is Required.";
        this.callLoader(false, null);
        return;
      }
      if (this.isComplete && checkvalidEmailData) {
        this.error = true;
        this.msg = "Please Enter Valid Email ";

        this.callLoader(false, null);
        return;
      }
      let data = {
        loanId: this.$route.query.id,
        taskId: this.taskId,
        isComplete: this.isComplete,
        floodBinderOrder: this.floodBinderOrder,
        selectedAgent: this.selectedAgent,
        agentType: this.agentType,
        reOrderCount: this.countToSendOnServer,
        formData: this.dsData,
        pathForSaving: this.pathForSaving,
      };
      if (this.isComplete) this.isOrderPlacedOnce = true;
      let response = await Axios.post(
        BASE_API_URL + "processor/saveFloodBinderOrderTask",
        data,
        {
          headers: authHeader(),
        }
      );
      if (!this.isComplete) {
        this.$snotify.success("Flood Binder Order Information has been saved");
      }

      if (response.status == 201 && this.isComplete)
        this.$snotify.success("Flood Binder Order Completed successfully.");
      if (this.isComplete) this.$emit("refresh-task-list", true);
      await this.getFloodBinderOrder();
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async getFloodBinderOrder() {
    this.callLoader(true, null);
    try {
      const response = await Axios.get(
        BASE_API_URL +
          "processor/getFloodBinderOrder/" +
          this.$route.query.id +
          "/" +
          this.taskId +
          "/" +
          this.countToSendOnServer,
        {
          headers: authHeader(),
        }
      );

      if (response.status === 201) {
        this.agentType = response.data.agentType;
        this.isTaskCompleted = response.data.isCompleted;
        this.showReOrderBtn = response.data.showReOrderBtn;
        this.showHistoryBtn = response.data.showHistoryBtn;
        this.floodBinderOrder = JSON.parse(
          JSON.stringify(response.data.floodBinderOrder)
        );
        this.dsData = JSON.parse(
          JSON.stringify(response.data.floodBinderOrder)
        );
        if (response.data.floodBinderOrder.agentInfo.email) {
          this.floodBinderOrder.agentInfo.email = JSON.parse(
            JSON.stringify(response.data.floodBinderOrder.agentInfo.email)
          );
        }
        if (response.data.floodBinderOrder.agentInfo.fax) {
          this.floodBinderOrder.agentInfo.fax = JSON.parse(
            JSON.stringify(response.data.floodBinderOrder.agentInfo.fax)
          );
        }
        this.loanNumber = response.data.loanNumber;
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  public async reOrderFlood() {
    this.callLoader(true, 1);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getFloodBinderOrder();
        return;
      }
      this.$refs.refReOrder["confitmationBox"]({
        message: "Are you sure you want to RE-Order Flood Binder?",
        apiPath: `processor/reOrderFlood`,
        data: {
          loanId: this.$route.query.id,
          taskId: this.taskId,
          selectedAgent: this.selectedAgent,
        },
      });
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async getListOfAgents() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "processor/getListOfAgents",
        {
          loanId: this.$route.query.id,
          agentType: this.agentType,
        },
        {
          headers: authHeader(),
        }
      );

      if (response.status === 201) this.allAgents = response.data;
    } catch (error) {
      console.log(error);
    }
  }

  public async getSelectedAgent() {
    try {
      this.agent = {};
      this.selectedAgent = null;
      let response = await Axios.post(
        BASE_API_URL + "processor/getSelectedAgent",
        {
          loanId: this.$route.query.id,
          agentType: this.agentType,
          taskId: this.taskId,
          // reOrderCount: this.countToSendOnServer,
          agentId: this.floodBinderOrder.agentContactId,
          companyId: this.floodBinderOrder.companyId,
          lenderId: this.floodBinderOrder.lenderId,
          isLoanCompleted: this.isTaskCompleted,
        },
        {
          headers: authHeader(),
        }
      );

      if (response.status == 201) {
        if (
          response.data.agentData &&
          response.data.agentData.agentContactData
        ) {
          this.agent = response.data.agentData;
          this.selectedAgent = JSON.parse(
            JSON.stringify(this.agent.agentContactData._id)
          );
          this.tempSelectedAgent = JSON.parse(
            JSON.stringify(this.agent.agentContactData._id)
          );
          this.floodBinderOrder.agentContactId =
            this.agent.agentContactData._id;
          this.floodBinderOrder.companyId = this.agent.agentData._id;
        }
        this.mortgageClause = response.data.mortgageClause;
        this.floodBinderOrder.lenderId = response.data.lenderId;

        this.floodBinderOrder.agentInfo.email =
          this.agent.agentContactData && this.agent.agentContactData.email
            ? this.agent.agentContactData.email
            : this.floodBinderOrder.agentInfo.email;

        this.floodBinderOrder.agentInfo.fax =
          this.agent.agentContactData && this.agent.agentContactData.fax
            ? this.agent.agentContactData.fax
            : this.floodBinderOrder.agentInfo.fax;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public onChangingAgent() {
    this.agent = this.allAgents.find(
      (e) => e.agentContactId == this.selectedAgent
    ).agentData;

    this.floodBinderOrder.agentInfo.email =
      this.agent.agentContactData && this.agent.agentContactData.email
        ? this.agent.agentContactData.email
        : null;
    this.floodBinderOrder.agentInfo.fax =
      this.agent.agentContactData && this.agent.agentContactData.fax
        ? this.agent.agentContactData.fax
        : null;

    this.floodBinderOrder.agentContactId = this.agent.agentContactData._id;
    this.floodBinderOrder.companyId = this.agent.agentData._id;
  }

  public async showHistory() {
    this.callLoader(true, 1);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getFloodBinderOrder();
        return;
      }
      this.$refs.refReOrder["showHistory"](this.taskId, "Flood Binder");
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }
  public async floodDownloadPDF() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "processor/floodDownloadPDF",
        {
          loanId: this.$route.query.id,
          taskId: this.taskId,
          floodOrder: this.floodOrder,
          selectedAgent: this.selectedAgent,
          pathForSaving: this.pathForSaving,
        },
        {
          headers: authHeader(),
        }
      );
      if (response.status == 201) {
        this.$refs.PDFRef["showFile"]({
          content: response.data,
          name: "FloodBinder",
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async allMountFunction(count) {
    this.countToSendOnServer = count;
    await this.getFloodBinderOrder();
    await this.getSelectedAgent();
    await this.getListOfAgents();
  }

  public async mounted() {
    await this.allMountFunction(this.reOrderCount);
  }
}
