





  import { Component, Vue } from "vue-property-decorator";
  // import Axios from "axios";
  // import { BASE_API_URL, EventBus } from "../../config";
  // import { authHeader } from "../../services/auth";
  // import ViewDocument from "../ViewDocument.vue";
  // import { DOCUMENT_CATEGORY } from "../../constants/constants";
  // import pdf from "vue-pdf";
  @Component({
    components: {
      // ViewDocument,
      // pdf,
    },
  })
  export default class DeletedDoc extends Vue {
    /* eslint-disable */
    
  /* eslint-disable */
}
