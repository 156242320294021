export class AgentInfo {
    constructor() {
      this.option = null;
      this.email = null;
      this.fax = null;
      
    }
    public option: any;
    public email: any;
    public fax: any;

  
  }


  export class VerificationOfAgent {
    constructor() {
        this.isFloodBinderOrder = true;
        this.agentInfo = new AgentInfo();
      }public isFloodBinderOrder: any;
      public agentInfo: AgentInfo;
}
