






























































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "../../../config";
import { authHeader } from "../../../services/auth";
// import CommonNotifyOnRejection from "../../../common-components/CommonNotifyOnRejection.vue";
import ViewDocument from "../../../common-components/ViewDocument.vue";
import FollowUpTask from "../../../common-components/FollowUpTask.vue";
import ReOrderTask from "../../../common-components/ReOrderTask.vue";

@Component({
  components: {
    ViewDocument,
    // CommonNotifyOnRejection,
    FollowUpTask,
    ReOrderTask,
  },
})
export default class PudQuestionnaireReceived extends Vue {
  /* eslint-disable */
  @Prop()
  public taskId: any;
  public loanId: any = this.$route.query.id;
  public pudQuestionnaireReceived: any = {};
  public followUpHistory: any = [];
  public error: any = {
    show: false,
    msg: null,
  };

  public docLoader = false;
  public isCompleted = false;
  @Prop()
  public isLoanInactive: any;
  public countToSendOnServer: any = null;
  public showHistoryBtn = false;
  @Prop()
  public reOrderCount: any;
  public agentContactId = null;
  public isOrderPlacedOnce = false;
  // public notifyBroker = false;
  /**
   * Taking dsData variable for checking form data is changed or not only for comaprison
   */
  public dsData: any = {};
  /**************************************************************** */
  @Prop()
  public isTaskDisabled: any;

  public async uploadReport(event) {
    if (this.isTaskDisabled) {
      this.callLoader(false, null);
      this.$snotify.info("Task is not assigned to you.");
      this.getPudQuestionnaireRecieved();
      return;
    }
    this.docLoader = true;
    try {
      if (event.target.files.length <= 0) return;
      const formData: any = new FormData();
      Array.from(event.target.files).forEach((element) => {
        formData.append("file", element);
      });
      formData.append("loanId", this.loanId);
      formData.append("taskId", this.taskId);
      formData.append("reOrderCount", this.reOrderCount);
      const response = await Axios.post(
        BASE_API_URL + "processor/uploadPudQuestionnaireReport",
        formData,
        { headers: authHeader() }
      );
      if (response.status === 201) this.$snotify.success(response.data.message);
      this.pudQuestionnaireReceived.loanChecklistData =
        response.data.loanChecklistData;
    } catch (error) {
      console.log(error);
    }
    this.docLoader = false;
  }

  public async checkSaveValidation() {
    try {
      if (
        !this.pudQuestionnaireReceived.isPudQuestionnaireRecieved &&
        this.pudQuestionnaireReceived.isPudQuestionnaireRecieved ==
          this.dsData.isPudQuestionnaireRecieved
      )
        return true;
      else return false;
    } catch (error) {
      console.log(error);
    }
  }

  public async savePudQuestionnaireRecievedTask(isComplete) {
    if(this.isOrderPlacedOnce) return
    this.callLoader(true, null);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getPudQuestionnaireRecieved();
        return;
      }
      if (!isComplete) {
        let notValid1 = await this.checkSaveValidation();
        if (notValid1) {
          this.callLoader(false, null);
          this.$snotify.info("No Changes");
          return;
        }
      }

      if (
        isComplete &&
        !this.pudQuestionnaireReceived.isPudQuestionnaireRecieved
      ) {
        this.error.show = true;
        this.error.msg = "All fields are Required.";
        this.callLoader(false, null);
        return;
      }
      if (isComplete && !this.pudQuestionnaireReceived.loanChecklistData) {
        this.error.show = true;
        this.error.msg = "Upload PUD Questionnaire Report";
        this.callLoader(false, null);
        return;
      }
          if(isComplete) this.isOrderPlacedOnce=true;

      const response = await Axios.post(
        `${BASE_API_URL}processor/savePudQuestionnaireRecievedTask`,
        {
          pudQuestionnaireReceived: this.pudQuestionnaireReceived,
          taskId: this.taskId,
          loanId: this.$route.query.id,
          isComplete: isComplete,
          reOrderCount: this.countToSendOnServer,
        },
        {
          headers: authHeader(),
        }
      );
      if (!isComplete) {
        this.$snotify.success(
          "PUD Questionnaire Received Information has been saved"
        );
      }

      if (response.status === 201 && isComplete)
        this.$snotify.success(response.data.msg);
      if (isComplete) this.$emit("refresh-task-list", true);
      await this.getPudQuestionnaireRecieved();
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  public async getPudQuestionnaireRecieved() {
    this.callLoader(true, null);
    try {
      const response = await Axios.get(
        BASE_API_URL +
          "processor/getPudQuestionnaireRecieved/" +
          this.$route.query.id +
          "/" +
          this.taskId +
          "/" +
          this.countToSendOnServer,
        {
          headers: authHeader(),
        }
      );

      if (response.status === 201) {
        this.agentContactId = response.data.agentContactId;
        this.pudQuestionnaireReceived = response.data.pudQuestionnaireReceived
          ? JSON.parse(JSON.stringify(response.data.pudQuestionnaireReceived))
          : {};
        this.dsData = JSON.parse(
          JSON.stringify(response.data.pudQuestionnaireReceived)
        );
        this.pudQuestionnaireReceived.loanChecklistData =
          response.data.loanChecklistData;
        this.isCompleted = response.data.isCompleted;
        this.showHistoryBtn = response.data.showHistoryBtn;
        console.log(this.showHistoryBtn);
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async viewDocument(document) {
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getPudQuestionnaireRecieved();
        return;
      }
      document.loanId = this.$route.query.id;
      this.$refs.ref["showFile"](document);
    } catch (error) {
      console.log(error);
    }
  }

  public async sendFollowUpMailToPudAgent() {
    this.callLoader(true, 1);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getPudQuestionnaireRecieved();
        return;
      }
      this.$refs.refFollowUp["openFollowUpModal"]({
        label: "Message To PUD Agent",
        message: "Follow Up",
        apiPath: `processor/sendFollowUpMailToPudAgent`,
        data: {
          loanId: this.$route.query.id,
          taskId: this.taskId,
          agentContactId: this.agentContactId,
          reOrderCount: this.countToSendOnServer
        },
      });
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async viewFollowUpHistory() {
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getPudQuestionnaireRecieved();
        return;
      }

      this.$refs.refFollowUp["openHistoryModal"]({
        label: "Select method to send",
        message: "Follow Up",
        apiPath: `processor/getFollowUpHistoryOfTitle`,
        data: { loanId: this.$route.query.id, taskId: this.taskId, reOrderCount: this.countToSendOnServer },
      });
    } catch (error) {
      console.log(error);
    }
  }
  public async showHistory() {
    this.callLoader(true, 1);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getPudQuestionnaireRecieved();
        return;
      }
      this.$refs.refReOrder["showHistory"](this.taskId);
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async allMountFunction(count) {
    this.countToSendOnServer = count;
    await this.getPudQuestionnaireRecieved();
  }

  public async mounted() {
    await this.allMountFunction(this.reOrderCount);
  }
}
