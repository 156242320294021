























































































  import { Component, Vue, Prop } from "vue-property-decorator";
  import Axios from "axios";
  import { BASE_API_URL } from "../../../config";
  import { authHeader } from "../../../services/auth";
  import {
    VerificationOfAgent,
  //VendorData,
} from "../../../models/workflow/re-Inspection-lendersRequest.model";
@Component
export default class ReInspectionLenderRequestOrder extends Vue {
  /* eslint-disable */
  @Prop()
  public taskId: any;
  public reInspectionLenderRequestOrder: any = new VerificationOfAgent();
  public error = false;
  public agent: any = {};
  @Prop()
  public isLoanInactive: any;
  public isLoanCompleted = false;
  public mortgageClause: any = {};
  public creditAgencies: any = [];
  public selectedCompany: any = null;
  public isOrderPlacedOnce = false
   /**
   * Taking dsData variable for checking form data is changed or not only for comaprison
   */
   public dsData: any = {};
   /**************************************************************** */

   public async checkSaveValidation() {
    try {
      if (!this.reInspectionLenderRequestOrder.isreInspectionLenderRequestOrdered  &&
        this.reInspectionLenderRequestOrder.isreInspectionLenderRequestOrdered == this.dsData.isreInspectionLenderRequestOrdered)
        return true;
      else return false;
    } catch (error) {
      console.log(error);
    }
  }


  public async checkValidation() {
    try {
      if (
        !this.reInspectionLenderRequestOrder.creditaAgenciesId ||
        !this.reInspectionLenderRequestOrder.isreInspectionLenderRequestOrdered
        )
        return true;
      else false;
    } catch (error) {
      console.log(error);
    }
  }


  



  public async saveReInspectionLenderRequestOrder(isComplete) {
    if(this.isOrderPlacedOnce) return
    this.callLoader(true, null);
    try {
     // let notValid = await this.checkValidation();
      if (!isComplete)  {
        let notValid1 = await this.checkSaveValidation();
        if (notValid1) {
          this.callLoader(false, null);
          this.$snotify.info('No Changes')
          return;
        }
      }
     
      if (!this.reInspectionLenderRequestOrder.isreInspectionLenderRequestOrdered && isComplete) {
        this.error = true;
        this.callLoader(false, null);
        return;
      }

       if(isComplete) this.isOrderPlacedOnce=true;
      let response = await Axios.post(
        BASE_API_URL + "processor/saveReInspectionLenderRequestOrder",
        {
          taskId: this.taskId,
          loanId: this.$route.query.id,
          reInspectionLenderRequestOrder: this.reInspectionLenderRequestOrder,
          isComplete: isComplete,
          formData: this.dsData
        },
        { headers: authHeader() }
        );
      if (!isComplete) {
        this.$snotify.success(
          "Re-Inspection Lenders Request Information has been saved"
          );
      }

      if (response.status === 201 && isComplete) {
        this.$snotify.success(
          "Re-Inspection Lenders Request Order details Completed Successfully"
          );
      }
      if (isComplete) this.$emit("refresh-task-list", true);
      await this.getReInspectionLenderRequestOrder();
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async getReInspectionLenderRequestOrder() {
    this.callLoader(true, null);
    try {
      const response = await Axios.get(
        BASE_API_URL +
        "processor/getReInspectionLenderRequestOrder/" +
        this.$route.query.id +
        "/" +
        this.taskId,
        {
          headers: authHeader(),
        }
        );

      if (response.status === 201) {
        this.isLoanCompleted = response.data.isCompleted;
        this.reInspectionLenderRequestOrder = JSON.parse(JSON.stringify(response.data.reInspectionLenderRequestOrder));
        this.dsData = JSON.parse(JSON.stringify(response.data.reInspectionLenderRequestOrder));
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }


  public onChangeF(id) {
    this.selectedCompany = id
    ? this.creditAgencies.filter((e) => e._id === id)[0]
    : null;
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  public mounted() {
    this.getReInspectionLenderRequestOrder();
    
  }
}
