












































































































































































































































































































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL, EventBus } from "../../config";
import { authHeader } from "../../services/auth";
import ViewDocument from "../ViewDocument.vue";
import { DOCUMENT_CATEGORY } from "../../constants/constants";
import SubmissionForm from "../../components/broker/loan/submission-form/SubmissionForm.vue";
import suneditor from "suneditor";
import plugins from "suneditor/src/plugins";
import loanInfo from "../../common-components/LoanInfoComponent.vue";
//import Processor from "./processor/Processor.vue";
import Urla from "../urla/Urla.vue";
import moment from "moment";

@Component({
  components: {
    ViewDocument,
    SubmissionForm,
    Urla,
    loanInfo,
  },
})
export default class MinimumCreditDocs extends Vue {
  /* eslint-disable */
  @Prop()
  public documentValidation;
  public documents: any = [];
  public loanId: any = this.$route.query.id;
  public files = [];
  public isValid: any = false;
  public loanDetails: any = null;
  public hasSubmit = false;
  public docId = null;
  public documentCategory = DOCUMENT_CATEGORY;
  public document: any = {};
  public selectedDocumentId: any = "";
  public editor: any = null;
  public notifyRemark = "";
  public remarkError = false;
  public submittedMilestone = false;
  public isUpload = false;
  public isRejected = false;
  public isAccepted = false;
  public isChecklist = true;

  public async notifyProcessor() {
    await EventBus.$emit("processorNotifed");
  }

  public async getDropDocument(event, document) {
    document.isUploading = true;
    try {
      let droppedFiles = event.dataTransfer.files;
      if (droppedFiles.length == 0) return;
      else await this.uploadDocument(droppedFiles, document);
    } catch (error) {
      console.log(error);
    }
  }

  public async getSelectedDocument(event, document) {
    document.isUploading = true;
    try {
      let files = event.target.files;
      if (files.length == 0) return;
      else await this.uploadDocument(files, document);
    } catch (error) {
      console.log(error);
    }
  }

  public async getAllDocuments() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "loanChecklist/getAllDocuments",
        {
          loanId: this.loanId,
        },
        {
          headers: authHeader(),
        }
      );
      this.documents = response.data.documents;
      this.documents = this.documents.map((ele) => {
        let msg = "";
        if (ele.allRejectedComments && ele.allRejectedComments.length > 0) {
          for (let j = 0; j < ele.allRejectedComments.length; j++) {
            const e = ele.allRejectedComments[j];
            msg += `${e.comment} ${
              e.acceptedOrRejectedOn
                ? moment(e.acceptedOrRejectedOn).format("MM/DD/YYYY, h:mm:ss a")
                : ""
            } -${e.name ? e.name : ""} *** `;
          }
        }
        ele["msgString"] = msg;

        let msg2 = "";
        if (ele.allAcceptedData && ele.allAcceptedData.length > 0) {
          for (let j = 0; j < ele.allAcceptedData.length; j++) {
            const e = ele.allAcceptedData[j];
            msg2 += `${
              e.acceptedOrRejectedOn
                ? moment(e.acceptedOrRejectedOn).format("MM/DD/YYYY, h:mm:ss a")
                : ""
            } -${e.name ? e.name : ""} *** `;
          }
        }
        ele["msgString1"] = msg2;

        return ele;
      });

      this.loanDetails = response.data.loanDetails;
      this.submittedMilestone = response.data.submittedMilestone;
      if (this.$store.state.sessionObj.userType === 3)
        EventBus.$emit("brokerNotifed");
    } catch (error) {
      console.log(error);
    }
  }

  public getDate(document) {
    let files = document.docFiles[document.docFiles.length - 1].files;
    document.lastUpdatedOn = files[files.length - 1].uploadedOn
      ? files[files.length - 1].uploadedOn
      : null;
    return document;
  }

  public async viewDocument(document) {
    try {
      await this.$refs.ref["showFile"]({
        documentId: document._id,
        loanId: this.$route.query.id,
        docName: document.name,
        docCategory: document.category,
        isComplete: document.isComplete,
        borrowerName: document.borrowerName,
        isRejected: this.isRejected,
        isAccepted: this.isAccepted,
        isChecklist: this.isChecklist,
      });
    } catch (error) {
      console.log(error);
    }
  }

  public async uploadDocument(files, document) {
    document.isUploading = true;
    try {
      let formData = new FormData();
      this.docId = document._id;
      formData.append("loanId", this.loanId);
      formData.append("documentId", document._id);

      if (document.borrowerUserId)
        formData.append("borrowerUserId", document.borrowerUserId);
      for (let i = 0; i < files.length; i++) {
        const element = files[i];
        formData.append("file", element);
      }

      let response = await Axios.post(
        BASE_API_URL + "loanChecklist/uploadDocument",
        formData,
        { headers: authHeader() }
      );

      if (response.status == 201) {
        console.log(response.data, "response.data");

        this.$snotify.success("Document has been uploaded");
        let doc = response.data.checklist.find((c) => c._id == document._id);
        document["docStatus"] = doc.docStatus;
        document["docFiles"] = doc.docFiles;
        document["isComplete"] = doc.isComplete;
        document["docUploadedBy"] = response.data.uploadedBy;
        document["acceptedByComment"] = response.data.acceptedByComment;

        //console.log('document',uploadedBy)

        EventBus.$emit("processorNotifed");
        await this.getAllDocuments();
        document["docUploadedBy"] = response.data.uploadedBy;

        if (this.$route.query.name == "disclosure" && this.hasSubmit) {
          await this.validateDocuments();
          EventBus.$emit("validation");
        }
      }
    } catch (error) {
      console.log(error);
    }
    document.isUploading = false;
  }

  public async submit() {
    this.hasSubmit = true;
    this.isValid = this.validateDocuments();

    if (!this.isValid) {
      this.$snotify.warning("Please upload mandatory documents");
      return;
    }
    this.$emit("nextTab");
    if (this.$route.query.name == "disclosure") {
      EventBus.$emit("validation");
    }
  }

  public validateDocuments() {
    let hasDocToUpload: any;
    if (this.loanDetails.idsHandledByTMPCO) {
      this.documents.forEach((d: any) => {
        Vue.set(
          d,
          "showValidation",
          d.source == "Broker Disclosure" ? true : false
        );
      });
      hasDocToUpload = this.documents.find(
        (d: any) =>
          d.source == "Broker Disclosure" && d.docStatus === "Required"
      );

      if (this.$route.query.name === "disclosure") {
        this.documents.forEach((d: any) => {
          Vue.set(
            d,
            "showValidation",
            d.docStatus == "Required" ? true : false
          );
        });
        hasDocToUpload = this.documents.find(
          (d: any) => d.docStatus === "Required"
        );
      }
    } else {
      this.documents.forEach((d: any) => {
        Vue.set(d, "showValidation", d.docStatus == "Required" ? true : false);
      });
      hasDocToUpload = this.documents.find(
        (d: any) => d.docStatus == "Required"
      );
    }
    return hasDocToUpload ? false : true;
  }

  public async updateLoanChecklist(documentId, isComplete) {
    try {
      let response = await Axios.post(
        BASE_API_URL + "loanChecklist/updateLoanChecklist",
        {
          loanId: this.loanId,
          documentId: documentId,
          isComplete: isComplete,
        },
        {
          headers: authHeader(),
        }
      );
      if (response.data == 200) {
        this.$snotify.success("Checklist has been Updated");
      }
    } catch (error) {
      console.log(error);
    }
  }

  public isProcessor() {
    return this.loanDetails.userType == 3 ? true : false;
  }

  public movePriviouseTab() {
    window.history.back();
  }

  public getDocName(document) {
    if (document.name && document.name != null) {
      return (
        document.name.replace(`<p>\g`, "").replace(`</p>\g`, "") +
        `  <strong>${
          document.borrowerName ? "" + document.borrowerName + "" : ""
        }</strong>`
      );
    }
  }

  public isUploadedByProcessor(document) {
    let isUploadedByProcessor = false;
    if (document.hasOwnProperty("toBeUploadedBy"))
      isUploadedByProcessor = document.toBeUploadedBy.find((u: any) => u == 3);
    return this.$store.state.sessionObj.userType != 3 && isUploadedByProcessor
      ? true
      : false;
  }

  public async addMinDocument() {
    try {
      let body = {
        loanId: this.loanId,
        category: this.document.category,
        name: this.document.name,
      };
      let res = await Axios.post(
        BASE_API_URL + "loanChecklist/addMinDocument",
        body,

        {
          headers: authHeader(),
        }
      );
      if ((res.status = 201)) {
        this.cancel();
        this.$snotify.success("Document has been Added.");
        EventBus.$emit("refreshDocs");
      }
    } catch (error) {
      console.log(error);
    }
  }

  public addDocumentModal() {
    this.$modal.show("addDocs");
    this.document.name = null;
  }

  public cancel() {
    this.$modal.hide("addDocs");
  }

  public selectedDoc(doc) {
    this.selectedDocumentId = doc;
  }

  public async deleteAddedDoc() {
    try {
      //console.log("doc", doc);
      this.callLoader(true, 1);
      let response = await Axios.post(
        BASE_API_URL + "loanChecklist/deleteAdditionalDoc",
        {
          loanId: this.loanId,
          additionDocId: this.selectedDocumentId,
        },
        {
          headers: authHeader(),
        }
      );
      if (response.data) {
        this.$modal.hide("deleteConfirmationModal");
        this.$snotify.success(response.data.msg);
        await this.getAllDocuments();
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public openProcessingDateModal() {
    setTimeout(async () => {
      this.editor = await suneditor.create("pageEditor", {
        plugins: {
          ...plugins,
        },
        width: "auto",
        height: "100px",
        buttonList: [["bold"], ["list"]],
      });
    }, 1);
    this.$modal.show("Incomplet- Credit-Package");
  }

  public async notifyIncompleteCreditPackage() {
    try {
      this.notifyRemark = this.editor.getContents();
      if (
        this.notifyRemark
          .replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, "")
          .trim() != ""
      ) {
        this.remarkError = false;
      } else {
        this.remarkError = true;
      }

      let myobj = {
        remarkNote: this.notifyRemark,
        loanId: this.loanId,
      };

      if (
        this.notifyRemark &&
        this.notifyRemark
          .replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, "")
          .trim() != ""
      ) {
        let res = await Axios.post(
          BASE_API_URL + "loanChecklist/notifyIncompleteCreditPackage",
          myobj,
          {
            headers: authHeader(),
          }
        );
        if (res.status == 201) {
          this.remarkError = false;
          this.notifyRemark = "";
          this.$modal.hide("Incomplet- Credit-Package");
          this.$snotify.success("Boker Notified Successfully");
          await EventBus.$emit("refesh-loaninfo");
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  public async mounted() {
    console.log("this.$route.name", this.$route.name);
    await this.getAllDocuments();
    if (this.documentValidation) {
      this.validateDocuments();
    }
    EventBus.$on("refreshDocs", async () => {
      await this.getAllDocuments();
    });

    EventBus.$on("refesh-loaninfo", async () => {
      await this.$refs.loanInfo["getProgressBarList"]();
      //await this.$refs.loanInfo["getLoanDetails"]();
    });
  }

  /* eslint-disable */
}
