












































































































































































































































































































































































































































































































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL, EventBus } from "../config";
import { authHeader } from "../services/auth";
import { AgentInfo, AgentContactInfo } from "../models/agent/agent.model";
@Component
export default class AgentComponent extends Vue {
  /* eslint-disable */
  @Prop()
  public agentType;
  @Prop()
  public hide;
  @Prop()
  public idsHandledByTMPCO;
  public addNew = false;
  public newAgent = false;
  public addNewContact = true;
  public showFields = false;
  public showContactFields = false;
  public agentId = "0";
  public previousContactId: any = null;
  public agentContactId = "0";
  public agentList: any = [];
  public agentContacts = [];
  public agentInfo = new AgentInfo();
  public agentContactInfo = new AgentContactInfo();
  public loanId = this.$route.query.id;
  public agentValidation: any = {};
  public contactValidation: any = {};
  public isDisabled: any = false;
  public stateAndCounty = [];
  public messageError: any = null;
  @Prop()
  public selectedAgent: any;
  @Prop()
  public isEdit;
  public isComplete = false;
  public agentCompanyError: any = { show: false, msg: null };
  public agentContactError: any = { show: false, msg: null };
  @Prop()
  public conVal;
  @Prop()
  public agentsNotSelected;
  public websiteError: any = "";
  public emailError: any = "";
  public phoneError: any = "";
  public zipError: any = false;
  @Prop()
  public contactMsg;
  public multipleAgentError: any = null;
  public existingAgentInfo: any = null;
  public checkEmailDuplicacy: any = false;
  public emailForcedChanged: any = false;
  public emailValidationError: any = null;

  public async getStatesAndCounty() {
    try {
      const response = await Axios.get(
        BASE_API_URL + "common/getStatesAndCounty"
      );
      this.stateAndCounty = response.data;
    } catch (error) {
      console.log(error);
    }
  }

  public async getAgentList() {
    let res = await Axios.get(
      BASE_API_URL + "agent/getActiveAgentList/" + this.agentType.id,
      {
        headers: authHeader(),
      }
    );

    this.agentList = res.data ? res.data : [];
  }
  public checkZip() {
    if (this.agentInfo.address.zip) {
      if (
        this.agentInfo.address.zip.length >= 5 &&
        this.agentInfo.address.zip.length < 9
      )
        this.zipError = false;
      else {
        this.zipError = true;
        return;
      }
    }
  }

  public async saveAgent() {
    try {
      if (this.agentInfo.isNa) {
        this.agentId = "0";
        this.agentInfo.name = null;
        if (!this.agentInfo.message && this.agentType.id == 3) {
          this.messageError = "please give reason";
          this.callLoader(false, null);
          return;
        }
      }

      if (
        this.agentInfo.name &&
        this.agentContactInfo.fullName &&
        !this.agentContactInfo.fullName.firstName
      ) {
        this.$snotify.warning("Please Add contact details");
        return;
      }

      if (this.agentInfo.website) {
        let res = this.agentInfo.website.match(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        );
        if (res == null) {
          this.websiteError = "Please provide valid website";
          return;
        }
      }

      if (this.agentInfo.address.zip) {
        this.checkZip();
      }

      if (this.agentContactInfo) {
        if (this.agentContactInfo.email) {
          let email =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          let validEmail = email.test(this.agentContactInfo.email);
          if (!validEmail) {
            this.emailError = "Please provide valid Email";
            return;
          }
          if (this.emailForcedChanged || this.checkEmailDuplicacy) {
            let checkMail = await this.emailValidationForAgent(
              this.agentContactInfo.email
            );
            if (
              checkMail &&
              checkMail.emailExists &&
              !checkMail.isEmailAllowed
            ) {
              this.emailValidationError =
                "You are not allowed to use this Email Address";
              return;
            }

            if (
              checkMail &&
              checkMail.emailExists &&
              !checkMail.isSameAgentType
            ) {
              this.emailValidationError =
                "Email is currently used by different type of agent";
              return;
            }
            if (checkMail && checkMail.emailExists && checkMail.isEmailAllowed)
              return this.$modal.show("duplicateEmail");
            if (checkMail && checkMail.emailExists) return;
          }
        }
        if (
          this.agentContactInfo.phone &&
          this.agentContactInfo.phone.length != 14
        ) {
          this.phoneError = "Please provide valid phone";
          return;
        }
      }
      if (!this.isComplete && this.agentId == "new") {
        if (
          !this.agentInfo.name &&
          !this.agentInfo.website &&
          !this.agentInfo.NMLSId &&
          !this.agentInfo.licenseId &&
          !this.agentInfo.address.street &&
          !this.agentInfo.address.city &&
          !this.agentInfo.address.state &&
          !this.agentInfo.address.zip
        ) {
          if (
            !this.agentContactInfo.fullName.firstName &&
            (this.agentContactInfo.fullName.lastName ||
              this.agentContactInfo.contactNMLSId ||
              this.agentContactInfo.contactlicenseId ||
              this.agentContactInfo.email ||
              this.agentContactInfo.phone)
          ) {
            this.agentCompanyError.show = true;
            this.agentCompanyError.msg = "Please provide Company Name";
            this.agentContactError.show = true;
            this.agentContactError.msg = "Please provide first name";

            return;
          }
          if (
            this.agentContactInfo.fullName.firstName ||
            this.agentContactInfo.fullName.lastName ||
            this.agentContactInfo.contactNMLSId ||
            this.agentContactInfo.contactlicenseId ||
            this.agentContactInfo.email ||
            this.agentContactInfo.phone
          ) {
            this.agentCompanyError.show = true;
            this.agentCompanyError.msg = "Please provide Company Name";
            return;
          }

          this.$emit("next-tab");
          return;
        }
        if (
          !this.agentInfo.name &&
          (this.agentInfo.website ||
            this.agentInfo.NMLSId ||
            this.agentInfo.licenseId ||
            this.agentInfo.address.street ||
            this.agentInfo.address.city ||
            this.agentInfo.address.state ||
            this.agentInfo.address.zip)
        ) {
          this.agentCompanyError.show = true;
          this.agentCompanyError.msg = "Please provide Company Name";

          return;
        }
      }

      if (this.agentContactId == "new" && !this.isComplete) {
        if (
          !this.agentContactInfo.fullName.firstName &&
          (this.agentContactInfo.fullName.middleName ||
            this.agentContactInfo.fullName.lastName ||
            this.agentContactInfo.contactNMLSId ||
            this.agentContactInfo.contactlicenseId ||
            this.agentContactInfo.email ||
            this.agentContactInfo.phone)
        ) {
          this.agentContactError.show = true;
          this.agentContactError.msg = "Please provide first name";
          return;
        }
      }

      this.agentCompanyError.show = false;
      this.agentContactError.show = false;

      this.callLoader(true, 2);
      this.isDisabled = true;

      if (!this.agentId) {
        await this.saveLoanAgentContact({ agentId: null });
        EventBus.$emit("contactValidation", this.agentType.position);
        this.$emit("next-tab");
        this.callLoader(false, null);
      }
      if (this.agentId == "0") {
        await this.saveLoanAgentContact({ agentId: null });
        if (this.$route.name != "DisclosureSign")
          EventBus.$emit("contactValidation", this.agentType.position);

        if (
          this.idsHandledByTMPCO ||
          this.agentType.id == 2 ||
          this.agentType.id == 3 ||
          this.agentType.id == 6 ||
          this.agentType.id == 7 ||
          this.agentType.id == 8 ||
          this.agentType.id == 9 ||
          this.agentType.id == 11
        ) {
          this.callLoader(false, null);
          this.isDisabled = false;
          this.$emit("next-tab");
        } else {
          this.callLoader(false, null);
          this.isDisabled = false;
          if (!this.agentInfo.isNa)
            this.$snotify.warning("Please select Company or Add new Company");
        }
      } else {
        if (this.showFields) {
          this.agentInfo.type = this.agentType.id;
          const response = await Axios.post(
            BASE_API_URL + "agent/saveAgent",
            {
              ...this.agentInfo,
              agentId: this.agentId,
            },
            {
              headers: authHeader(),
            }
          );
          if (response.status == 201) {
            if (this.agentId)
              await this.saveAgentContact(response.data.agentId);

            if (this.$route.name == "DisclosureSign")
              EventBus.$emit("validation");
            else EventBus.$emit("contactValidation", this.agentType.position);

            if (
              this.$store.state.sessionObj.userType == 3 ||
              this.$store.state.sessionObj.userType === 1
            )
              EventBus.$emit("loanContacts");
          }
        }
      }
    } catch (error) {
      this.callLoader(false, null);
      this.isDisabled = false;
      console.log(error);
    }
  }

  public async saveAgentContact(agentId) {
    try {
      if (this.agentContactId == "new" && !this.isComplete) {
        this.callLoader(false, null);
        if (
          !this.agentContactInfo.fullName.firstName &&
          !this.agentContactInfo.fullName.middleName &&
          !this.agentContactInfo.fullName.lastName &&
          !this.agentContactInfo.contactNMLSId &&
          !this.agentContactInfo.contactlicenseId &&
          !this.agentContactInfo.email &&
          !this.agentContactInfo.phone
        ) {
          this.$emit("next-tab");
          return;
        }
      }
      if (this.agentContactId == "0" && this.isComplete) {
        this.callLoader(false, null);
        this.isDisabled = false;
        this.$snotify.warning("Please select Contact or Add new Contact");
      } else if (this.agentContactId == "0" && !this.isComplete)
        this.$emit("next-tab");
      else {
        console.log("this.agentInfo.type", this.agentInfo.type);
        if (this.showContactFields || this.addNew) {
          const response = await Axios.post(
            BASE_API_URL + "agent/saveAgentContact",
            {
              ...this.agentContactInfo,
              agentId: agentId,
              agentContactId: this.agentContactId,
              agentTypeId: this.agentInfo.type,
            },
            { headers: authHeader() }
          );
          if (response.status == 201) {
            this.emailValidationError = null;
            await this.getAgentList();
            if (response.data.contactId) {
              let data = {
                agentConId: response.data.contactId,
                agentId: agentId,
              };

              //  if (this.isEdit) {
              //     this.$emit("next-tab");
              //     this.$emit("complete");
              //     this.isDisabled = false;}
              //  else
              await this.saveLoanAgentContact(data);
            }
          }
        }
      }
    } catch (error) {
      this.callLoader(false, null);
      this.isDisabled = false;
      console.log(error);
    }
  }

  public async saveLoanAgentContact(data) {
    try {
      let res = await Axios.post(
        BASE_API_URL + "broker/saveLoanAgentContact",
        {
          loanId: this.loanId,
          agentType: this.agentType.id,
          agentContactId: data.agentConId ? data.agentConId : null,
          isEdit: this.isEdit,
          agentId: data.agentId,
          isNa: this.agentInfo.isNa,
          message: this.agentInfo.message,
          previousContactId: this.previousContactId,
        },
        {
          headers: authHeader(),
        }
      );
      if (res.status == 200) this.multipleAgentError = res.data.msg;
      else if (res.status == 201) {
        this.callLoader(false, null);
        this.$snotify.success("Details has been saved");
        this.$emit("next-tab");
        this.$emit("complete");
        this.isDisabled = false;
        if (this.$route.query.name == "disclosure")
          EventBus.$emit("validation");
      } else {
        EventBus.$emit("contactValidation", this.agentType.position);
        this.callLoader(false, null);
        this.isDisabled = false;
      }
    } catch (error) {
      this.callLoader(false, null);
      this.isDisabled = false;
      console.log(error);
    }
  }

  // public showAgentFields() {
  //   this.showFields = true;
  //   this.addNew = true;
  //   this.agentId = this.agentContactId = "new";
  //   this.agentInfo = new AgentInfo();
  //   this.agentContactInfo = new AgentContactInfo();
  // }

  public async getSpecificAgentData() {
    try {
      if (this.agentId != "0" && this.agentId != "new") {
        this.callLoader(true, 1);
        this.addNew = false;
        const response = await Axios.get(
          BASE_API_URL + "agent/getSpecificAgent/" + this.agentId,
          {
            headers: authHeader(),
          }
        );
        // console.log(response.data, 'res')
        this.showFields = true;
        this.showContactFields = false;
        this.agentInfo = response.data;

        this.agentContactId = "0";
        this.agentContacts = [];
        this.agentContactInfo = new AgentContactInfo();
        this.callLoader(false, null);
        await this.getAgentContacts(response.data._id);
      } else if (this.agentId == "new") {
        this.addNewContact = true;
        this.showFields = true;
        this.addNew = true;
        this.agentContactId = "new";
        this.agentInfo = new AgentInfo();
        this.agentContactInfo = new AgentContactInfo();
      } else {
        this.showFields = false;
        this.showContactFields = false;
        this.agentInfo = new AgentInfo();
        this.agentContactId = "0";
        this.agentContacts = [];
        this.agentContactInfo = new AgentContactInfo();
      }
    } catch (error) {
      console.log(error);
    }
  }

  async getAgentContacts(agentId) {
    try {
      const response = await Axios.get(
        BASE_API_URL + "agent/getAgentContacts/" + agentId,
        {
          headers: authHeader(),
        }
      );

      this.agentContacts = response.data.sort((a, b) =>
        a.agentName.localeCompare(b.agentName)
      );
    } catch (error) {
      console.log(error);
    }
  }

  async getAgentContactDetails() {
    try {
      if (this.agentContactId != "0" && this.agentContactId != "new") {
        this.addNewContact = false;
        this.callLoader(true, 1);
        const response = await Axios.get(
          BASE_API_URL + "agent/getAgentContactDetails/" + this.agentContactId,
          {
            headers: authHeader(),
          }
        );
        this.showContactFields = true;
        this.agentContactInfo = response.data;
        this.callLoader(false, null);
      } else if (this.agentContactId == "new") {
        this.checkEmailDuplicacy = true;
        this.addNewContact = true;
        this.showContactFields = true;
        this.agentContactInfo = new AgentContactInfo();
      } else {
        this.checkEmailDuplicacy = true;
        this.addNewContact = true;
        this.showContactFields = false;
        this.agentContactInfo = new AgentContactInfo();
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async getSelectedAgent() {
    try {
      this.callLoader(true, 1);
      const response = await Axios.post(
        BASE_API_URL + "broker/getSelectedAgent",
        {
          loanId: this.loanId,
          agentType: this.agentType.id,
        },
        {
          headers: authHeader(),
        }
      );

      if (response.status == 201) {
        if (response.data.agentInfo) {
          this.agentInfo.isNa = response.data.agentInfo.status;
          this.agentInfo.message = response.data.agentInfo.message;

          this.agentId = response.data.agentInfo.agentId;
          if (this.agentId) await this.getSpecificAgentData();
          else this.agentId = "0";
          this.agentContactId = response.data.agentInfo.agentContactId
            ? response.data.agentInfo.agentContactId
            : 0;
          if (this.agentContactId) await this.getAgentContactDetails();
        }
        this.callLoader(false, null);
      }
    } catch (error) {
      this.callLoader(false, null);
      console.log(error);
    }
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  public showValidation() {
    try {
      if (this.agentType.id == 2) {
        this.agentValidation = {
          companyName: true,
          website: false,
          NMLS: false,
          address: true,
        };
        this.contactValidation = {
          name: true,
          NMLS: false,
          email: true,
          phone: true,
        };
      } else if (this.agentType.id == 3) {
        this.agentValidation = {
          companyName: true,
          website: false,
          NMLS: false,
          address: true,
        };
        this.contactValidation = {
          name: true,
          NMLS: false,
          email: true,
          phone: true,
        };
      } else if (this.agentType.id == 4) {
        this.agentValidation = {
          companyName: true,
          website: false,
          NMLS: false,
          address: true,
          license: true,
        };
        this.contactValidation = {
          name: true,
          NMLS: false,
          email: true,
          phone: true,
          license: true,
        };
      } else if (this.agentType.id == 5) {
        this.agentValidation = {
          companyName: true,
          website: false,
          NMLS: false,
          address: true,
          license: true,
        };
        this.contactValidation = {
          name: true,
          NMLS: false,
          email: true,
          phone: true,
          license: true,
        };
      } else if (this.agentType.id == 6) {
        this.agentValidation = {
          companyName: true,
          website: false,
          NMLS: false,
          address: false,
        };
        this.contactValidation = {
          name: true,
          NMLS: false,
          email: true,
          phone: true,
        };
      } else if (this.agentType.id == 7) {
        this.agentValidation = {
          companyName: true,
          website: false,
          NMLS: false,
          address: false,
        };
        this.contactValidation = {
          name: true,
          NMLS: false,
          email: true,
          phone: true,
        };
      } else if (this.agentType.id == 8) {
        this.agentValidation = {
          companyName: true,
          website: false,
          NMLS: false,
          address: false,
        };
        this.contactValidation = {
          name: true,
          NMLS: false,
          email: true,
          phone: true,
        };
      } else if (this.agentType.id == 9) {
        this.agentValidation = {
          companyName: true,
          website: false,
          NMLS: false,
          address: false,
        };
        this.contactValidation = {
          name: true,
          NMLS: false,
          email: true,
          phone: true,
        };
      } else if (this.agentType.id == 10) {
        this.agentValidation = {
          companyName: true,
          website: true,
          NMLS: false,
          address: false,
        };
        this.contactValidation = {
          name: true,
          NMLS: false,
          email: true,
          phone: true,
        };
      } else if (this.agentType.id == 11) {
        this.agentValidation = {
          companyName: true,
          website: false,
          NMLS: false,
          address: false,
        };
        this.contactValidation = {
          name: true,
          NMLS: false,
          email: true,
          phone: true,
        };
      }
    } catch (error) {
      console.log(error);
    }
  }

  public checkForWebsite() {
    if (this.agentType.id == 10) return true;
    else return false;
  }

  public checkForNmls() {
    if (this.agentType.id == 4 || this.agentType.id == 5) return true;
    else return false;
  }
  public checkForLicense() {
    if (this.agentType.id == 4 || this.agentType.id == 5) return true;
    else return false;
  }

  public checkForState() {
    if (
      this.agentType.id == 5 ||
      this.agentType.id == 2 ||
      this.agentType.id == 3 ||
      this.agentType.id == 4
    )
      return true;
    else return false;
  }

  public checkForContactNmls() {
    if (this.agentType.id == 5 || this.agentType.id == 4) return true;
    else return false;
  }
  public checkForContactLicense() {
    if (this.agentType.id == 5 || this.agentType.id == 4) return true;
    else return false;
  }

  public checkForEmail() {
    if (this.agentType.id == 2) {
      if (!this.agentContactInfo.email && !this.agentContactInfo.phone)
        return true;
    } else if (!this.agentContactInfo.email) return true;
    else return false;
  }

  public checkForPhone() {
    if (this.agentType.id == 2) {
      if (!this.agentContactInfo.email && !this.agentContactInfo.phone)
        return true;
    } else if (!this.agentContactInfo.phone) return true;
    else return false;
  }

  public checkContact() {
    if (this.agentType.id == 10) return this.contactMsg.lenderContactMsg;
  }

  public showValidatedData(id) {
    if (id == 10) return true;
  }

  //
  public async emailValidationForAgent(email) {
    try {
      let emailExists = false;
      let isEmailAllowed = false;
      let isSameAgentType = false;
      let response = await Axios.get(
        BASE_API_URL +
          `processor/agentDuplicateEmail/${email}?agentTypeId=${this.agentType.id}`,
        { headers: authHeader() }
      );
      if (response.data.code === 200) {
        emailExists = false;
      } else {
        emailExists = true;
        let companyData;
        isEmailAllowed = response.data.isNotAgent ? false : true;
        this.existingAgentInfo = response.data.data;
        isSameAgentType = response.data.isSameAgentType;
        companyData =
          this.existingAgentInfo && this.existingAgentInfo.agentId
            ? this.agentList.find(
                (e) => e._id == this.existingAgentInfo.agentId
              )
            : null;
        this.existingAgentInfo["companyName"] =
          companyData && companyData.name ? companyData.name : null;
      }
      console.log(
        "emailExists, isEmailAllowed --",
        emailExists,
        isEmailAllowed
      );
      return { emailExists, isEmailAllowed, isSameAgentType };
    } catch (error) {
      console.log(error);
    }
  }

  public async useTheEmailMatch() {
    this.$modal.hide("duplicateEmail");
    this.agentId = this.existingAgentInfo.agentId;
    await this.getSpecificAgentData();
    this.agentContactId = this.existingAgentInfo._id;
    await this.getAgentContactDetails();
    this.checkEmailDuplicacy = false;
    this.emailForcedChanged = false;
  }

  async mounted() {
    await this.getAgentList();
    if (this.isEdit) {
      this.agentId =
        this.agentType.companyData && this.agentType.companyData._id;
      if (this.agentId) await this.getSpecificAgentData();
      else this.agentId = "0";
      this.agentContactId =
        this.agentType.contactData && this.agentType.contactData._id;
      this.previousContactId = this.agentContactId;
      if (this.agentContactId) await this.getAgentContactDetails();
    } else await this.getSelectedAgent();
    this.showValidation();
    this.getStatesAndCounty();
  }
  /* eslint-disable */
}
