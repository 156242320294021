// @typescript-eslint/no-explicit-any
import Address from '../common/address.model';
export class BorrowerId {
    constructor() {
        this.id = null
    }
    public id: any
}

export class OtherLiabilities {
    constructor() {
        this.expenseType = {
            name: null,
            description: null
        },
            this.monthlyPayment = 0
        this.borrowerId = []
    }
    public expenseType: any
    public monthlyPayment: any
    public borrowerId: Array<BorrowerId>
}

export class Liabilities {
    constructor() {
        this.accountType = {
            name: null,
            description: null
        }
        this.companyName = null
        this.accountNumber = null
        this.unpaidBalance = 0
        this.toBePaidOff = false
        this.toBePaidOffBeforeClosing = false
        this.isOmit = false
        this.monthlyPayment = 0
        this.types = {
            name: null,
            description: null
        }
        this.creditLimit = 0
        this.borrowerId = []
        this.reoId = null

    }
    public accountType: any
    public companyName: any
    public accountNumber: any
    public unpaidBalance: any
    public toBePaidOff: boolean
    public toBePaidOffBeforeClosing: boolean
    public isOmit: boolean
    public monthlyPayment: any
    public types: any
    public creditLimit: any
    public borrowerId: Array<BorrowerId>
    public reoId: any
}

export class Reo {
    constructor() {
        this.address = new Address(),
            this.propertyValue = 0
        this.status = null
        this.intendedOccupancy = {
            name: null,
            description: null
        }
        this.monthlyExpense = 0
        this.monthlyRentalIncome = 0
        this.liabilities = [new Liabilities()]
        this.borrowerId = []
    }
    public address: any
    public propertyValue: any
    public status: any
    public intendedOccupancy: any
    public monthlyExpense: any
    public monthlyRentalIncome: any
    public liabilities: Array<Liabilities>
    public borrowerId: Array<BorrowerId>
}

export class Asset {
    constructor() {
        this.accountType = null,
            this.accountNumber = null
        this.institutionName = null
        this.cash = 0
        this.borrowerId = []
    }
    public accountType: any
    public accountNumber: any
    public institutionName: any
    public cash: any
    public borrowerId: Array<BorrowerId>
}

export class OtherAssets {
    constructor() {
        this.assetType = {
            name: null,
            description: null
        },
            this.cash = 0,
            this.borrowerId = []
    }
    public assetType: any
    public cash: any
    public borrowerId: Array<BorrowerId>
}

export class OtherCredits {
    constructor() {
        this.creditType = {
            name: null,
            description: null
        },
            this.cash = 0
    }
    public creditType: any
    public cash: any
}

