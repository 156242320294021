






























































































  import { Component, Vue, Prop } from "vue-property-decorator";
  import Axios from "axios";
  import { BASE_API_URL } from "../../../config";
  import { authHeader } from "../../../services/auth";
// import CommonNotifyOnRejection from '../../../common-components/CommonNotifyOnRejection.vue'
import ViewDocument from "../../../common-components/ViewDocument.vue";
@Component({
  components: {
    ViewDocument,
      // CommonNotifyOnRejection
    },
  })
export default class VodReceived extends Vue {
  /* eslint-disable */
  public loanId: any = this.$route.query.id
  public vodRecieved: any = {};
  public doc = false;
  public error: any = {
    msg:null,
    show:false
  };

  public creditorInfo : any = [];
  @Prop()
  public taskId: any;
  public docLoader = false;
  public isUploading = false;
  @Prop()
  public isLoanInactive: any;
  public isTaskCompleted = false;
  @Prop()
  public reOrderCount: any;
  public isOrderPlacedOnce = false
    // public notifyBroker = false;
  /**
   * Taking dsData variable for checking form data is changed or not only for comaprison
   */
   public dsData: any = {};
   /**************************************************************** */
   @Prop()
   public isTaskDisabled: any;


   public async checkSaveValidation() {
    try {
      if (!this.vodRecieved.isVodRecieved && this.vodRecieved.isVodRecieved == this.dsData.isVodRecieved 
        )
        return true;
      else return false;
    } catch (error) {
      console.log(error);
    }
  }


  public async uploadReport(event,creditor) {
   if (this.isTaskDisabled) {
    this.callLoader(false, null);
    this.$snotify.info("Task is not assigned to you.");
    this.getVodReceivedData();
    return;
  }
  creditor.isUploading = true;
  this.callLoader(true,1)
  try {
    let files = event.target.files;
    let formData: any = new FormData();
    formData.append("loanId",this.loanId)
    formData.append("taskId",this.taskId)
    formData.append("reOrderCount", this.reOrderCount);
    formData.append("verificationId", creditor.refId);
    Array.from(files).forEach((f) => {
      formData.append("file", f);
    });

    let response = await Axios.post(
      BASE_API_URL + "processor/uploadVodRecievedReport",
      formData,
      {
        headers: authHeader(),
      }
      );

    if(response.status === 201) {
      this.$snotify.success(response.data.message);
      this.error.msg = null;
      this.error.show = false
      creditor.doc = response.data.doc;
    }
    this.getVodReceivedData();
  } catch(error) {
    console.log(error)
  }
  creditor.isUploading = false;
  this.callLoader(false,null)
}

public async saveVodRecievedTask(isComplete) {
   if(this.isOrderPlacedOnce) return
 this.callLoader(true,null)
 try {
  if (this.isTaskDisabled) {
    this.callLoader(false, null);
    this.$snotify.info("Task is not assigned to you.");
    this.getVodReceivedData();
    return;
  }
  if (!isComplete)  {
    let notValid1 = await this.checkSaveValidation();
    if (notValid1) {
      this.callLoader(false, null);
      this.$snotify.info('No Changes')
      return;
    }
  }
  if(isComplete){
    if (!this.vodRecieved.isVodRecieved) {
      this.error.show = true;
      this.error.msg = 'All fields are mandatory.';
      this.callLoader(false,null)
      return;
    }
    
    else if (this.creditorInfo.find(e=> !e.doc)) {
      this.error.show = true;
      this.error.msg = 'Please Upload VOD Report.';
      this.callLoader(false,null)
      return;
    }
  }
  if(isComplete) this.isOrderPlacedOnce=true;
  let response = await Axios.post(
    BASE_API_URL + "processor/saveVodRecievedTask",
    {
      isComplete: isComplete, 
      vodRecieved: this.vodRecieved,
      loanId: this.loanId,
      taskId: this.taskId
    },
    {
      headers: authHeader(),
    }
    );
  if (!isComplete){this.$snotify.success("VOD Recieved Information has been saved");} 

  if(response.status == 201 && isComplete) this.$snotify.success(response.data.msg)
    if (isComplete) this.$emit("refresh-task-list", true);
  await  this.getVodReceivedData();
} catch(error) {
  console.log(error)
}
this.callLoader(false,null)
}

public async getVodReceivedData() {
  this.callLoader(true,null)
  try {
    const response = await Axios.get(
      BASE_API_URL + "processor/getVodReceivedData/" + this.loanId +"/" +this.taskId,
      {
        headers: authHeader(),
      }
      );
    if (response.status === 201) {
      this.vodRecieved = JSON.parse(JSON.stringify(response.data.vodRecieved));
      this.dsData = JSON.parse(JSON.stringify(response.data.vodRecieved));
      this.doc = response.data.doc;
      this.isTaskCompleted = response.data.isCompleted;
      this.creditorInfo = response.data.creditorInfo;
    }

  } catch (error) {
    console.log(error);
  }
  this.callLoader(false,null)
}

public async viewDocument(document) {
  try {
   if (this.isTaskDisabled) {
    this.callLoader(false, null);
    this.$snotify.info("Task is not assigned to you.");
    this.getVodReceivedData();
    return;
  }
  document.loanId = this.$route.query.id
  this.$refs.ref["showFile"](document);
} catch (error) {
  console.log(error);
}
}

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  mounted() {
    this.getVodReceivedData()
}


}
