var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"side-tabs"},[_c('div',{staticStyle:{"border":"none"},attrs:{"no-body":""}},[_c('div',{staticClass:"loan-process-wrapper"},[_c('b-tabs',{attrs:{"pills":"","vertical":""}},[_vm._l((_vm.workflow),function(task,index){return _c('b-tab',{key:index,attrs:{"title":task.name,"title-item-class":task.completedOn
                ? 'highlight__green'
                : task.isDisabled || task.isTaskDisabled
                ? 'highlight__disable'
                : '',"disabled":task.isDisabled ? true : false,"id":task.workflowTaskId === 0 ? 'create-task' : null,"active":task.workflowTaskId == _vm.taskId &&
              task.reOrderCount == _vm.reOrderCount &&
              task.secondAppraisalCount == _vm.secondAppraisalCount &&
              task.orderSequence == _vm.orderSequence
                ? true
                : false},on:{"click":function($event){return _vm.showCurrentComponent(
                task.component,
                task.workflowTaskId,
                task.lenderResponseSequence,
                task.reOrderCount,
                task.secondAppraisalCount,
                task.totalTaskCount,
                task.appraisalRefCount,
                task.orderSequence,
                task.isTaskDisabled
              )}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"max_270"},[_vm._v(" "+_vm._s(task.name)+" "+_vm._s(_vm.returnCount(task))+" ")]),_c('div',{staticClass:"workflow-date"},[_vm._v(" "+_vm._s(task.completedOn ? _vm.$moment(task.completedOn).format( "MM/DD/YYYY, h:mm:ss a" ) : "")+" ")]),(
                  _vm.expandLenderResponse &&
                  _vm.sequenceNumber == task.workflowTaskId
                )?_c('div',_vm._l((_vm.lenderResponseTask),function(lenderResponse){return _c('b-list-group',{key:lenderResponse.workflowTaskId},[_c('b-list-group-item',{staticClass:"text-success"},[_vm._v(_vm._s(lenderResponse.name))])],1)}),1):_vm._e()]},proxy:true}],null,true)})}),_c('b-card-text',[_c(_vm.componentRef,{ref:"taskref",tag:"component",attrs:{"taskId":_vm.taskId,"lenderResponseSequence":_vm.lenderResponseCount,"secondAppraisalCount":_vm.secondAppraisalCount,"appraisalRefCount":_vm.appraisalRefCount,"isLoanInactive":_vm.isLoanInactive,"reOrderCount":_vm.reOrderCount,"totalTaskCount":_vm.totalTaskCount,"isTaskDisabled":_vm.isTaskDisabled},on:{"refresh-task-list":function($event){return _vm.refreshList()},"refreshOnlyMilestone":function($event){return _vm.refreshOnlyMilestone()}}})],1)],2)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }