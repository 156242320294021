






































































































































































































































import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "../config";
import { authHeader } from "../services/auth";
import suneditor from "suneditor";
import plugins from "suneditor/src/plugins";
import notes from "../common-components/CommunicationNotes.vue";
import email from "../common-components/CommunicationEmail.vue";
import brokerNotes from "../common-components/CommunicationBrokerNotes.vue";
import Fax from "../common-components/CommunicationFax.vue";

@Component({
  components: {
    notes,
    email,
    brokerNotes,
    Fax,
  },
})
export default class Communication extends Vue {
  /* eslint-disable */
  public tabIndex = 0;
  public step = 0;
  public allCommunication: any = [];
  public editor: any = null;
  public processorNotesData: any = {
    title: "",
    message: "",
    internalNotes: false,
  };
  public isEdit = false;
  public errorMessage = "";
  public errorTitle = "";

  public async getAllCommunication() {
    this.callLoader(true, null);
    try {
      let response = await Axios.get(
        BASE_API_URL + "communication/getAllCommunication",
        {
          headers: authHeader(),
        }
      );
      if (response.status === 201) {
        this.allCommunication = response.data;
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async saveProcessorNotes() {
    try {
      this.processorNotesData.message = this.editor.getContents();
      this.processorNotesData.loanId = this.$route.query.id;

      if (
        this.processorNotesData.message
          .replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, "")
          .trim() != ""
      ) {
        this.errorMessage = "";
      } else {
        this.errorMessage = "Please add a Message";
        this.errorTitle = "Please add a Title";
      }
      if (
        this.processorNotesData.message
          .replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, "")
          .trim() != "" &&
        this.processorNotesData.title
      ) {
        const response = await Axios.post(
          BASE_API_URL + "communication/saveProcessorNotes",
          this.processorNotesData,
          {
            headers: authHeader(),
          }
        );
        if (response.status === 201) {
          this.tabIndex = 0;
          this.step = 0;
          this.$snotify.success(
            this.isEdit ? "Updated Sucessfully" : "Saved Successfully"
          );
          this.processorNotesData = {
            message: "",
            title: "",
            internalNotes: false,
          };
          this.$modal.hide("addNotes");
          await this.getAllCommunication();
          this.$refs.notes["getCommunicationNotes"]();
        }
      }
      // } else {
      //   this.errorMessage = "Please add a Message";
      //   this.errorTitle = "Please add a Title";
      // }
    } catch (error) {
      console.log(error);
    }
  }

  public async openAddNotes(list: "") {
    this.processorNotesData = list ? list : { message: "" };
    this.$modal.show("addNotes");
    setTimeout(async () => {
      this.editor = await suneditor.create("pageEditor", {
        plugins: {
          ...plugins,
        },
        width: "auto",
        height: "100px",
        buttonList: [["bold"], ["list"]],
      });
    }, 1);
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  cancel() {
    this.$modal.hide("addNotes");
  }

  async mounted() {
    // await this.getAllCommunication();
  }
  /* eslint-disable */
}
