


























































































































































  import { Component, Vue, Prop } from "vue-property-decorator";
  import Axios from "axios";
  import { BASE_API_URL, EventBus } from "../../../config";
  import { authHeader } from "../../../services/auth";
  import { ChangOfCircumstances } from "../../../models/workflow/change-of-circumstance.model";
  @Component
  export default class ChangeOfCircumstanceLow extends Vue {
    /* eslint-disable */
    public dataObj: any = new ChangOfCircumstances();

    @Prop()
    public taskId: any;
    @Prop()
    public isLoanInactive: any;
    public isTaskCompleted = false;
    public error: any = { show: false, msg: null };
  // @Prop()
  // public reOrderCount: any;
  @Prop()
  public secondAppraisalCount: any;
  // public countToSendOnServer: any = null;
  public secondAppraisalCountForServer: any = null;
  public showHistoryBtn = false;
  @Prop()
  public totalTaskCount: any;
  public totalTaskCountForServer = null;
  @Prop()
  public isTaskDisabled: any;
  public isOrderPlacedOnce = false;
  /**
   * Taking dsData variable for checking form data is changed or not only for comaprison
   */
   public dsData: any = {};
   /**************************************************************** */

   public async checkSaveValidation() {
    try {
      let mainDataHasData = await this.formHasData(
        JSON.parse(JSON.stringify(this.dataObj))
        );
      let valueIsNotSame = false;
      for (const [key, value] of Object.entries(this.dataObj)) {
        for (const [key1, value1] of Object.entries(this.dsData)) {
          if (
            key != "createdBy" &&
            key != "createdOn" &&
            key != "updatedBy" &&
            key != "updatedOn" &&
            key != "isCompleted"
            ) {
            if (key === key1 && value != value1) {
              valueIsNotSame = true;
            }
          }
        }
      }
      if (!mainDataHasData && !valueIsNotSame) return true;
      else return false;
    } catch (error) {
      console.log(error);
    }
  }

  public formHasData(dsData) {
    for (const [key, value] of Object.entries(dsData)) {
      if (
        key != "createdBy" &&
        key != "createdOn" &&
        key != "updatedBy" &&
        key != "updatedOn" &&
        key != "isCompleted" &&
        value
        ) {
        return true;
    }
  }
  return false;
}

public checkValForFields() {
 if(!this.dataObj.interestRate && !this.dataObj.propertyType
  && !this.dataObj.occupancy && !this.dataObj.productProgramType
  && !this.dataObj.appraisedValue && !this.dataObj.salesPrice
  && !this.dataObj.baseLoanAmount && !this.dataObj.escrowImpounds
  && !this.dataObj.addressChange && !this.dataObj.other)
  return true
else
  return false
}

public async saveChangeOfCircumstance(isComplete) {
    if(this.isOrderPlacedOnce) return
  this.callLoader(true, 1);
  try {
    if (this.isTaskDisabled) {
      this.callLoader(false, null);
      this.$snotify.info("Task is not assigned to you.");
      this.getChangeOfCircumstanceData();
      return;
    }
    if (!isComplete) {
      let notValid1 = await this.checkSaveValidation();
      if (notValid1) {
        this.callLoader(false, null);
        this.$snotify.info("No Changes");
        return;
      }
    }
    if (isComplete && this.checkValForFields()) {
      this.error.show = true;
      this.error.msg = "Please fill at least one Information.";
      this.callLoader(false, null);
      return;
    }
    if(isComplete) this.isOrderPlacedOnce=true;
    let response = await Axios.post(
      BASE_API_URL + "processor/saveChangeOfCircumstance",
      {
        loanId: this.$route.query.id,
        changeOfCircumstance: this.dataObj,
        taskId: this.taskId,
          // reOrderCount: this.countToSendOnServer,
          secondAppraisalCount: this.secondAppraisalCountForServer,
          totalTaskCount: this.totalTaskCountForServer,
          formData: this.dsData,
          isComplete: isComplete,
        },
        { headers: authHeader() }
        );
    if (!isComplete) {
      this.$snotify.success(
        "Circumstance Of Change Subject To Completion Information has been saved"
        );
    }

    if (response.status === 201 && isComplete) {
      this.$snotify.success("COC Subject To Completion Completed Successfully");
    }
    this.error.show = false;
    this.error.msg = " ";
    await this.getChangeOfCircumstanceData();
    if (isComplete) this.$emit("refresh-task-list", true);
  } catch (error) {
    console.log(error);
  }
  this.callLoader(false, null);
}

public async getChangeOfCircumstanceData() {
  this.callLoader(true, 1);
  try {
    let response = await Axios.post(
      BASE_API_URL + "processor/getChangeOfCircumstanceData",
      {
        loanId: this.$route.query.id,
        taskId: this.taskId,
          // reOrderCount: this.countToSendOnServer,
          totalTaskCount: this.totalTaskCountForServer,
          secondAppraisalCount: this.secondAppraisalCountForServer,
        },
        { headers: authHeader() }
        );
    if (response.status === 201) {
      this.dataObj = response.data;
      this.isTaskCompleted = response.data.isCompleted;
      this.dataObj = JSON.parse(JSON.stringify(response.data));
      this.showHistoryBtn = response.data.showHistoryBtn;
      this.dsData = JSON.parse(JSON.stringify(response.data));
    }
  } catch (error) {
    console.log(error);
  }
  this.callLoader(false, null);
}

public callLoader(loader, loaderTitleId) {
  this.$store.state.tmpcoLoader = loader;
  this.$store.state.loaderTitleId = loaderTitleId;
}

public async showHistory() {
  this.callLoader(true, 1);
  try {
    this.$refs.refReOrder["showHistory"](this.taskId);
  } catch (error) {
    console.log(error);
  }
  this.callLoader(false, null);
}

public async allMountFunction(count, secondAppraisalCount) {
  this.totalTaskCountForServer = count;
    // this.countToSendOnServer = count;
    this.secondAppraisalCountForServer = secondAppraisalCount;
    await this.getChangeOfCircumstanceData();
  }

  async mounted() {
    await this.allMountFunction(this.totalTaskCount, this.secondAppraisalCount);
    // await this.allMountFunction(this.reOrderCount, this.secondAppraisalCount);
  }
}
