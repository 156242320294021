export class OtherContactInfo {
  constructor() {
    this.option = null;
    this.firstName = null;
    this.lastName = null;
    this.companyName = null;
    this.contactEmail = null;
    this.contactFax = null;
   
  }
  public option: any;
  public firstName: any;
  public lastName: any;
  public contactEmail: any;
  public contactFax: any;
  public companyName: any;   
}



export class AgentInfo {
    constructor() {
      this.option = null;
      this.email = null;
      this.fax = null;
      this.otherContact = new OtherContactInfo();
      this.masterInsuranceCertificateVendorId = null;
      
    }
    public option: any;
    public email: any;
    public fax: any;
    public otherContact: OtherContactInfo;
   public masterInsuranceCertificateVendorId: any;

  
  }
  export class VerificationOfAgent {
    constructor() {
        this.isMasterInsuranceCertificateOrder = false;
        this.agentInfo = new AgentInfo();
      }public isMasterInsuranceCertificateOrder: boolean;
      public agentInfo: AgentInfo;
}

export class MasterVendorData {
  constructor() {
      this.vendorTypeId = 8;
      this.companyName = null;
      this.companyUrl = null;
      this.userName = "";
      this.password = "";
      this.createdOn = new Date();
  }

  public vendorTypeId: number;
  public companyName: any;
  public companyUrl: any;
  public userName: any;
  public password: any;
  public createdOn: Date;


}

export class IsCondoNameAccuratelyReflected {
constructor() {
    
    this.revisionRequestedOn = new Date();
  this.revisionCompletedOn =  new Date() ;
  this.nARemark =  null;
  this.mode = null;
}
public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}

export class IsBorrowerNameAccuratelyReflected {
  constructor() {
      this.revisionRequestedOn = new Date();
  this.revisionCompletedOn =  new Date() ;
  this.nARemark =  null;
  this.mode = null;
  }
  public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}

export class IsPropertyAddressAccuratelyReflected{
  constructor() {
      this.revisionRequestedOn = new Date();
  this.revisionCompletedOn =  new Date() ;
  this.nARemark =  null;
  this.mode = null;
  }
  public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}
export class IsMortgageClauseAndLoanNumberAccuratelyReflected{
  constructor() {
      this.revisionRequestedOn = new Date();
  this.revisionCompletedOn =  new Date() ;
  this.nARemark =  null;
  this.mode = null;
  }
  public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}
export class IsPolicyValid{
constructor() {
    this.revisionRequestedOn = new Date();
  this.revisionCompletedOn =  new Date() ;
  this.nARemark =  null;
  this.mode = null;
}
public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}
export class IsPolicySigned {
constructor() {
    this.revisionRequestedOn = new Date();
  this.revisionCompletedOn =  new Date() ;
  this.nARemark =  null;
  this.mode = null;
}
public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}




export class MasterInsuranceCertificateReceivedChecklist {
  constructor() {
      this.isCondoNameAccuratelyReflected = new IsCondoNameAccuratelyReflected();
      this.isBorrowerNameAccuratelyReflected = new IsBorrowerNameAccuratelyReflected();
      this.isPropertyAddressAccuratelyReflected = new IsPropertyAddressAccuratelyReflected();
      this.isMortgageClauseAndLoanNumberAccuratelyReflected = new IsMortgageClauseAndLoanNumberAccuratelyReflected();
      this.isPolicyValid = new IsPolicyValid();
      this.isPolicySigned  = new IsPolicySigned ();
      this.isMasterInsuranceCertificateRecieved = null;

   }
   public isCondoNameAccuratelyReflected: IsCondoNameAccuratelyReflected;
   public isBorrowerNameAccuratelyReflected: IsBorrowerNameAccuratelyReflected;
   public isPropertyAddressAccuratelyReflected: IsPropertyAddressAccuratelyReflected;
   public isMortgageClauseAndLoanNumberAccuratelyReflected: IsMortgageClauseAndLoanNumberAccuratelyReflected;
   public isPolicyValid: IsPolicyValid;
   public isPolicySigned: IsPolicySigned ;
   public isMasterInsuranceCertificateRecieved: any;
  }