















































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "../../../config";
import { authHeader } from "../../../services/auth";
import ViewDocument from "../../../common-components/ViewDocument.vue";
import ReOrderTask from "../../../common-components/ReOrderTask.vue";
import FollowUpTask from "../../../common-components/FollowUpTask.vue";
import { CondoChecklist } from "../../../models/workflow/condoQuestionnair.model";
import datepicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
@Component({
  components: {
    ViewDocument,
    FollowUpTask,
    ReOrderTask,
    datepicker,
  },
})
export default class CondoQuestionnaireReceived extends Vue {
  /* eslint-disable */
  @Prop()
  public taskId: any;
  public isComplete = false;
  public condoQuestionaireReceived: any = new CondoChecklist();
  public documents: any = {};
  public loanId: any = this.$route.query.id;
  public followUpHistory: any = [];
  public isDisabled = false;
  public error: any = {
    show: false,
    msg: null,
  };
  @Prop()
  public isLoanInactive: any;
  @Prop()
  public reOrderCount: any;
  public countToSendOnServer: any = null;
  public showHistoryBtn = false;
  /**
   * Taking dsData variable for checking form data is changed or not only for comaprison
   */
  public dsData: any = {};
  /**************************************************************** */
  public isTaskCompleted = false;
  @Prop()
  public isTaskDisabled: any;
  public agentContactId = null;
  public isOrderPlacedOnce = false;

  public async checkSaveValidation() {
    try {
      if (
        !this.condoQuestionaireReceived.review &&
        this.condoQuestionaireReceived.review == this.dsData.review
      )
        return true;
      else return false;
    } catch (error) {
      console.log(error);
    }
  }

  public async saveCondoQuestionnaire() {
     if(this.isOrderPlacedOnce) return
    this.callLoader(true, null);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getCondoQuestionarie();
        return;
      }
      if (
        this.isComplete &&
        this.condoQuestionaireReceived.isFullCondoQuestionnaireReview.mode ==
          "Yes" &&
        this.condoQuestionaireReceived.review == "Limited Review"
      ) {
        this.error.show = true;
        this.error.msg = `Please Select Full Review`;
        this.callLoader(false, null);
        return;
      }
      if (
        this.isComplete &&
        this.condoQuestionaireReceived.isFullCondoQuestionnaireReview.mode ==
          "No" &&
        this.condoQuestionaireReceived.review == "Full Review"
      ) {
        this.error.show = true;
        this.error.msg = `Please Select Yes for Question No. 7`;
        this.callLoader(false, null);
        return;
      }
      if (this.isComplete && !this.condoQuestionaireReceived.review) {
        this.error.show = true;
        this.error.msg = `All Fields are Required.`;
        this.callLoader(false, null);
        return;
      }
      let notUploadedYet =
        this.documents && this.documents
          ? this.documents.filter((e) =>
              e.docFiles ? e.docFiles.length == 0 : false
            )
          : [];
      if (this.isComplete && notUploadedYet.length > 0) {
        this.error.show = true;
        this.error.msg = `Please Upload all Document of ${this.condoQuestionaireReceived.review}`;
        this.callLoader(false, null);
        return;
      }
      let revisedDocNotUploadedYet = this.documents.find(
        (e) => e.revisedDoc && e.docFiles.length <= 1
      );

      if (this.isComplete && revisedDocNotUploadedYet) {
        this.error.show = true;
        this.error.msg = `Please Upload Updated Document ${this.condoQuestionaireReceived.review}`;
        this.callLoader(false, null);
        return;
      }
       if(this.isComplete)
      this.isOrderPlacedOnce = true
      const response = await Axios.post(
        BASE_API_URL + "processor/saveCondoQuestionnaireReceived",
        {
          loanId: this.$route.query.id,
          condoQuestionaireReceived: this.condoQuestionaireReceived,
          taskId: this.taskId,
          reOrderCount: this.countToSendOnServer,
          isComplete: this.isComplete,
        },
        {
          headers: authHeader(),
        }
      );
      // if (!this.isComplete) {
      //   this.$snotify.success(
      //     "Condo Questionnaire Received Information has been saved"
      //   );
      // }
      if (response.status === 201 && this.isComplete) {
        this.isDisabled = true;
        this.$snotify.success(
          " Condo Questionnaire Received Completed successfully."
        );
      }
      this.error.msg = null;
      this.error.show = false;
      if (this.isComplete) this.$emit("refresh-task-list", true);
      await this.getCondoQuestionarie();
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async uploadDocument(event, documentId) {
    this.callLoader(true, null);
    if (this.isTaskDisabled) {
      this.callLoader(false, null);
      this.$snotify.info("Task is not assigned to you.");
      this.getCondoQuestionarie();
      return;
    }
    try {
      const files = event.target.files;
      const formData: any = new FormData();
      formData.append("loanId", this.loanId);
      formData.append("reOrderCount", this.countToSendOnServer);
      formData.append("documentId", documentId);
      Array.from(files).forEach((f) => {
        formData.append("file", f);
      });
      const response = await Axios.post(
        BASE_API_URL + "processor/uploadCondoDocs",
        formData,
        {
          headers: authHeader(),
        }
      );
      this.$snotify.success("File Uploded Successfully");
      this.error.msg = null;
      this.error.show = false;
      await this.getAllDocuments();
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async getAllDocuments() {
    this.callLoader(true, null);
    try {
      const response = await Axios.post(
        BASE_API_URL + "processor/getAlldocuments",
        {
          loanId: this.$route.query.id,
          condoQuestionaireReceived: this.condoQuestionaireReceived,
          reOrderCount: this.countToSendOnServer,
          taskId: this.taskId,
        },
        {
          headers: authHeader(),
        }
      );
      if (response.status === 201) this.documents = response.data;
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async showHistory() {
    this.callLoader(true, 1);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getCondoQuestionarie();
        return;
      }
      this.$refs.refReOrder["showHistory"](this.taskId);
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async getCondoQuestionarie() {
    this.callLoader(true, null);
    try {
      const response = await Axios.get(
        BASE_API_URL +
          "processor/getCondoQuestionarieReceived/" +
          this.$route.query.id +
          "/" +
          this.taskId +
          "/" +
          this.countToSendOnServer,
        {
          headers: authHeader(),
        }
      );
      if (response.status === 201) {
        this.agentContactId = response.data.agentContactId;
        this.isTaskCompleted = response.data.isCompleted;
        this.condoQuestionaireReceived = response.data.taskData
          ? JSON.parse(JSON.stringify(response.data.taskData))
          : new CondoChecklist();

        this.dsData = JSON.parse(JSON.stringify(response.data.taskData));
        this.showHistoryBtn = response.data.showHistoryBtn;
      }
      this.getAllDocuments();
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async viewDocument(document) {
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getCondoQuestionarie();
        return;
      }
      this.$refs.ref["showFile"]({
        documentId: document._id,
        loanId: this.$route.query.id,
        docName: document.name,
        docCategory: document.category,
      });
    } catch (error) {
      console.log(error);
    }
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  public async sendFollowUpMailToCondoAgent() {
    this.callLoader(true, 1);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getCondoQuestionarie();
        return;
      }
      this.$refs.refFollowUp["openFollowUpModal"]({
        label: "Message To Condo Agent",
        message: "Follow Up",
        apiPath: `processor/sendFollowUpMailToCondoAgent`,
        data: {
          loanId: this.$route.query.id,
          taskId: this.taskId,
          agentContactId: this.agentContactId,
          reOrderCount: this.countToSendOnServer
        },
      });
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async viewFollowUpHistory() {
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getCondoQuestionarie();
        return;
      }
      
      this.$refs.refFollowUp["openHistoryModal"]({
        label: "Select method to send",
        message: "Follow Up",
        apiPath: `processor/getFollowUpHistoryOfTitle`,
        data: { loanId: this.$route.query.id, taskId: this.taskId, reOrderCount: this.countToSendOnServer },
      });
    } catch (error) {
      console.log(error);
    }
  }

  public showUploadRevisedReportDiv() {
    let show = false;
    let value;
    let key;
    for ([key, value] of Object.entries(this.condoQuestionaireReceived)) {
      if (
        value &&
        Object.keys(value).length > 0 &&
        key != "createdBy" &&
        key != "createdOn" &&
        key != "documents" &&
        key != "updatedBy" &&
        key != "updatedOn"
      ) {
        if (value.mode == "No" && value.revisionRequestedOn) {
          show = true;
        }
      }
    }
    if (show) {
      for (let i = 0; i < this.documents.length; i++) {
        const e = this.documents[i];
        e.revisedDoc = true;
      }
    }
    return show;
  }

  public async allMountFunction(count) {
    this.countToSendOnServer = count;
    this.getCondoQuestionarie();
    this.getAllDocuments();
  }

  public mounted() {
    this.allMountFunction(this.reOrderCount);
  }
  /* eslint-disable */
}
