export class IsBorrowersnameSocialDOBReflected {
    constructor() {
        this.mode = null;

        this.nARemark = null;

    }
    public mode: any
    public nARemark: any
}
export class IsSubjectPropertyAddress {
    constructor() {
        this.mode = null;
        this.nARemark = null;
    }
    public mode: any
    public nARemark: any
}
export class IsSalesPriceLoanmount {
    constructor() {
        this.mode = null;
        this.nARemark = null;
    }
    public mode: any
    public nARemark: any
}
export class IsClosingDateReflected {
    constructor() {
        this.mode = null;
        this.nARemark = null;
    }
    public mode: any
    public nARemark: any
}
export class IsVerifiedIncomeAssetsLiabilities {
    constructor() {
        this.mode = null;
        this.nARemark = null;
    }
    public mode: any
    public nARemark: any
}
export class IsCreditReportValidUnexpired {
    constructor() {
        this.mode = null;
        this.nARemark = null;
    }
    public mode: any
    public nARemark: any
}
export class IsCumulativeBalance {
    constructor() {
        this.mode = null;
        this.nARemark = null;
    }
    public mode: any
    public nARemark: any
}
export class IsLeasePayments {
    constructor() {
        this.mode = null;
        this.nARemark = null;
    }
    public mode: any
    public nARemark: any
}
export class IsAuthorizedUserAccounts {
    constructor() {
        this.mode = null;
        this.nARemark = null;
    }
    public mode: any
    public nARemark: any
}
export class IsEmployersNamePaystubsMatches {
    constructor() {
        this.mode = null;
        this.nARemark = null;
    }
    public mode: any
    public nARemark: any
}
export class IsDeductionsLoansReflectedPaystub {
    constructor() {
        this.mode = null;
        this.nARemark = null;
    }
    public mode: any
    public nARemark: any
}
export class HasLiabilitySectionIn1003 {
    constructor() {
        this.mode = null;
        this.nARemark = null;
    }
    public mode: any
    public nARemark: any
}
export class IsEscrowDepositSellerContribution {
    constructor() {
        this.mode = null;
        this.nARemark = null;
    }
    public mode: any
    public nARemark: any
}
export class IsLargeDepositsNSFFees {
    constructor() {
        this.mode = null;
        this.nARemark = null;
    }
    public mode: any
    public nARemark: any
}
export class IsNonBorrowingSpouseInformation {
    constructor() {
        this.mode = null;
        this.nARemark = null;
    }
    public mode: any
    public nARemark: any
}
export class IsAUSRun {
    constructor() {
        this.mode = null;
        this.nARemark = null;
    }
    public mode: any
    public nARemark: any
}
export class IsmatterResolved {
    constructor() {
        this.mode = null;
        this.nARemark = null;
    }
    public mode: any
    public nARemark: any
}
export class IsDisputedaccounts {
    constructor() {
        this.ismatterResolved = new IsmatterResolved();
        this.mode = null;
        this.nARemark = null;
    }
    public mode: any
    public nARemark: any
    public ismatterResolved: IsmatterResolved;
}



export class IsBankruptcyReflected {
    constructor() {
        this.ismatterResolved = new IsmatterResolved();
        this.mode = null;
        this.nARemark = null;
    }
    public mode: any
    public nARemark: any
    public ismatterResolved: IsmatterResolved;
}
export class IsMortgageLatesReflected {
    constructor() {
        this.ismatterResolved = new IsmatterResolved();
        this.mode = null;
        this.nARemark = null;
    }
    public mode: any
    public nARemark: any
    public ismatterResolved = new IsmatterResolved;
}
export class IsForeclosureDeedInLieuShortSale {
    constructor() {
        this.ismatterResolved = new IsmatterResolved();
        this.mode = null;
        this.nARemark = null;
    }
    public mode: any

    public nARemark: any
    public ismatterResolved = new IsmatterResolved;
}


export class LoanCancel {
    constructor() {
        this.isCancel = null;
        this.remark = null;
        this.updatedBy = null;
        this.createdOn = null
    }
    public isCancel: any;
    public remark: any;
    public updatedBy: any;
    public createdOn: any;
}

export class RunAus {
    constructor() {
        this.isRunAus = null;
        this.status = null;
    }
    public isRunAus: any;
    public status: any;
}
export class ApplicationReviewChecklist {
    constructor() {
        this.isBorrowersnameSocialDOBReflected = new IsBorrowersnameSocialDOBReflected();
        this.isSubjectPropertyAddress = new IsSubjectPropertyAddress();
        this.isSalesPriceLoanmount = new IsSalesPriceLoanmount();
        this.isClosingDateReflected = new IsClosingDateReflected();
        this.isVerifiedIncomeAssetsLiabilities = new IsVerifiedIncomeAssetsLiabilities();
        this.isCreditReportValidUnexpired = new IsCreditReportValidUnexpired();
        this.isDisputedaccounts = new IsDisputedaccounts();
        this.isBankruptcyReflected = new IsBankruptcyReflected();
        this.isMortgageLatesReflected = new IsMortgageLatesReflected();
        this.isForeclosureDeedInLieuShortSale = new IsForeclosureDeedInLieuShortSale();
        this.isCumulativeBalance = new IsCumulativeBalance();
        this.isLeasePayments = new IsLeasePayments();
        this.isAuthorizedUserAccounts = new IsAuthorizedUserAccounts();
        this.isEmployersNamePaystubsMatches = new IsEmployersNamePaystubsMatches();
        this.isDeductionsLoansReflectedPaystub = new IsDeductionsLoansReflectedPaystub();
        this.hasLiabilitySectionIn1003 = new HasLiabilitySectionIn1003();
        this.isEscrowDepositSellerContribution = new IsEscrowDepositSellerContribution();
        this.isLargeDepositsNSFFees = new IsLargeDepositsNSFFees();
        this.isNonBorrowingSpouseInformation = new IsNonBorrowingSpouseInformation();
        this.isAUSRun = new IsAUSRun();
        this.isReviewCreditPackage = null;
        this.runAus = new RunAus();
        this.loanCancel = new LoanCancel()

    }
    public isBorrowersnameSocialDOBReflected: IsBorrowersnameSocialDOBReflected;
    public isSubjectPropertyAddress: IsSubjectPropertyAddress;
    public isSalesPriceLoanmount: IsSalesPriceLoanmount;
    public isClosingDateReflected: IsClosingDateReflected;
    public isVerifiedIncomeAssetsLiabilities: IsVerifiedIncomeAssetsLiabilities;
    public isCreditReportValidUnexpired: IsCreditReportValidUnexpired;
    public isDisputedaccounts: IsDisputedaccounts;
    public isBankruptcyReflected: IsBankruptcyReflected;
    public isMortgageLatesReflected: IsMortgageLatesReflected;
    public isForeclosureDeedInLieuShortSale: IsForeclosureDeedInLieuShortSale;
    public isCumulativeBalance: IsCumulativeBalance;
    public isLeasePayments: IsLeasePayments;
    public isAuthorizedUserAccounts: IsAuthorizedUserAccounts;
    public isEmployersNamePaystubsMatches: IsEmployersNamePaystubsMatches;
    public isDeductionsLoansReflectedPaystub: IsDeductionsLoansReflectedPaystub;
    public hasLiabilitySectionIn1003: HasLiabilitySectionIn1003;
    public isEscrowDepositSellerContribution: IsEscrowDepositSellerContribution;
    public isLargeDepositsNSFFees: IsLargeDepositsNSFFees;
    public isNonBorrowingSpouseInformation: IsNonBorrowingSpouseInformation;
    public isAUSRun: IsAUSRun;
    public isReviewCreditPackage: any;

    public loanCancel: any;
    public runAus: any;

}