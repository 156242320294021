


































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import Axios from "axios";
import { authHeader } from "../../../services/auth";
import CommonUserChoiceModal from "../../../common-components/CommonUserChoiceModal.vue";
import { BASE_API_URL } from "../../../config";
import suneditor from "suneditor";
import plugins from "suneditor/src/plugins";

@Component({
  components: {
    CommonUserChoiceModal,
  },
})
export default class InitialDisclosureReceived extends Vue {
  /* eslint-disable */
  @Prop()
  public taskId: any;
  public error: any = {
    show: false,
    messae: null,
    expandMessage: null,
  };
  public isDisabled = false;
  public remarkNote: any = null;
  public disclosureData: any = {};
  // @Prop()
  // public isLoanInactive: any;
  public commData: any = [];
  public isDisableOnUpdate = false;
  public isUpdate = false;
  public editor: any = null;
  @Prop()
  public isTaskDisabled: any;
  public isOrderPlacedOnce = false;
  public submitButton: any = null;
  /**
   * Taking dsData variable for checking form data is changed or not only for comaprison
   */
  public dsData: any = {};
  /**************************************************************** */

  public async checkSaveValidation() {
    try {
      let mainDataHasData = await this.formHasData(
        JSON.parse(JSON.stringify(this.disclosureData))
      );
      let valueIsNotSame = false;
      for (const [key, value] of Object.entries(this.disclosureData)) {
        for (const [key1, value1] of Object.entries(this.dsData)) {
          if (
            key != "createdBy" &&
            key != "createdOn" &&
            key != "updatedBy" &&
            key != "updatedOn" &&
            key != "isCompleted" &&
            key != "remarks"
          ) {
            if (key === key1 && value != value1) {
              valueIsNotSame = true;
            }
          }
        }
      }

      if (!mainDataHasData && !valueIsNotSame) return true;
      else return false;
    } catch (error) {
      console.log(error);
    }
  }

  public formHasData(dsData) {
    for (const [key, value] of Object.entries(dsData)) {
      if (
        key != "createdBy" &&
        key != "createdOn" &&
        key != "updatedBy" &&
        key != "updatedOn" &&
        key != "isCompleted" &&
        key != "remarks" &&
        value
      ) {
        return true;
      }
    }
    return false;
  }

  public async checkValidation() {
    try {
      let response = await Axios.get(
        BASE_API_URL +
          "processor/checkAgentsAndRequiredDocs/" +
          this.$route.query.id,
        { headers: authHeader() }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  public async saveInitialDisclosureReceived(isComplete) {
    if (this.isOrderPlacedOnce) return;
    this.callLoader(true, null);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getDisclosureReceivedInfo();
        return;
      }
      if (!isComplete) {
        let notValid1 = await this.checkSaveValidation();
        if (notValid1) {
          this.callLoader(false, null);
          this.$snotify.info("No Changes");
          return;
        }
      }

      if (
        isComplete &&
        (!this.disclosureData.isReceived || !this.disclosureData.isSigned)
      ) {
        this.error.message = "All fields are Mandatory.";
        this.error.show = true;
        this.callLoader(false, null);
        return;
      }
      if (isComplete && this.disclosureData.notSigned) {
        this.error.message = "Initial Disclosures Not Signed/ Not Found.";
        this.error.show = true;
        this.callLoader(false, null);
        return;
      }
      // if (isComplete) {
      //   this.callLoader(false, null);
      //   let res = await this.checkValidation();
      //   if (res.length > 0) {
      //     this.error.message = "Documents are not Added.";
      //     this.error.show = true;
      //     this.error.expandMessage = "";
      //     res.forEach((e, i) => {
      //       this.error.expandMessage += `${e}`;
      //     });

      //     return;
      //   }
      // }

      let myObj = {
        loanId: this.$route.query.id,
        initialDisclosureReceived: this.disclosureData,
        remarkNote: this.remarkNote,
        isComplete: isComplete,
        taskId: this.taskId,
        formData: this.dsData,
      };

      if (isComplete) {
        this.submitButton = document.getElementById("saveButton");
        this.submitButton.setAttribute("disabled", "disabled");
        this.isOrderPlacedOnce = true;
      }

      let response = await Axios.post(
        BASE_API_URL + "processor/saveDisclosureDetails",
        myObj,
        { headers: authHeader() }
      );

      if (response.status == 201)
        this.$snotify.success(
          isComplete
            ? "Disclosure Details Completed successfully."
            : "Disclosure Information has been saved"
        );
      if (isComplete) this.$emit("refresh-task-list", true);
      await this.getDisclosureReceivedInfo();
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async openDisclosureNotFoundModal() {
    this.isUpdate = true;
    await this.$refs.refrence["openDisclosureNotFoundModal"]({
      loanId: this.$route.query.id,
      disclosureData: this.disclosureData.remarks,
      isUpdate: this.isUpdate,
    });
  }

  public async getDisclosureReceivedInfo() {
    this.callLoader(true, 1);
    try {
      this.isDisabled = false;
      this.isUpdate = false;
      let response = await Axios.get(
        BASE_API_URL +
          "processor/getDisclosureReceivedInfo/" +
          this.$route.query.id,
        { headers: authHeader() }
      );

      if (response.status === 201) {
        this.disclosureData = JSON.parse(JSON.stringify(response.data));
        this.dsData = JSON.parse(JSON.stringify(response.data));
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  public async addRemark() {
    this.callLoader(true, 1);
    try {
      // if (!this.remarkNote) {
      //   this.callLoader(false, null);
      //   return;
      // }
      this.remarkNote = this.editor.getContents();
      if (
        this.remarkNote
          .replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, "")
          .trim() != ""
      ) {
        this.error.Messae = "";
      } else {
        this.error.show = true;
        this.error.Messae = "Please add a Remark";
      }
      let myobj = {
        remarkNote: this.remarkNote,
        loanId: this.$route.query.id,
        taskId: this.taskId,
      };
      if (
        this.remarkNote
          .replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, "")
          .trim() != ""
      ) {
        let response = await Axios.post(
          BASE_API_URL + "processor/addRemarkFromIds",
          myobj,
          { headers: authHeader() }
        );

        if (response.status === 201) {
          this.$snotify.success("Remark Added Successfully");
          this.isDisabled = false;
          this.$modal.hide("addRemarkModal");
          await this.saveInitialDisclosureReceived(false);
          this.remarkNote = null;
          this.$emit("refreshOnlyMilestone", true);
        }
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public closeModal() {
    this.disclosureData.notSigned = null;
    this.$modal.hide("addRemarkModal");
  }

  public hideAndShowModel() {
    if (this.isTaskDisabled) {
      this.callLoader(false, null);
      this.$snotify.info("Task is not assigned to you.");
      this.getDisclosureReceivedInfo();
      return;
    }

    this.error = {
      show: false,
      messae: null,
      expandMessage: null,
    };
    if (this.disclosureData.notSigned) {
      this.isDisabled = true;
      setTimeout(async () => {
        this.editor = await suneditor.create("pageEditor", {
          plugins: {
            ...plugins,
          },
          width: "auto",
          height: "100px",
          buttonList: [["bold"], ["list"]],
        });
      }, 1);
      this.$modal.show("addRemarkModal");
    } else this.$modal.hide("addRemarkModal");
  }

  mounted() {
    this.getDisclosureReceivedInfo();
  }
}
