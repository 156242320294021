

import { Component, Vue, Prop } from "vue-property-decorator";
import Address from "../urlaAddress.vue";
import { Reo, Liabilities } from "../../../models/urla/financial-info.model";
import Axios from "axios";
import { BASE_API_URL, EventBus } from "../../../config";
import { authHeader } from "../../../services/auth";
import Multiselect from "vue-multiselect";
import {
  REO_PROPERTY_STATUS,
  REO_INTENDED_OCCUPANCY,
  LOAN_TYPE,
} from "../../../constants/constants";
@Component({
  components: {
    Address,
    Multiselect,
  },
})
export default class RealEstate extends Vue {
  /* eslint-disable */
  public propertyAddress: any = {};
  public realEstate: any = [new Reo()];
  public liability = new Liabilities();
  public realEstateData: any = [new Reo()];
  public loanId = this.$route.query.id;
  public borrowers = [];
  public propertyStatus = REO_PROPERTY_STATUS;
  public intendedOccupancy = REO_INTENDED_OCCUPANCY;
  public loanType = LOAN_TYPE;
  public liabilityToRemove: any = [];
  public reoToRemove: any = [];
  public total: any = {};
  public doesNotApplyForReo: any = false;
  public doesNotApplyForReoData: any = false;
  public loanPurpose: any = null;
  public count = 0;
  public changedValues: any = [];
  @Prop()
  public validationData: any;
  @Prop()
  public isLoanInactive: any;

  public addAndRemoveReo(isAdd, index) {
    if (isAdd) this.realEstate.push(new Reo());
    else {
      this.reoToRemove.push({
        reoId: this.realEstate[index]._id,
      });
      this.realEstate.splice(index, 1);
      if (index == 0 && this.realEstate.length == 0)  {
        this.doesNotApplyForReo = true;
        this.realEstate = [new Reo()];
      }
      this.calculatePresentMarketValue();
      this.calculateGrossRentalIncome();
      this.calculateTotalNetRentalIncome();
      this.calculateMonthlyExpense();
    }
  }

  public addAndRemoveLiability(isAdd, reoIndex, index) {
    console.log(
      "this.realEstate[reoIndex].liabilitiesId",
      this.realEstate[reoIndex]
    );
    if (isAdd) {
      !this.realEstate[reoIndex].liabilitiesId
        ? (this.realEstate[reoIndex].liabilitiesId = [{ id: null }])
        : "";
      this.realEstate[reoIndex].liabilities.push(new Liabilities());
      this.realEstate[reoIndex].liabilitiesId.push({ id: null });
    } else {
      if (this.realEstate[reoIndex].liabilitiesId.length>0 && this.realEstate[reoIndex].liabilitiesId[index].id) {
        this.liabilityToRemove.push({
          liabId: this.realEstate[reoIndex].liabilitiesId[index].id,
          reoId: this.realEstate[reoIndex]._id,
        });
      }
      this.realEstate[reoIndex].liabilities.splice(index, 1);
      this.realEstate[reoIndex].liabilitiesId.splice(index, 1);
      this.calculateMonthlyPayment();
    }
  }

  public async getRealEstateData() {
    try {
      this.changedValues = [];
      this.callLoader(true, null);
      let response = await Axios.post(
        BASE_API_URL + "urla/getRealEstateData",
        {
          loanId: this.loanId,
        },
        {
          headers: authHeader(),
        }
      );
      this.count = 0;
      if (response.data.borrowers && response.data.borrowers.length > 0) {
        this.borrowers = response.data.borrowers;
      }

      if (response.data.realEstate && response.data.realEstate.length > 0) {
        for (let i = 0; i < response.data.realEstate.length; i++) {
          response.data.realEstate[i].propertyValue = response.data.realEstate[
            i
          ].propertyValue
            ? Number(response.data.realEstate[i].propertyValue)
            : 0;
          response.data.realEstate[i].monthlyExpense = response.data.realEstate[
            i
          ].monthlyExpense
            ? Number(response.data.realEstate[i].monthlyExpense)
            : 0;
          response.data.realEstate[i].monthlyRentalIncome = response.data
            .realEstate[i].monthlyRentalIncome
            ? Number(response.data.realEstate[i].monthlyRentalIncome)
            : 0;
          response.data.realEstate[i].netMonthlyRentalIncome = response.data
            .realEstate[i].netMonthlyRentalIncome
            ? Number(response.data.realEstate[i].netMonthlyRentalIncome)
            : 0;

          if (response.data.realEstate[i].liabilities.length > 0) {
            for (
              let j = 0;
              j < response.data.realEstate[i].liabilities.length;
              j++
            ) {
              response.data.realEstate[i].liabilities[j].unpaidBalance =
                response.data.realEstate[i].liabilities[j].unpaidBalance
                  ? Number(
                      response.data.realEstate[i].liabilities[j].unpaidBalance
                    )
                  : 0;
              response.data.realEstate[i].liabilities[j].monthlyPayment =
                response.data.realEstate[i].liabilities[j].monthlyPayment
                  ? Number(
                      response.data.realEstate[i].liabilities[j].monthlyPayment
                    )
                  : 0;
              response.data.realEstate[i].liabilities[j].creditLimit = response
                .data.realEstate[i].liabilities[j].creditLimit
                ? Number(response.data.realEstate[i].liabilities[j].creditLimit)
                : 0;
            }
          }
        }
        if (response.data && response.data.realEstate.length > 0) {
          this.realEstate = response.data.realEstate;
        }

        // console.log( this.realEstate, 'dfd')
      }
      this.doesNotApplyForReo = response.data.doesNotApplyForReo;
      this.loanPurpose = response.data.loanPurpose;
      this.realEstateData = JSON.parse(JSON.stringify(this.realEstate));
      this.doesNotApplyForReoData = JSON.parse(
        JSON.stringify(this.doesNotApplyForReo)
      );

      // this.realEstate.forEach(e=>{
      //   console.log(e)
      // })
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  public async propertyDetails() {
    try {
      this.$bvModal.show("propertyModal");
    } catch (error) {
      console.log(error);
    }
  }

  public async saveRealEstateData() {
    try {
      this.realEstate.forEach((r) => {
        r.borrowerId.map((b: any) => {
          b.id = b.borrowerId;
        });
      });
      let response = await Axios.post(
        BASE_API_URL + "urla/saveRealEstateData",
        {
          loanId: this.loanId,
          realEstate: this.realEstate,
          liabilityToRemove: this.liabilityToRemove,
          reoToRemove: this.reoToRemove,
          doesNotApplyForReo: this.doesNotApplyForReo,
          changedValues: this.changedValues,
        },
        {
          headers: authHeader(),
        }
      );
      if (response.status == 201 && this.count > 0)
        this.$snotify.success("Real Estate Information has been Updated");
      this.$emit("referesh-tab", true);
      EventBus.$emit("refesh-loaninfo");
      await this.getRealEstateData();
    } catch (error) {
      console.log(error);
    }
  }

  public async handleChange(currentVal, previousVal, fieldName) {
    if (previousVal !== undefined) {
      const index = this.changedValues.findIndex((c) => c.name === fieldName);
      if (index !== -1) {
        if (JSON.stringify(currentVal) === JSON.stringify(previousVal)) {
          this.changedValues.splice(index, 1);
        } else {
          let data = this.changedValues.find((v) => v.name === fieldName);
          data.from = previousVal;
          data.to = currentVal;
        }
      } else {
        this.changedValues.push({
          name: fieldName,
          from: previousVal,
          to: currentVal,
        });
      }
    }
  }

  public calculatePresentMarketValue() {
    this.total.totalPresentMarketValue = 0;
    this.realEstate.forEach((r) => {
      this.total.totalPresentMarketValue =
        this.total.totalPresentMarketValue + r.propertyValue;
    });
    this.total = JSON.parse(JSON.stringify(this.total));
  }

  public hasAnychangedValues() {
    return this.changedValues;
  }

  public async calculateNetMonthlyRentalValue() {
    this.total.totalPresentMarketValue = 0;
    this.total.totalNetRentalIncome = 0;
    this.realEstate.forEach((r) => {
      let totalMonthlyPayment = 0;
      if (r.liabilities.length > 0) {
        for (let i = 0; i < r.liabilities.length; i++) {
          const element = r.liabilities[i];
          totalMonthlyPayment = element.monthlyPayment + totalMonthlyPayment;
        }
      }
      if (r.monthlyRentalIncome > 0) {
        r.netMonthlyRentalIncome =
          r.monthlyRentalIncome - r.monthlyExpense - totalMonthlyPayment;
        // this.total.totalNetRentalIncome =
        // r.monthlyRentalIncome - r.monthlyExpense - totalMonthlyPayment;
        this.total.totalNetRentalIncome =
          this.total.totalNetRentalIncome + r.netMonthlyRentalIncome;
      } else {
        r.netMonthlyRentalIncome = 0;
        this.total.totalNetRentalIncome = this.total.totalNetRentalIncome;
      }
    });

    this.total = JSON.parse(JSON.stringify(this.total));
  }

  public calculateGrossRentalIncome() {
    // this.total.totalRentalIncome = 0;
    Vue.set(this.total, "totalRentalIncome", 0);
    this.realEstate.forEach((r) => {
      this.total.totalRentalIncome =
        this.total.totalRentalIncome + r.monthlyRentalIncome;
    });
    this.total = JSON.parse(JSON.stringify(this.total));
  }

  public calculateMonthlyExpense() {
    // this.total.totalMonthlyExpense = 0;
    Vue.set(this.total, "totalMonthlyExpense", 0);
    this.realEstate.forEach((r) => {
      this.total.totalMonthlyExpense =
        this.total.totalMonthlyExpense + r.monthlyExpense;
    });
    this.total = JSON.parse(JSON.stringify(this.total));
  }

  public calculateMonthlyPayment() {
    // this.total.totalMonthlyPayment = 0;
    Vue.set(this.total, "totalMonthlyPayment", 0);
    this.realEstate.forEach((r) => {
      r.liabilities.forEach((l) => {
        this.total.totalMonthlyPayment =
          this.total.totalMonthlyPayment + l.monthlyPayment;
      });
    });

    this.total = JSON.parse(JSON.stringify(this.total));
  }

  public calculateTotalNetRentalIncome() {
    // this.total.totalNetRentalIncome = 0;
    Vue.set(this.total, "totalNetRentalIncome", 0);
    this.realEstate.forEach((r) => {
      this.total.totalNetRentalIncome =
        this.total.totalNetRentalIncome + r.netMonthlyRentalIncome;
    });
    console.log();

    this.total = JSON.parse(JSON.stringify(this.total));
  }

  public totalValue(total) {
    const totals = total.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return totals;
  }

  public checkForValidation() {
    this.$emit("call-mount", true);
  }

  public checkCreditorName(liability) {
    if (this.validationData) {
      if (
        !liability.companyName &&
        (liability.accountNumber ||
          liability.monthlyPayment ||
          liability.unpaidBalance ||
          liability.types.name ||
          liability.creditLimit ||
          liability.toBePaidOff)
      ) {
        return true;
      }
      return false;
    }
  }

  public checkAccountNumber(liability) {
    if (this.validationData) {
      if (
        !liability.accountNumber &&
        (liability.companyName ||
          liability.toBePaidOff ||
          liability.monthlyPayment ||
          liability.unpaidBalance ||
          liability.types.name ||
          liability.creditLimit)
      )
        return true;
    }
    return false;
  }

  public checkMonthlyPayment(liability) {
    if (this.validationData) {
      if (
        !liability.monthlyPayment &&
        (liability.companyName ||
          liability.toBePaidOff ||
          liability.accountNumber ||
          liability.unpaidBalance ||
          liability.types.name ||
          liability.creditLimit)
      )
        return true;
    }
    return false;
  }

  public checkUnpaidBalance(liability) {
    if (this.validationData) {
      if (
        !liability.unpaidBalance &&
        (liability.companyName ||
          liability.toBePaidOff ||
          liability.monthlyPayment ||
          liability.accountNumber ||
          liability.types.name ||
          liability.creditLimit)
      )
        return true;
    }
    return false;
  }

  public checkotherLoanType(liability) {
    if (this.validationData) {
      if (
        liability.types.name == "Other" &&
        !liability.types.description &&
        (liability.companyName ||
          liability.toBePaidOff ||
          liability.monthlyPayment ||
          liability.unpaidBalance ||
          liability.accountNumber ||
          liability.creditLimit)
      )
        return true;
    }
    return false;
  }

  public initializeReo() {
   // this.realEstate = [new Reo()];
  }

  public removedReostarting() {
   // this.realEstate = [new Reo()];
  }

  public preciseVal(totalMonthlyPayment) {
    const totals = totalMonthlyPayment.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return totals;
  }

  public initializeOtherVal(reo) {
    if (reo.intendedOccupancy && reo.intendedOccupancy.name != "Other") {
      reo.intendedOccupancy.description = null;
    }
  }

  public initializeTypesOtherDescription(liability) {
    if (liability.types && liability.types.name != "Other") {
      liability.types.description = null;
    }
  }

  public checkValidationForMobile() {
    EventBus.$emit("check-validation", true);
  }

  public async mounted() {
    await this.getRealEstateData();
    await this.calculatePresentMarketValue();
    await this.calculateGrossRentalIncome();
    await this.calculateMonthlyExpense();
    await this.calculateMonthlyPayment();
    await this.calculateTotalNetRentalIncome();
    this.validationData ? this.checkForValidation() : "";
  }
}
