











































































































import { Component, Vue, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "../../../config";
import { authHeader } from "../../../services/auth";
@Component
export default class Funded extends Vue {
  /* eslint-disable */
  @Prop()
  public taskId: any;
  public funded: any = {};
  public error = false;
  // @Prop()
  // public isLoanInactive: any;
  public isTaskCompleted = false;
  @Prop()
  public isTaskDisabled: any;
  public isOrderPlacedOnce = false;
  /**
   * Taking dsData variable for checking form data is changed or not only for comaprison
   */
  public dsData: any = {};
  /**************************************************************** */

  public async checkSaveValidation() {
    try {
      let mainDataHasData = await this.formHasData(
        JSON.parse(JSON.stringify(this.funded))
      );
      let valueIsNotSame = false;
      for (const [key, value] of Object.entries(this.funded)) {
        for (const [key1, value1] of Object.entries(this.dsData)) {
          if (
            key != "createdBy" &&
            key != "createdOn" &&
            key != "updatedBy" &&
            key != "updatedOn" &&
            key != "isCompleted"
          ) {
            if (key === key1 && value != value1) {
              valueIsNotSame = true;
            }
          }
        }
      }
      if (!mainDataHasData && !valueIsNotSame) return true;
      else return false;
    } catch (error) {
      console.log(error);
    }
  }

  public formHasData(dsData) {
    for (const [key, value] of Object.entries(dsData)) {
      if (
        key != "createdBy" &&
        key != "createdOn" &&
        key != "updatedBy" &&
        key != "updatedOn" &&
        key != "isCompleted" &&
        value
      ) {
        return true;
      }
    }
    return false;
  }

  public async saveFundedInfo(isComplete) {
    if(this.isOrderPlacedOnce) return

    this.callLoader(true, null);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        await this.getFundedDetails();
        return;
      }
      if (!isComplete) {
        let notValid1 = await this.checkSaveValidation();
        if (notValid1) {
          this.callLoader(false, null);
          this.$snotify.info("No Changes");
          return;
        }
      }
      if (
        isComplete &&
        (!this.funded.hasCreditPackageUploaded ||
          !this.funded.isLenderWiredFundToTile ||
          !this.funded.isLoackedLEUploaded ||
          !this.funded.isLoanAmountMatchToFinalCD)
      ) {
        this.error = true;
        this.callLoader(false, null);
        return;
      }

      if(isComplete) this.isOrderPlacedOnce=true;
      let response = await Axios.post(
        BASE_API_URL + "processor/saveFundedInfo",
        {
          loanId: this.$route.query.id,
          funded: this.funded,
          isComplete: isComplete,
          taskId: this.taskId,
        },
        {
          headers: authHeader(),
        }
      );

      if (response.status === 201) {
        this.$snotify.success(
          isComplete
            ? "Funded Info Completed successfully."
            : "Funded  Information has been saved"
        );
      }
      if (isComplete) {
        this.$emit("refresh-task-list", true);
        this.$router.push({ path: "/processor-dashboard" });
      }
      await this.getFundedDetails();
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async getFundedDetails() {
    this.callLoader(true, null);
    try {
      const response = await Axios.get(
        BASE_API_URL +
          "processor/getFundedDetails/" +
          this.$route.query.id +
          "/" +
          this.taskId,
        {
          headers: authHeader(),
        }
      );
      if (response.status === 201) {
        console.log('response...',response.data)
        this.funded = JSON.parse(JSON.stringify(response.data.funded));
        this.dsData = JSON.parse(JSON.stringify(response.data.funded));
        this.isTaskCompleted = response.data.isCompleted;
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  public mounted() {
    this.getFundedDetails();
  }
}
