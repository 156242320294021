

/* eslint-disable  @typescript-eslint/no-explicit-any */
export class OtherContactInfo {
    constructor() {
        this.option = null;
        this.firstName = null;
        this.lastName = null;
        this.companyName = null;
        this.contactEmail = null;
        this.contactFax = null;

    }
    public option: any;
    public firstName: any;
    public lastName: any;
    public contactEmail: any;
    public contactFax: any;
    public companyName: any;
}

export class CondoAgentInfo {
    constructor() {
        this.option = null;
        this.email = null;
        this.fax = null;
        this.otherContact = new OtherContactInfo();
        this.condoVendorId = null;

    }
    public option: any;
    public email: any;
    public fax: any;
    public otherContact: OtherContactInfo;
    public condoVendorId: any;

}

export class VerificationOfAgent {
    constructor() {
        this.orderPlaced = false;
        this.condoAgentInfo = new CondoAgentInfo();
    }
    public orderPlaced: boolean;
    public condoAgentInfo: CondoAgentInfo;

}
export class CondoVendorData {
    constructor() {
        this.vendorTypeId = 7;
        this.companyName = null;
        this.companyUrl = null;
        this.userName = "";
        this.password = "";
        this.createdOn = new Date();
    }

    public vendorTypeId: number;
    public companyName: any;
    public companyUrl: any;
    public userName: any;
    public password: any;
    public createdOn: Date;


}

export class IsCondoNameAccuratelyReflected {
    constructor() {

        this.revisionRequestedOn = new Date();
        this.revisionCompletedOn = new Date();
        this.nARemark = null;
        this.mode = null;
    }
    public revisionRequestedOn: any;
    public revisionCompletedOn: any;
    public nARemark: any
    public mode: any
}

export class AreBorrowersNameAccuratelyReflected {
    constructor() {
        this.revisionRequestedOn = new Date();
        this.revisionCompletedOn = new Date();
        this.nARemark = null;
        this.mode = null;
    }
    public revisionRequestedOn: any;
    public revisionCompletedOn: any;
    public nARemark: any
    public mode: any
}

export class IsPropertyAddressAccuratelyReflected {
    constructor() {
        this.revisionRequestedOn = new Date();
        this.revisionCompletedOn = new Date();
        this.nARemark = null;
        this.mode = null;
    }
    public revisionRequestedOn: any;
    public revisionCompletedOn: any;
    public nARemark: any
    public mode: any
}

export class IsMasterInsuranceAgentNameContactInformationReflected {
    constructor() {
        this.revisionRequestedOn = new Date();
        this.revisionCompletedOn = new Date();
        this.nARemark = null;
        this.mode = null;
    }
    public revisionRequestedOn: any;
    public revisionCompletedOn: any;
    public nARemark: any
    public mode: any
}

export class IsCondoQuestionnaireCompleted {
    constructor() {
        this.revisionRequestedOn = new Date();
        this.revisionCompletedOn = new Date();
        this.nARemark = null;
        this.mode = null;
    }
    public revisionRequestedOn: any;
    public revisionCompletedOn: any;
    public nARemark: any
    public mode: any
}

export class IsCondoQuestionnaireSignedAuthorizedRepresentative {
    constructor() {
        this.revisionRequestedOn = new Date();
        this.revisionCompletedOn = new Date();
        this.nARemark = null;
        this.mode = null;
    }
    public revisionRequestedOn: any;
    public revisionCompletedOn: any;
    public nARemark: any
    public mode: any
}

export class IsFullCondoQuestionnaireReview {
    constructor() {
        this.revisionRequestedOn = new Date();
        this.revisionCompletedOn = new Date();
        this.nARemark = null;
        this.mode = null;
    }
    public revisionRequestedOn: any;
    public revisionCompletedOn: any;
    public nARemark: any
    public mode: any
}

export class CondoChecklist {
    constructor() {
        this.isCondoNameAccuratelyReflected = new IsCondoNameAccuratelyReflected();
        this.areBorrowersNameAccuratelyReflected = new AreBorrowersNameAccuratelyReflected();
        this.isPropertyAddressAccuratelyReflected = new IsPropertyAddressAccuratelyReflected();
        this.isMasterInsuranceAgentNameContactInformationReflected = new IsMasterInsuranceAgentNameContactInformationReflected();
        this.isCondoQuestionnaireCompleted = new IsCondoQuestionnaireCompleted();
        this.isCondoQuestionnaireSignedAuthorizedRepresentative = new IsCondoQuestionnaireSignedAuthorizedRepresentative();
        this.isFullCondoQuestionnaireReview = new IsFullCondoQuestionnaireReview();
        this.review = null;

    }
    public isCondoNameAccuratelyReflected: IsCondoNameAccuratelyReflected;
    public areBorrowersNameAccuratelyReflected: AreBorrowersNameAccuratelyReflected;
    public isPropertyAddressAccuratelyReflected: IsPropertyAddressAccuratelyReflected;
    public isMasterInsuranceAgentNameContactInformationReflected: IsMasterInsuranceAgentNameContactInformationReflected;
    public isCondoQuestionnaireCompleted: IsCondoQuestionnaireCompleted;
    public isCondoQuestionnaireSignedAuthorizedRepresentative: IsCondoQuestionnaireSignedAuthorizedRepresentative;
    public isFullCondoQuestionnaireReview: IsFullCondoQuestionnaireReview;
    public review: any;

}