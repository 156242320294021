

import { Component, Vue, Prop } from "vue-property-decorator";
import AddressComponent from "../urlaAddress.vue";
import Axios from "axios";
import { authHeader } from "../../../services/auth";
import { BASE_API_URL, EventBus } from "../../../config";
import datepicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Address from "../../../models/common/address.model";
import {
  Borrower,
  Dependent,
  FormerAddress,
  AlternateName,
} from "../../../models/urla/borrower.model";
import {
  CITIZENSHIP,
  MARITAL_STATUS,
  UNMARRIED_STATUS_TYPE,
  BORROWER_RESIDENCE_BASIS_TYPE,
  TYPE_OF_RELATIONSHIP,
} from "../../../constants/constants";
@Component({
  components: {
    AddressComponent,
    datepicker,
  },
})
export default class BorrowerInfo extends Vue {
  /* eslint-disable */
  public borrowerData: any = new Borrower();
  public borrowerInfo: any = new Borrower();
  public citizenship = CITIZENSHIP;
  public marital_status = MARITAL_STATUS;
  public marital_status_type = UNMARRIED_STATUS_TYPE;
  public dependent = new Dependent();
  public updatedEmail: any = null;
  public error = {
    dependentError: false,
    formerAddressError: false,
    emailError: false,
  };
  public housing = BORROWER_RESIDENCE_BASIS_TYPE;
  public formerAddress: any = new FormerAddress();
  public relationTypes = TYPE_OF_RELATIONSHIP;
  public alternateNames: any = new AlternateName();
  public address = new Address();
  public states = [];
  public count = 0;
  public isRequired = false;
  public changedValues: any = [];
  @Prop()
  public validationData: any;
  @Prop()
  public isLoanInactive: any;
  public isForcedChangeEmail = false;
  public index: any;
  public selectBorrower: any = {};
  public emailExist = false;
  public noChange = false;
  public borroweInfo = [];
  public isDisable = false;

  public msg = "";

  public async getBorrowerInfo() {
    try {
      this.changedValues = [];
      this.callLoader(true, null);
      const response: any = await Axios.post(
        BASE_API_URL + "urla/getBorrowerInfo",
        {
          loanId: this.$route.query.id,
        },
        {
          headers: authHeader(),
        }
      );
      this.count = 0;
      if (response.data.status == 201) {
        // await this.assignData(response.data);
        this.borrowerData = response.data.borrowerInfo;
        this.isRequired = response.data.isRequired;
        this.states = response.data.states;
        this.borrowerInfo = JSON.parse(JSON.stringify(this.borrowerData));
      }
      this.validationData ? this.checkValidation() : "";
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public hasAnychangedValues() {
    return this.changedValues;
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  // public async assignData(data) {
  //   try {
  //     data.borrowerInfo.forEach(a => {
  //       a.formerAddress[0]["address"] = this.formerAddress.address;
  //       a["mailingAddress"] = this.formerAddress.address;
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  public async addDependents(index) {
    try {
      this.error.dependentError = false;
      this.borrowerData.borrowerInfo[index].dependents = [];
      if (
        this.borrowerData.borrowerInfo[index].numberOfDependents >= 0 &&
        this.borrowerData.borrowerInfo[index].numberOfDependents < 10
      ) {
        for (
          let i = 0;
          i < this.borrowerData.borrowerInfo[index].numberOfDependents;
          i++
        ) {
          this.borrowerData.borrowerInfo[index].dependents.push({
            ...this.dependent,
          });
        }
      } else {
        this.error.dependentError = true;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public addInputField(identifier, type, borrowerIndex, index) {
    if (identifier === "address" && type === "add") {
      this.borrowerData.borrowerInfo[borrowerIndex].formerAddress.push(
        new FormerAddress()
      );
    } else if (identifier === "address" && type === "del") {
      this.borrowerData.borrowerInfo[borrowerIndex].formerAddress.splice(
        index,
        1
      );
    } else if (identifier === "alternateNames" && type === "add") {
      this.borrowerData.borrowerInfo[borrowerIndex].alternateNames.push(
        new AlternateName()
      );
    } else if (identifier === "alternateNames" && type === "del") {
      this.borrowerData.borrowerInfo[borrowerIndex].alternateNames.splice(
        index,
        1
      );
    }
  }

  public async updateBorrowerInfo(i) {
    try {
    //  console.log("this.error.emailError");

      //   (this.validationData && validationData.duplicateEmail) && !isRequired
      const response = await Axios.post(
        BASE_API_URL + "urla/updateBorrowerInfo",
        {
          borrowerData: this.borrowerData,
          loanId: this.$route.query.id,
          borrIndex: i,
          changedValues: this.changedValues,
        },
        {
          headers: authHeader(),
        }
      );

      if (response.data.status === 400 && this.count > 0) {
        // this.isForcedChangeEmail = false;

        this.$snotify.success(response.data.msg);
        this.error.emailError = false;
      } else if (response.data.status === 200) {
        this.error.emailError = true;
      }
      await this.getBorrowerInfo();
      this.$emit("referesh-tab", true);
      EventBus.$emit("refesh-loaninfo");
      this.validationData ? await this.refreshBorrowerTabs() : "";
    } catch (error) {
      console.log(error);
    }
  }

  public async handleChange(currentVal, previousVal, fieldName) {
    if (previousVal !== undefined) {
      const index = this.changedValues.findIndex((c) => c.name === fieldName);
      if (index !== -1) {
        if (currentVal === previousVal) {
          this.changedValues.splice(index, 1);
        } else {
          let data = this.changedValues.find((v) => v.name === fieldName);
          data.from = previousVal;
          data.to = currentVal;
        }
      } else {
        this.changedValues.push({
          name: fieldName,
          from: previousVal,
          to: currentVal,
        });
      }
    }
  }

  public async refreshBorrowerTabs() {
    try {
      const response = await Axios.post(
        BASE_API_URL + "urla/refreshBorrowerTabs",
        {
          loanId: this.$route.query.id,
          identifier: "Borrower",
        },
        {
          headers: authHeader(),
        }
      );
      const borIndex = response.data;
      this.borrowerData.borrowerInfo.forEach((b, i) => {
        const hasExist =
          borIndex.length > 0 ? borIndex.find((index) => index == i) : null;
        Vue.set(b, "showValidation", hasExist != null ? true : false);
      });
    } catch (error) {
      console.log(error);
    }
  }

  public addFormerAddress(borrowerIndex) {
    console.log(borrowerIndex, "borrowerIndex");
    console.log(
      this.borrowerData.borrowerInfo[borrowerIndex].currentAddress.period,
      "period"
    );
    console.log(
      this.borrowerData.borrowerInfo[borrowerIndex].formerAddress.length,
      "length"
    );
    try {
      if (
        this.borrowerData.borrowerInfo[borrowerIndex].currentAddress.period
          .year < 2 &&
        this.borrowerData.borrowerInfo[borrowerIndex].formerAddress.length == 0
      ) {
        this.borrowerData.borrowerInfo[borrowerIndex].formerAddress.push(
          this.formerAddress
        );
      } else
        this.borrowerData.borrowerInfo[borrowerIndex].formerAddress = [
          new FormerAddress(),
        ];
    } catch (error) {
      console.log(error);
    }
  }

  public removeMailingAddress(borrowerIndex) {
    try {
      this.borrowerData.borrowerInfo[
        borrowerIndex
      ].mailingAddress = new Address();
    } catch (error) {
      console.log(error);
    }
  }

  public displayName() {
    const borrowersName: string[] = [];
    //console.log(this.borrowerData.borrowerInfo, 'this.borrowerData.borrowerInfo')
    this.borrowerData.borrowerInfo.forEach((e) => {
      // console.log(e.name.firstName, 'e.name.firstName')
      const name = e.name.firstName + " " + e.name.lastName;
      borrowersName.push(name);
    });
    return borrowersName.toString();
  }

  public alternateFirstNameCheck(altName) {
    if (this.validationData) {
      if (
        altName.firstName ||
        altName.middleName ||
        altName.lastName ||
        altName.suffix
      )
        if (!altName.firstName) return true;
    } else {
      return false;
    }
  }

  public alternateLastNameCheck(altName) {
    if (this.validationData) {
      if (
        altName.firstName ||
        altName.middleName ||
        altName.lastName ||
        altName.suffix
      )
        if (!altName.lastName) return true;
    } else {
      return false;
    }
  }

  //  public getErrorText() {
  //    if(this.validationData && this.error.emailError)
  //  }

  public checkFormerAddressLength(borrowerIndex) {
    try {
      if (
        this.borrowerData.borrowerInfo[borrowerIndex].formerAddress.length === 1
      ) {
        this.error.formerAddressError = true;
        return true;
      } else {
        this.error.formerAddressError = false;
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public checkValidation() {
    this.borrowerData.borrowerInfo.forEach((d, i) => {
      const hasExist =
        this.validationData.borIndex.length > 0
          ? this.validationData.borIndex.find((index) => index == i)
          : null;
      Vue.set(d, "showValidation", hasExist != null ? true : false);
    });
    this.$emit("call-mount", true);
  }

  public hasMilitaryService(militaryService) {
    if (this.validationData) {
      if (
        !militaryService.hasServingActiveDuty &&
        !militaryService.hasRetired &&
        !militaryService.hasNonActivatedForPeriod &&
        !militaryService.isServingSpouse
      )
        return true;
    }
    return false;
  }

  // public initializeError() {
  //   if (this.validationData) {
  //     this.validationData.duplicateEmail = false;
  //   }
  //   this.error.emailError = false;
  // }

  public checkPhone(contactInfo) {
    if (this.validationData) {
      if (
        !contactInfo.homePhone &&
        !contactInfo.cellPhone &&
        !contactInfo.workPhone
      )
        return true;
    }
    return false;
  }

  public showEmailModel(userEmail, index) {
    if (this.$store.state.sessionObj.userType == 3 && index == 0) {
      this.updatedEmail = userEmail;
      this.$modal.show("emailModal");
    }
  }

  public async userChoice(data) {
    const response: any = await Axios.post(
      BASE_API_URL + "urla/updateConfirmation",
      {
        loanId: this.$route.query.id,
        status: data,
        email: this.updatedEmail,
        borrowerData: this.borrowerInfo,
      },
      {
        headers: authHeader(),
      }
    );
    if (response.data.status == 201) this.$modal.hide("emailModal");
    else if (response.data.status == 400) {
      this.$modal.hide("emailModal");
      this.$snotify.warning("Invalid email");
    }
  }

  public async updateEmail() {
    try {
      this.updateBorrowerInfo(0);
      this.isDisable = true;
      if (this.borrowerData.borrowerInfo[0].contactInfo.email) {
        if (
          this.noChange ||
          this.selectBorrower.email.toLowerCase() ===
            this.borrowerData.borrowerInfo[0].contactInfo.email.toLowerCase()
        ) {
          this.$snotify.info("No Changes");
          this.$modal.hide("changeEmail");
          this.noChange = false;
          return;
        }
      }
      this.selectBorrower.userType = 4;
      this.selectBorrower.loanId = this.$route.query.id;
      this.selectBorrower.isForcedChangeEmail = this.isForcedChangeEmail;

      const response = await Axios.post(
        BASE_API_URL + "common/broker/updateEmail",
        this.selectBorrower,
        { headers: authHeader() }
      );
      if (response.data.status == 201) {
        this.$snotify.success("Email Address updated Successfully.");
        this.isForcedChangeEmail = false;
        EventBus.$emit("refreshBorrowerTab");
        this.$modal.hide("changeEmail");
        this.selectBorrower.isVerification = false;
        await this.getBorrowerInfo();
        this.isDisable = false;

        //this.$modal.show("confirmation");
      } else if (response.data.status === 401) {
        this.$snotify.error(response.data.message);
        this.isDisable = false;
        this.$modal.hide("changeEmail");
      } else if (response.data.status == 400) {
        this.emailExist = response.data.emailExist;
        this.borroweInfo = response.data.borroweInfo
          ? response.data.borroweInfo
          : [];
        if (response.data.userType == 4) {
          this.$modal.show("changeEmailConfirmationModal");
        }
        this.$snotify.error("Email Address already Exist.");
        this.$modal.hide("changeEmail");
        this.isDisable = false;
      } else if (response.status === 202) {
        this.msg = response.data.message;
        this.isDisable = false;
      } else {
        this.$snotify.error("Something went wrong.");
        this.isDisable = false;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public checkValidationForMobile() {
    EventBus.$emit("check-validation", true);
  }

  async mounted() {
    EventBus.$on("refreshBorrowerTab", async () => {
      await this.getBorrowerInfo();
    });
    await this.getBorrowerInfo();
    this.validationData ? await this.checkValidation() : "";
  }
  /* eslint-disable */
}
