























































































































  import { Component, Vue, Prop } from "vue-property-decorator";
  import Axios from "axios";
  import { BASE_API_URL } from "../../../config";
  import { authHeader } from "../../../services/auth";
import ReOrderTask from "../../../common-components/ReOrderTask.vue";
@Component({
  components: {
    ReOrderTask,
  },
})
export default class EngineerCertificationOrder extends Vue {
  /* eslint-disable */
  public engineerCertificationOrder: any = {};
  public isCompleted = false;
  @Prop()
  public taskId: any;
  @Prop()
  public isLoanInactive: any;
  public error: any = {
    show: false,
    msg: null,
  };
  @Prop()
  public isTaskDisabled: any;
  public showReOrderBtn = false;
  public showHistoryBtn = false;
  @Prop()
  public secondAppraisalCount: any;
  public secondAppraisalCountForServer: any = null;
  @Prop()
  public totalTaskCount: any;
  public totalTaskCountForServer = null;
  public dsData: any = {};
  public isOrderPlacedOnce = false;

  public saveValidation() {
    if (
      this.dsData.isEngineerCertificationOrdered ==
      this.engineerCertificationOrder.isEngineerCertificationOrdered
      )
      return true;
    else return false;
  }

  public async saveEngineerCerificationOrder(isComplete) {
    if(this.isOrderPlacedOnce) return
    this.callLoader(true, null);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        await this.getEngineerCerificationOrder();
        return;
      }
      if (!isComplete) {
        let notValid1 = await this.saveValidation();
        if (notValid1) {
          this.$snotify.info("No Changes");
          this.callLoader(false, null);
          return;
        }
      }

      if (
        isComplete &&
        !this.engineerCertificationOrder.isEngineerCertificationOrdered
        ) {
        this.error.show = true;
      this.error.msg = "All fields are Required.";
      this.callLoader(false, null);
      return;
    }
    if(isComplete) this.isOrderPlacedOnce=true;
    const response = await Axios.post(
      BASE_API_URL + "processor/saveEngineerCerificationOrder",
      {
        taskId: this.taskId,
        loanId: this.$route.query.id,
        engineerCertificationOrder: this.engineerCertificationOrder,
        totalTaskCount: this.totalTaskCountForServer,
          // reOrderCount: this.countToSendOnServer,
          secondAppraisalCount: this.secondAppraisalCountForServer,
          isComplete: isComplete,
          formData: this.dsData,
        },
        { headers: authHeader() }
        );
    if (response) {
      isComplete
      ? this.$snotify.success(
        "Engineer's Certification  Order Completed successfully"
        )
      : this.$snotify.success(
        "Engineer's Certification  Order Saved successfully"
        );
      await this.getEngineerCerificationOrder();
      if (isComplete) this.$emit("refresh-task-list", true);
    }
  } catch (error) {
    console.log(error);
  }
  this.callLoader(false, null);
}

public async getEngineerCerificationOrder() {
  this.callLoader(true, null);
  try {
    const response = await Axios.post(
      BASE_API_URL + "processor/getEngineerCerificationOrder/",
      {
        loanId: this.$route.query.id,
        taskId: this.taskId,
        totalTaskCount: this.totalTaskCountForServer,
          // reOrderCount: this.countToSendOnServer,
          secondAppraisalCount: this.secondAppraisalCountForServer,
        },
        {
          headers: authHeader(),
        }
        );

    if (response.status === 201) {
      this.isCompleted = response.data.isCompleted;
      this.showReOrderBtn = response.data.showReOrderBtn;
      this.engineerCertificationOrder = response.data.taskData
      ? JSON.parse(JSON.stringify(response.data.taskData))
      : {};
      this.dsData = response.data.taskData
      ? JSON.parse(JSON.stringify(response.data.taskData))
      : {};
      this.showHistoryBtn = response.data.showHistoryBtn;
    }
  } catch (error) {
    console.log(error);
  }
  this.callLoader(false, null);
}

public callLoader(loader, loaderTitleId) {
  this.$store.state.tmpcoLoader = loader;
  this.$store.state.loaderTitleId = loaderTitleId;
}

public async reOrderEngineerCertificate() {
  this.callLoader(true, 1);
  try {
    if (this.isTaskDisabled) {
      this.callLoader(false, null);
      this.$snotify.info("Task is not assigned to you.");
      return;
    }
    this.$refs.refReOrder["confitmationBox"]({
      message:
      "Are you sure you want to RE-Order Engineer's Certification Order?",
      apiPath: `processor/reOrderEngineerCertificate`,
      data: { loanId: this.$route.query.id, taskId: this.taskId },
    });
  } catch (error) {
    console.log(error);
  }
  this.callLoader(false, null);
}

public async allMountFunction(count, secondAppraisalCount) {
  this.secondAppraisalCountForServer = secondAppraisalCount;
  this.totalTaskCountForServer = count;
    // this.countToSendOnServer = count;
    this.getEngineerCerificationOrder();
  }

  public async showHistory() {
    this.callLoader(true, 1);
    try {
      this.$refs.refReOrder["showHistory"](
        this.taskId,
        "Engineer's Certification"
        );
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async mounted() {
    await this.allMountFunction(this.totalTaskCount, this.secondAppraisalCount);
    // await this.allMountFunction(this.reOrderCount, this.secondAppraisalCount);
  }
}
