




















import { Component, Vue, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "../../../../config";
import { authHeader } from "../../../../services/auth";
import AgentComponent from "../../../../common-components/AgentComponent.vue";
@Component({
  components: {
    AgentComponent,
  },
})
export default class SubmissionForm extends Vue {
  /* eslint-disable */
  @Prop()
  public agentsNotSelected;
  public index = 0;
  public agentTypes: any = [];
  public step = 0;
  public idsHandledByTMPCO: any = null;
  //public agentData: any = []
  @Prop()
  public contactVal;
  @Prop()
  public contactMsg;
  public async getAgentTypes() {
    try{
    let res = await Axios.get(BASE_API_URL + "agent/getAgentTypes/"+ this.$route.query.id, {
      headers: authHeader(),
    });
    this.agentTypes = res.data.agentTypes ? res.data.agentTypes : [];
    //this.agentData = res.data.agentData ? res.data.agentData : [];
    this.idsHandledByTMPCO = res.data.idsHandledByTMPCO;
    this.moveToNextTab(-1)
  }catch (error){
    console.log(error)
  }
  }

  public moveToNextTab(i){
    if(this.agentTypes.length==i+1){
      if(this.$route.query.name != 'disclosure')
      this.$emit('next-tab')
    }
    else{ 
      this.index++;
      this.step = this.agentTypes[i+1].id
    }
  }

  // public showVal(id){
  //    this.agentData.for
  //   if(id == 4){
     
  //     return true
  //   }
  // }





  async mounted() {
    await this.getAgentTypes();
  }
  /* eslint-disable */
}
