

















































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "../../../config";
import ReOrderTask from "../../../common-components/ReOrderTask.vue";
import { authHeader } from "../../../services/auth";
@Component({
  components: {
    ReOrderTask,
  },
})
export default class AppraisalOrder extends Vue {
  /* eslint-disable */
  public companyLists: any = [];
  public appraisalOrder: any = {};
  public selectedCompany: any = {};
  @Prop()
  public taskId: any;
  public error = false;
  public serverMessage = null;
  public loandata: any = {};
  @Prop()
  public isLoanInactive: any;
  public taskCompleted = false;
  public showReOrderBtn = false;
  @Prop()
  public reOrderCount: any;
  @Prop()
  public secondAppraisalCount: any;
  @Prop()
  public appraisalRefCount: any;
  public taskIdForServer = null;
  public countToSendOnServer: any = null;
  public secondAppraisalCountForServer: any = null;
  public appraisalRefCountForServer: any = null;
  public showHistoryBtn = false;
  public isLenderAdded = false;
  public appraisalCompanyInfo = {};
  public isAppraisalOrder = false;
  /**
   * Taking dsData variable for checking form data is changed or not only for comaprison
   */
  public dsData: any = {};
  public msg = "";
  @Prop()
  public isTaskDisabled: any;
  /**************************************************************** */

  async getAppraisalCompanyList() {
    this.callLoader(true, 1);
    try {
      let response = await Axios.post(
        BASE_API_URL + "processor/getAppraisalCompanyList",
        {
          loanId: this.$route.query.id,
          taskId: this.taskIdForServer,
          reOrderCount: this.countToSendOnServer,
          appraisalRefCount: this.appraisalRefCountForServer,
          secondAppraisalCount: this.secondAppraisalCountForServer,
        },
        {
          headers: authHeader(),
        }
      );
      if (response.status === 201) {
        this.isLenderAdded = response.data.isLenderAdded;
        this.companyLists = response.data.agent;
        this.taskCompleted = response.data.isCompleted;
        this.serverMessage = response.data.message;
        this.appraisalCompanyInfo = response.data.appraisalCompany;

        this.appraisalOrder = response.data.appraisalOrder
          ? JSON.parse(JSON.stringify(response.data.appraisalOrder))
          : {};

        this.dsData = response.data.appraisalOrder
          ? JSON.parse(JSON.stringify(response.data.appraisalOrder))
          : {};
        this.showReOrderBtn = response.data.showReOrderBtn;
        this.showHistoryBtn = response.data.showHistoryBtn;

        if (this.appraisalOrder.appraisalCompanyId)
          this.selectedCompany = this.companyLists.find(
            (e) => e._id == this.appraisalOrder.appraisalCompanyId
          );
        this.appraisalOrder.lenderId = response.data.lenderId;
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async checkValidation() {
    try {
      if (
        (!this.appraisalOrder.appraisalCompanyId &&
          !this.appraisalOrder.isPIW) ||
        (!this.appraisalOrder.isAppraisalOrdered && !this.appraisalOrder.isPIW)
      )
        return true;
      else false;
    } catch (error) {
      console.log(error);
    }
  }

  public checkValidation1() {
    if (
      (this.loandata.loanType.type == "FHA" ||
        this.loandata.loanType.type == "VA") &&
      !this.appraisalOrder.caseNumber &&
      !this.appraisalOrder.isPIW
    )
      return true;
    else false;
  }

  public async checkSaveValidation() {
    try {
      if (
        !this.appraisalOrder.appraisalCompanyId &&
        !(
          this.appraisalOrder.isAppraisalOrdered ||
          this.appraisalOrder.isPIW ||
          this.appraisalOrder.appraisalOnHold
        ) &&
        this.appraisalOrder.appraisalCompanyId ==
          this.dsData.appraisalCompanyId &&
        (this.appraisalOrder.isAppraisalOrdered ==
          this.dsData.isAppraisalOrdered ||
          this.appraisalOrder.isPIW == this.dsData.isPIW ||
          this.appraisalOrder.appraisalOnHold == this.dsData.appraisalOnHold)
      )
        return true;
      else return false;
    } catch (error) {
      console.log(error);
    }
  }

  public async saveAppraisalOrderDetails(isComplete) {
    //appraisalOnHold
    if (this.isAppraisalOrder) return;
    this.callLoader(true, 1);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        await this.getAppraisalCompanyList();
        return;
      }
      let notValid = await this.checkValidation();
      let forCaseNo = await this.checkValidation1();

      if (isComplete && (notValid || forCaseNo)) {
        this.error = true;
        this.msg = "All fields are Mandatory.";
        this.callLoader(false, null);
        return;
      }
      if (isComplete) {
        if (!this.isLenderAdded) {
          this.error = true;
          this.msg = "Lender Is Required.";
          this.callLoader(false, null);
          return;
        }
      }
      if (isComplete && !this.isLenderAdded && this.appraisalOrder.isPIW) {
        this.error = true;
        this.msg = "Lender Is Required.";
        this.callLoader(false, null);
        return;
      }

      if (!isComplete) {
        let notValid1 = await this.checkSaveValidation();
        if (notValid1) {
          this.callLoader(false, null);
          this.$snotify.info("No Changes");
          return;
        }
      }
      if (isComplete) this.isAppraisalOrder = true;
      // this.isAppraisalOrder = true
      let response = await Axios.post(
        BASE_API_URL + "processor/saveAppraisalOrderDetails",
        {
          loanId: this.$route.query.id,
          taskId: this.taskIdForServer,
          appraisalOrder: this.appraisalOrder,
          reOrderCount: this.countToSendOnServer,
          secondAppraisalCount: this.secondAppraisalCountForServer,
          appraisalRefCount: this.appraisalRefCountForServer,
          isComplete: isComplete,
          formData: this.dsData,
        },
        { headers: authHeader() }
      );
      if (response.status === 201)
        this.$snotify.success(
          isComplete
            ? "Appraisal Order Completed successfully."
            : "Appraisal Order Information has been saved."
        );

      if (isComplete) this.$emit("refresh-task-list", true);
      await this.getAppraisalCompanyList();
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  public onChangeF(id) {
    this.selectedCompany = id
      ? this.companyLists.filter((e) => e._id === id)[0]
      : null;
  }

  public async getLoanAndPropertyInfo() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "urla/getLoanAndPropertyInfo",
        {
          loanId: this.$route.query.id,
        },
        {
          headers: authHeader(),
        }
      );
      if (response.status === 201) {
        this.loandata = response.data;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async reOrderAppraisalTask() {
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        return;
      }
      this.$refs.refReOrder["confitmationBox"]({
        message: "Are you sure you want to RE-Order Appraisal Order?",
        apiPath: `processor/reOrderAppraisalTask`,
        data: { loanId: this.$route.query.id, taskId: this.taskIdForServer },
      });
    } catch (error) {
      console.log(error);
    }
  }

  public async showHistory() {
    this.callLoader(true, 1);
    try {
      this.$refs.refReOrder["showHistory"](this.taskIdForServer, "Appraisal");
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async allMountFunction(
    count,
    secondAppraisalCount,
    taskId,
    appraisalRefCount
  ) {
    this.countToSendOnServer = count;
    this.taskIdForServer = taskId;
    this.secondAppraisalCountForServer = secondAppraisalCount;
    this.appraisalRefCountForServer = appraisalRefCount;
    await this.getAppraisalCompanyList();
    await this.getLoanAndPropertyInfo();
    this.isAppraisalOrder = false;
  }

  public async mounted() {
    await this.allMountFunction(
      this.reOrderCount,
      this.secondAppraisalCount,
      this.taskId,
      this.appraisalRefCount
    );
  }
}
