






































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL, EventBus } from "../../../config";
import { authHeader } from "../../../services/auth";
@Component
export default class CreateTask extends Vue {
  /* eslint-disable */
  public floodBinder = false;
  public isDisable = false;
  public creditSuppliment = false;
  public masterInsurance = false;
  public vod = false;
  public voe = false;
  public vor = false;
  public vom = false;
  public condo = false;
  public showPayOff = false;
  public reinspectionLenders = false;
  public payOff = false;
  public circumstance = false;
  public pudQuestionnarie = false;
  public showFloodBinder = false;
  public cdInfo = [];
  public taskId = null;
  public isDisabled = false;
  public options = [
    { text: "No", value: false },
    { text: "Yes", value: true },
  ];
  public formTabData: any = {};
  public remarks = null;
  public error: any = {
    show: false,
    messgae: null,
  };
  public isAllTaskCreated = false;
  @Prop()
  public isLoanInactive: any;
  public taskName = null;
  public allRemarks = [];

  public hideModal() {
    if (this.taskId == 19) this.vod = !this.vod;
    if (this.taskId == 37) this.circumstance = !this.circumstance;
    if (this.taskId == 11) this.floodBinder = !this.floodBinder;
    if (this.taskId == 43) this.creditSuppliment = !this.creditSuppliment;
    if (this.taskId == 45) this.pudQuestionnarie = !this.pudQuestionnarie;
    if (this.taskId == 41) this.masterInsurance = !this.masterInsurance;
    if (this.taskId == 24) this.payOff = !this.payOff;
    if (this.taskId == 7) this.voe = !this.voe;
    if (this.taskId == 17) this.vom = !this.vom;
    if (this.taskId == 15) this.vor = !this.vor;
    if (this.taskId == 47) this.condo = !this.condo;
    if (this.taskId == 58) this.reinspectionLenders = !this.reinspectionLenders;
    this.remarks = null;

    this.taskId = null;
    this.$modal.hide("confirmationModal");
    this.$snotify.info("No Changes");
    this.isDisabled = false;
  }

  public showModal() {
    this.$modal.show("confirmationModal");
    this.isDisabled = true;
  }

  public async submitData() {
    this.callLoader(true, 1);
    this.isDisable = true;
    try {
      let response = await Axios.post(
        BASE_API_URL + "processor/pushTask",
        {
          loanId: this.$route.query.id,
          taskId: this.taskId,
          remarks: this.remarks,
        },
        { headers: authHeader() }
      );
      if (response.status == 201) this.$modal.hide("confirmationModal");
      this.$snotify.success("Task Added Successfully");
      this.taskId = null;
      this.remarks = null;
      this.isDisabled = false;
      this.$emit("refresh-task-list", true);
      await this.getTaskToShow();
    } catch (error) {
      console.log(error);
    }
    this.isDisable = false;
    this.callLoader(false, null);
  }

  public async getTaskToShow() {
    try {
      let response = await Axios.get(
        BASE_API_URL + "processor/getTaskToShow/" + this.$route.query.id,
        { headers: authHeader() }
      );

      if (response.status === 201) {
        console.log(response.data);
        this.floodBinder = response.data.floodBinder;
        this.allRemarks = response.data.loanAuditData;
        this.showPayOff = response.data.showPayOff;
        this.showFloodBinder = response.data.showFloodBinder;
        this.creditSuppliment = response.data.creditSuppliment;
        this.pudQuestionnarie = response.data.pudQuestionnaire;
        this.masterInsurance = response.data.masterInsurance;
        this.payOff = response.data.payOff;
        this.vod = response.data.vod;
        this.voe = response.data.voe;
        this.vom = response.data.vom;
        this.vor = response.data.vor;
        this.condo = response.data.condo;
        this.circumstance = response.data.circumstance;
        this.cdInfo = response.data.cdInfo;
        this.reinspectionLenders = response.data.reinspectionLenders;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async getDisclosureHandledBy() {
    try {
      let res = await Axios.get(
        BASE_API_URL + "broker/getDisclosureHandledBy/" + this.$route.query.id,
        { headers: authHeader() }
      );
      this.formTabData = res.data;
    } catch (error) {
      console.log(error);
    }
  }

  public getData(index) {
    let ind: any = 0;
    if (this.cdInfo.length > 0)
      ind = this.cdInfo.findIndex(
        (e) => e["taskId"] === 6 && e["taskName"] !== "Title Received"
      );
    if (index == ind) return true;
    else return false;
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }
  mounted() {
    this.getTaskToShow();
    this.getDisclosureHandledBy();
  }
}
