
































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import ConditionalApproval from "./ConditionalApproval.vue";
import Suspended from "./Suspended.vue";
import Axios from "axios";
import { BASE_API_URL } from "../../../config";
import { authHeader } from "../../../services/auth";
import ViewDocument from "../../../common-components/ViewDocument.vue";

@Component({
  components: {
    ConditionalApproval,
    Suspended,
    ViewDocument,
  },
})
export default class CancelledLoan extends Vue {
  /* eslint-disable */
  public mileStoneId = null;
  public lenderResTask: any = [];
  public loanId: any = this.$route.query.id;
  @Prop()
  public taskId: any;

  @Prop()
  public lenderResponseSequence: any;
  public sequence = null;
  public workflowTaskId = null;
  // @Prop()
  // public isLoanInactive: any;
  public loanChecklistData: any = {};
  public isTaskCompleted = false;
  public docLoader = false;

  public remark: any = null;
  public isCompleted = false;
  public allAgents = [];
  public cancelDenail: any = {};
  public addedBy: any = "";
  public isRemark = false;
  public error: any = {
    show: true,
    msg: null,
  };
  @Prop()
  public isTaskDisabled: any;
  public isLoanCancelledOnce = false;

  public async uploadReport(event) {
    this.docLoader = true;
    try {
      if (this.isTaskDisabled) {
        this.docLoader = false;
        this.$snotify.info("Task is not assigned to you.");
        return;
      }
     if (event.target.files.length <= 0) {
        this.callLoader(false, 1);
        return;
      }

      const formData: any = new FormData();
      let loanId: any = this.$route.query.id;

      Array.from(event.target.files).forEach((element) => {
        formData.append("file", element);
      });

      formData.append("loanId", loanId);
      formData.append("taskId", this.taskId);
     
      const response = await Axios.post(
        BASE_API_URL + "processor/uploadNoatReport",
        formData,
        { headers: authHeader() }
      );
      if (response.status === 201) this.$snotify.success(response.data.message);
      this.docLoader = false;
      this.loanChecklistData = response.data.loanChecklistData;
      this.error.msg = null;
      this.error.show = false;
    } catch (error) {
      console.log(error);
    }
  }

  public async viewDocument(document) {
    try {
      document.loanId = this.$route.query.id;
      this.$refs.ref["showFile"](document);
    } catch (error) {
      console.log(error);
    }
  }

  public async saveCancelledLoanData() {
      if(this.isLoanCancelledOnce) return
    this.callLoader(true, null);
    try {
       if (this.isTaskDisabled) {
      this.callLoader(false, null);
      this.$snotify.info("Task is not assigned to you.");
      this.getCancelledLoanDetails();
      return;
    }
      if (this.isCompleted) {
        if (this.remark == null) {
          this.isRemark = true;
          this.error.msg = "Can Not Cancel! Please Enter Remarks";
          this.callLoader(false, null);
          return;
        }
      }

      if (!this.mileStoneId) {
        this.error.show = true;
        this.error.msg = "Please choose atleast one.";
        this.callLoader(false, null);
        return;
      }

         if(this.isCompleted) this.isLoanCancelledOnce = true;

      let response = await Axios.post(
        BASE_API_URL + "processor/saveCancelledLoanData",
        {
          loanId: this.loanId,
          mileStoneId: this.mileStoneId,
          remark: this.remark,
          isCompleted: this.isCompleted,
          taskId: this.workflowTaskId,
        },
        {
          headers: authHeader(),
        }
      );

      if (response.status == 201 && this.isCompleted) {
        if(this.mileStoneId == 59){
        this.$snotify.success("Loan has been Cancelled successfully");

        }else{
          this.$snotify.success("Loan has been Denied successfully");

        }
        this.$modal.hide("confirmationModal");
        await this.getCancelledLoanDetails();
        this.$emit("refresh-task-list", true);
        if (this.mileStoneId == 1) {
          this.$router.push({ path: "/processor-dashboard" });
        }
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async getDocument() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "processor/getLenderResponseDocument",
        {
          loanId: this.loanId,
          taskId: this.taskId,
          lenderResponseSequence: this.sequence,
        },
        {
          headers: authHeader(),
        }
      );

      this.loanChecklistData = response.data.loanChecklistData;
    } catch (error) {
      console.log(error);
    }
  }

  public async getCancelledLoanDetails() {
    this.callLoader(true, null);
    try {
      let response = await Axios.post(
        BASE_API_URL + "processor/getCancelledLoanDetails",
        {
          loanId: this.loanId,
          taskId: this.taskId,

          //lenderResponseSequence: this.sequence,
        },
        {
          headers: authHeader(),
        }
      );

      this.mileStoneId = response.data.mileStoneId;
      this.isTaskCompleted = response.data.isCompleted;
      this.addedBy = response.data.addedBy;
      this.cancelDenail = response.data.cancelDenail;

     // console.log("response.data.cancelDenail", response.data);
      // this.loanChecklistData =
      // response.data.loanChecklistData;

      // this.$emit("refresh-task-list", true);
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public openStatusModal(milestoneId) {
    if (milestoneId === 59) {
      this.$modal.show("confirmationModal");
    }
  }

  public async getListOfAgents() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "processor/getListOfAgents",
        {
          loanId: this.$route.query.id,
          agentType: 3,
        },
        {
          headers: authHeader(),
        }
      );

      if (response.status === 201) this.allAgents = response.data;
    } catch (error) {
      console.log(error);
    }
  }

  public closeLoanCancelModal() {
    // this.applicationReview.loanCancel.isCancel = null;
    // this.isCanceled = false;
    this.mileStoneId = null;
    this.$modal.hide("confirmationModal");
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  public allMountFunction(sequence) {
    this.workflowTaskId = this.taskId;

    this.sequence = sequence;
    this.getDocument();
    this.getCancelledLoanDetails();

    this.getListOfAgents();
  }

  public mounted() {
    this.allMountFunction(this.lenderResponseSequence);
  }
}
