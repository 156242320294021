














import { Component, Vue } from "vue-property-decorator";
@Component
export default class ThankYou extends Vue {
  /* eslint-disable */

  /* eslint-disable */
}
