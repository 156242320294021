























































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "../../../config";
import { authHeader } from "../../../services/auth";
import ViewDocument from "../../../common-components/ViewDocument.vue";
import CommonNotifyOnRejection from "../../../common-components/CommonNotifyOnRejection.vue";
@Component({
  components: {
    ViewDocument,
    CommonNotifyOnRejection,
  },
})
export default class InitialDisclosure extends Vue {
  /* eslint-disable */
  @Prop()
  public taskId: any;
  public disclosureData: any = {};

  /**
   * Taking dsData variable for checking form data is changed or not only for comaprison
   */
  public dsData: any = {};
  /**************************************************************** */

  public error: any = {
    show: false,
    messgae: null,
  };
  // @Prop()
  // public isLoanInactive: any;
  @Prop()
  public isTaskDisabled: any;
  public isOrderPlacedOnce = false;

  public notifyBroker = false;

  public async getDisclosureInfo() {
    this.callLoader(true, null);
    try {
      let response = await Axios.get(
        BASE_API_URL + "processor/getDisclosureInfo/" + this.$route.query.id,
        { headers: authHeader() }
      );

      if (response.status === 201) {
        this.disclosureData = JSON.parse(JSON.stringify(response.data));
        this.dsData = JSON.parse(JSON.stringify(response.data));
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async checkValidation() {
    try {
      if (
        !this.disclosureData.wsUploaded ||
        !this.disclosureData.psUploaded ||
        !this.disclosureData.isDisclosureSent
      )
        return true;
      else return false;
    } catch (error) {
      console.log(error);
    }
  }

  public async checkSaveValidation() {
    try {
      if (
        !this.disclosureData.wsUploaded &&
        !this.disclosureData.psUploaded &&
        !this.disclosureData.isDisclosureSent &&
        this.disclosureData.wsUploaded == this.dsData.wsUploaded &&
        this.disclosureData.psUploaded == this.dsData.psUploaded &&
        this.disclosureData.isDisclosureSent == this.dsData.isDisclosureSent
      )
        return true;
      else return false;
    } catch (error) {
      console.log(error);
    }
  }

  public async saveDisclosureDetails(isComplete) {
     if(this.isOrderPlacedOnce) return
    this.callLoader(true, null);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getDisclosureInfo();
        return;
      }
      let notValid = await this.checkValidation();
      if (isComplete && notValid) {
        this.error.show = true;
        this.error.message = "All fields are mandatory.";
        this.callLoader(false, null);
        return;
      }

      if (!isComplete) {
        let notValid1 = await this.checkSaveValidation();
        if (notValid1) {
          this.callLoader(false, null);
          this.$snotify.info("No Changes");
          return;
        }
      }

      let myObj = {
        loanId: this.$route.query.id,
        initialDisclosure: this.disclosureData,
        isComplete: isComplete,
        taskId: this.taskId,
        formData: this.dsData,
      };

      if(isComplete) this.isOrderPlacedOnce = true;
      let response = await Axios.post(
        BASE_API_URL + "processor/saveDisclosureDetails",
        myObj,
        { headers: authHeader() }
      );


      if (response.status === 201)
        this.$snotify.success(
          isComplete
            ? "Disclosure Details Completed successfully."
            : "Disclosure Information has been saved"
        );
      if (isComplete) this.$emit("refresh-task-list", true);
      await this.getDisclosureInfo();
    } catch (error) {
      console.log();
    }
    this.callLoader(false, null);
  }

  public async viewDocument(document) {
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        return;
      }
      document.loanId = this.$route.query.id;
      this.$refs.ref["showFile"]({
        ...document,
      });
    } catch (error) {
      console.log(error);
    }
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  public async isBrokerNotifiedForRejectedDocs() {
    this.callLoader(true, null);
    try {
      let res = await Axios.get(
        BASE_API_URL +
          "loanChecklist/isBrokerNotifiedForRejectedDocs/" +
          this.$route.query.id +
          "/" +
          this.taskId+'/'+null,
        {
          headers: authHeader(),
        }
      );
      if (res.status == 201) {
        this.notifyBroker = res.data.notifyBorker;
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async mounted() {
    await this.getDisclosureInfo();
    await this.isBrokerNotifiedForRejectedDocs();
  }
}
