var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-2 white-card loan-process-height-249 d-flex flex-column"},[_c('div',{staticClass:"loan-process-heading"},[_c('div',{staticClass:"col-lg-12  d-flex justify-content-between"},[_c('p',{staticClass:"heading mb-0"},[_vm._v("VOR Order")]),(_vm.notifyBroker)?_c('div',{staticClass:"top-2"},[_c('CommonNotifyOnRejection',{ref:"refNotify",attrs:{"loanTxnId":_vm.$route.query.id},on:{"refresh-task":function($event){return _vm.isBrokerNotifiedForRejectedDocs()}}})],1):_vm._e()])]),_c('div',{staticClass:"col-lg-12 pt-3 pb-3 flex-1 height_100"},[_c('div',{staticClass:"form-row min_293 flex-column"},[_c('div',{staticClass:"flex-1"},[_c('div',{staticClass:"col-12 row px-2 m-0"},[_vm._l((_vm.vorData.landLordInfo),function(vor,i){return _c('div',{key:i,staticClass:"col-12 px-1"},[_c('div',{staticClass:"col-lg-12 p-0"},[_c('label',{staticClass:"heading bold text__blue mb-0"},[_vm._v(" "+_vm._s(vor.address)+" ")])]),_c('div',{staticClass:"form-group mb-0 form-row"},[_c('div',{staticClass:"col-lg-4 col-md-5"},[_c('label',{staticClass:"label"},[_vm._v("LandLord Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(vor.landLordName),expression:"vor.landLordName"}],staticClass:"login-input",class:[
                                    _vm.vorData.isCompleted
                                    ? 'login-input cursor-not-allow'
                                    : 'login-input' ],attrs:{"type":"text","name":"LandLord","disabled":_vm.vorData.isCompleted},domProps:{"value":(vor.landLordName)},on:{"change":function($event){_vm.error = { show: false, msg: null }},"input":function($event){if($event.target.composing){ return; }_vm.$set(vor, "landLordName", $event.target.value)}}})]),_c('div',{staticClass:"col-lg-4 col-md-6"},[_c('ValidationProvider',{attrs:{"rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("LandLord Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(vor.email),expression:"vor.email"}],staticClass:"login-input",class:[
                                    _vm.vorData.isCompleted
                                    ? 'login-input cursor-not-allow'
                                    : 'login-input' ],attrs:{"disabled":_vm.vorData.isCompleted,"name":"Email"},domProps:{"value":(vor.email)},on:{"change":function($event){_vm.error = { show: false, msg: null }},"input":function($event){if($event.target.composing){ return; }_vm.$set(vor, "email", $event.target.value)}}}),(errors)?_c('span',{staticClass:"login-error show"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)])])}),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group d-flex align-items-center col-12 column_592"},[_c('div',{staticClass:"file-upload mt-1 overflow_hidden"},[_c('label',{staticClass:"file-upload-label mb-0",attrs:{"for":"upload"}},[_c('i',{staticClass:"fa fa-upload mr-1"}),_vm._v(" "+_vm._s(_vm.bImgLoader ? "Uploading..." : "Upload Borrower authorization"))]),_c('input',{staticClass:"file-upload-input",attrs:{"id":"upload","type":"file","name":"file-upload","disabled":_vm.vorData.isCompleted},on:{"change":function($event){_vm.uploadFile($event, 'Borrower Authorization');
                        _vm.errorObj = { show: false, msg: null };}}})]),(_vm.docsDetails)?_c('div',[(!_vm.docsDetails.docApproved && !_vm.docsDetails.comment)?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"fa fa-exclamation-circle color-red ml-2",attrs:{"title":"Not Approved"}}):_vm._e(),(!_vm.docsDetails.docApproved && _vm.docsDetails.comment)?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"fa fa-exclamation-circle ml-2",staticStyle:{"color":"orange"},attrs:{"title":_vm.docsDetails.comment}}):_vm._e(),( _vm.docsDetails.docApproved)?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"fa fa-check-circle-o text__blue ml-2",attrs:{"title":"Approved"}}):_vm._e(),_c('i',{staticClass:"ri-file-text-line ml-2 text__blue cursor-pointer",on:{"click":function($event){_vm.isRejected = false;
                                        _vm.isAccepted = true;_vm.viewDocument(_vm.docsDetails)}}})]):_vm._e()])])],2)]),_c('hr',{staticClass:"loan-process-border-top"}),_c('div',{staticClass:"d-flex justify-content-end form-group align-items-center-768 flex_767"},[(_vm.error.show)?_c('span',{staticClass:"login-error show pr-2 show_768"},[_vm._v(_vm._s(_vm.error.msg))]):_vm._e(),_c('div',{},[_c('button',{staticClass:"tmpco-btn tmpco-btn__default-blue mr-2",class:[
                _vm.vorData.isCompleted
                ? 'btn-upload cursor-pointer cursor-not-allow'
                : 'btn-upload cursor-pointer' ],attrs:{"type":"button","disabled":_vm.vorData.isCompleted},on:{"click":function($event){_vm.saveVorOrderData();
                _vm.isComplete = false;}}},[_vm._v(" Save ")]),_c('button',{staticClass:"tmpco-btn tmpco-btn__theme",class:[
              _vm.vorData.isCompleted
              ? 'btn-upload cursor-pointer cursor-not-allow'
              : 'btn-upload cursor-pointer' ],attrs:{"type":"submit","disabled":_vm.vorData.isCompleted},on:{"click":function($event){return _vm.$modal.show('VOROrderConfirmationModal');}}},[_vm._v(" "+_vm._s(_vm.vorData.isCompleted ? "Completed" : "Complete")+" ")])]),_c('div',{staticClass:"show_767"},[(_vm.error.show)?_c('span',{staticClass:"login-error show pr-2"},[_vm._v(_vm._s(_vm.error.msg))]):_vm._e()])]),_c('modal',{staticClass:"modal",attrs:{"centered":"","name":"VOROrderConfirmationModal","hide-footer":true}},[_c('div',{staticClass:"modal--md-regular"},[_c('div',{staticClass:"tmpco-modal__content"},[_c('div',{staticClass:"tmpco-modal__header"},[_c('p',{staticClass:"tmpco-modal__title"},[_vm._v("VOR Order")]),_c('button',{staticClass:"tmpco-modal__close",attrs:{"type":"button","data-dismiss":"modal"},on:{"click":function($event){return _vm.$modal.hide('VOROrderConfirmationModal');}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"tmpco-modal__body"},[_c('div',{staticClass:"col-lg-12 col-xl-12"},[_c('p',{staticClass:"mt-3 mb-3 content-modal"},[_vm._v(" Are you sure you want to complete? ")])])]),_c('div',{staticClass:"tmpco-modal__footer"},[_c('button',{staticClass:"tmpco-btn tmpco-btn__sm-default mr-2",on:{"click":function($event){return _vm.$modal.hide('VOROrderConfirmationModal');}}},[_vm._v("No")]),_c('button',{staticClass:"tmpco-btn tmpco-btn__sm-theme",attrs:{"type":"button"},on:{"click":function($event){_vm.isComplete = true;_vm.saveVorOrderData();_vm.$modal.hide('VOROrderConfirmationModal')}}},[_vm._v("Yes")])])])])])],1)]),_c('ViewDocument',{ref:"ref",on:{"call-mount-funtion":function($event){_vm.getBorrowerAuthorizationrDocument();_vm.isBrokerNotifiedForRejectedDocs()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }