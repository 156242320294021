var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-2 white-card loan-process-height-249 d-flex flex-column"},[_c('div',{staticClass:"loan-process-heading"},[_c('div',{staticClass:"col-lg-12 d-flex justify-content-between"},[_c('p',{staticClass:"heading mb-0"},[_vm._v(_vm._s(_vm.secondAppraisalCountForServer ? 'Second' : '')+" Engineer's Certification Received ")])])]),_c('div',{staticClass:"col-lg-12 pt-3 pb-3 flex-1 height_100"},[_c('div',{staticClass:"row m-0 flex-column min_293"},[_c('div',{staticClass:"flex-1"},[_c('div',[_c('div',{staticClass:"form-group d-flex align-items-center"},[_c('div',{staticClass:"file-upload mt-1 overflow_hidden"},[_c('label',{staticClass:"file-upload-label mb-0",attrs:{"for":"upload"}},[_c('i',{staticClass:"fa fa-upload mr-1",attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.docLoader ? "Uploading..." : "Upload Engineer's Certification Report")+" ")]),_c('input',{staticClass:"file-upload-input",attrs:{"disabled":_vm.isCompleted,"id":"upload","type":"file","name":"file-upload","multiple":""},on:{"change":function($event){_vm.uploadReport($event);
                    _vm.error = {
                    show: false,
                    msg: null}}}})]),(_vm.engineerCertificationRecieved.loanChecklistData)?_c('i',{staticClass:"ri-file-text-line ml-3 text__blue cursor-pointer",attrs:{"aria-hidden":"true"},on:{"click":function($event){return _vm.viewDocument(_vm.engineerCertificationRecieved.loanChecklistData)}}}):_vm._e()])]),_c('div',{staticClass:"custom-checkbox d-flex align-items-center mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.engineerCertificationRecieved.isEngineerCertificationRecieved),expression:"engineerCertificationRecieved.isEngineerCertificationRecieved"}],staticClass:"custom-checkbox-input",attrs:{"type":"checkbox","id":"b","disabled":_vm.isCompleted},domProps:{"checked":Array.isArray(_vm.engineerCertificationRecieved.isEngineerCertificationRecieved)?_vm._i(_vm.engineerCertificationRecieved.isEngineerCertificationRecieved,null)>-1:(_vm.engineerCertificationRecieved.isEngineerCertificationRecieved)},on:{"change":[function($event){var $$a=_vm.engineerCertificationRecieved.isEngineerCertificationRecieved,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.engineerCertificationRecieved, "isEngineerCertificationRecieved", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.engineerCertificationRecieved, "isEngineerCertificationRecieved", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.engineerCertificationRecieved, "isEngineerCertificationRecieved", $$c)}},function($event){_vm.error = {
            show: false,
            msg: null}}]}}),_c('label',{staticClass:"label",attrs:{"for":"a"}},[_vm._v("Engineer's Certification Received")]),_c('div',{staticClass:"indicator"})])]),_c('hr',{staticClass:"loan-process-border-top"}),_c('div',{staticClass:"d-flex align-items-center justify-content-end form-group"},[(_vm.error.show)?_c('span',{staticClass:"login-error show mr-2"},[_vm._v(_vm._s(_vm.error.msg))]):_vm._e(),_c('button',{staticClass:"tmpco-btn tmpco-btn__default-blue mr-2",class:[
                _vm.isCompleted
                ? 'btn-upload cursor-pointer cursor-not-allow'
                : 'btn-upload cursor-pointer' ],attrs:{"type":"submit","disabled":_vm.isCompleted},on:{"click":function($event){return _vm.saveEngineerCertificationRecieved(false)}}},[_vm._v(" Save ")]),_c('button',{staticClass:"tmpco-btn tmpco-btn__theme",class:[
              _vm.isCompleted
              ? 'btn-upload cursor-pointer cursor-not-allow'
              : 'btn-upload cursor-pointer' ],attrs:{"type":"submit","disabled":_vm.isCompleted},on:{"click":function($event){return _vm.$modal.show('engineerConfirmationModal')}}},[_vm._v(" "+_vm._s(_vm.isCompleted ? "Completed" : "Complete")+" ")])]),_c('modal',{staticClass:"modal",attrs:{"centered":"","name":"engineerConfirmationModal","hide-footer":true}},[_c('div',{staticClass:"modal--md-regular"},[_c('div',{staticClass:"tmpco-modal__content"},[_c('div',{staticClass:"tmpco-modal__header"},[_c('p',{staticClass:"tmpco-modal__title"},[_vm._v("Engineer's Certification Received")]),_c('button',{staticClass:"tmpco-modal__close",attrs:{"type":"button","data-dismiss":"modal"},on:{"click":function($event){return _vm.$modal.hide('engineerConfirmationModal');}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"tmpco-modal__body"},[_c('div',{staticClass:"col-lg-12 col-xl-12"},[_c('p',{staticClass:"mt-3 mb-3 content-modal"},[_vm._v(" Are you sure you want to complete? ")])])]),_c('div',{staticClass:"tmpco-modal__footer"},[_c('button',{staticClass:"tmpco-btn tmpco-btn__sm-default mr-2",on:{"click":function($event){return _vm.$modal.hide('engineerConfirmationModal');}}},[_vm._v("No")]),_c('button',{staticClass:"tmpco-btn tmpco-btn__sm-theme",attrs:{"type":"button"},on:{"click":function($event){_vm.saveEngineerCertificationRecieved(true);_vm.$modal.hide('engineerConfirmationModal')}}},[_vm._v("Yes")])])])])])],1)]),_c('ViewDocument',{ref:"ref",on:{"call-mount-funtion":function($event){return _vm.getEngineerCertificateReceived()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }