var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"white-card loan-process-height-249 mb-2 d-flex flex-column"},[_vm._m(0),_c('div',{staticClass:"col-lg-12 pt-3 pb-3 flex-1 height_100"},[_c('div',{staticClass:"form-row min_293 flex-column"},[_c('div',{staticClass:"flex-1"},[_c('div',{staticClass:"col-12 row px-2 m-0"},[_c('div',{staticClass:"custom-checkbox mb-1 d-flex align-items-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.cdRequest.isCdOrdered),expression:"cdRequest.isCdOrdered"}],class:[
                _vm.isTaskCompleted
                ? 'custom-checkbox-input cursor-pointer cursor-not-allow'
                : 'custom-checkbox-input cursor-pointer' ],attrs:{"type":"checkbox","disabled":_vm.isTaskCompleted,"id":"isCdOrdered"},domProps:{"checked":Array.isArray(_vm.cdRequest.isCdOrdered)?_vm._i(_vm.cdRequest.isCdOrdered,null)>-1:(_vm.cdRequest.isCdOrdered)},on:{"change":[function($event){var $$a=_vm.cdRequest.isCdOrdered,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.cdRequest, "isCdOrdered", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.cdRequest, "isCdOrdered", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.cdRequest, "isCdOrdered", $$c)}},function($event){_vm.error = false}]}}),_c('label',{staticClass:"label",staticStyle:{"color":"#2c3e50"},attrs:{"for":"isCdOrdered"}},[_vm._v(" Order Placed ")]),_c('div',{staticClass:"indicator"})])])]),_c('hr',{staticClass:"loan-process-border-top"}),_c('div',{staticClass:"d-flex align-items-center justify-content-end form-group"},[(_vm.error)?_c('span',{staticClass:"login-error show mr-2"},[_vm._v("All fields are Mandatory.")]):_vm._e(),_c('button',{staticClass:"tmpco-btn tmpco-btn__default-blue mr-2",class:[
          _vm.isTaskCompleted
          ? 'btn-upload cursor-pointer cursor-not-allow'
          : 'btn-upload cursor-pointer' ],attrs:{"type":"button","disabled":_vm.isTaskCompleted},on:{"click":function($event){return _vm.saveCDRequest(false)}}},[_vm._v(" Save ")]),_c('button',{staticClass:"tmpco-btn tmpco-btn__theme",class:[
         _vm.isTaskCompleted
        ? 'btn-upload cursor-pointer cursor-not-allow'
        : 'btn-upload cursor-pointer' ],attrs:{"type":"button","disabled":_vm.isTaskCompleted},on:{"click":function($event){return _vm.$modal.show('cdConfirmationModal')}}},[_vm._v(" "+_vm._s(_vm.isTaskCompleted ? "Completed" : "Complete")+" ")])]),_c('modal',{staticClass:"modal",attrs:{"centered":"","name":"cdConfirmationModal","hide-footer":true}},[_c('div',{staticClass:"modal--md-regular"},[_c('div',{staticClass:"tmpco-modal__content"},[_c('div',{staticClass:"tmpco-modal__header"},[_c('p',{staticClass:"tmpco-modal__title"},[_vm._v("CD Order")]),_c('button',{staticClass:"tmpco-modal__close",attrs:{"type":"button","data-dismiss":"modal"},on:{"click":function($event){return _vm.$modal.hide('cdConfirmationModal');}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"tmpco-modal__body"},[_c('div',{staticClass:"col-lg-12 col-xl-12"},[_c('p',{staticClass:"mt-3 mb-3 content-modal"},[_vm._v(" Are you sure you want to complete? ")])])]),_c('div',{staticClass:"tmpco-modal__footer"},[_c('button',{staticClass:"tmpco-btn tmpco-btn__sm-default mr-2",on:{"click":function($event){return _vm.$modal.hide('cdConfirmationModal');}}},[_vm._v("No")]),_c('button',{staticClass:"tmpco-btn tmpco-btn__sm-theme",attrs:{"type":"button"},on:{"click":function($event){_vm.saveCDRequest(true);_vm.$modal.hide('cdConfirmationModal')}}},[_vm._v("Yes")])])])])])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"loan-process-heading"},[_c('div',{staticClass:"col-lg-12"},[_c('p',{staticClass:"heading mb-0"},[_vm._v("CD Order")])])])}]

export { render, staticRenderFns }