












































































import { Component, Vue, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL, EventBus } from "../config";
import { authHeader } from "../services/auth";
import { CONDITIONS_CATEGORY } from "../constants/constants";
import Multiselect from "vue-multiselect";
@Component({
  components: {
    Multiselect,
  },
})
export default class ConditionComponent extends Vue {
  /* eslint-disable */
  @Prop()
  public loanId: any;

  @Prop()
  public workflowTaskId: any;

  @Prop()
  public lenderResponseSequence: any;

  @Prop()
  public source: any;

  @Prop()
  public disableCondition: any;

  public conditionsCategory = CONDITIONS_CATEGORY;
  public isConditionForError = false;
  public isConditionError = false;
  public search = "";
  public allConditions: any = [];
  public conditionFor: any = [];
  public category: any = null;
  public choosedConditionArray: any = [];
  public taskId = null;
  public document: any = document;
  public conditionsPushedOnce = false;

  public async getConditionList() {
    try {
      let res = await Axios.post(
        BASE_API_URL + "processor/getConditionList",
        {
          type: this.category,
          searchParam: this.search,
        },
        {
          headers: authHeader(),
        }
        );

      res.data.forEach(async (c) => {
        c["condition"] = await c.name.replace(
          /<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g,
          " "
          );
      });
      this.allConditions = res.data;
    } catch (error) {
      console.log(error);
    }
  }

  public onChangeFunction(selectedOption, i, event) {
    if (!this.checkValidation(i) && event.target.checked)
      this.choosedConditionArray.push(selectedOption);
    else {
      this.choosedConditionArray = this.choosedConditionArray.filter(
        (e) => e._id != selectedOption._id
        );
      this.allConditions[i].selectedCondition = false;
    }
  }

  public async pushConditionToChecklist() {
    if(this.conditionsPushedOnce) return
    try {
      for (let index = 0; index < this.choosedConditionArray.length; index++) {
        const element = this.choosedConditionArray[index];
        let conditionName : any;
        conditionName = element.name;
        let indexOfAllCondition = this.allConditions.findIndex((a) => a._id == element._id);
        let topElement: any = this.document.getElementById("condition" + indexOfAllCondition).children;
        for (let i = 0; i < topElement.length; i++) {
          const e = topElement[i];
          for (let j = 0; j < e.children.length; j++) {
            const ele = e.children[j];
            let inputField: any = ele;
            if (inputField.id)
              conditionName = conditionName.replace(/<input[^>]*>/, inputField.value);
            element.condition = conditionName;
          }
        }
      }
      if (!this.checkValidationWhileAdding()) {
        let res = await Axios.post(
          BASE_API_URL + "processor/pushConditionToChecklist",
          {
            conditions: this.choosedConditionArray,
            conditionFor: this.conditionFor,
            loanId: this.loanId,
            taskId: this.workflowTaskId,
            lenderResponseSequence: this.lenderResponseSequence,
            source: this.source,
          },
          {
            headers: authHeader(),
          }
          );
        if (res.status == 201) {
          this.$emit("refresh-list");
          this.$snotify.success("Condition has been saved");
          this.cancel();
          this.getConditionList();
          this.removeValueFromInput();
          this.conditionsPushedOnce = false;
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async openModal(params) {
    this.loanId = params.loanId;
    this.taskId = params.workflowTaskId;
    this.lenderResponseSequence = params.lenderResponseSequence;
    this.source = params.source;
    this.isConditionForError = false;
    this.isConditionError = false;
  }

  public async notifyUser() {
    try {
      let res = await Axios.post(
        BASE_API_URL + "processor/notifyUser",
        {
          loanId: this.loanId,
          lenderResponseSequence: this.lenderResponseSequence,
          source: this.source,
        },
        {
          headers: authHeader(),
        }
        );
      if (res.status == 201) {
        this.$snotify.success(res.data.msg);
        EventBus.$emit("refreshConditionList");
      }
      if (res.status == 202) this.$snotify.info(res.data.msg);
      this.cancel();
    } catch (error) {
      console.log(error);
    }
  }

  public cancel() {
    this.isConditionForError = false;
    this.isConditionError = false;
    this.choosedConditionArray = [];
  }

  public removeValueFromInput() {
    try {
      for (let index = 0; index < this.choosedConditionArray.length; index++) {
        const element = this.choosedConditionArray[index];
        let indexOfAllCondition = this.allConditions.findIndex((a) => a._id == element._id );
        for (let i = 0;i <this.document.getElementById("condition" + indexOfAllCondition).querySelectorAll("input").length;i++) {
          const e = this.document
          .getElementById("condition" + indexOfAllCondition)
          .querySelectorAll("input")[i];
          e.value = "";
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  public checkValidation(index) {
    let isError = false;
    let topElement: any = this.document.getElementById("condition" + index).children;
    for (let i = 0; i < topElement.length; i++) {
      const e = topElement[i];
      for (let j = 0; j < e.children.length; j++) {
        const ele = e.children[j];
        let inputField: any = ele;
        if (inputField.id && inputField.value.trim() == "") {
          isError = true;
          inputField.style["border-color"] = "red";
        } else {
          inputField.style["border-color"] = "#d9d9d9";
        }
      }
    }
    if (this.conditionFor.length < 1) {
      this.isConditionForError = true;
      isError = true;
    }
    return isError;
  }

  public checkValidationWhileAdding() {
    try {
      let splicedIndexes: any = [];
      let isError = false;
      for (let index = 0; index < this.choosedConditionArray.length; index++) {
        const element = this.choosedConditionArray[index];
        let indexOfAllCondition = this.allConditions.findIndex( (a) => a._id == element._id );
        let topElement: any = this.document.getElementById("condition" + indexOfAllCondition).children;
        for (let i = 0; i < topElement.length; i++) {
          const e = topElement[i];
          for (let j = 0; j < e.children.length; j++) {
            const ele = e.children[j];
            let inputField: any = ele;
            if (inputField.id && inputField.value.trim() == "") {
              inputField.style["border-color"] = "red";
              this.allConditions[indexOfAllCondition].selectedCondition = false;
              splicedIndexes.push(index);
              isError = true;
            } else {
              inputField.style["border-color"] = "#d9d9d9";
            }
          }
        }
      }
      this.choosedConditionArray = this.choosedConditionArray.filter(e=> e.selectedCondition)
      if (this.conditionFor.length < 1) {
        this.isConditionForError = true;
        isError = true;
      }
      return isError;
    } catch (error) {
      console.log(error);
    }
  }

  public async notify(params) {
    this.loanId = params.loanId;
    this.lenderResponseSequence = params.lenderResponseSequence;
    this.source = params.source;
    await this.notifyUser()
  }
}
/* eslint-disable */
