














































/* eslint-disable */
// IMPORT-----------------------------------------------------------
import { Component, Vue, Provide } from "vue-property-decorator";
import pdf from "vue-pdf";
// IMPORT END-------------------------------------------------------

@Component({
  components: {
    pdf,
  },
})
export default class PDFPreview extends Vue {
  public numPages = 0;
  public loadedRatio = 0;
  public page = 1;
  public sessionObject: any = null;
  public rotate = 0;
  public pdfContent: any = null;
  public downloadPdfContent: any = null;
  public originalFilename = null;

  public async showFile(body) {
    try {
      this.downloadPdfContent = body.content;
      this.originalFilename = body.name;
      this.pdfContent = pdf.createLoadingTask(body.content);
      this.pdfContent.promise.then((pdf) => {
        this.numPages = pdf.numPages;
      });
      this.$modal.show("pdfPreviewModal");
    } catch (error) {
      this.callLoader(false, null);
      console.log(error);
    }
  }

  public hideModal() {
    this.originalFilename = null;
    this.downloadPdfContent = null;
    this.pdfContent = null;
    this.$modal.hide("pdfPreviewModal");
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
    this.sessionObject = this.$store.state.sessionObj;
  }
}
