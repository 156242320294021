






















































import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "../config";
import { authHeader } from "../services/auth";
import ViewDocument from './ViewDocument.vue';

@Component({
  components: {
    ViewDocument
  }
})
export default class CommunicationEmail extends Vue {
  /* eslint-disable */
  public communicationEmail: any = []
// public communicationMessage = ''
public selectedComm: any = {};

public commEmailTo(data){
  let toArray: any=[]
  data.forEach((e)=>{
    toArray.push(e.email)
  }) 
  return toArray.toString() 
}
public commEmailCC(data){
  let ccArray: any=[]
  data.forEach((e)=>{
    ccArray.push(e.email)
  }) 
  return ccArray.toString() 
}



public async getCommunicationEmail() {
  this.callLoader(true, null)
  try {
    let response = await Axios.get(
      BASE_API_URL + "communication/getCommunicationEmail/"+this.$route.query.id,
      {
        headers: authHeader(),
      }
      );
    this.communicationEmail = response.data;
    
  } catch (error) {
    console.log(error);
  }
  this.callLoader(false, null)
}

public callLoader(loader, loaderTitleId) {
  this.$store.state.tmpcoLoader = loader;
  this.$store.state.loaderTitleId = loaderTitleId;
}

public async viewAttachment(attachments) {
  this.$refs.ref["viewAttachment"]({attachments:attachments,loanId:this.$route.query.id,});
}
public async viewCommunication(communication) {
 try {
   this.selectedComm = communication;
          // this.communicationMessage = communication.message;
          this.$modal.show("ViewCommunicationModal");
        } catch(error) {
         console.log(error)
       }
     }

     async mounted() {
      this.getCommunicationEmail();
    }
    /* eslint-disable */
  }
