var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-2 white-card loan-process-height-249 d-flex flex-column"},[_vm._m(0),_c('div',{staticClass:"col-lg-12 pt-3 pb-3 px-1 flex-1 height_100"},[_c('div',{staticClass:"form-row min_293 flex-column m-0 col-12"},[_c('div',{staticClass:"flex-1"},[_c('div',{staticClass:"custom-checkbox d-flex align-items-center mb-0"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.vorRecieved.isVorRecieved),expression:"vorRecieved.isVorRecieved"}],class:[
               _vm.isTaskCompleted
                ? 'custom-checkbox-input cursor-not-allow'
                : 'custom-checkbox-input' ],attrs:{"type":"checkbox","disabled":_vm.isTaskCompleted},domProps:{"checked":Array.isArray(_vm.vorRecieved.isVorRecieved)?_vm._i(_vm.vorRecieved.isVorRecieved,null)>-1:(_vm.vorRecieved.isVorRecieved)},on:{"change":[function($event){var $$a=_vm.vorRecieved.isVorRecieved,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.vorRecieved, "isVorRecieved", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.vorRecieved, "isVorRecieved", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.vorRecieved, "isVorRecieved", $$c)}},function($event){_vm.error = { show: false, msg: null }}]}}),_c('label',{staticClass:"label",attrs:{"for":"a"}},[_vm._v("VOR Received")]),_c('div',{staticClass:"indicator"})]),_c('div',[_c('div',{staticClass:"d-flex align-items-center col-12 p-0"},[_c('div',{staticClass:"file-upload mt-2 overflow_hidden"},[_c('label',{staticClass:"file-upload-label mb-0",attrs:{"for":"upload"}},[_c('i',{staticClass:"fa fa-upload mr-1",attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.docLoader ? "Uploading..." : "Upload VOR Report"))]),_c('input',{staticClass:"file-upload-input",attrs:{"id":"upload","type":"file","name":"file-upload","disabled":_vm.isTaskCompleted,"multiple":""},on:{"change":function($event){_vm.uploadReport($event);
                    _vm.error = { show: false, msg: null };}}})]),(_vm.doc)?_c('i',{staticClass:"ri-file-text-line text__blue cursor-pointer ml-2 mt-2",attrs:{"aria-hidden":"true"},on:{"click":function($event){return _vm.viewDocument(_vm.doc)}}}):_vm._e()])])]),_c('hr',{staticClass:"loan-process-border-top"}),_c('div',{staticClass:"d-flex justify-content-end form-group align-items-center"},[(_vm.error.show)?_c('span',{staticClass:"login-error show mr-2"},[_vm._v(_vm._s(_vm.error.msg))]):_vm._e(),_c('button',{staticClass:"tmpco-btn tmpco-btn__default-blue mr-2",class:[
               _vm.isTaskCompleted
                ? 'btn-upload cursor-pointer cursor-not-allow'
                : 'btn-upload cursor-pointer' ],attrs:{"type":"submit","disabled":_vm.isTaskCompleted},on:{"click":function($event){return _vm.saveVorRecievedTask(false)}}},[_vm._v(" Save ")]),_c('button',{staticClass:"tmpco-btn tmpco-btn__theme",class:[
               _vm.isTaskCompleted
                ? 'btn-upload cursor-pointer cursor-not-allow'
                : 'btn-upload cursor-pointer' ],attrs:{"type":"submit","disabled":_vm.isTaskCompleted},on:{"click":function($event){return _vm.$modal.show('VORReceivedConfirmationModal');}}},[_vm._v(" "+_vm._s(_vm.isTaskCompleted ? "Completed" : "Complete")+" ")])])])]),_c('modal',{staticClass:"modal",attrs:{"centered":"","name":"VORReceivedConfirmationModal","hide-footer":true}},[_c('div',{staticClass:"modal--md-regular"},[_c('div',{staticClass:"tmpco-modal__content"},[_c('div',{staticClass:"tmpco-modal__header"},[_c('p',{staticClass:"tmpco-modal__title"},[_vm._v("VOR Received")]),_c('button',{staticClass:"tmpco-modal__close",attrs:{"type":"button","data-dismiss":"modal"},on:{"click":function($event){return _vm.$modal.hide('VORReceivedConfirmationModal');}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"tmpco-modal__body"},[_c('div',{staticClass:"col-lg-12 col-xl-12"},[_c('p',{staticClass:"mt-3 mb-3 content-modal"},[_vm._v(" Are you sure you want to complete? ")])])]),_c('div',{staticClass:"tmpco-modal__footer"},[_c('button',{staticClass:"tmpco-btn tmpco-btn__sm-default mr-2",on:{"click":function($event){return _vm.$modal.hide('VORReceivedConfirmationModal');}}},[_vm._v("No")]),_c('button',{staticClass:"tmpco-btn tmpco-btn__sm-theme",attrs:{"type":"button"},on:{"click":function($event){_vm.saveVorRecievedTask(true);_vm.$modal.hide('VORReceivedConfirmationModal')}}},[_vm._v("Yes")])])])])]),_c('ViewDocument',{ref:"ref"})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"loan-process-heading"},[_c('div',{staticClass:"col-lg-12"},[_c('p',{staticClass:"heading mb-0"},[_vm._v("VOR Received")])])])}]

export { render, staticRenderFns }