
































import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "../config";
import { authHeader } from "../services/auth";



@Component({
})
export default class CommunicationBrokerNotes extends Vue {
  /* eslint-disable */
  public brokerNotes: any = []



  public async getBrokerNotes() {
    this.callLoader(true, null)
    try {
      let response = await Axios.get(
        BASE_API_URL + "communication/getBrokerNotes/"+this.$route.query.id,
        {
          headers: authHeader(),
        }
      );
      this.brokerNotes = response.data;
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null)
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }


  
  
  async mounted() {
    this.getBrokerNotes();
  }
  /* eslint-disable */
}
