var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-2 white-card loan-process-height-249 d-flex flex-column"},[_c('div',{staticClass:"loan-process-heading"},[_c('div',{staticClass:"col-lg-12 d-flex justify-content-between"},[_c('p',{staticClass:"heading mb-0"},[_vm._v(" "+_vm._s(_vm.secondAppraisalCount > 0 ? "Second Appraisal Received" : "Appraisal Received")+" ")])])]),_c('div',{staticClass:"col-lg-12 pt-3 pb-3 flex-1 height_100"},[_c('div',{staticClass:"form-row m-0 flex-1 flex-column min_293 col-12 p-0"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.saveAppraisalReceivedTask)},"change":function($event){_vm.error = { show: false, msg: null, valError: false }},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();$event.preventDefault();}}},[_c('div',{staticClass:"flex-1"},[_c('div',{staticClass:"col-lg-12 p-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 col-xl-6 col-md-12 col-sm-12 mb-2"},[_c('p',{staticClass:"content-text m-0"},[_c('b',[_vm._v("Reports")])]),_c('hr',{staticClass:"loan-process-border-top mb-2 mt-1"}),_c('div',{staticClass:"flex-1"},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-lg-5 col-md-5 col-sm-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label"},[_vm._v("Sales Price/Appraisal Value")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sellingPrice),expression:"sellingPrice"}],staticClass:"login-input",attrs:{"type":"text","value":"sellingPrice","id":"sellingPrice","disabled":""},domProps:{"value":(_vm.sellingPrice)},on:{"input":function($event){if($event.target.composing){ return; }_vm.sellingPrice=$event.target.value}}})])]),_c('div',{staticClass:"col-lg-5 col-md-5 col-sm-12"},[_c('div',{staticClass:"form-group",on:{"change":function($event){_vm.isComplete = false;
                              _vm.handleChange(
                                _vm.appraisalInfo.appraisalPrice,
                                _vm.appraisalInfoData.appraisalPrice,
                                'Appraisal Price'
                              );
                              _vm.saveAppraisalReceivedTask();}}},[_c('label',{staticClass:"label"},[_vm._v("Appraisal")]),_c('vueNumeric',{staticClass:"login-input",attrs:{"id":"Appraisal Price","disabled":_vm.appraisalInfo.isCompleted,"precision":2,"currency":"$"},on:{"input":function($event){return _vm.compare()}},model:{value:(_vm.appraisalInfo.appraisalPrice),callback:function ($$v) {_vm.$set(_vm.appraisalInfo, "appraisalPrice", $$v)},expression:"appraisalInfo.appraisalPrice"}}),(_vm.error.valError)?_c('span',{staticClass:"login-error show"},[_vm._v(" "+_vm._s(_vm.error.msg)+" ")]):_vm._e()],1)]),(_vm.appraisalInfo.appraisalPrice)?_c('div',{staticClass:"col-lg-2 col-md-2 d-flex justify-content-end"},[_c('span',{staticClass:"text-center align-self-center",attrs:{"id":"msgSpan"}})]):_vm._e(),_c('div',{staticClass:"\n                            col-lg-12\n                            d_flex_704\n                            align-items-center\n                            form-group\n                          "},[_c('div',{staticClass:"d-flex align-items-center"},[(!_vm.appraisalInfo.appraisalReport)?_c('div',{staticClass:"file-upload mt-1 overflow_hidden"},[_c('label',{staticClass:"file-upload-label",attrs:{"for":"upload"}},[_c('i',{staticClass:"fa fa-upload mr-1"}),_vm._v(_vm._s(_vm.uploading ? "uploading..." : "Upload Appraisal Report"))]),_c('input',{staticClass:"file-upload-input",class:[
                                  _vm.appraisalInfo.isCompleted ||
                                  _vm.appraisalInfo.appraisalReport
                                    ? 'btn-upload cursor-pointer cursor-not-allow'
                                    : 'btn-upload cursor-pointer' ],attrs:{"id":"upload","disabled":_vm.appraisalInfo.isCompleted ||
                                  _vm.appraisalInfo.appraisalReport,"type":"file","name":"file-upload","multiple":""},on:{"change":function($event){return _vm.uploadFile($event, null)}}})]):_c('label',{staticClass:"label"},[_vm._v(" Uploaded Appraisal Report")]),(
                                _vm.appraisalInfo.appraisalReport &&
                                _vm.appraisalInfo.appraisalReport.docLength
                              )?_c('div',{staticClass:"d-flex align-items-center"},[_c('p',{staticClass:"mb-0 card-content-title ml-2 app-count"},[_c('b',[_vm._v(_vm._s(_vm.appraisalInfo.appraisalReport.docLength))])]),(_vm.appraisalInfo.appraisalReport)?_c('i',{staticClass:"\n                                  ri-file-text-line\n                                  ml-2\n                                  mr-2\n                                  text__blue\n                                  cursor-pointer\n                                ",on:{"click":function($event){$event.preventDefault();return _vm.viewDocument(_vm.appraisalInfo.appraisalReport)}}}):_vm._e()]):_vm._e()]),(
                              _vm.appraisalInfo.appraisalReport &&
                              _vm.showUploadRevisedReportDiv()
                            )?_c('div',{staticClass:"d-flex top-2"},[_c('label',{staticClass:"file-upload-label mb-0",attrs:{"for":"upload1"}},[_vm._v(" "+_vm._s(_vm.revisedUploading ? "Uploading.." : "Upload Updated Report")+" ")]),_c('div',{staticClass:"file-upload mt-1 overflow_hidden"},[_c('input',{staticClass:"file-upload-input",class:[
                                  _vm.appraisalInfo.isCompleted
                                    ? 'btn-upload cursor-pointer cursor-not-allow'
                                    : 'btn-upload cursor-pointer' ],attrs:{"id":"upload1","disabled":_vm.appraisalInfo.isCompleted,"type":"file","name":"file-upload","multiple":""},on:{"change":function($event){return _vm.uploadFile($event, 'Revised')}}})])]):_vm._e()]),_c('div',{staticClass:"col-12"},_vm._l((_vm.appraisalInfo
                              .isPropertyManufacturedHome.remarks),function(comm,i){return _c('div',{key:i,staticClass:"loan-process-border p-3 mb-2"},[_c('p',{staticClass:"mb-0 card-content-title"},[_c('b',{},[_vm._v(_vm._s(comm.fromName)+":- "+_vm._s(comm.message)+":- ")]),_vm._v(_vm._s(_vm.$moment(comm.AddedOn).format( "MM/DD/YYYY, h:mm:ss a" ))+" ")])])}),0)])])]),_c('div',{staticClass:"\n                      col-lg-6 col-xl-6 col-md-12 col-sm-12\n                      mb-2\n                      border_left_991\n                    "},[_c('p',{staticClass:"content-text m-0"},[_c('b',[_vm._v("Checklist")])]),_c('hr',{staticClass:"loan-process-border-top mb-2 mt-1"}),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 p-0"},[_c('p',{staticClass:"content-text-black mt-1 mb-1"},[_c('span',[_vm._v("1.")]),_vm._v(" Appraisal ordered on the correct Form? ")]),_c('div',{staticClass:"mb-2"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0 ml-3"},[_c('span',{staticClass:"radio-text"},[_vm._v("Yes")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.appraisalInfo.isAppraisalOrderedOnCorrectForm
                                  .mode
                              ),expression:"\n                                appraisalInfo.isAppraisalOrderedOnCorrectForm\n                                  .mode\n                              "}],attrs:{"type":"radio","value":"Yes","disabled":_vm.appraisalInfo.isCompleted},domProps:{"checked":_vm._q(
                                _vm.appraisalInfo.isAppraisalOrderedOnCorrectForm
                                  .mode
                              ,"Yes")},on:{"change":[function($event){return _vm.$set(_vm.appraisalInfo.isAppraisalOrderedOnCorrectForm
                                  , "mode", "Yes")},function($event){_vm.isComplete = false;
                                _vm.handleChange(
                                  _vm.appraisalInfo.isAppraisalOrderedOnCorrectForm
                                    .mode,
                                  _vm.appraisalInfoData
                                    .isAppraisalOrderedOnCorrectForm.mode,
                                  'Appraisal ordered on the correct Form?'
                                );
                                _vm.saveAppraisalReceivedTask();}],"click":function($event){_vm.appraisalInfo.isAppraisalOrderedOnCorrectForm.revisionRequestedOn =
                                  null;
                                _vm.appraisalInfo.isAppraisalOrderedOnCorrectForm.revisionCompletedOn =
                                  null;
                                _vm.appraisalInfo.isAppraisalOrderedOnCorrectForm.nARemark =
                                  null;}}}),_c('span',{class:[
                                errors.length > 0
                                  ? 'checkround checkround1'
                                  : 'checkround' ]})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("No")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.appraisalInfo.isAppraisalOrderedOnCorrectForm
                                  .mode
                              ),expression:"\n                                appraisalInfo.isAppraisalOrderedOnCorrectForm\n                                  .mode\n                              "}],attrs:{"type":"radio","value":"No","disabled":_vm.appraisalInfo.isCompleted},domProps:{"checked":_vm._q(
                                _vm.appraisalInfo.isAppraisalOrderedOnCorrectForm
                                  .mode
                              ,"No")},on:{"change":[function($event){return _vm.$set(_vm.appraisalInfo.isAppraisalOrderedOnCorrectForm
                                  , "mode", "No")},function($event){_vm.isComplete = false;
                                _vm.handleChange(
                                  _vm.appraisalInfo.isAppraisalOrderedOnCorrectForm
                                    .mode,
                                  _vm.appraisalInfoData
                                    .isAppraisalOrderedOnCorrectForm.mode,
                                  'Appraisal ordered on the correct Form?'
                                );
                                _vm.saveAppraisalReceivedTask();}],"click":function($event){_vm.appraisalInfo.isAppraisalOrderedOnCorrectForm.revisionRequestedOn =
                                  null;
                                _vm.appraisalInfo.isAppraisalOrderedOnCorrectForm.revisionCompletedOn =
                                  null;
                                _vm.appraisalInfo.isAppraisalOrderedOnCorrectForm.nARemark =
                                  null;}}}),_c('span',{class:[
                                errors.length > 0
                                  ? 'checkround checkround1'
                                  : 'checkround' ]})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("NA")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.appraisalInfo.isAppraisalOrderedOnCorrectForm
                                  .mode
                              ),expression:"\n                                appraisalInfo.isAppraisalOrderedOnCorrectForm\n                                  .mode\n                              "}],attrs:{"type":"radio","value":"NA","disabled":_vm.appraisalInfo.isCompleted},domProps:{"checked":_vm._q(
                                _vm.appraisalInfo.isAppraisalOrderedOnCorrectForm
                                  .mode
                              ,"NA")},on:{"change":[function($event){return _vm.$set(_vm.appraisalInfo.isAppraisalOrderedOnCorrectForm
                                  , "mode", "NA")},function($event){_vm.isComplete = false;
                                _vm.handleChange(
                                  _vm.appraisalInfo.isAppraisalOrderedOnCorrectForm
                                    .mode,
                                  _vm.appraisalInfoData
                                    .isAppraisalOrderedOnCorrectForm.mode,
                                  'Appraisal ordered on the correct Form?'
                                );
                                _vm.saveAppraisalReceivedTask();}],"click":function($event){_vm.appraisalInfo.isAppraisalOrderedOnCorrectForm.nARemark =
                                  null;
                                _vm.appraisalInfo.isAppraisalOrderedOnCorrectForm.revisionRequestedOn =
                                  null;
                                _vm.appraisalInfo.isAppraisalOrderedOnCorrectForm.revisionCompletedOn =
                                  null;}}}),_c('span',{class:[
                                errors.length > 0
                                  ? 'checkround checkround1'
                                  : 'checkround' ]})])]}}],null,true)})],1),_c('div',{staticClass:"row m-0"},[(
                            _vm.appraisalInfo.isAppraisalOrderedOnCorrectForm
                              .mode == 'No'
                          )?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('label',{staticClass:"label ml-3",attrs:{"for":"initial"}},[_vm._v("Revision Requested")]),_c('div',{staticClass:"d-flex align-items-center ml-3"},[_c('datepicker',{class:[
                                  errors.length > 0 ? 'mx-input-error' : '' ],attrs:{"disabled":_vm.appraisalInfo.isCompleted,"valueType":"format"},on:{"change":function($event){_vm.isComplete = false;
                                  _vm.handleChange(
                                    _vm.appraisalInfo
                                      .isAppraisalOrderedOnCorrectForm
                                      .revisionRequestedOn,
                                    _vm.appraisalInfoData
                                      .isAppraisalOrderedOnCorrectForm
                                      .revisionRequestedOn,
                                    'Appraisal ordered on the correct Form? Revision Requested'
                                  );
                                  _vm.saveAppraisalReceivedTask();}},model:{value:(
                                  _vm.appraisalInfo.isAppraisalOrderedOnCorrectForm
                                    .revisionRequestedOn
                                ),callback:function ($$v) {_vm.$set(_vm.appraisalInfo.isAppraisalOrderedOnCorrectForm
                                    , "revisionRequestedOn", $$v)},expression:"\n                                  appraisalInfo.isAppraisalOrderedOnCorrectForm\n                                    .revisionRequestedOn\n                                "}})],1)]}}],null,true)})],1):_vm._e(),(
                            _vm.appraisalInfo.isAppraisalOrderedOnCorrectForm
                              .mode == 'No'
                          )?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('label',{staticClass:"label",attrs:{"for":"initial"}},[_vm._v("Revision Completed")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('datepicker',{class:[
                                  errors.length > 0 ? 'mx-input-error' : '' ],attrs:{"disabled-date":function (date) { return date <=
                                    _vm.$moment(
                                      new Date(
                                        _vm.appraisalInfo.isAppraisalOrderedOnCorrectForm.revisionRequestedOn
                                      ).setDate(
                                        new Date(
                                          _vm.appraisalInfo.isAppraisalOrderedOnCorrectForm.revisionRequestedOn
                                        ).getDate() - 1
                                      )
                                    ); },"disabled":_vm.appraisalInfo.isCompleted,"valueType":"format"},on:{"change":function($event){_vm.isComplete = false;
                                  _vm.handleChange(
                                    _vm.appraisalInfo
                                      .isAppraisalOrderedOnCorrectForm
                                      .revisionCompletedOn,
                                    _vm.appraisalInfoData
                                      .isAppraisalOrderedOnCorrectForm
                                      .revisionCompletedOn,
                                    'Appraisal ordered on the correct Form? Revision Completed'
                                  );
                                  _vm.saveAppraisalReceivedTask();}},model:{value:(
                                  _vm.appraisalInfo.isAppraisalOrderedOnCorrectForm
                                    .revisionCompletedOn
                                ),callback:function ($$v) {_vm.$set(_vm.appraisalInfo.isAppraisalOrderedOnCorrectForm
                                    , "revisionCompletedOn", $$v)},expression:"\n                                  appraisalInfo.isAppraisalOrderedOnCorrectForm\n                                    .revisionCompletedOn\n                                "}})],1)]}}],null,true)})],1):_vm._e()]),(
                          _vm.appraisalInfo.isAppraisalOrderedOnCorrectForm.mode ==
                          'NA'
                        )?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 p-0"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label ml-3",attrs:{"for":"creditorName"}},[_vm._v("Add remarks")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                              _vm.appraisalInfo.isAppraisalOrderedOnCorrectForm
                                .nARemark
                            ),expression:"\n                              appraisalInfo.isAppraisalOrderedOnCorrectForm\n                                .nARemark\n                            "}],staticClass:"login-input line_height_40 ml-3",attrs:{"type":"text","disabled":_vm.appraisalInfo.isCompleted,"name":"Add remarks"},domProps:{"value":(
                              _vm.appraisalInfo.isAppraisalOrderedOnCorrectForm
                                .nARemark
                            )},on:{"change":function($event){_vm.isComplete = false;
                              _vm.handleChange(
                                _vm.appraisalInfo.isAppraisalOrderedOnCorrectForm
                                  .nARemark,
                                _vm.appraisalInfoData
                                  .isAppraisalOrderedOnCorrectForm.nARemark,
                                'Appraisal ordered on the correct Form? Remarks'
                              );
                              _vm.saveAppraisalReceivedTask();},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.appraisalInfo.isAppraisalOrderedOnCorrectForm
                                , "nARemark", $event.target.value)}}})])]):_vm._e()]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 p-0"},[_c('p',{staticClass:"content-text-black mt-1 mb-1"},[_c('span',[_vm._v("2.")]),_vm._v(" Is the Borrower and Co-Borrower’s name correctly reflected to coincide with the 1003? ")]),_c('div',{staticClass:"mb-2"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0 ml-3"},[_c('span',{staticClass:"radio-text"},[_vm._v("Yes")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.appraisalInfo
                                  .isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003
                                  .mode
                              ),expression:"\n                                appraisalInfo\n                                  .isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003\n                                  .mode\n                              "}],attrs:{"type":"radio","value":"Yes","disabled":_vm.appraisalInfo.isCompleted},domProps:{"checked":_vm._q(
                                _vm.appraisalInfo
                                  .isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003
                                  .mode
                              ,"Yes")},on:{"change":[function($event){return _vm.$set(_vm.appraisalInfo
                                  .isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003
                                  , "mode", "Yes")},function($event){_vm.isComplete = false;
                                _vm.handleChange(
                                  _vm.appraisalInfo
                                    .isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003
                                    .mode,
                                  _vm.appraisalInfoData
                                    .isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003
                                    .mode,
                                  'Is the Borrower and Co-Borrower’s name correctly reflected to coincide with the 1003?'
                                );
                                _vm.saveAppraisalReceivedTask();}],"click":function($event){_vm.appraisalInfo.isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003.revisionRequestedOn =
                                  null;
                                _vm.appraisalInfo.isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003.revisionCompletedOn =
                                  null;
                                _vm.appraisalInfo.isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003.nARemark =
                                  null;}}}),_c('span',{class:[
                                errors.length > 0
                                  ? 'checkround checkround1'
                                  : 'checkround' ]})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("No")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.appraisalInfo
                                  .isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003
                                  .mode
                              ),expression:"\n                                appraisalInfo\n                                  .isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003\n                                  .mode\n                              "}],attrs:{"type":"radio","value":"No","disabled":_vm.appraisalInfo.isCompleted},domProps:{"checked":_vm._q(
                                _vm.appraisalInfo
                                  .isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003
                                  .mode
                              ,"No")},on:{"change":[function($event){return _vm.$set(_vm.appraisalInfo
                                  .isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003
                                  , "mode", "No")},function($event){_vm.isComplete = false;
                                _vm.handleChange(
                                  _vm.appraisalInfo
                                    .isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003
                                    .mode,
                                  _vm.appraisalInfoData
                                    .isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003
                                    .mode,
                                  'Is the Borrower and Co-Borrower’s name correctly reflected to coincide with the 1003?'
                                );
                                _vm.saveAppraisalReceivedTask();}],"click":function($event){_vm.appraisalInfo.isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003.revisionRequestedOn =
                                  null;
                                _vm.appraisalInfo.isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003.revisionCompletedOn =
                                  null;
                                _vm.appraisalInfo.isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003.nARemark =
                                  null;}}}),_c('span',{class:[
                                errors.length > 0
                                  ? 'checkround checkround1'
                                  : 'checkround' ]})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("NA")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.appraisalInfo
                                  .isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003
                                  .mode
                              ),expression:"\n                                appraisalInfo\n                                  .isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003\n                                  .mode\n                              "}],attrs:{"type":"radio","value":"NA","disabled":_vm.appraisalInfo.isCompleted},domProps:{"checked":_vm._q(
                                _vm.appraisalInfo
                                  .isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003
                                  .mode
                              ,"NA")},on:{"change":[function($event){return _vm.$set(_vm.appraisalInfo
                                  .isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003
                                  , "mode", "NA")},function($event){_vm.isComplete = false;
                                _vm.handleChange(
                                  _vm.appraisalInfo
                                    .isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003
                                    .mode,
                                  _vm.appraisalInfoData
                                    .isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003
                                    .mode,
                                  'Is the Borrower and Co-Borrower’s name correctly reflected to coincide with the 1003?'
                                );
                                _vm.saveAppraisalReceivedTask();}],"click":function($event){_vm.appraisalInfo.isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003.revisionRequestedOn =
                                  null;
                                _vm.appraisalInfo.isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003.revisionCompletedOn =
                                  null;
                                _vm.appraisalInfo.isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003.nARemark =
                                  null;}}}),_c('span',{class:[
                                errors.length > 0
                                  ? 'checkround checkround1'
                                  : 'checkround' ]})])]}}],null,true)})],1),_c('div',{staticClass:"row m-0"},[(
                            _vm.appraisalInfo
                              .isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003
                              .mode == 'No'
                          )?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('label',{staticClass:"label ml-3",attrs:{"for":"initial"}},[_vm._v("Revision Requested")]),_c('div',{staticClass:"d-flex align-items-center ml-3"},[_c('datepicker',{class:[
                                  errors.length > 0 ? 'mx-input-error' : '' ],attrs:{"disabled":_vm.appraisalInfo.isCompleted,"valueType":"format"},on:{"change":function($event){_vm.isComplete = false;
                                  _vm.handleChange(
                                    _vm.appraisalInfo
                                      .isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003
                                      .revisionRequestedOn,
                                    _vm.appraisalInfoData
                                      .isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003
                                      .revisionRequestedOn,
                                    'Is the Borrower and Co-Borrower’s name correctly reflected to coincide with the 1003? Revision Requested'
                                  );
                                  _vm.saveAppraisalReceivedTask();}},model:{value:(
                                  _vm.appraisalInfo
                                    .isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003
                                    .revisionRequestedOn
                                ),callback:function ($$v) {_vm.$set(_vm.appraisalInfo
                                    .isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003
                                    , "revisionRequestedOn", $$v)},expression:"\n                                  appraisalInfo\n                                    .isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003\n                                    .revisionRequestedOn\n                                "}})],1)]}}],null,true)})],1):_vm._e(),(
                            _vm.appraisalInfo
                              .isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003
                              .mode == 'No'
                          )?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('label',{staticClass:"label",attrs:{"for":"initial"}},[_vm._v("Revision Completed")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('datepicker',{class:[
                                  errors.length > 0 ? 'mx-input-error' : '' ],attrs:{"disabled-date":function (date) { return date <=
                                    _vm.$moment(
                                      new Date(
                                        _vm.appraisalInfo.isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003.revisionRequestedOn
                                      ).setDate(
                                        new Date(
                                          _vm.appraisalInfo.isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003.revisionRequestedOn
                                        ).getDate() - 1
                                      )
                                    ); },"disabled":_vm.appraisalInfo.isCompleted,"valueType":"format"},on:{"change":function($event){_vm.isComplete = false;
                                  _vm.handleChange(
                                    _vm.appraisalInfo
                                      .isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003
                                      .revisionCompletedOn,
                                    _vm.appraisalInfoData
                                      .isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003
                                      .revisionCompletedOn,
                                    'Is the Borrower and Co-Borrower’s name correctly reflected to coincide with the 1003? Revision Completed'
                                  );
                                  _vm.saveAppraisalReceivedTask();}},model:{value:(
                                  _vm.appraisalInfo
                                    .isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003
                                    .revisionCompletedOn
                                ),callback:function ($$v) {_vm.$set(_vm.appraisalInfo
                                    .isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003
                                    , "revisionCompletedOn", $$v)},expression:"\n                                  appraisalInfo\n                                    .isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003\n                                    .revisionCompletedOn\n                                "}})],1)]}}],null,true)})],1):_vm._e()]),(
                          _vm.appraisalInfo
                            .isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003
                            .mode == 'NA'
                        )?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 p-0"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label ml-3",attrs:{"for":"creditorName"}},[_vm._v("Add remarks")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                              _vm.appraisalInfo
                                .isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003
                                .nARemark
                            ),expression:"\n                              appraisalInfo\n                                .isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003\n                                .nARemark\n                            "}],staticClass:"login-input line_height_40 ml-3",attrs:{"type":"text","disabled":_vm.appraisalInfo.isCompleted,"name":"Add remarks"},domProps:{"value":(
                              _vm.appraisalInfo
                                .isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003
                                .nARemark
                            )},on:{"change":function($event){_vm.isComplete = false;
                              _vm.handleChange(
                                _vm.appraisalInfo
                                  .isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003
                                  .nARemark,
                                _vm.appraisalInfoData
                                  .isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003
                                  .nARemark,
                                'Is the Borrower and Co-Borrower’s name correctly reflected to coincide with the 1003? Remarks'
                              );
                              _vm.saveAppraisalReceivedTask();},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.appraisalInfo
                                .isBorrowerOrCoBorrowerNameCorrectlyReflectedOn1003
                                , "nARemark", $event.target.value)}}})])]):_vm._e()]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 p-0"},[_c('p',{staticClass:"content-text-black mt-1 mb-1"},[_c('span',[_vm._v("3.")]),_vm._v(" Does the property address coincide the Sales Contract or Sales Addendum? ")]),_c('div',{staticClass:"mb-2"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0 ml-3"},[_c('span',{staticClass:"radio-text"},[_vm._v("Yes")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.appraisalInfo
                                  .isPropertyAddressCoincideSalesContractOrSalesAddendum
                                  .mode
                              ),expression:"\n                                appraisalInfo\n                                  .isPropertyAddressCoincideSalesContractOrSalesAddendum\n                                  .mode\n                              "}],attrs:{"type":"radio","value":"Yes","disabled":_vm.appraisalInfo.isCompleted},domProps:{"checked":_vm._q(
                                _vm.appraisalInfo
                                  .isPropertyAddressCoincideSalesContractOrSalesAddendum
                                  .mode
                              ,"Yes")},on:{"change":[function($event){return _vm.$set(_vm.appraisalInfo
                                  .isPropertyAddressCoincideSalesContractOrSalesAddendum
                                  , "mode", "Yes")},function($event){_vm.isComplete = false;
                                _vm.handleChange(
                                  _vm.appraisalInfo
                                    .isPropertyAddressCoincideSalesContractOrSalesAddendum
                                    .mode,
                                  _vm.appraisalInfoData
                                    .isPropertyAddressCoincideSalesContractOrSalesAddendum
                                    .mode,
                                  'Does the property address coincide the Sales Contract or Sales Addendum?'
                                );
                                _vm.saveAppraisalReceivedTask();}],"click":function($event){_vm.appraisalInfo.isPropertyAddressCoincideSalesContractOrSalesAddendum.revisionRequestedOn =
                                  null;
                                _vm.appraisalInfo.isPropertyAddressCoincideSalesContractOrSalesAddendum.revisionCompletedOn =
                                  null;
                                _vm.appraisalInfo.isPropertyAddressCoincideSalesContractOrSalesAddendum.nARemark =
                                  null;}}}),_c('span',{class:[
                                errors.length > 0
                                  ? 'checkround checkround1'
                                  : 'checkround' ]})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("No")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.appraisalInfo
                                  .isPropertyAddressCoincideSalesContractOrSalesAddendum
                                  .mode
                              ),expression:"\n                                appraisalInfo\n                                  .isPropertyAddressCoincideSalesContractOrSalesAddendum\n                                  .mode\n                              "}],attrs:{"type":"radio","value":"No","disabled":_vm.appraisalInfo.isCompleted},domProps:{"checked":_vm._q(
                                _vm.appraisalInfo
                                  .isPropertyAddressCoincideSalesContractOrSalesAddendum
                                  .mode
                              ,"No")},on:{"change":[function($event){return _vm.$set(_vm.appraisalInfo
                                  .isPropertyAddressCoincideSalesContractOrSalesAddendum
                                  , "mode", "No")},function($event){_vm.isComplete = false;
                                _vm.handleChange(
                                  _vm.appraisalInfo
                                    .isPropertyAddressCoincideSalesContractOrSalesAddendum
                                    .mode,
                                  _vm.appraisalInfoData
                                    .isPropertyAddressCoincideSalesContractOrSalesAddendum
                                    .mode,
                                  'Does the property address coincide the Sales Contract or Sales Addendum?'
                                );
                                _vm.saveAppraisalReceivedTask();}],"click":function($event){_vm.appraisalInfo.isPropertyAddressCoincideSalesContractOrSalesAddendum.revisionRequestedOn =
                                  null;
                                _vm.appraisalInfo.isPropertyAddressCoincideSalesContractOrSalesAddendum.revisionCompletedOn =
                                  null;
                                _vm.appraisalInfo.isPropertyAddressCoincideSalesContractOrSalesAddendum.nARemark =
                                  null;}}}),_c('span',{class:[
                                errors.length > 0
                                  ? 'checkround checkround1'
                                  : 'checkround' ]})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("NA")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.appraisalInfo
                                  .isPropertyAddressCoincideSalesContractOrSalesAddendum
                                  .mode
                              ),expression:"\n                                appraisalInfo\n                                  .isPropertyAddressCoincideSalesContractOrSalesAddendum\n                                  .mode\n                              "}],attrs:{"type":"radio","value":"NA","disabled":_vm.appraisalInfo.isCompleted},domProps:{"checked":_vm._q(
                                _vm.appraisalInfo
                                  .isPropertyAddressCoincideSalesContractOrSalesAddendum
                                  .mode
                              ,"NA")},on:{"change":[function($event){return _vm.$set(_vm.appraisalInfo
                                  .isPropertyAddressCoincideSalesContractOrSalesAddendum
                                  , "mode", "NA")},function($event){_vm.isComplete = false;
                                _vm.handleChange(
                                  _vm.appraisalInfo
                                    .isPropertyAddressCoincideSalesContractOrSalesAddendum
                                    .mode,
                                  _vm.appraisalInfoData
                                    .isPropertyAddressCoincideSalesContractOrSalesAddendum
                                    .mode,
                                  'Does the property address coincide the Sales Contract or Sales Addendum?'
                                );
                                _vm.saveAppraisalReceivedTask();}],"click":function($event){_vm.appraisalInfo.isPropertyAddressCoincideSalesContractOrSalesAddendum.revisionRequestedOn =
                                  null;
                                _vm.appraisalInfo.isPropertyAddressCoincideSalesContractOrSalesAddendum.revisionCompletedOn =
                                  null;
                                _vm.appraisalInfo.isPropertyAddressCoincideSalesContractOrSalesAddendum.nARemark =
                                  null;}}}),_c('span',{class:[
                                errors.length > 0
                                  ? 'checkround checkround1'
                                  : 'checkround' ]})])]}}],null,true)})],1),_c('div',{staticClass:"row m-0"},[(
                            _vm.appraisalInfo
                              .isPropertyAddressCoincideSalesContractOrSalesAddendum
                              .mode == 'No'
                          )?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('label',{staticClass:"label ml-3",attrs:{"for":"initial"}},[_vm._v("Revision Requested")]),_c('div',{staticClass:"d-flex align-items-center ml-3"},[_c('datepicker',{class:[
                                  errors.length > 0 ? 'mx-input-error' : '' ],attrs:{"disabled":_vm.appraisalInfo.isCompleted,"valueType":"format"},on:{"change":function($event){_vm.isComplete = false;
                                  _vm.handleChange(
                                    _vm.appraisalInfo
                                      .isPropertyAddressCoincideSalesContractOrSalesAddendum
                                      .revisionRequestedOn,
                                    _vm.appraisalInfoData
                                      .isPropertyAddressCoincideSalesContractOrSalesAddendum
                                      .revisionRequestedOn,
                                    'Does the property address coincide the Sales Contract or Sales Addendum? Revision Requested'
                                  );
                                  _vm.saveAppraisalReceivedTask();}},model:{value:(
                                  _vm.appraisalInfo
                                    .isPropertyAddressCoincideSalesContractOrSalesAddendum
                                    .revisionRequestedOn
                                ),callback:function ($$v) {_vm.$set(_vm.appraisalInfo
                                    .isPropertyAddressCoincideSalesContractOrSalesAddendum
                                    , "revisionRequestedOn", $$v)},expression:"\n                                  appraisalInfo\n                                    .isPropertyAddressCoincideSalesContractOrSalesAddendum\n                                    .revisionRequestedOn\n                                "}})],1)]}}],null,true)})],1):_vm._e(),(
                            _vm.appraisalInfo
                              .isPropertyAddressCoincideSalesContractOrSalesAddendum
                              .mode == 'No'
                          )?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('label',{staticClass:"label",attrs:{"for":"initial"}},[_vm._v("Revision Completed")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('datepicker',{class:[
                                  errors.length > 0 ? 'mx-input-error' : '' ],attrs:{"disabled-date":function (date) { return date <=
                                    _vm.$moment(
                                      new Date(
                                        _vm.appraisalInfo.isPropertyAddressCoincideSalesContractOrSalesAddendum.revisionRequestedOn
                                      ).setDate(
                                        new Date(
                                          _vm.appraisalInfo.isPropertyAddressCoincideSalesContractOrSalesAddendum.revisionRequestedOn
                                        ).getDate() - 1
                                      )
                                    ); },"disabled":_vm.appraisalInfo.isCompleted,"valueType":"format"},on:{"change":function($event){_vm.isComplete = false;
                                  _vm.handleChange(
                                    _vm.appraisalInfo
                                      .isPropertyAddressCoincideSalesContractOrSalesAddendum
                                      .revisionCompletedOn,
                                    _vm.appraisalInfoData
                                      .isPropertyAddressCoincideSalesContractOrSalesAddendum
                                      .revisionCompletedOn,
                                    'Does the property address coincide the Sales Contract or Sales Addendum? Revision Completed'
                                  );
                                  _vm.saveAppraisalReceivedTask();}},model:{value:(
                                  _vm.appraisalInfo
                                    .isPropertyAddressCoincideSalesContractOrSalesAddendum
                                    .revisionCompletedOn
                                ),callback:function ($$v) {_vm.$set(_vm.appraisalInfo
                                    .isPropertyAddressCoincideSalesContractOrSalesAddendum
                                    , "revisionCompletedOn", $$v)},expression:"\n                                  appraisalInfo\n                                    .isPropertyAddressCoincideSalesContractOrSalesAddendum\n                                    .revisionCompletedOn\n                                "}})],1)]}}],null,true)})],1):_vm._e()]),(
                          _vm.appraisalInfo
                            .isPropertyAddressCoincideSalesContractOrSalesAddendum
                            .mode == 'NA'
                        )?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 p-0"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label ml-3",attrs:{"for":"creditorName"}},[_vm._v("Add remarks")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                              _vm.appraisalInfo
                                .isPropertyAddressCoincideSalesContractOrSalesAddendum
                                .nARemark
                            ),expression:"\n                              appraisalInfo\n                                .isPropertyAddressCoincideSalesContractOrSalesAddendum\n                                .nARemark\n                            "}],staticClass:"login-input line_height_40 ml-3",attrs:{"type":"text","disabled":_vm.appraisalInfo.isCompleted,"name":"Add remarks"},domProps:{"value":(
                              _vm.appraisalInfo
                                .isPropertyAddressCoincideSalesContractOrSalesAddendum
                                .nARemark
                            )},on:{"change":function($event){_vm.isComplete = false;
                              _vm.handleChange(
                                _vm.appraisalInfo
                                  .isPropertyAddressCoincideSalesContractOrSalesAddendum
                                  .nARemark,
                                _vm.appraisalInfoData
                                  .isPropertyAddressCoincideSalesContractOrSalesAddendum
                                  .nARemark,
                                'Does the property address coincide the Sales Contract or Sales Addendum? Remarks'
                              );
                              _vm.saveAppraisalReceivedTask();},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.appraisalInfo
                                .isPropertyAddressCoincideSalesContractOrSalesAddendum
                                , "nARemark", $event.target.value)}}})])]):_vm._e()]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 p-0"},[_c('p',{staticClass:"content-text-black mt-1 mb-1"},[_c('span',[_vm._v("4.")]),_vm._v(" Is the Sales Price accurately reflected to coincide with the Sales Contract or Sales Addendum? ")]),_c('div',{staticClass:"mb-2"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0 ml-3"},[_c('span',{staticClass:"radio-text"},[_vm._v("Yes")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.appraisalInfo.isSalesPriceAccuratelyReflected
                                  .mode
                              ),expression:"\n                                appraisalInfo.isSalesPriceAccuratelyReflected\n                                  .mode\n                              "}],attrs:{"type":"radio","value":"Yes","disabled":_vm.appraisalInfo.isCompleted},domProps:{"checked":_vm._q(
                                _vm.appraisalInfo.isSalesPriceAccuratelyReflected
                                  .mode
                              ,"Yes")},on:{"change":[function($event){return _vm.$set(_vm.appraisalInfo.isSalesPriceAccuratelyReflected
                                  , "mode", "Yes")},function($event){_vm.isComplete = false;
                                _vm.handleChange(
                                  _vm.appraisalInfo.isSalesPriceAccuratelyReflected
                                    .mode,
                                  _vm.appraisalInfoData
                                    .isSalesPriceAccuratelyReflected.mode,
                                  'Is the Sales Price accurately reflected to coincide with the Sales Contract or Sales Addendum?'
                                );
                                _vm.saveAppraisalReceivedTask();}],"click":function($event){_vm.appraisalInfo.isSalesPriceAccuratelyReflected.revisionRequestedOn =
                                  null;
                                _vm.appraisalInfo.isSalesPriceAccuratelyReflected.revisionCompletedOn =
                                  null;
                                _vm.appraisalInfo.isSalesPriceAccuratelyReflected.nARemark =
                                  null;}}}),_c('span',{class:[
                                errors.length > 0
                                  ? 'checkround checkround1'
                                  : 'checkround' ]})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("No")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.appraisalInfo.isSalesPriceAccuratelyReflected
                                  .mode
                              ),expression:"\n                                appraisalInfo.isSalesPriceAccuratelyReflected\n                                  .mode\n                              "}],attrs:{"type":"radio","value":"No","disabled":_vm.appraisalInfo.isCompleted},domProps:{"checked":_vm._q(
                                _vm.appraisalInfo.isSalesPriceAccuratelyReflected
                                  .mode
                              ,"No")},on:{"change":[function($event){return _vm.$set(_vm.appraisalInfo.isSalesPriceAccuratelyReflected
                                  , "mode", "No")},function($event){_vm.isComplete = false;
                                _vm.handleChange(
                                  _vm.appraisalInfo.isSalesPriceAccuratelyReflected
                                    .mode,
                                  _vm.appraisalInfoData
                                    .isSalesPriceAccuratelyReflected.mode,
                                  'Is the Sales Price accurately reflected to coincide with the Sales Contract or Sales Addendum?'
                                );
                                _vm.saveAppraisalReceivedTask();}],"click":function($event){_vm.appraisalInfo.isSalesPriceAccuratelyReflected.revisionRequestedOn =
                                  null;
                                _vm.appraisalInfo.isSalesPriceAccuratelyReflected.revisionCompletedOn =
                                  null;
                                _vm.appraisalInfo.isSalesPriceAccuratelyReflected.nARemark =
                                  null;}}}),_c('span',{class:[
                                errors.length > 0
                                  ? 'checkround checkround1'
                                  : 'checkround' ]})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("NA")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.appraisalInfo.isSalesPriceAccuratelyReflected
                                  .mode
                              ),expression:"\n                                appraisalInfo.isSalesPriceAccuratelyReflected\n                                  .mode\n                              "}],attrs:{"type":"radio","value":"NA","disabled":_vm.appraisalInfo.isCompleted},domProps:{"checked":_vm._q(
                                _vm.appraisalInfo.isSalesPriceAccuratelyReflected
                                  .mode
                              ,"NA")},on:{"change":[function($event){return _vm.$set(_vm.appraisalInfo.isSalesPriceAccuratelyReflected
                                  , "mode", "NA")},function($event){_vm.isComplete = false;
                                _vm.handleChange(
                                  _vm.appraisalInfo.isSalesPriceAccuratelyReflected
                                    .mode,
                                  _vm.appraisalInfoData
                                    .isSalesPriceAccuratelyReflected.mode,
                                  'Is the Sales Price accurately reflected to coincide with the Sales Contract or Sales Addendum?'
                                );
                                _vm.saveAppraisalReceivedTask();}],"click":function($event){_vm.appraisalInfo.isSalesPriceAccuratelyReflected.revisionRequestedOn =
                                  null;
                                _vm.appraisalInfo.isSalesPriceAccuratelyReflected.revisionCompletedOn =
                                  null;
                                _vm.appraisalInfo.isSalesPriceAccuratelyReflected.nARemark =
                                  null;}}}),_c('span',{class:[
                                errors.length > 0
                                  ? 'checkround checkround1'
                                  : 'checkround' ]})])]}}],null,true)})],1),_c('div',{staticClass:"row m-0"},[(
                            _vm.appraisalInfo.isSalesPriceAccuratelyReflected
                              .mode == 'No'
                          )?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('label',{staticClass:"label ml-3",attrs:{"for":"initial"}},[_vm._v("Revision Requested")]),_c('div',{staticClass:"d-flex align-items-center ml-3"},[_c('datepicker',{class:[
                                  errors.length > 0 ? 'mx-input-error' : '' ],attrs:{"disabled":_vm.appraisalInfo.isCompleted,"valueType":"format"},on:{"change":function($event){_vm.isComplete = false;
                                  _vm.handleChange(
                                    _vm.appraisalInfo
                                      .isSalesPriceAccuratelyReflected
                                      .revisionRequestedOn,
                                    _vm.appraisalInfoData
                                      .isSalesPriceAccuratelyReflected
                                      .revisionRequestedOn,
                                    'Is the Sales Price accurately reflected to coincide with the Sales Contract or Sales Addendum? Revision Requested'
                                  );
                                  _vm.saveAppraisalReceivedTask();}},model:{value:(
                                  _vm.appraisalInfo.isSalesPriceAccuratelyReflected
                                    .revisionRequestedOn
                                ),callback:function ($$v) {_vm.$set(_vm.appraisalInfo.isSalesPriceAccuratelyReflected
                                    , "revisionRequestedOn", $$v)},expression:"\n                                  appraisalInfo.isSalesPriceAccuratelyReflected\n                                    .revisionRequestedOn\n                                "}})],1)]}}],null,true)})],1):_vm._e(),(
                            _vm.appraisalInfo.isSalesPriceAccuratelyReflected
                              .mode == 'No'
                          )?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('label',{staticClass:"label",attrs:{"for":"initial"}},[_vm._v("Revision Completed")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('datepicker',{class:[
                                  errors.length > 0 ? 'mx-input-error' : '' ],attrs:{"disabled-date":function (date) { return date <=
                                    _vm.$moment(
                                      new Date(
                                        _vm.appraisalInfo.isSalesPriceAccuratelyReflected.revisionRequestedOn
                                      ).setDate(
                                        new Date(
                                          _vm.appraisalInfo.isSalesPriceAccuratelyReflected.revisionRequestedOn
                                        ).getDate() - 1
                                      )
                                    ); },"disabled":_vm.appraisalInfo.isCompleted,"valueType":"format"},on:{"change":function($event){_vm.isComplete = false;
                                  _vm.handleChange(
                                    _vm.appraisalInfo
                                      .isSalesPriceAccuratelyReflected
                                      .revisionCompletedOn,
                                    _vm.appraisalInfoData
                                      .isSalesPriceAccuratelyReflected
                                      .revisionCompletedOn,
                                    'Is the Sales Price accurately reflected to coincide with the Sales Contract or Sales Addendum? Revision Completed'
                                  );
                                  _vm.saveAppraisalReceivedTask();}},model:{value:(
                                  _vm.appraisalInfo.isSalesPriceAccuratelyReflected
                                    .revisionCompletedOn
                                ),callback:function ($$v) {_vm.$set(_vm.appraisalInfo.isSalesPriceAccuratelyReflected
                                    , "revisionCompletedOn", $$v)},expression:"\n                                  appraisalInfo.isSalesPriceAccuratelyReflected\n                                    .revisionCompletedOn\n                                "}})],1)]}}],null,true)})],1):_vm._e()]),(
                          _vm.appraisalInfo.isSalesPriceAccuratelyReflected.mode ==
                          'NA'
                        )?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 p-0"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label ml-3",attrs:{"for":"creditorName"}},[_vm._v("Add remarks")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                              _vm.appraisalInfo.isSalesPriceAccuratelyReflected
                                .nARemark
                            ),expression:"\n                              appraisalInfo.isSalesPriceAccuratelyReflected\n                                .nARemark\n                            "}],staticClass:"login-input line_height_40 ml-3",attrs:{"type":"text","disabled":_vm.appraisalInfo.isCompleted,"name":"Add remarks"},domProps:{"value":(
                              _vm.appraisalInfo.isSalesPriceAccuratelyReflected
                                .nARemark
                            )},on:{"change":function($event){_vm.isComplete = false;
                              _vm.handleChange(
                                _vm.appraisalInfo.isSalesPriceAccuratelyReflected
                                  .nARemark,
                                _vm.appraisalInfoData
                                  .isSalesPriceAccuratelyReflected.nARemark,
                                'Is the Sales Price accurately reflected to coincide with the Sales Contract or Sales Addendum? Remarks'
                              );
                              _vm.saveAppraisalReceivedTask();},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.appraisalInfo.isSalesPriceAccuratelyReflected
                                , "nARemark", $event.target.value)}}})])]):_vm._e()]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 p-0"},[_c('p',{staticClass:"content-text-black mt-1 mb-1"},[_c('span',[_vm._v("5.")]),_vm._v(" Is the Owner of Record the same as the Seller reflected on the Sales Contract? ")]),_c('div',{staticClass:"mb-2"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0 ml-3"},[_c('span',{staticClass:"radio-text"},[_vm._v("Yes")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.appraisalInfo
                                  .isOwnerOfRecordSameAsSellerReflectedOnSalesContract
                                  .mode
                              ),expression:"\n                                appraisalInfo\n                                  .isOwnerOfRecordSameAsSellerReflectedOnSalesContract\n                                  .mode\n                              "}],attrs:{"type":"radio","value":"Yes","disabled":_vm.appraisalInfo.isCompleted},domProps:{"checked":_vm._q(
                                _vm.appraisalInfo
                                  .isOwnerOfRecordSameAsSellerReflectedOnSalesContract
                                  .mode
                              ,"Yes")},on:{"change":[function($event){return _vm.$set(_vm.appraisalInfo
                                  .isOwnerOfRecordSameAsSellerReflectedOnSalesContract
                                  , "mode", "Yes")},function($event){_vm.isComplete = false;
                                _vm.handleChange(
                                  _vm.appraisalInfo
                                    .isOwnerOfRecordSameAsSellerReflectedOnSalesContract
                                    .mode,
                                  _vm.appraisalInfoData
                                    .isOwnerOfRecordSameAsSellerReflectedOnSalesContract
                                    .mode,
                                  'Is the Owner of Record the same as the Seller reflected on the Sales Contract?'
                                );
                                _vm.saveAppraisalReceivedTask();}],"click":function($event){_vm.appraisalInfo.isOwnerOfRecordSameAsSellerReflectedOnSalesContract.revisionRequestedOn =
                                  null;
                                _vm.appraisalInfo.isOwnerOfRecordSameAsSellerReflectedOnSalesContract.revisionCompletedOn =
                                  null;
                                _vm.appraisalInfo.isOwnerOfRecordSameAsSellerReflectedOnSalesContract.nARemark =
                                  null;}}}),_c('span',{class:[
                                errors.length > 0
                                  ? 'checkround checkround1'
                                  : 'checkround' ]})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("No")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.appraisalInfo
                                  .isOwnerOfRecordSameAsSellerReflectedOnSalesContract
                                  .mode
                              ),expression:"\n                                appraisalInfo\n                                  .isOwnerOfRecordSameAsSellerReflectedOnSalesContract\n                                  .mode\n                              "}],attrs:{"type":"radio","value":"No","disabled":_vm.appraisalInfo.isCompleted},domProps:{"checked":_vm._q(
                                _vm.appraisalInfo
                                  .isOwnerOfRecordSameAsSellerReflectedOnSalesContract
                                  .mode
                              ,"No")},on:{"change":[function($event){return _vm.$set(_vm.appraisalInfo
                                  .isOwnerOfRecordSameAsSellerReflectedOnSalesContract
                                  , "mode", "No")},function($event){_vm.isComplete = false;
                                _vm.handleChange(
                                  _vm.appraisalInfo
                                    .isOwnerOfRecordSameAsSellerReflectedOnSalesContract
                                    .mode,
                                  _vm.appraisalInfoData
                                    .isOwnerOfRecordSameAsSellerReflectedOnSalesContract
                                    .mode,
                                  'Is the Owner of Record the same as the Seller reflected on the Sales Contract?'
                                );
                                _vm.saveAppraisalReceivedTask();}],"click":function($event){_vm.appraisalInfo.isOwnerOfRecordSameAsSellerReflectedOnSalesContract.revisionRequestedOn =
                                  null;
                                _vm.appraisalInfo.isOwnerOfRecordSameAsSellerReflectedOnSalesContract.revisionCompletedOn =
                                  null;
                                _vm.appraisalInfo.isOwnerOfRecordSameAsSellerReflectedOnSalesContract.nARemark =
                                  null;}}}),_c('span',{class:[
                                errors.length > 0
                                  ? 'checkround checkround1'
                                  : 'checkround' ]})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("NA")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.appraisalInfo
                                  .isOwnerOfRecordSameAsSellerReflectedOnSalesContract
                                  .mode
                              ),expression:"\n                                appraisalInfo\n                                  .isOwnerOfRecordSameAsSellerReflectedOnSalesContract\n                                  .mode\n                              "}],attrs:{"type":"radio","value":"NA","disabled":_vm.appraisalInfo.isCompleted},domProps:{"checked":_vm._q(
                                _vm.appraisalInfo
                                  .isOwnerOfRecordSameAsSellerReflectedOnSalesContract
                                  .mode
                              ,"NA")},on:{"change":[function($event){return _vm.$set(_vm.appraisalInfo
                                  .isOwnerOfRecordSameAsSellerReflectedOnSalesContract
                                  , "mode", "NA")},function($event){_vm.isComplete = false;
                                _vm.handleChange(
                                  _vm.appraisalInfo
                                    .isOwnerOfRecordSameAsSellerReflectedOnSalesContract
                                    .mode,
                                  _vm.appraisalInfoData
                                    .isOwnerOfRecordSameAsSellerReflectedOnSalesContract
                                    .mode,
                                  'Is the Owner of Record the same as the Seller reflected on the Sales Contract?'
                                );
                                _vm.saveAppraisalReceivedTask();}],"click":function($event){_vm.appraisalInfo.isOwnerOfRecordSameAsSellerReflectedOnSalesContract.revisionRequestedOn =
                                  null;
                                _vm.appraisalInfo.isOwnerOfRecordSameAsSellerReflectedOnSalesContract.revisionCompletedOn =
                                  null;
                                _vm.appraisalInfo.isOwnerOfRecordSameAsSellerReflectedOnSalesContract.nARemark =
                                  null;}}}),_c('span',{class:[
                                errors.length > 0
                                  ? 'checkround checkround1'
                                  : 'checkround' ]})])]}}],null,true)})],1),_c('div',{staticClass:"row m-0"},[(
                            _vm.appraisalInfo
                              .isOwnerOfRecordSameAsSellerReflectedOnSalesContract
                              .mode == 'No'
                          )?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('label',{staticClass:"label ml-3",attrs:{"for":"initial"}},[_vm._v("Revision Requested")]),_c('div',{staticClass:"d-flex align-items-center ml-3"},[_c('datepicker',{class:[
                                  errors.length > 0 ? 'mx-input-error' : '' ],attrs:{"disabled":_vm.appraisalInfo.isCompleted,"valueType":"format"},on:{"change":function($event){_vm.isComplete = false;
                                  _vm.handleChange(
                                    _vm.appraisalInfo
                                      .isOwnerOfRecordSameAsSellerReflectedOnSalesContract
                                      .revisionRequestedOn,
                                    _vm.appraisalInfoData
                                      .isOwnerOfRecordSameAsSellerReflectedOnSalesContract
                                      .revisionRequestedOn,
                                    'Is the Owner of Record the same as the Seller reflected on the Sales Contract? Revision Requested'
                                  );
                                  _vm.saveAppraisalReceivedTask();}},model:{value:(
                                  _vm.appraisalInfo
                                    .isOwnerOfRecordSameAsSellerReflectedOnSalesContract
                                    .revisionRequestedOn
                                ),callback:function ($$v) {_vm.$set(_vm.appraisalInfo
                                    .isOwnerOfRecordSameAsSellerReflectedOnSalesContract
                                    , "revisionRequestedOn", $$v)},expression:"\n                                  appraisalInfo\n                                    .isOwnerOfRecordSameAsSellerReflectedOnSalesContract\n                                    .revisionRequestedOn\n                                "}})],1)]}}],null,true)})],1):_vm._e(),(
                            _vm.appraisalInfo
                              .isOwnerOfRecordSameAsSellerReflectedOnSalesContract
                              .mode == 'No'
                          )?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('label',{staticClass:"label",attrs:{"for":"initial"}},[_vm._v("Revision Completed")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('datepicker',{class:[
                                  errors.length > 0 ? 'mx-input-error' : '' ],attrs:{"disabled-date":function (date) { return date <=
                                    _vm.$moment(
                                      new Date(
                                        _vm.appraisalInfo.isOwnerOfRecordSameAsSellerReflectedOnSalesContract.revisionRequestedOn
                                      ).setDate(
                                        new Date(
                                          _vm.appraisalInfo.isOwnerOfRecordSameAsSellerReflectedOnSalesContract.revisionRequestedOn
                                        ).getDate() - 1
                                      )
                                    ); },"disabled":_vm.appraisalInfo.isCompleted,"valueType":"format"},on:{"change":function($event){_vm.isComplete = false;
                                  _vm.handleChange(
                                    _vm.appraisalInfo
                                      .isOwnerOfRecordSameAsSellerReflectedOnSalesContract
                                      .revisionCompletedOn,
                                    _vm.appraisalInfoData
                                      .isOwnerOfRecordSameAsSellerReflectedOnSalesContract
                                      .revisionCompletedOn,
                                    'Is the Owner of Record the same as the Seller reflected on the Sales Contract? Revision Completed'
                                  );
                                  _vm.saveAppraisalReceivedTask();}},model:{value:(
                                  _vm.appraisalInfo
                                    .isOwnerOfRecordSameAsSellerReflectedOnSalesContract
                                    .revisionCompletedOn
                                ),callback:function ($$v) {_vm.$set(_vm.appraisalInfo
                                    .isOwnerOfRecordSameAsSellerReflectedOnSalesContract
                                    , "revisionCompletedOn", $$v)},expression:"\n                                  appraisalInfo\n                                    .isOwnerOfRecordSameAsSellerReflectedOnSalesContract\n                                    .revisionCompletedOn\n                                "}})],1)]}}],null,true)})],1):_vm._e()]),(
                          _vm.appraisalInfo
                            .isOwnerOfRecordSameAsSellerReflectedOnSalesContract
                            .mode == 'NA'
                        )?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 p-0"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label ml-3",attrs:{"for":"creditorName"}},[_vm._v("Add remarks")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                              _vm.appraisalInfo
                                .isOwnerOfRecordSameAsSellerReflectedOnSalesContract
                                .nARemark
                            ),expression:"\n                              appraisalInfo\n                                .isOwnerOfRecordSameAsSellerReflectedOnSalesContract\n                                .nARemark\n                            "}],staticClass:"login-input line_height_40 ml-3",attrs:{"type":"text","disabled":_vm.appraisalInfo.isCompleted,"name":"Add remarks"},domProps:{"value":(
                              _vm.appraisalInfo
                                .isOwnerOfRecordSameAsSellerReflectedOnSalesContract
                                .nARemark
                            )},on:{"change":function($event){_vm.isComplete = false;
                              _vm.handleChange(
                                _vm.appraisalInfo
                                  .isOwnerOfRecordSameAsSellerReflectedOnSalesContract
                                  .nARemark,
                                _vm.appraisalInfoData
                                  .isOwnerOfRecordSameAsSellerReflectedOnSalesContract
                                  .nARemark,
                                'Is the Owner of Record the same as the Seller reflected on the Sales Contract? Remarks'
                              );
                              _vm.saveAppraisalReceivedTask();},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.appraisalInfo
                                .isOwnerOfRecordSameAsSellerReflectedOnSalesContract
                                , "nARemark", $event.target.value)}}})])]):_vm._e()]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 p-0"},[_c('p',{staticClass:"content-text-black mt-1 mb-1"},[_c('span',[_vm._v("6.")]),_vm._v(" Is the Lender name accurately reflected? ")]),_c('div',{staticClass:"mb-2"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0 ml-3"},[_c('span',{staticClass:"radio-text"},[_vm._v("Yes")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.appraisalInfo.isLenderNameAccuratelyReflected
                                  .mode
                              ),expression:"\n                                appraisalInfo.isLenderNameAccuratelyReflected\n                                  .mode\n                              "}],attrs:{"type":"radio","value":"Yes","disabled":_vm.appraisalInfo.isCompleted},domProps:{"checked":_vm._q(
                                _vm.appraisalInfo.isLenderNameAccuratelyReflected
                                  .mode
                              ,"Yes")},on:{"change":[function($event){return _vm.$set(_vm.appraisalInfo.isLenderNameAccuratelyReflected
                                  , "mode", "Yes")},function($event){_vm.isComplete = false;
                                _vm.handleChange(
                                  _vm.appraisalInfo.isLenderNameAccuratelyReflected
                                    .mode,
                                  _vm.appraisalInfoData
                                    .isLenderNameAccuratelyReflected.mode,
                                  'Is the Lender name accurately reflected?'
                                );
                                _vm.saveAppraisalReceivedTask();}],"click":function($event){_vm.appraisalInfo.isLenderNameAccuratelyReflected.revisionRequestedOn =
                                  null;
                                _vm.appraisalInfo.isLenderNameAccuratelyReflected.revisionCompletedOn =
                                  null;
                                _vm.appraisalInfo.isLenderNameAccuratelyReflected.nARemark =
                                  null;}}}),_c('span',{class:[
                                errors.length > 0
                                  ? 'checkround checkround1'
                                  : 'checkround' ]})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("No")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.appraisalInfo.isLenderNameAccuratelyReflected
                                  .mode
                              ),expression:"\n                                appraisalInfo.isLenderNameAccuratelyReflected\n                                  .mode\n                              "}],attrs:{"type":"radio","value":"No","disabled":_vm.appraisalInfo.isCompleted},domProps:{"checked":_vm._q(
                                _vm.appraisalInfo.isLenderNameAccuratelyReflected
                                  .mode
                              ,"No")},on:{"change":[function($event){return _vm.$set(_vm.appraisalInfo.isLenderNameAccuratelyReflected
                                  , "mode", "No")},function($event){_vm.isComplete = false;
                                _vm.handleChange(
                                  _vm.appraisalInfo.isLenderNameAccuratelyReflected
                                    .mode,
                                  _vm.appraisalInfoData
                                    .isLenderNameAccuratelyReflected.mode,
                                  'Is the Lender name accurately reflected?'
                                );
                                _vm.saveAppraisalReceivedTask();}],"click":function($event){_vm.appraisalInfo.isLenderNameAccuratelyReflected.revisionRequestedOn =
                                  null;
                                _vm.appraisalInfo.isLenderNameAccuratelyReflected.revisionCompletedOn =
                                  null;
                                _vm.appraisalInfo.isLenderNameAccuratelyReflected.nARemark =
                                  null;}}}),_c('span',{class:[
                                errors.length > 0
                                  ? 'checkround checkround1'
                                  : 'checkround' ]})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("NA")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.appraisalInfo.isLenderNameAccuratelyReflected
                                  .mode
                              ),expression:"\n                                appraisalInfo.isLenderNameAccuratelyReflected\n                                  .mode\n                              "}],attrs:{"type":"radio","value":"NA","disabled":_vm.appraisalInfo.isCompleted},domProps:{"checked":_vm._q(
                                _vm.appraisalInfo.isLenderNameAccuratelyReflected
                                  .mode
                              ,"NA")},on:{"change":[function($event){return _vm.$set(_vm.appraisalInfo.isLenderNameAccuratelyReflected
                                  , "mode", "NA")},function($event){_vm.isComplete = false;
                                _vm.handleChange(
                                  _vm.appraisalInfo.isLenderNameAccuratelyReflected
                                    .mode,
                                  _vm.appraisalInfoData
                                    .isLenderNameAccuratelyReflected.mode,
                                  'Is the Lender name accurately reflected?'
                                );
                                _vm.saveAppraisalReceivedTask();}],"click":function($event){_vm.appraisalInfo.isLenderNameAccuratelyReflected.revisionRequestedOn =
                                  null;
                                _vm.appraisalInfo.isLenderNameAccuratelyReflected.revisionCompletedOn =
                                  null;
                                _vm.appraisalInfo.isLenderNameAccuratelyReflected.nARemark =
                                  null;}}}),_c('span',{class:[
                                errors.length > 0
                                  ? 'checkround checkround1'
                                  : 'checkround' ]})])]}}],null,true)})],1),_c('div',{staticClass:"row m-0"},[(
                            _vm.appraisalInfo.isLenderNameAccuratelyReflected
                              .mode == 'No'
                          )?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('label',{staticClass:"label ml-3",attrs:{"for":"initial"}},[_vm._v("Revision Requested")]),_c('div',{staticClass:"d-flex align-items-center ml-3"},[_c('datepicker',{class:[
                                  errors.length > 0 ? 'mx-input-error' : '' ],attrs:{"disabled":_vm.appraisalInfo.isCompleted,"valueType":"format"},on:{"change":function($event){_vm.isComplete = false;
                                  _vm.handleChange(
                                    _vm.appraisalInfo
                                      .isLenderNameAccuratelyReflected
                                      .revisionRequestedOn,
                                    _vm.appraisalInfoData
                                      .isLenderNameAccuratelyReflected
                                      .revisionRequestedOn,
                                    'Is the Lender name accurately reflected? Revision Requested'
                                  );
                                  _vm.saveAppraisalReceivedTask();}},model:{value:(
                                  _vm.appraisalInfo.isLenderNameAccuratelyReflected
                                    .revisionRequestedOn
                                ),callback:function ($$v) {_vm.$set(_vm.appraisalInfo.isLenderNameAccuratelyReflected
                                    , "revisionRequestedOn", $$v)},expression:"\n                                  appraisalInfo.isLenderNameAccuratelyReflected\n                                    .revisionRequestedOn\n                                "}})],1)]}}],null,true)})],1):_vm._e(),(
                            _vm.appraisalInfo.isLenderNameAccuratelyReflected
                              .mode == 'No'
                          )?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('label',{staticClass:"label",attrs:{"for":"initial"}},[_vm._v("Revision Completed")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('datepicker',{class:[
                                  errors.length > 0 ? 'mx-input-error' : '' ],attrs:{"disabled-date":function (date) { return date <=
                                    _vm.$moment(
                                      new Date(
                                        _vm.appraisalInfo.isLenderNameAccuratelyReflected.revisionRequestedOn
                                      ).setDate(
                                        new Date(
                                          _vm.appraisalInfo.isLenderNameAccuratelyReflected.revisionRequestedOn
                                        ).getDate() - 1
                                      )
                                    ); },"disabled":_vm.appraisalInfo.isCompleted,"valueType":"format"},on:{"change":function($event){_vm.isComplete = false;
                                  _vm.handleChange(
                                    _vm.appraisalInfo
                                      .isLenderNameAccuratelyReflected
                                      .revisionCompletedOn,
                                    _vm.appraisalInfoData
                                      .isLenderNameAccuratelyReflected
                                      .revisionCompletedOn,
                                    'Is the Lender name accurately reflected? Revision Completed'
                                  );
                                  _vm.saveAppraisalReceivedTask();}},model:{value:(
                                  _vm.appraisalInfo.isLenderNameAccuratelyReflected
                                    .revisionCompletedOn
                                ),callback:function ($$v) {_vm.$set(_vm.appraisalInfo.isLenderNameAccuratelyReflected
                                    , "revisionCompletedOn", $$v)},expression:"\n                                  appraisalInfo.isLenderNameAccuratelyReflected\n                                    .revisionCompletedOn\n                                "}})],1)]}}],null,true)})],1):_vm._e()]),(
                          _vm.appraisalInfo.isLenderNameAccuratelyReflected.mode ==
                          'NA'
                        )?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 p-0"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label ml-3",attrs:{"for":"creditorName"}},[_vm._v("Add remarks")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                              _vm.appraisalInfo.isLenderNameAccuratelyReflected
                                .nARemark
                            ),expression:"\n                              appraisalInfo.isLenderNameAccuratelyReflected\n                                .nARemark\n                            "}],staticClass:"login-input line_height_40 ml-3",attrs:{"type":"text","disabled":_vm.appraisalInfo.isCompleted,"name":"Add remarks"},domProps:{"value":(
                              _vm.appraisalInfo.isLenderNameAccuratelyReflected
                                .nARemark
                            )},on:{"change":function($event){_vm.isComplete = false;
                              _vm.handleChange(
                                _vm.appraisalInfo.isLenderNameAccuratelyReflected
                                  .nARemark,
                                _vm.appraisalInfoData
                                  .isLenderNameAccuratelyReflected.nARemark,
                                'Is the Lender name accurately reflected? Remarks'
                              );
                              _vm.saveAppraisalReceivedTask();},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.appraisalInfo.isLenderNameAccuratelyReflected
                                , "nARemark", $event.target.value)}}})])]):_vm._e()]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 p-0"},[_c('p',{staticClass:"content-text-black mt-1 mb-1"},[_c('span',[_vm._v("7.")]),_vm._v(" Monthly HOA Payment updated on the 1003? ")]),_c('div',{staticClass:"mb-2"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0 ml-3"},[_c('span',{staticClass:"radio-text"},[_vm._v("Yes")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.appraisalInfo.isMonthlyHOAPaymentUpdatedOn1003
                                  .mode
                              ),expression:"\n                                appraisalInfo.isMonthlyHOAPaymentUpdatedOn1003\n                                  .mode\n                              "}],attrs:{"type":"radio","value":"Yes","disabled":_vm.appraisalInfo.isCompleted},domProps:{"checked":_vm._q(
                                _vm.appraisalInfo.isMonthlyHOAPaymentUpdatedOn1003
                                  .mode
                              ,"Yes")},on:{"change":[function($event){return _vm.$set(_vm.appraisalInfo.isMonthlyHOAPaymentUpdatedOn1003
                                  , "mode", "Yes")},function($event){_vm.isComplete = false;
                                _vm.handleChange(
                                  _vm.appraisalInfo.isMonthlyHOAPaymentUpdatedOn1003
                                    .mode,
                                  _vm.appraisalInfoData
                                    .isMonthlyHOAPaymentUpdatedOn1003.mode,
                                  'Monthly HOA Payment updated on the 1003?'
                                );
                                _vm.saveAppraisalReceivedTask();}],"click":function($event){_vm.appraisalInfo.isMonthlyHOAPaymentUpdatedOn1003.revisionRequestedOn =
                                  null;
                                _vm.appraisalInfo.isMonthlyHOAPaymentUpdatedOn1003.revisionCompletedOn =
                                  null;
                                _vm.appraisalInfo.isMonthlyHOAPaymentUpdatedOn1003.nARemark =
                                  null;}}}),_c('span',{class:[
                                errors.length > 0
                                  ? 'checkround checkround1'
                                  : 'checkround' ]})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("No")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.appraisalInfo.isMonthlyHOAPaymentUpdatedOn1003
                                  .mode
                              ),expression:"\n                                appraisalInfo.isMonthlyHOAPaymentUpdatedOn1003\n                                  .mode\n                              "}],attrs:{"type":"radio","value":"No","disabled":_vm.appraisalInfo.isCompleted},domProps:{"checked":_vm._q(
                                _vm.appraisalInfo.isMonthlyHOAPaymentUpdatedOn1003
                                  .mode
                              ,"No")},on:{"change":[function($event){return _vm.$set(_vm.appraisalInfo.isMonthlyHOAPaymentUpdatedOn1003
                                  , "mode", "No")},function($event){_vm.isComplete = false;
                                _vm.handleChange(
                                  _vm.appraisalInfo.isMonthlyHOAPaymentUpdatedOn1003
                                    .mode,
                                  _vm.appraisalInfoData
                                    .isMonthlyHOAPaymentUpdatedOn1003.mode,
                                  'Monthly HOA Payment updated on the 1003?'
                                );
                                _vm.saveAppraisalReceivedTask();}],"click":function($event){_vm.appraisalInfo.isMonthlyHOAPaymentUpdatedOn1003.revisionRequestedOn =
                                  null;
                                _vm.appraisalInfo.isMonthlyHOAPaymentUpdatedOn1003.revisionCompletedOn =
                                  null;
                                _vm.appraisalInfo.isMonthlyHOAPaymentUpdatedOn1003.nARemark =
                                  null;}}}),_c('span',{class:[
                                errors.length > 0
                                  ? 'checkround checkround1'
                                  : 'checkround' ]})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("NA")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.appraisalInfo.isMonthlyHOAPaymentUpdatedOn1003
                                  .mode
                              ),expression:"\n                                appraisalInfo.isMonthlyHOAPaymentUpdatedOn1003\n                                  .mode\n                              "}],attrs:{"type":"radio","value":"NA","disabled":_vm.appraisalInfo.isCompleted},domProps:{"checked":_vm._q(
                                _vm.appraisalInfo.isMonthlyHOAPaymentUpdatedOn1003
                                  .mode
                              ,"NA")},on:{"change":[function($event){return _vm.$set(_vm.appraisalInfo.isMonthlyHOAPaymentUpdatedOn1003
                                  , "mode", "NA")},function($event){_vm.isComplete = false;
                                _vm.handleChange(
                                  _vm.appraisalInfo.isMonthlyHOAPaymentUpdatedOn1003
                                    .mode,
                                  _vm.appraisalInfoData
                                    .isMonthlyHOAPaymentUpdatedOn1003.mode,
                                  'Monthly HOA Payment updated on the 1003?'
                                );
                                _vm.saveAppraisalReceivedTask();}],"click":function($event){_vm.appraisalInfo.isMonthlyHOAPaymentUpdatedOn1003.revisionRequestedOn =
                                  null;
                                _vm.appraisalInfo.isMonthlyHOAPaymentUpdatedOn1003.revisionCompletedOn =
                                  null;
                                _vm.appraisalInfo.isMonthlyHOAPaymentUpdatedOn1003.nARemark =
                                  null;}}}),_c('span',{class:[
                                errors.length > 0
                                  ? 'checkround checkround1'
                                  : 'checkround' ]})])]}}],null,true)})],1),(
                          _vm.appraisalInfo.isMonthlyHOAPaymentUpdatedOn1003.mode ==
                          'NA'
                        )?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 p-0"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label ml-3",attrs:{"for":"creditorName"}},[_vm._v("Add remarks")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                              _vm.appraisalInfo.isMonthlyHOAPaymentUpdatedOn1003
                                .nARemark
                            ),expression:"\n                              appraisalInfo.isMonthlyHOAPaymentUpdatedOn1003\n                                .nARemark\n                            "}],staticClass:"login-input line_height_40 ml-3",attrs:{"type":"text","disabled":_vm.appraisalInfo.isCompleted,"name":"Add remarks"},domProps:{"value":(
                              _vm.appraisalInfo.isMonthlyHOAPaymentUpdatedOn1003
                                .nARemark
                            )},on:{"change":function($event){_vm.isComplete = false;
                              _vm.handleChange(
                                _vm.appraisalInfo.isMonthlyHOAPaymentUpdatedOn1003
                                  .nARemark,
                                _vm.appraisalInfoData
                                  .isMonthlyHOAPaymentUpdatedOn1003.nARemark,
                                'Monthly HOA Payment updated on the 1003? Remarks'
                              );
                              _vm.saveAppraisalReceivedTask();},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.appraisalInfo.isMonthlyHOAPaymentUpdatedOn1003
                                , "nARemark", $event.target.value)}}})])]):_vm._e()]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 p-0"},[_c('p',{staticClass:"content-text-black mt-1 mb-1"},[_c('span',[_vm._v("8.")]),_vm._v(" Is the property located in a Flood Zone? ")]),_c('div',{staticClass:"mb-2"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0 ml-3"},[_c('span',{staticClass:"radio-text"},[_vm._v("Yes")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.appraisalInfo.isPropertyLocatedInFloodZone.mode
                              ),expression:"\n                                appraisalInfo.isPropertyLocatedInFloodZone.mode\n                              "}],attrs:{"type":"radio","value":"Yes","disabled":_vm.appraisalInfo.isCompleted},domProps:{"checked":_vm._q(
                                _vm.appraisalInfo.isPropertyLocatedInFloodZone.mode
                              ,"Yes")},on:{"change":[function($event){return _vm.$set(_vm.appraisalInfo.isPropertyLocatedInFloodZone, "mode", "Yes")},function($event){_vm.isComplete = false;
                                _vm.handleChange(
                                  _vm.appraisalInfo.isPropertyLocatedInFloodZone
                                    .mode,
                                  _vm.appraisalInfoData.isPropertyLocatedInFloodZone
                                    .mode,
                                  'Is the property located in a Flood Zone?'
                                );
                                _vm.saveAppraisalReceivedTask();}],"click":function($event){_vm.appraisalInfo.isPropertyLocatedInFloodZone.revisionRequestedOn =
                                  null;
                                _vm.appraisalInfo.isPropertyLocatedInFloodZone.revisionCompletedOn =
                                  null;
                                _vm.appraisalInfo.isPropertyLocatedInFloodZone.nARemark =
                                  null;}}}),_c('span',{class:[
                                errors.length > 0
                                  ? 'checkround checkround1'
                                  : 'checkround' ]})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("No")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.appraisalInfo.isPropertyLocatedInFloodZone.mode
                              ),expression:"\n                                appraisalInfo.isPropertyLocatedInFloodZone.mode\n                              "}],attrs:{"type":"radio","value":"No","disabled":_vm.appraisalInfo.isCompleted},domProps:{"checked":_vm._q(
                                _vm.appraisalInfo.isPropertyLocatedInFloodZone.mode
                              ,"No")},on:{"change":[function($event){return _vm.$set(_vm.appraisalInfo.isPropertyLocatedInFloodZone, "mode", "No")},function($event){_vm.isComplete = false;
                                _vm.handleChange(
                                  _vm.appraisalInfo.isPropertyLocatedInFloodZone
                                    .mode,
                                  _vm.appraisalInfoData.isPropertyLocatedInFloodZone
                                    .mode,
                                  'Is the property located in a Flood Zone?'
                                );
                                _vm.saveAppraisalReceivedTask();}],"click":function($event){_vm.appraisalInfo.isPropertyLocatedInFloodZone.revisionRequestedOn =
                                  null;
                                _vm.appraisalInfo.isPropertyLocatedInFloodZone.revisionCompletedOn =
                                  null;
                                _vm.appraisalInfo.isPropertyLocatedInFloodZone.nARemark =
                                  null;}}}),_c('span',{class:[
                                errors.length > 0
                                  ? 'checkround checkround1'
                                  : 'checkround' ]})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("NA")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.appraisalInfo.isPropertyLocatedInFloodZone.mode
                              ),expression:"\n                                appraisalInfo.isPropertyLocatedInFloodZone.mode\n                              "}],attrs:{"type":"radio","value":"NA","disabled":_vm.appraisalInfo.isCompleted},domProps:{"checked":_vm._q(
                                _vm.appraisalInfo.isPropertyLocatedInFloodZone.mode
                              ,"NA")},on:{"change":[function($event){return _vm.$set(_vm.appraisalInfo.isPropertyLocatedInFloodZone, "mode", "NA")},function($event){_vm.isComplete = false;
                                _vm.handleChange(
                                  _vm.appraisalInfo.isPropertyLocatedInFloodZone
                                    .mode,
                                  _vm.appraisalInfoData.isPropertyLocatedInFloodZone
                                    .mode,
                                  'Is the property located in a Flood Zone?'
                                );
                                _vm.saveAppraisalReceivedTask();}],"click":function($event){_vm.appraisalInfo.isPropertyLocatedInFloodZone.revisionRequestedOn =
                                  null;
                                _vm.appraisalInfo.isPropertyLocatedInFloodZone.revisionCompletedOn =
                                  null;
                                _vm.appraisalInfo.isPropertyLocatedInFloodZone.nARemark =
                                  null;}}}),_c('span',{class:[
                                errors.length > 0
                                  ? 'checkround checkround1'
                                  : 'checkround' ]})])]}}],null,true)})],1),(
                          _vm.appraisalInfo.isPropertyLocatedInFloodZone.mode ==
                          'NA'
                        )?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 p-0"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label ml-3",attrs:{"for":"creditorName"}},[_vm._v("Add remarks")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                              _vm.appraisalInfo.isPropertyLocatedInFloodZone
                                .nARemark
                            ),expression:"\n                              appraisalInfo.isPropertyLocatedInFloodZone\n                                .nARemark\n                            "}],staticClass:"login-input line_height_40 ml-3",attrs:{"type":"text","disabled":_vm.appraisalInfo.isCompleted,"name":"Add remarks"},domProps:{"value":(
                              _vm.appraisalInfo.isPropertyLocatedInFloodZone
                                .nARemark
                            )},on:{"change":function($event){_vm.isComplete = false;
                              _vm.handleChange(
                                _vm.appraisalInfo.isPropertyLocatedInFloodZone
                                  .nARemark,
                                _vm.appraisalInfoData.isPropertyLocatedInFloodZone
                                  .nARemark,
                                'Is the property located in a Flood Zone? Remarks'
                              );
                              _vm.saveAppraisalReceivedTask();},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.appraisalInfo.isPropertyLocatedInFloodZone
                                , "nARemark", $event.target.value)}}})])]):_vm._e()]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 p-0"},[_c('p',{staticClass:"content-text-black mt-1 mb-1"},[_c('span',[_vm._v("9.")]),_vm._v(" Is the property a Manufactured Home? ")]),_c('div',{staticClass:"mb-2"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0 ml-3"},[_c('span',{staticClass:"radio-text"},[_vm._v("Yes")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.appraisalInfo.isPropertyManufacturedHome.mode
                              ),expression:"\n                                appraisalInfo.isPropertyManufacturedHome.mode\n                              "}],attrs:{"type":"radio","value":"Yes","disabled":_vm.appraisalInfo.isCompleted},domProps:{"checked":_vm._q(
                                _vm.appraisalInfo.isPropertyManufacturedHome.mode
                              ,"Yes")},on:{"change":[function($event){return _vm.$set(_vm.appraisalInfo.isPropertyManufacturedHome, "mode", "Yes")},function($event){_vm.isComplete = false;
                                _vm.handleChange(
                                  _vm.appraisalInfo.isPropertyManufacturedHome.mode,
                                  _vm.appraisalInfoData.isPropertyManufacturedHome
                                    .mode,
                                  'Is the property a Manufactured Home?'
                                );
                                _vm.saveAppraisalReceivedTask();}],"click":function($event){_vm.appraisalInfo.isPropertyManufacturedHome.revisionRequestedOn =
                                  null;
                                _vm.appraisalInfo.isPropertyManufacturedHome.revisionCompletedOn =
                                  null;
                                _vm.appraisalInfo.isPropertyManufacturedHome.nARemark =
                                  null;}}}),_c('span',{class:[
                                errors.length > 0
                                  ? 'checkround checkround1'
                                  : 'checkround' ]})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("No")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.appraisalInfo.isPropertyManufacturedHome.mode
                              ),expression:"\n                                appraisalInfo.isPropertyManufacturedHome.mode\n                              "}],attrs:{"type":"radio","value":"No","disabled":_vm.appraisalInfo.isCompleted},domProps:{"checked":_vm._q(
                                _vm.appraisalInfo.isPropertyManufacturedHome.mode
                              ,"No")},on:{"change":[function($event){return _vm.$set(_vm.appraisalInfo.isPropertyManufacturedHome, "mode", "No")},function($event){return _vm.$modal.show('manufactured-status-modal')}],"click":function($event){_vm.appraisalInfo.isPropertyManufacturedHome.revisionRequestedOn =
                                  null;
                                _vm.appraisalInfo.isPropertyManufacturedHome.revisionCompletedOn =
                                  null;
                                _vm.appraisalInfo.isPropertyManufacturedHome.nARemark =
                                  null;}}}),_c('span',{class:[
                                errors.length > 0
                                  ? 'checkround checkround1'
                                  : 'checkround' ]})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("NA")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.appraisalInfo.isPropertyManufacturedHome.mode
                              ),expression:"\n                                appraisalInfo.isPropertyManufacturedHome.mode\n                              "}],attrs:{"type":"radio","value":"NA","disabled":_vm.appraisalInfo.isCompleted},domProps:{"checked":_vm._q(
                                _vm.appraisalInfo.isPropertyManufacturedHome.mode
                              ,"NA")},on:{"change":[function($event){return _vm.$set(_vm.appraisalInfo.isPropertyManufacturedHome, "mode", "NA")},function($event){_vm.isComplete = false;
                                _vm.handleChange(
                                  _vm.appraisalInfo.isPropertyManufacturedHome.mode,
                                  _vm.appraisalInfoData.isPropertyManufacturedHome
                                    .mode,
                                  'Is the property a Manufactured Home?'
                                );
                                _vm.saveAppraisalReceivedTask();}],"click":function($event){_vm.appraisalInfo.isPropertyManufacturedHome.revisionRequestedOn =
                                  null;
                                _vm.appraisalInfo.isPropertyManufacturedHome.revisionCompletedOn =
                                  null;
                                _vm.appraisalInfo.isPropertyManufacturedHome.nARemark =
                                  null;}}}),_c('span',{class:[
                                errors.length > 0
                                  ? 'checkround checkround1'
                                  : 'checkround' ]})])]}}],null,true)})],1),(
                          _vm.appraisalInfo.isPropertyManufacturedHome.mode == 'Yes'
                        )?_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"\n                              custom-checkbox\n                              d-flex\n                              align-items-center\n                              mb-1\n                              mt-1\n                              mr-2\n                              ml-3\n                            "},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.appraisalInfo.isPropertyManufacturedHome
                                  .isIbtsRequired
                              ),expression:"\n                                appraisalInfo.isPropertyManufacturedHome\n                                  .isIbtsRequired\n                              "}],staticClass:"custom-checkbox-input",attrs:{"type":"checkbox","id":"a","disabled":""},domProps:{"checked":Array.isArray(
                                _vm.appraisalInfo.isPropertyManufacturedHome
                                  .isIbtsRequired
                              )?_vm._i(
                                _vm.appraisalInfo.isPropertyManufacturedHome
                                  .isIbtsRequired
                              ,null)>-1:(
                                _vm.appraisalInfo.isPropertyManufacturedHome
                                  .isIbtsRequired
                              )},on:{"change":[function($event){var $$a=
                                _vm.appraisalInfo.isPropertyManufacturedHome
                                  .isIbtsRequired
                              ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.appraisalInfo.isPropertyManufacturedHome
                                  , "isIbtsRequired", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.appraisalInfo.isPropertyManufacturedHome
                                  , "isIbtsRequired", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.appraisalInfo.isPropertyManufacturedHome
                                  , "isIbtsRequired", $$c)}},function($event){_vm.error = false}]}}),_c('label',{staticClass:"label",attrs:{"for":"a"}},[_vm._v("IBTS Required")]),_c('div',{staticClass:"indicator"})]),_c('div',{staticClass:"\n                              custom-checkbox\n                              d-flex\n                              align-items-center\n                              mb-1\n                              mt-1\n                            "},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.appraisalInfo.isPropertyManufacturedHome
                                  .isEngineerRequired
                              ),expression:"\n                                appraisalInfo.isPropertyManufacturedHome\n                                  .isEngineerRequired\n                              "}],staticClass:"custom-checkbox-input",attrs:{"type":"checkbox","id":"b","disabled":""},domProps:{"checked":Array.isArray(
                                _vm.appraisalInfo.isPropertyManufacturedHome
                                  .isEngineerRequired
                              )?_vm._i(
                                _vm.appraisalInfo.isPropertyManufacturedHome
                                  .isEngineerRequired
                              ,null)>-1:(
                                _vm.appraisalInfo.isPropertyManufacturedHome
                                  .isEngineerRequired
                              )},on:{"change":[function($event){var $$a=
                                _vm.appraisalInfo.isPropertyManufacturedHome
                                  .isEngineerRequired
                              ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.appraisalInfo.isPropertyManufacturedHome
                                  , "isEngineerRequired", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.appraisalInfo.isPropertyManufacturedHome
                                  , "isEngineerRequired", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.appraisalInfo.isPropertyManufacturedHome
                                  , "isEngineerRequired", $$c)}},function($event){_vm.error = false}]}}),_c('label',{staticClass:"label",attrs:{"for":"ab"}},[_vm._v("Engineers Certification Required")]),_c('div',{staticClass:"indicator"})])]),_c('div',{staticClass:"mt-1"},[_c('button',{staticClass:"tmpco-btn tmpco-btn__default mr-2 ml-3",class:[
                              _vm.appraisalInfo.isCompleted
                                ? 'btn-upload cursor-pointer cursor-not-allow'
                                : 'btn-upload cursor-pointer' ],attrs:{"disabled":_vm.appraisalInfo.isCompleted,"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.openManufacturedHomeModal(
                                _vm.appraisalInfo.isPropertyManufacturedHome
                              )}}},[_vm._v(" Update ")]),_c('button',{staticClass:"tmpco-btn tmpco-btn__default",class:[
                              _vm.appraisalInfo.isCompleted
                                ? 'btn-upload cursor-pointer cursor-not-allow'
                                : 'btn-upload cursor-pointer' ],attrs:{"disabled":_vm.appraisalInfo.isCompleted,"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.openModal()}}},[_vm._v(" LO Confirmation ")])])]):_vm._e(),(
                          _vm.appraisalInfo.isPropertyManufacturedHome.mode == 'NA'
                        )?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 p-0"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label ml-3",attrs:{"for":"creditorName"}},[_vm._v("Add remarks")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                              _vm.appraisalInfo.isPropertyManufacturedHome.nARemark
                            ),expression:"\n                              appraisalInfo.isPropertyManufacturedHome.nARemark\n                            "}],staticClass:"login-input line_height_40 ml-3",attrs:{"type":"text","disabled":_vm.appraisalInfo.isCompleted,"name":"Add remarks"},domProps:{"value":(
                              _vm.appraisalInfo.isPropertyManufacturedHome.nARemark
                            )},on:{"change":function($event){_vm.isComplete = false;
                              _vm.handleChange(
                                _vm.appraisalInfo.isPropertyManufacturedHome
                                  .nARemark,
                                _vm.appraisalInfoData.isPropertyManufacturedHome
                                  .nARemark,
                                'Is the property a Manufactured Home? Remarks'
                              );
                              _vm.saveAppraisalReceivedTask();},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.appraisalInfo.isPropertyManufacturedHome, "nARemark", $event.target.value)}}})])]):_vm._e()]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 p-0"},[_c('p',{staticClass:"content-text-black mt-1 mb-1"},[_c('span',[_vm._v("10.")]),_vm._v(" Is the FHA or VA Case Number accurately reflected? ")]),_c('div',{staticClass:"mb-2"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0 ml-3"},[_c('span',{staticClass:"radio-text"},[_vm._v("Yes")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.appraisalInfo
                                  .isFHAOrVACaseNumberAccuratelyReflected.mode
                              ),expression:"\n                                appraisalInfo\n                                  .isFHAOrVACaseNumberAccuratelyReflected.mode\n                              "}],attrs:{"type":"radio","value":"Yes","disabled":_vm.appraisalInfo.isCompleted},domProps:{"checked":_vm._q(
                                _vm.appraisalInfo
                                  .isFHAOrVACaseNumberAccuratelyReflected.mode
                              ,"Yes")},on:{"change":[function($event){return _vm.$set(_vm.appraisalInfo
                                  .isFHAOrVACaseNumberAccuratelyReflected, "mode", "Yes")},function($event){_vm.isComplete = false;
                                _vm.handleChange(
                                  _vm.appraisalInfo
                                    .isFHAOrVACaseNumberAccuratelyReflected
                                    .mode,
                                  _vm.appraisalInfoData
                                    .isFHAOrVACaseNumberAccuratelyReflected
                                    .mode,
                                  'Is the FHA or VA Case Number accurately reflected?'
                                );
                                _vm.saveAppraisalReceivedTask();}],"click":function($event){_vm.appraisalInfo.isFHAOrVACaseNumberAccuratelyReflected.revisionRequestedOn =
                                  null;
                                _vm.appraisalInfo.isFHAOrVACaseNumberAccuratelyReflected.revisionCompletedOn =
                                  null;
                                _vm.appraisalInfo.isFHAOrVACaseNumberAccuratelyReflected.nARemark =
                                  null;}}}),_c('span',{class:[
                                errors.length > 0
                                  ? 'checkround checkround1'
                                  : 'checkround' ]})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("No")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.appraisalInfo
                                  .isFHAOrVACaseNumberAccuratelyReflected.mode
                              ),expression:"\n                                appraisalInfo\n                                  .isFHAOrVACaseNumberAccuratelyReflected.mode\n                              "}],attrs:{"type":"radio","value":"No","disabled":_vm.appraisalInfo.isCompleted},domProps:{"checked":_vm._q(
                                _vm.appraisalInfo
                                  .isFHAOrVACaseNumberAccuratelyReflected.mode
                              ,"No")},on:{"change":[function($event){return _vm.$set(_vm.appraisalInfo
                                  .isFHAOrVACaseNumberAccuratelyReflected, "mode", "No")},function($event){_vm.isComplete = false;
                                _vm.handleChange(
                                  _vm.appraisalInfo
                                    .isFHAOrVACaseNumberAccuratelyReflected
                                    .mode,
                                  _vm.appraisalInfoData
                                    .isFHAOrVACaseNumberAccuratelyReflected
                                    .mode,
                                  'Is the FHA or VA Case Number accurately reflected?'
                                );
                                _vm.saveAppraisalReceivedTask();}],"click":function($event){_vm.appraisalInfo.isFHAOrVACaseNumberAccuratelyReflected.revisionRequestedOn =
                                  null;
                                _vm.appraisalInfo.isFHAOrVACaseNumberAccuratelyReflected.revisionCompletedOn =
                                  null;
                                _vm.appraisalInfo.isFHAOrVACaseNumberAccuratelyReflected.nARemark =
                                  null;}}}),_c('span',{class:[
                                errors.length > 0
                                  ? 'checkround checkround1'
                                  : 'checkround' ]})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("NA")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.appraisalInfo
                                  .isFHAOrVACaseNumberAccuratelyReflected.mode
                              ),expression:"\n                                appraisalInfo\n                                  .isFHAOrVACaseNumberAccuratelyReflected.mode\n                              "}],attrs:{"type":"radio","value":"NA","disabled":_vm.appraisalInfo.isCompleted},domProps:{"checked":_vm._q(
                                _vm.appraisalInfo
                                  .isFHAOrVACaseNumberAccuratelyReflected.mode
                              ,"NA")},on:{"change":[function($event){return _vm.$set(_vm.appraisalInfo
                                  .isFHAOrVACaseNumberAccuratelyReflected, "mode", "NA")},function($event){_vm.isComplete = false;
                                _vm.handleChange(
                                  _vm.appraisalInfo
                                    .isFHAOrVACaseNumberAccuratelyReflected
                                    .mode,
                                  _vm.appraisalInfoData
                                    .isFHAOrVACaseNumberAccuratelyReflected
                                    .mode,
                                  'Is the FHA or VA Case Number accurately reflected?'
                                );
                                _vm.saveAppraisalReceivedTask();}],"click":function($event){_vm.appraisalInfo.isFHAOrVACaseNumberAccuratelyReflected.revisionRequestedOn =
                                  null;
                                _vm.appraisalInfo.isFHAOrVACaseNumberAccuratelyReflected.revisionCompletedOn =
                                  null;
                                _vm.appraisalInfo.isFHAOrVACaseNumberAccuratelyReflected.nARemark =
                                  null;}}}),_c('span',{class:[
                                errors.length > 0
                                  ? 'checkround checkround1'
                                  : 'checkround' ]})])]}}],null,true)})],1),_c('div',{staticClass:"row m-0"},[(
                            _vm.appraisalInfo.isFHAOrVACaseNumberAccuratelyReflected
                              .mode == 'No'
                          )?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('label',{staticClass:"label ml-3",attrs:{"for":"initial"}},[_vm._v("Revision Requested")]),_c('div',{staticClass:"d-flex align-items-center mb-2 ml-2 ml-3"},[_c('datepicker',{class:[
                                  errors.length > 0 ? 'mx-input-error' : '' ],attrs:{"disabled":_vm.appraisalInfo.isCompleted,"valueType":"format"},on:{"change":function($event){_vm.isComplete = false;
                                  _vm.handleChange(
                                    _vm.appraisalInfo
                                      .isFHAOrVACaseNumberAccuratelyReflected
                                      .revisionRequestedOn,
                                    _vm.appraisalInfoData
                                      .isFHAOrVACaseNumberAccuratelyReflected
                                      .revisionRequestedOn,
                                    'Is the FHA or VA Case Number accurately reflected? Revision Requested'
                                  );
                                  _vm.saveAppraisalReceivedTask();}},model:{value:(
                                  _vm.appraisalInfo
                                    .isFHAOrVACaseNumberAccuratelyReflected
                                    .revisionRequestedOn
                                ),callback:function ($$v) {_vm.$set(_vm.appraisalInfo
                                    .isFHAOrVACaseNumberAccuratelyReflected
                                    , "revisionRequestedOn", $$v)},expression:"\n                                  appraisalInfo\n                                    .isFHAOrVACaseNumberAccuratelyReflected\n                                    .revisionRequestedOn\n                                "}})],1)]}}],null,true)})],1):_vm._e(),(
                            _vm.appraisalInfo.isFHAOrVACaseNumberAccuratelyReflected
                              .mode == 'No'
                          )?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('label',{staticClass:"label",attrs:{"for":"initial"}},[_vm._v("Revision Completed")]),_c('div',{staticClass:"d-flex align-items-center mb-2"},[_c('datepicker',{class:[
                                  errors.length > 0 ? 'mx-input-error' : '' ],attrs:{"disabled-date":function (date) { return date <=
                                    _vm.$moment(
                                      new Date(
                                        _vm.appraisalInfo.isFHAOrVACaseNumberAccuratelyReflected.revisionRequestedOn
                                      ).setDate(
                                        new Date(
                                          _vm.appraisalInfo.isFHAOrVACaseNumberAccuratelyReflected.revisionRequestedOn
                                        ).getDate() - 1
                                      )
                                    ); },"disabled":_vm.appraisalInfo.isCompleted,"valueType":"format"},on:{"change":function($event){_vm.isComplete = false;
                                  _vm.handleChange(
                                    _vm.appraisalInfo
                                      .isFHAOrVACaseNumberAccuratelyReflected
                                      .revisionCompletedOn,
                                    _vm.appraisalInfoData
                                      .isFHAOrVACaseNumberAccuratelyReflected
                                      .revisionCompletedOn,
                                    'Is the FHA or VA Case Number accurately reflected? Revision Completed'
                                  );
                                  _vm.saveAppraisalReceivedTask();}},model:{value:(
                                  _vm.appraisalInfo
                                    .isFHAOrVACaseNumberAccuratelyReflected
                                    .revisionCompletedOn
                                ),callback:function ($$v) {_vm.$set(_vm.appraisalInfo
                                    .isFHAOrVACaseNumberAccuratelyReflected
                                    , "revisionCompletedOn", $$v)},expression:"\n                                  appraisalInfo\n                                    .isFHAOrVACaseNumberAccuratelyReflected\n                                    .revisionCompletedOn\n                                "}})],1)]}}],null,true)})],1):_vm._e()]),(
                          _vm.appraisalInfo.isFHAOrVACaseNumberAccuratelyReflected
                            .mode == 'NA'
                        )?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 p-0"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label ml-3",attrs:{"for":"creditorName"}},[_vm._v("Add remarks")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                              _vm.appraisalInfo
                                .isFHAOrVACaseNumberAccuratelyReflected.nARemark
                            ),expression:"\n                              appraisalInfo\n                                .isFHAOrVACaseNumberAccuratelyReflected.nARemark\n                            "}],staticClass:"login-input line_height_40 ml-3",attrs:{"type":"text","disabled":_vm.appraisalInfo.isCompleted,"name":"Add remarks"},domProps:{"value":(
                              _vm.appraisalInfo
                                .isFHAOrVACaseNumberAccuratelyReflected.nARemark
                            )},on:{"change":function($event){_vm.isComplete = false;
                              _vm.handleChange(
                                _vm.appraisalInfo
                                  .isFHAOrVACaseNumberAccuratelyReflected
                                  .nARemark,
                                _vm.appraisalInfoData
                                  .isFHAOrVACaseNumberAccuratelyReflected
                                  .nARemark,
                                'Is the FHA or VA Case Number accurately reflected? Remarks'
                              );
                              _vm.saveAppraisalReceivedTask();},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.appraisalInfo
                                .isFHAOrVACaseNumberAccuratelyReflected, "nARemark", $event.target.value)}}})])]):_vm._e()]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 p-0"},[_c('p',{staticClass:"content-text-black mt-1 mb-1"},[_c('span',[_vm._v("11.")]),_vm._v(" Is Appraisal completed “As Is”? ")]),_c('div',{staticClass:"mb-2"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0 ml-3"},[_c('span',{staticClass:"radio-text"},[_vm._v("Yes")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.appraisalInfo.isAppraisalCompletedAsIs.mode
                              ),expression:"\n                                appraisalInfo.isAppraisalCompletedAsIs.mode\n                              "}],attrs:{"type":"radio","value":"Yes","disabled":_vm.appraisalInfo.isCompleted},domProps:{"checked":_vm._q(
                                _vm.appraisalInfo.isAppraisalCompletedAsIs.mode
                              ,"Yes")},on:{"change":[function($event){return _vm.$set(_vm.appraisalInfo.isAppraisalCompletedAsIs, "mode", "Yes")},function($event){_vm.isComplete = false;
                                _vm.handleChange(
                                  _vm.appraisalInfo.isAppraisalCompletedAsIs.mode,
                                  _vm.appraisalInfoData.isAppraisalCompletedAsIs
                                    .mode,
                                  'Is Appraisal completed “As Is”?'
                                );
                                _vm.saveAppraisalReceivedTask();}],"click":function($event){_vm.appraisalInfo.isAppraisalCompletedAsIs.nARemark =
                                  null}}}),_c('span',{class:[
                                errors.length > 0
                                  ? 'checkround checkround1'
                                  : 'checkround' ]})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("No")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.appraisalInfo.isAppraisalCompletedAsIs.mode
                              ),expression:"\n                                appraisalInfo.isAppraisalCompletedAsIs.mode\n                              "}],attrs:{"type":"radio","value":"No","disabled":_vm.appraisalInfo.isCompleted},domProps:{"checked":_vm._q(
                                _vm.appraisalInfo.isAppraisalCompletedAsIs.mode
                              ,"No")},on:{"change":[function($event){return _vm.$set(_vm.appraisalInfo.isAppraisalCompletedAsIs, "mode", "No")},function($event){_vm.isComplete = false;
                                _vm.handleChange(
                                  _vm.appraisalInfo.isAppraisalCompletedAsIs.mode,
                                  _vm.appraisalInfoData.isAppraisalCompletedAsIs
                                    .mode,
                                  'Is Appraisal completed “As Is”?'
                                );
                                _vm.saveAppraisalReceivedTask();}],"click":function($event){_vm.appraisalInfo.isAppraisalCompletedAsIs.nARemark =
                                  null}}}),_c('span',{class:[
                                errors.length > 0
                                  ? 'checkround checkround1'
                                  : 'checkround' ]})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("NA")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.appraisalInfo.isAppraisalCompletedAsIs.mode
                              ),expression:"\n                                appraisalInfo.isAppraisalCompletedAsIs.mode\n                              "}],attrs:{"type":"radio","value":"NA","disabled":_vm.appraisalInfo.isCompleted},domProps:{"checked":_vm._q(
                                _vm.appraisalInfo.isAppraisalCompletedAsIs.mode
                              ,"NA")},on:{"change":[function($event){return _vm.$set(_vm.appraisalInfo.isAppraisalCompletedAsIs, "mode", "NA")},function($event){_vm.isComplete = false;
                                _vm.handleChange(
                                  _vm.appraisalInfo.isAppraisalCompletedAsIs.mode,
                                  _vm.appraisalInfoData.isAppraisalCompletedAsIs
                                    .mode,
                                  'Is Appraisal completed “As Is”?'
                                );
                                _vm.saveAppraisalReceivedTask();}],"click":function($event){_vm.appraisalInfo.isAppraisalCompletedAsIs.nARemark =
                                  null}}}),_c('span',{class:[
                                errors.length > 0
                                  ? 'checkround checkround1'
                                  : 'checkround' ]})])]}}],null,true)})],1),(
                          _vm.appraisalInfo.isAppraisalCompletedAsIs.mode == 'No'
                        )?_c('div',{staticClass:"form-group d-flex align-items-center mb-2"},[_c('div',{staticClass:"mb-2"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0 ml-3"},[_c('span',{staticClass:"radio-text"},[_vm._v("Is it “Subject to Repairs”?")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                  _vm.appraisalInfo.isAppraisalCompletedAsIs.choice
                                ),expression:"\n                                  appraisalInfo.isAppraisalCompletedAsIs.choice\n                                "}],attrs:{"type":"radio","disabled":_vm.appraisalInfo.isCompleted,"value":"SubjectToRepair"},domProps:{"checked":_vm._q(
                                  _vm.appraisalInfo.isAppraisalCompletedAsIs.choice
                                ,"SubjectToRepair")},on:{"change":[function($event){return _vm.$set(_vm.appraisalInfo.isAppraisalCompletedAsIs, "choice", "SubjectToRepair")},function($event){_vm.isComplete = false;
                                  _vm.handleChange(
                                    _vm.appraisalInfo.isAppraisalCompletedAsIs
                                      .choice,
                                    _vm.appraisalInfoData.isAppraisalCompletedAsIs
                                      .choice,
                                    'Is Appraisal completed “As Is”? Choice'
                                  );
                                  _vm.saveAppraisalReceivedTask();}]}}),_c('span',{class:[
                                  errors.length > 0
                                    ? 'checkround checkround1'
                                    : 'checkround' ]})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("Is it “Subject to Completion”?")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                  _vm.appraisalInfo.isAppraisalCompletedAsIs.choice
                                ),expression:"\n                                  appraisalInfo.isAppraisalCompletedAsIs.choice\n                                "}],attrs:{"type":"radio","disabled":_vm.appraisalInfo.isCompleted,"value":"SubjectToCompletion"},domProps:{"checked":_vm._q(
                                  _vm.appraisalInfo.isAppraisalCompletedAsIs.choice
                                ,"SubjectToCompletion")},on:{"change":[function($event){return _vm.$set(_vm.appraisalInfo.isAppraisalCompletedAsIs, "choice", "SubjectToCompletion")},function($event){_vm.isComplete = false;
                                  _vm.handleChange(
                                    _vm.appraisalInfo.isAppraisalCompletedAsIs
                                      .choice,
                                    _vm.appraisalInfoData.isAppraisalCompletedAsIs
                                      .choice,
                                    'Is Appraisal completed “As Is”? Choice'
                                  );
                                  _vm.saveAppraisalReceivedTask();}]}}),_c('span',{class:[
                                  errors.length > 0
                                    ? 'checkround checkround1'
                                    : 'checkround' ]})])]}}],null,true)})],1)]):_vm._e(),(
                          _vm.appraisalInfo.isAppraisalCompletedAsIs.mode == 'NA'
                        )?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 p-0"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label ml-3",attrs:{"for":"creditorName"}},[_vm._v("Add remarks")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                              _vm.appraisalInfo.isAppraisalCompletedAsIs.nARemark
                            ),expression:"\n                              appraisalInfo.isAppraisalCompletedAsIs.nARemark\n                            "}],staticClass:"login-input line_height_40 ml-3",attrs:{"type":"text","disabled":_vm.appraisalInfo.isCompleted,"name":"Add remarks"},domProps:{"value":(
                              _vm.appraisalInfo.isAppraisalCompletedAsIs.nARemark
                            )},on:{"change":function($event){_vm.isComplete = false;
                              _vm.handleChange(
                                _vm.appraisalInfo.isAppraisalCompletedAsIs.nARemark,
                                _vm.appraisalInfoData.isAppraisalCompletedAsIs
                                  .nARemark,
                                'Is Appraisal completed “As Is”? Remarks'
                              );
                              _vm.saveAppraisalReceivedTask();},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.appraisalInfo.isAppraisalCompletedAsIs, "nARemark", $event.target.value)}}})])]):_vm._e()]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 p-0"},[_c('p',{staticClass:"content-text-black mt-1 mb-1"},[_c('span',[_vm._v("12.")]),_vm._v(" Is Appraised Value lower than the Sales Price? ")]),_c('div',{staticClass:"mb-2"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0 ml-3"},[_c('span',{staticClass:"radio-text"},[_vm._v("Yes")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.appraisalInfo
                                  .isAppraisedValueLowerThanSalesPrice.mode
                              ),expression:"\n                                appraisalInfo\n                                  .isAppraisedValueLowerThanSalesPrice.mode\n                              "}],staticClass:"checkround cursor-not-allow",attrs:{"type":"radio","value":"Yes","disabled":""},domProps:{"checked":_vm._q(
                                _vm.appraisalInfo
                                  .isAppraisedValueLowerThanSalesPrice.mode
                              ,"Yes")},on:{"change":[function($event){return _vm.$set(_vm.appraisalInfo
                                  .isAppraisedValueLowerThanSalesPrice, "mode", "Yes")},function($event){_vm.isComplete = false;
                                _vm.handleChange(
                                  _vm.appraisalInfo
                                    .isAppraisedValueLowerThanSalesPrice.mode,
                                  _vm.appraisalInfoData
                                    .isAppraisedValueLowerThanSalesPrice.mode,
                                  'Is Appraised Value lower than the Sales Price?'
                                );
                                _vm.saveAppraisalReceivedTask();}],"click":function($event){_vm.appraisalInfo.isAppraisedValueLowerThanSalesPrice.nARemark =
                                  null}}}),_c('span',{class:[
                                errors.length > 0
                                  ? 'checkround checkround1'
                                  : 'checkround' ]})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("No")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.appraisalInfo
                                  .isAppraisedValueLowerThanSalesPrice.mode
                              ),expression:"\n                                appraisalInfo\n                                  .isAppraisedValueLowerThanSalesPrice.mode\n                              "}],staticClass:"checkround cursor-not-allow",attrs:{"type":"radio","value":"No","disabled":""},domProps:{"checked":_vm._q(
                                _vm.appraisalInfo
                                  .isAppraisedValueLowerThanSalesPrice.mode
                              ,"No")},on:{"change":[function($event){return _vm.$set(_vm.appraisalInfo
                                  .isAppraisedValueLowerThanSalesPrice, "mode", "No")},function($event){_vm.isComplete = false;
                                _vm.handleChange(
                                  _vm.appraisalInfo
                                    .isAppraisedValueLowerThanSalesPrice.mode,
                                  _vm.appraisalInfoData
                                    .isAppraisedValueLowerThanSalesPrice.mode,
                                  'Is Appraised Value lower than the Sales Price?'
                                );
                                _vm.saveAppraisalReceivedTask();}],"click":function($event){_vm.appraisalInfo.isAppraisedValueLowerThanSalesPrice.nARemark =
                                  null}}}),_c('span',{class:[
                                errors.length > 0
                                  ? 'checkround checkround1'
                                  : 'checkround' ]})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("NA")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.appraisalInfo
                                  .isAppraisedValueLowerThanSalesPrice.mode
                              ),expression:"\n                                appraisalInfo\n                                  .isAppraisedValueLowerThanSalesPrice.mode\n                              "}],staticClass:"checkround cursor-not-allow",attrs:{"type":"radio","value":"NA","disabled":""},domProps:{"checked":_vm._q(
                                _vm.appraisalInfo
                                  .isAppraisedValueLowerThanSalesPrice.mode
                              ,"NA")},on:{"change":[function($event){return _vm.$set(_vm.appraisalInfo
                                  .isAppraisedValueLowerThanSalesPrice, "mode", "NA")},function($event){_vm.isComplete = false;
                                _vm.handleChange(
                                  _vm.appraisalInfo
                                    .isAppraisedValueLowerThanSalesPrice.mode,
                                  _vm.appraisalInfoData
                                    .isAppraisedValueLowerThanSalesPrice.mode,
                                  'Is Appraised Value lower than the Sales Price?'
                                );
                                _vm.saveAppraisalReceivedTask();}],"click":function($event){_vm.appraisalInfo.isAppraisedValueLowerThanSalesPrice.nARemark =
                                  null}}}),_c('span',{class:[
                                errors.length > 0
                                  ? 'checkround checkround1'
                                  : 'checkround' ]})])]}}],null,true)})],1),(
                          _vm.appraisalInfo.isAppraisedValueLowerThanSalesPrice
                            .mode == 'NA'
                        )?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 p-0"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label ml-3",attrs:{"for":"creditorName"}},[_vm._v("Add remarks")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                              _vm.appraisalInfo.isAppraisedValueLowerThanSalesPrice
                                .nARemark
                            ),expression:"\n                              appraisalInfo.isAppraisedValueLowerThanSalesPrice\n                                .nARemark\n                            "}],staticClass:"login-input line_height_40 ml-3",attrs:{"type":"text","disabled":_vm.appraisalInfo.isCompleted,"name":"Add remarks"},domProps:{"value":(
                              _vm.appraisalInfo.isAppraisedValueLowerThanSalesPrice
                                .nARemark
                            )},on:{"change":function($event){_vm.isComplete = false;
                              _vm.handleChange(
                                _vm.appraisalInfo
                                  .isAppraisedValueLowerThanSalesPrice.nARemark,
                                _vm.appraisalInfoData
                                  .isAppraisedValueLowerThanSalesPrice.nARemark,
                                'Is Appraised Value lower than the Sales Price? Remarks'
                              );
                              _vm.saveAppraisalReceivedTask();},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.appraisalInfo.isAppraisedValueLowerThanSalesPrice
                                , "nARemark", $event.target.value)}}})])]):_vm._e()]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 p-0"},[_c('p',{staticClass:"content-text-black mt-1 mb-1"},[_c('span',[_vm._v("13.")]),_vm._v(" Is this an FHA Property wherein the Seller/Owner of Record has owned the property for less than 180 days? ")]),_c('div',{staticClass:"mb-2"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0 ml-3"},[_c('span',{staticClass:"radio-text"},[_vm._v("Yes")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.appraisalInfo.isOwnerOwnPropertLessThan180Days
                                  .mode
                              ),expression:"\n                                appraisalInfo.isOwnerOwnPropertLessThan180Days\n                                  .mode\n                              "}],attrs:{"type":"radio","value":"Yes","disabled":_vm.appraisalInfo.isCompleted},domProps:{"checked":_vm._q(
                                _vm.appraisalInfo.isOwnerOwnPropertLessThan180Days
                                  .mode
                              ,"Yes")},on:{"change":[function($event){return _vm.$set(_vm.appraisalInfo.isOwnerOwnPropertLessThan180Days
                                  , "mode", "Yes")},function($event){_vm.isComplete = false;
                                _vm.handleChange(
                                  _vm.appraisalInfo.isOwnerOwnPropertLessThan180Days
                                    .mode,
                                  _vm.appraisalInfoData
                                    .isOwnerOwnPropertLessThan180Days.mode,
                                  'Is this an FHA Property wherein the Seller/Owner of Record has owned the property for less than 180 days?'
                                );
                                _vm.saveAppraisalReceivedTask();}],"click":function($event){_vm.appraisalInfo.isOwnerOwnPropertLessThan180Days.nARemark =
                                  null}}}),_c('span',{class:[
                                errors.length > 0
                                  ? 'checkround checkround1'
                                  : 'checkround' ]})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('label',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
                              title:
                                _vm.appraisalInfo.hasPropertyOwnedForAtLeast91Days
                                  .mode == 'No'
                                  ? 'Loan has been put on Hold '
                                  : '',
                            }),expression:"{\n                              title:\n                                appraisalInfo.hasPropertyOwnedForAtLeast91Days\n                                  .mode == 'No'\n                                  ? 'Loan has been put on Hold '\n                                  : '',\n                            }"}],staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("No")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.appraisalInfo.isOwnerOwnPropertLessThan180Days
                                  .mode
                              ),expression:"\n                                appraisalInfo.isOwnerOwnPropertLessThan180Days\n                                  .mode\n                              "}],attrs:{"type":"radio","value":"No","disabled":_vm.appraisalInfo.isCompleted ||
                                _vm.appraisalInfo.hasPropertyOwnedForAtLeast91Days
                                  .mode == 'No'},domProps:{"checked":_vm._q(
                                _vm.appraisalInfo.isOwnerOwnPropertLessThan180Days
                                  .mode
                              ,"No")},on:{"change":[function($event){return _vm.$set(_vm.appraisalInfo.isOwnerOwnPropertLessThan180Days
                                  , "mode", "No")},function($event){_vm.isComplete = false;
                                _vm.handleChange(
                                  _vm.appraisalInfo.isOwnerOwnPropertLessThan180Days
                                    .mode,
                                  _vm.appraisalInfoData
                                    .isOwnerOwnPropertLessThan180Days.mode,
                                  'Is this an FHA Property wherein the Seller/Owner of Record has owned the property for less than 180 days?'
                                );
                                _vm.saveAppraisalReceivedTask();}],"click":function($event){_vm.appraisalInfo.isOwnerOwnPropertLessThan180Days.nARemark =
                                  null;
                                _vm.appraisalInfo.hasPropertyOwnedForAtLeast91Days.mode =
                                  null;}}}),_c('span',{class:[
                                errors.length > 0
                                  ? 'checkround checkround1'
                                  : 'checkround' ]})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('label',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
                              title:
                                _vm.appraisalInfo.hasPropertyOwnedForAtLeast91Days
                                  .mode == 'No'
                                  ? 'Loan has been put on Hold '
                                  : '',
                            }),expression:"{\n                              title:\n                                appraisalInfo.hasPropertyOwnedForAtLeast91Days\n                                  .mode == 'No'\n                                  ? 'Loan has been put on Hold '\n                                  : '',\n                            }"}],staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("NA")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.appraisalInfo.isOwnerOwnPropertLessThan180Days
                                  .mode
                              ),expression:"\n                                appraisalInfo.isOwnerOwnPropertLessThan180Days\n                                  .mode\n                              "}],attrs:{"type":"radio","value":"NA","disabled":_vm.appraisalInfo.isCompleted ||
                                _vm.appraisalInfo.hasPropertyOwnedForAtLeast91Days
                                  .mode == 'No'},domProps:{"checked":_vm._q(
                                _vm.appraisalInfo.isOwnerOwnPropertLessThan180Days
                                  .mode
                              ,"NA")},on:{"change":[function($event){return _vm.$set(_vm.appraisalInfo.isOwnerOwnPropertLessThan180Days
                                  , "mode", "NA")},function($event){_vm.isComplete = false;
                                _vm.handleChange(
                                  _vm.appraisalInfo.isOwnerOwnPropertLessThan180Days
                                    .mode,
                                  _vm.appraisalInfoData
                                    .isOwnerOwnPropertLessThan180Days.mode,
                                  'Is this an FHA Property wherein the Seller/Owner of Record has owned the property for less than 180 days?'
                                );
                                _vm.saveAppraisalReceivedTask();}],"click":function($event){_vm.appraisalInfo.isOwnerOwnPropertLessThan180Days.nARemark =
                                  null;
                                _vm.appraisalInfo.hasPropertyOwnedForAtLeast91Days.mode =
                                  null;}}}),_c('span',{class:[
                                errors.length > 0
                                  ? 'checkround checkround1'
                                  : 'checkround' ]})])]}}],null,true)})],1),(
                          _vm.appraisalInfo.isOwnerOwnPropertLessThan180Days.mode ==
                          'Yes'
                        )?_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 p-0"},[_c('p',{staticClass:"content-text-black mt-1 mb-1 ml-2"},[_vm._v(" has the property been owned for at least 91 days? ")]),_c('div',{staticClass:"mb-2"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('label',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
                                title:
                                  _vm.appraisalInfo.hasPropertyOwnedForAtLeast91Days
                                    .mode == 'No'
                                    ? 'Loan has been put on Hold '
                                    : '',
                              }),expression:"{\n                                title:\n                                  appraisalInfo.hasPropertyOwnedForAtLeast91Days\n                                    .mode == 'No'\n                                    ? 'Loan has been put on Hold '\n                                    : '',\n                              }"}],staticClass:"radio mr-3 mb-0 ml-3"},[_c('span',{staticClass:"radio-text"},[_vm._v("Yes")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                  _vm.appraisalInfo.hasPropertyOwnedForAtLeast91Days
                                    .mode
                                ),expression:"\n                                  appraisalInfo.hasPropertyOwnedForAtLeast91Days\n                                    .mode\n                                "}],attrs:{"type":"radio","value":"Yes","disabled":_vm.appraisalInfo.isCompleted ||
                                  _vm.appraisalInfo.hasPropertyOwnedForAtLeast91Days
                                    .mode == 'No'},domProps:{"checked":_vm._q(
                                  _vm.appraisalInfo.hasPropertyOwnedForAtLeast91Days
                                    .mode
                                ,"Yes")},on:{"change":[function($event){return _vm.$set(_vm.appraisalInfo.hasPropertyOwnedForAtLeast91Days
                                    , "mode", "Yes")},function($event){_vm.isComplete = false;
                                  _vm.handleChange(
                                    _vm.appraisalInfo
                                      .hasPropertyOwnedForAtLeast91Days.mode,
                                    _vm.appraisalInfoData
                                      .hasPropertyOwnedForAtLeast91Days.mode,
                                    'has the property been owned for at least 91 days?'
                                  );
                                  _vm.saveAppraisalReceivedTask();}]}}),_c('span',{class:[
                                  errors.length > 0
                                    ? 'checkround checkround1'
                                    : 'checkround' ]})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('label',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
                                title:
                                  _vm.appraisalInfo.hasPropertyOwnedForAtLeast91Days
                                    .mode == 'No'
                                    ? 'Loan has been put on Hold '
                                    : '',
                              }),expression:"{\n                                title:\n                                  appraisalInfo.hasPropertyOwnedForAtLeast91Days\n                                    .mode == 'No'\n                                    ? 'Loan has been put on Hold '\n                                    : '',\n                              }"}],staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("No")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                  _vm.appraisalInfo.hasPropertyOwnedForAtLeast91Days
                                    .mode
                                ),expression:"\n                                  appraisalInfo.hasPropertyOwnedForAtLeast91Days\n                                    .mode\n                                "}],attrs:{"type":"radio","value":"No","disabled":_vm.appraisalInfo.isCompleted ||
                                  _vm.appraisalInfo.hasPropertyOwnedForAtLeast91Days
                                    .mode == 'No'},domProps:{"checked":_vm._q(
                                  _vm.appraisalInfo.hasPropertyOwnedForAtLeast91Days
                                    .mode
                                ,"No")},on:{"click":function($event){return _vm.openStatusModal()},"change":function($event){return _vm.$set(_vm.appraisalInfo.hasPropertyOwnedForAtLeast91Days
                                    , "mode", "No")}}}),_c('span',{class:[
                                  errors.length > 0
                                    ? 'checkround checkround1'
                                    : 'checkround' ]})])]}}],null,true)})],1)]):_vm._e(),(
                          _vm.appraisalInfo.isOwnerOwnPropertLessThan180Days.mode ==
                          'NA'
                        )?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 p-0"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label ml-3",attrs:{"for":"creditorName"}},[_vm._v("Add remarks")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                              _vm.appraisalInfo.isOwnerOwnPropertLessThan180Days
                                .nARemark
                            ),expression:"\n                              appraisalInfo.isOwnerOwnPropertLessThan180Days\n                                .nARemark\n                            "}],staticClass:"login-input line_height_40 ml-3",attrs:{"type":"text","name":"Add remarks","disabled":_vm.appraisalInfo.isCompleted},domProps:{"value":(
                              _vm.appraisalInfo.isOwnerOwnPropertLessThan180Days
                                .nARemark
                            )},on:{"change":function($event){_vm.isComplete = false;
                              _vm.handleChange(
                                _vm.appraisalInfo.isOwnerOwnPropertLessThan180Days
                                  .nARemark,
                                _vm.appraisalInfoData
                                  .isOwnerOwnPropertLessThan180Days.nARemark,
                                'Is this an FHA Property wherein the Seller/Owner of Record has owned the property for less than 180 days? Remarks'
                              );
                              _vm.saveAppraisalReceivedTask();},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.appraisalInfo.isOwnerOwnPropertLessThan180Days
                                , "nARemark", $event.target.value)}}})])]):_vm._e()])])])])]),_c('hr',{staticClass:"loan-process-border-top"}),_c('div',{staticClass:"d-flex justify-content-end align-items-center form-group"},[(_vm.error.show)?_c('span',{staticClass:"login-error show mr-2"},[_vm._v(" "+_vm._s(_vm.error.msg))]):_vm._e(),_c('button',{staticClass:"tmpco-btn tmpco-btn__sm-theme height_40",class:[
                  _vm.appraisalInfo.isCompleted
                    ? 'btn-upload cursor-pointer cursor-not-allow'
                    : 'btn-upload cursor-pointer' ],attrs:{"type":"submit","disabled":_vm.appraisalInfo.isCompleted},on:{"click":function($event){return _vm.$modal.show('appraisalReceivedConfirmationModal')}}},[_vm._v(" "+_vm._s(_vm.appraisalInfo.isCompleted ? "Completed" : "Complete")+" ")])]),_c('modal',{staticClass:"modal",attrs:{"centered":"","name":"appraisalReceivedConfirmationModal","hide-footer":true}},[_c('div',{staticClass:"modal--md-regular"},[_c('div',{staticClass:"tmpco-modal__content"},[_c('div',{staticClass:"tmpco-modal__header"},[_c('p',{staticClass:"tmpco-modal__title"},[_vm._v("Appraisal Received")]),_c('button',{staticClass:"tmpco-modal__close",attrs:{"type":"button","data-dismiss":"modal"},on:{"click":function($event){return _vm.$modal.hide('appraisalReceivedConfirmationModal')}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"tmpco-modal__body"},[_c('div',{staticClass:"col-lg-12 col-xl-12"},[_c('p',{staticClass:"mt-3 mb-3 content-modal"},[_vm._v(" Are you sure you want to complete? ")])])]),_c('div',{staticClass:"tmpco-modal__footer"},[_c('button',{staticClass:"tmpco-btn tmpco-btn__sm-default mr-2",on:{"click":function($event){return _vm.$modal.hide('appraisalReceivedConfirmationModal')}}},[_vm._v(" No ")]),_c('button',{staticClass:"tmpco-btn tmpco-btn__sm-theme",attrs:{"type":"submit"},on:{"click":function($event){_vm.isComplete = true;
                        _vm.$modal.hide('appraisalReceivedConfirmationModal');}}},[_vm._v(" Yes ")])])])])])],1)]}}])})],1)]),_c('ReOrderTask',{ref:"refReOrder",on:{"refresh-task-after-reorder":function($event){_vm.getAppraisalCompanyList();
        _vm.isOrderPlacedOnce = false;
        _vm.$emit('refresh-task-list', true);}}}),_c('CommonUserChoiceModal',{ref:"refrence",on:{"call-mount-funtion":function($event){return _vm.getAppraisalReceivedTask()}}}),_c('modal',{staticClass:"modal",attrs:{"size":"lg","centered":"","name":"status-modal","hide-footer":true,"clickToClose":false}},[_c('div',{staticClass:"modal--md-regular"},[_c('div',{staticClass:"tmpco-modal__content"},[_c('div',{staticClass:"tmpco-modal__header"},[_c('h4',{staticClass:"tmpco-modal__title"},[_vm._v("Loan Status")])]),_c('div',{staticClass:"modal-body-content"},[_c('p',{staticClass:"mt-3 mb-3 content-modal"},[_vm._v(" Are You sure you want to put this loan on hold? ")])]),_c('div',{staticClass:"tmpco-modal__footer"},[_c('button',{staticClass:"tmpco-btn tmpco-btn__sm-default mr-2",on:{"click":function($event){$event.preventDefault();_vm.$modal.hide('status-modal');
                _vm.appraisalInfo.hasPropertyOwnedForAtLeast91Days.mode =
                  _vm.appraisalInfoData.hasPropertyOwnedForAtLeast91Days.mode;}}},[_vm._v(" No ")]),(_vm.isDisabled)?_c('button',{staticClass:"tmpco-btn tmpco-btn__sm-theme",attrs:{"id":"selector"}},[_vm._v(" Yes ")]):_vm._e(),(!_vm.isDisabled)?_c('button',{staticClass:"tmpco-btn tmpco-btn__sm-theme",attrs:{"id":"selector"},on:{"click":function($event){$event.preventDefault();_vm.isDisabled = true;
                _vm.isComplete = false;
                _vm.handleChange(
                  _vm.appraisalInfo.hasPropertyOwnedForAtLeast91Days.mode,
                  _vm.appraisalInfoData.hasPropertyOwnedForAtLeast91Days.mode,
                  'has the property been owned for at least 91 days?'
                );
                _vm.saveAppraisalReceivedTask();
                _vm.isOnHold = true;}}},[_vm._v(" Yes ")]):_vm._e()])])])]),_c('modal',{staticClass:"modal",attrs:{"size":"lg","centered":"","name":"manufactured-status-modal","hide-footer":true}},[_c('div',{staticClass:"modal--md-regular"},[_c('div',{staticClass:"tmpco-modal__content"},[_c('div',{staticClass:"tmpco-modal__header"},[_c('h4',{staticClass:"tmpco-modal__title"},[_vm._v(" Is the property a Manufactured Home? ")])]),_c('div',{staticClass:"modal-body-content"},[_c('p',{staticClass:"mt-3 mb-3 content-modal"},[_vm._v(" Are You sure, You want to change manufactured home value to NO? ")])]),_c('div',{staticClass:"tmpco-modal__footer"},[_c('button',{staticClass:"tmpco-btn tmpco-btn__sm-default mr-2",on:{"click":function($event){$event.preventDefault();_vm.$modal.hide('manufactured-status-modal');
                _vm.appraisalInfo.isPropertyManufacturedHome.mode = 'Yes';}}},[_vm._v(" No ")]),_c('button',{staticClass:"tmpco-btn tmpco-btn__sm-theme",on:{"click":function($event){$event.preventDefault();_vm.isComplete = false;
                _vm.handleChange(
                  _vm.appraisalInfo.isPropertyManufacturedHome.mode,
                  _vm.appraisalInfoData.isPropertyManufacturedHome.mode,
                  'Is the property a Manufactured Home?'
                );
                _vm.saveAppraisalReceivedTask();
                _vm.$modal.hide('manufactured-status-modal');
                _vm.$emit('refresh-task-list', true);}}},[_vm._v(" Yes ")])])])])]),_c('ViewDocument',{ref:"ref",on:{"call-mount-funtion":function($event){return _vm.getAppraisalReceivedTask()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }