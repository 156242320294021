





























































































































import { Component, Vue, Prop } from "vue-property-decorator";
import ConditionalApproval from "./ConditionalApproval.vue";
import Suspended from "./Suspended.vue";
import Axios from "axios";
import { BASE_API_URL } from "../../../config";
import { authHeader } from "../../../services/auth";
import ViewDocument from "../../../common-components/ViewDocument.vue";

@Component({
  components: {
    ConditionalApproval,
    Suspended,
    ViewDocument,
  },
})
export default class LenderResponse extends Vue {
  /* eslint-disable */
  public mileStoneId = null;
  public lenderResTask: any = [];
  public loanId: any = this.$route.query.id;
  @Prop()
  public taskId: any;

  @Prop()
  public lenderResponseSequence: any;
  public sequence = null;
  public workflowTaskId = null;
  // @Prop()
  // public isLoanInactive: any;
  public loanChecklistData: any = {};
  public isTaskCompleted = false;
  public docLoader = false;

  public remark: any = null;
  public isCompleted = false;
  public allAgents = [];
  public lenderResponse: any = {};
  public addedBy: any = "";
  public isRemark = false;
  public error: any = {
    show: true,
    msg: null,
  };
  @Prop()
  public isTaskDisabled: any;
  public isOrderPlacedOnce = false;

  public async uploadReport(event) {
    this.docLoader = true;
    try {
      if (this.isTaskDisabled) {
        this.docLoader = false;
        this.$snotify.info("Task is not assigned to you.");
        return;
      }
      if (event.target.files.length <= 0) return;
      const formData: any = new FormData();
      Array.from(event.target.files).forEach((element) => {
        formData.append("file", element);
      });
      formData.append("loanId", this.loanId);
      formData.append("taskId", this.taskId);
      const response = await Axios.post(
        BASE_API_URL + "processor/uploadNoatReport",
        formData,
        { headers: authHeader() }
      );
      if (response.status === 201) this.$snotify.success(response.data.message);
      this.docLoader = false;
      this.loanChecklistData = response.data.loanChecklistData;
      this.error.msg = null;
      this.error.show = false;
    } catch (error) {
      console.log(error);
    }
  }

  public async viewDocument(document) {
    try {
      document.loanId = this.$route.query.id;
      this.$refs.ref["showFile"](document);
    } catch (error) {
      console.log(error);
    }
  }

  public async saveLenderResponse() {
     if(this.isOrderPlacedOnce) return
    this.callLoader(true, null);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getLenderResponseDetails();
        return;
      }
      if (this.isCompleted) {
        if (this.mileStoneId == 59 && this.remark == null) {
          this.isRemark = true;
          this.error.msg = "Please Enter Remarks";
          this.callLoader(false, null);
          return;
        }
      }

      if (!this.mileStoneId) {
        this.error.show = true;
        this.error.msg = "Please choose atleast one.";
        this.callLoader(false, null);
        return;
      }

      if (this.mileStoneId === 31 && this.allAgents.length <= 0) {
        this.error.show = true;
        this.error.msg = "Title Agent Not Added Yet";
        this.callLoader(false, null);
        return;
      }

          if(this.isCompleted) this.isOrderPlacedOnce=true;
      let response = await Axios.post(
        BASE_API_URL + "processor/saveLenderResponse",
        {
          loanId: this.loanId,
          mileStoneId: this.mileStoneId,
          taskId: this.workflowTaskId,
          lenderResponseSequence: this.sequence,
          remark: this.remark,
          isCompleted: this.isCompleted,
        },
        {
          headers: authHeader(),
        }
      );

      if (response.status == 201 && this.isCompleted) {
        this.$snotify.success("Lender Response saved successfully");
        this.$modal.hide("confirmationModal");
        await this.getLenderResponseDetails();
        this.$emit("refresh-task-list", true);
        if (this.mileStoneId == 1) {
          this.$router.push({ path: "/processor-dashboard" });
        }
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async getDocument() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "processor/getLenderResponseDocument",
        {
          loanId: this.loanId,
          taskId: this.workflowTaskId,
          lenderResponseSequence: this.sequence,
        },
        {
          headers: authHeader(),
        }
      );

      this.loanChecklistData = response.data.loanChecklistData;
    } catch (error) {
      console.log(error);
    }
  }

  public async getLenderResponseDetails() {
    this.callLoader(true, null);
    try {
      let response = await Axios.post(
        BASE_API_URL + "processor/getLenderResponseDetails",
        {
          loanId: this.loanId,
          taskId: this.workflowTaskId,
          lenderResponseSequence: this.sequence,
        },
        {
          headers: authHeader(),
        }
      );

      this.lenderResTask = response.data.milestone;
      this.mileStoneId = response.data.mileStoneId;
      this.isTaskCompleted = response.data.isCompleted;
      this.lenderResponse = response.data.lenderResponseInfo;
      this.addedBy = response.data.addedBy;
      // this.loanChecklistData =
      // response.data.loanChecklistData;

      // this.$emit("refresh-task-list", true);
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public openStatusModal(milestoneId) {
    if (milestoneId === 59) {
      this.$modal.show("confirmationModal");
    }
  }

  public async getListOfAgents() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "processor/getListOfAgents",
        {
          loanId: this.$route.query.id,
          agentType: 3,
        },
        {
          headers: authHeader(),
        }
      );

      if (response.status === 201) this.allAgents = response.data;
    } catch (error) {
      console.log(error);
    }
  }

  public closeLoanCancelModal() {
    // this.applicationReview.loanCancel.isCancel = null;
    // this.isCanceled = false;
    this.mileStoneId = null;
    this.$modal.hide("confirmationModal");
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  public allMountFunction(sequence) {
    this.workflowTaskId = this.taskId;
    this.sequence = sequence;
    this.getDocument();
    this.getLenderResponseDetails();

    this.getListOfAgents();
  }

  public mounted() {
    this.allMountFunction(this.lenderResponseSequence);
  }
}
