






























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































/* eslint-disable */
import { Component, Vue, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL, EventBus } from "../../../config";
import { authHeader } from "../../../services/auth";
import ViewDocument from "../../../common-components/ViewDocument.vue";
import ReOrderTask from "../../../common-components/ReOrderTask.vue";
import { AppraisalReceivedChecklist } from "../../../models/workflow/appraisalReceived.model";
import CommonUserChoiceModal from "../../../common-components/CommonUserChoiceModal.vue";
//import LoanInfoComponent from "../../../common-components/LoanInfoComponent.vue";

import datepicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

@Component({
  components: {
    ViewDocument,
    ReOrderTask,
    datepicker,
    CommonUserChoiceModal,
    // LoanInfoComponent
  },
})
export default class AppraisalReceived extends Vue {
  @Prop()
  public taskId: any;
  public appraisalInfo: any = new AppraisalReceivedChecklist();
  public appraisalInfoData: any = new AppraisalReceivedChecklist();
  public sellingPrice: number = 0;
  public document: any = document;
  public isComplete = false;
  @Prop()
  public reOrderCount: any;
  @Prop()
  public isLoanInactive: any;
  @Prop()
  public secondAppraisalCount: any;
  @Prop()
  public appraisalRefCount: any;
  public countToSendOnServer: any = null;
  public taskIdForServer: any = null;
  public secondAppraisalCountForServer: any = null;
  public appraisalRefCountForServer: any = null;
  public showHistoryBtn = false;
  public revisedUploading = false;
  public uploading = false;
  public changedValues: any = [];
  public error: any = {
    show: false,
    msg: null,
  };
  public disabledDate: any = null;
  @Prop()
  public isTaskDisabled: any;
  public statusDetail: any = {};
  public isOrderPlacedOnce = false;
  public isDisabled = false;
  public isOnHold = false;

  public async compare() {
    try {
      if (this.appraisalInfo.appraisalPrice && this.sellingPrice) {
        setTimeout(() => {
          if (this.appraisalInfo.appraisalPrice > this.sellingPrice) {
            this.appraisalInfo.isAppraisedValueLowerThanSalesPrice.mode = "No";
            this.document.getElementById(
              "msgSpan"
            ).innerHTML = `<i class="fa fa-arrow-up text__blue mt-3" aria-hidden="true" v-b-tooltip title="High"></i>  <span class="up_text"></span>`;
          } else if (
            this.appraisalInfo.appraisalPrice < this.sellingPrice &&
            this.appraisalInfo.appraisalPrice > 0
          ) {
            this.appraisalInfo.isAppraisedValueLowerThanSalesPrice.mode = "Yes";

            this.document.getElementById(
              "msgSpan"
            ).innerHTML = `<i class="fa fa-arrow-down text__blue mt-3-0" v-b-tooltip title="Low"></i>  <span class="up_text"></span>`;
          } else if (this.appraisalInfo.appraisalPrice == this.sellingPrice) {
            this.appraisalInfo.isAppraisedValueLowerThanSalesPrice.mode = "No";

            this.document.getElementById(
              "msgSpan"
            ).innerHTML = `<i class="fa fa-check text__blue mt-3" aria-hidden="true" v-b-tooltip title="Equal"></i> `;
          }
        }, 0);
      }
    } catch (error) {
      console.log(error);
    }
  }


  public async saveAppraisalReceivedTask() {
    if (this.isOrderPlacedOnce) return;
   
    await this.callLoader(true, null);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        await this.getAppraisalReceivedTask();
        return;
      }
      if (
        this.isComplete &&
        // this.statusDetail.statusDetails && this.statusDetail.statusDetails.status == 1
        this.appraisalInfo.hasPropertyOwnedForAtLeast91Days.mode == "No"
      ) {
        this.error.show = true;
        this.error.msg = `Loan has been put on Hold `;
        this.callLoader(false, null);
        return;
      }
      if (this.isComplete) {
        if (!this.appraisalInfo.appraisalReport) {
          this.error.show = true;
          this.error.msg = "Upload Appraisal Report.";
          await this.callLoader(false, null);
          return;
        } else if (
          this.appraisalInfo.appraisalReport.revisedDoc &&
          this.appraisalInfo.appraisalReport.docLength < 2
        ) {
          this.error.show = true;
          this.error.msg = "Upload Appraisal Updated Report.";
          await this.callLoader(false, null);
          return;
        } else if (!this.appraisalInfo.appraisalPrice) {
          this.error.valError = true;
          this.error.msg = "The Appraisal Price Field is required";
          await this.callLoader(false, null);
          return;
        } else if (
          this.appraisalInfo.isPropertyManufacturedHome.mode === "Yes" &&
          !this.appraisalInfo.isPropertyManufacturedHome.isIbtsRequired &&
          !this.appraisalInfo.isPropertyManufacturedHome.isEngineerRequired
        ) {
          this.error.show = true;
          this.error.msg = "Choose at least one in manufactured home";
          await this.callLoader(false, null);
          return;
        } else if (
          this.appraisalInfo.isMonthlyHOAPaymentUpdatedOn1003.mode == "No"
        ) {
          this.error.show = true;
          this.error.msg = "Please select yes for the Question No. 7";
          await this.callLoader(false, null);
          return;
        }
      }
      if (this.isComplete) this.isOrderPlacedOnce = true;

      // }
      const response = await Axios.post(
        `${BASE_API_URL}processor/saveAppraisalReceivedTask`,
        {
          appraisalInfo: this.appraisalInfo,
          taskId: this.taskIdForServer,
          loanId: this.$route.query.id,
          reOrderCount: this.countToSendOnServer,
          secondAppraisalCount: this.secondAppraisalCountForServer,
          appraisalRefCount: this.appraisalRefCountForServer,
          isComplete: this.isComplete,
          changedValues: this.changedValues,
        },
        {
          headers: authHeader(),
        }
      );

      if (response.status === 201 && this.isComplete)
        this.$snotify.success("Appraisal Received Completed Successfully");
      if (
        this.appraisalInfo.hasPropertyOwnedForAtLeast91Days &&
        this.appraisalInfo.hasPropertyOwnedForAtLeast91Days.mode == "No" &&
        this.statusDetail.statusDetails.status != 1
      ) {
        this.$snotify.success("Loan has been put on Hold");
        this.$modal.hide("status-modal");
              this.isDisabled = false;

        await this.$emit("refresh-task-list", true);
      }
      if (this.isComplete) await this.$emit("refresh-task-list", true);
      await this.getAppraisalReceivedTask();
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async handleChange(currentVal, previousVal, fieldName) {
    let index = this.changedValues.findIndex((c) => c.name === fieldName);
    if (index !== -1) {
      if (currentVal === previousVal) {
        this.changedValues.splice(index, 1);
      } else {
        let data = this.changedValues.find((v) => v.name === fieldName);
        data.from = previousVal;
        data.to = currentVal;
      }
    } else {
      this.changedValues.push({
        name: fieldName,
        from: previousVal,
        to: currentVal,
      });
    }
  }

  public async getAppraisalReceivedTask() {
    this.changedValues = [];
    this.callLoader(true, null);
    try {
      const response = await Axios.post(
        BASE_API_URL + "processor/getAppraisalReceivedTask",
        {
          loanId: this.$route.query.id,
          taskId: this.taskIdForServer,
          reOrderCount: this.countToSendOnServer,
          secondAppraisalCount: this.secondAppraisalCountForServer,
          appraisalRefCount: this.appraisalRefCountForServer,
        },
        {
          headers: authHeader(),
        }
      );

      if (response.status === 201) {
        EventBus.$emit("refesh-loaninfo");
        this.statusDetail = response.data.statusDetails;
        this.sellingPrice = response.data.sellingPrice;
        this.appraisalInfo = response.data.taskData
          ? JSON.parse(JSON.stringify(response.data.taskData))
          : new AppraisalReceivedChecklist();

        this.appraisalInfo.appraisalReport = response.data.appraisalReport;
        this.showHistoryBtn = response.data.showHistoryBtn;
        this.appraisalInfo.isCompleted = response.data.isCompleted;

        if (response.data.otherData) {
          this.appraisalInfo.hasPropertyOwnedForAtLeast91Days =
            response.data.otherData.hasPropertyOwnedForAtLeast91Days;
          this.appraisalInfo.isOwnerOwnPropertLessThan180Days =
            response.data.otherData.isOwnerOwnPropertLessThan180Days;
          this.appraisalInfo.isPropertyManufacturedHome =
            response.data.otherData.isPropertyManufacturedHome;
        }

        this.appraisalInfoData = JSON.parse(JSON.stringify(this.appraisalInfo));
        this.error.show = false;
        this.error.msg = null;
        if (this.appraisalInfo.appraisalPrice == 0) {
          this.appraisalInfo.isAppraisedValueLowerThanSalesPrice.mode = null;
        }
        this.compare();
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async uploadFile(event, status) {
    status != "Revised"
      ? (this.uploading = true)
      : (this.revisedUploading = true);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        return;
      }
      const formData: any = new FormData();
      let loanId: any = this.$route.query.id;
      if (event.target.files.length <= 0) {
        status != "Revised"
          ? (this.uploading = false)
          : (this.revisedUploading = false);
        return;
      }

      Array.from(event.target.files).forEach((file) => {
        formData.append("file", file);
      });
      formData.append("loanId", loanId);
      formData.append("taskId", this.taskIdForServer);
      formData.append("reOrderCount", this.countToSendOnServer);
      formData.append(
        "secondAppraisalCount",
        this.secondAppraisalCountForServer
      );
      formData.append("appraisalRefCount", this.appraisalRefCountForServer);

      const response = await Axios.post(
        BASE_API_URL + "processor/uploadAppraisalReport",
        formData,
        { headers: authHeader() }
      );

      if (response.status === 201) {
        this.$snotify.success(response.data.message);
        this.appraisalInfo.appraisalReport = response.data.appraisalReport;
      }
    } catch (error) {
      console.log(error);
    }
    status != "Revised"
      ? (this.uploading = false)
      : (this.revisedUploading = false);
  }

  public async viewDocument(document) {
    try {
      document.loanId = this.$route.query.id;
      this.$refs.ref["showFile"](document);
    } catch (error) {
      console.log(error);
    }
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  public async showHistory() {
    this.callLoader(true, 1);
    try {
      this.$refs.refReOrder["showHistory"](this.taskIdForServer);
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public showUploadRevisedReportDiv() {
    let show = false;
    let value;
    let key;
    for ([key, value] of Object.entries(this.appraisalInfo)) {
      if (
        value &&
        Object.keys(value).length > 0 &&
        key != "createdBy" &&
        key != "createdOn" &&
        key != "appraisalReport" &&
        key != "updatedBy" &&
        key != "updatedOn" &&
        key != "isAppraisalCompletedAsIs" &&
        key != "isOwnerOwnPropertLessThan180Days" &&
        key != "isPropertyLocatedInFloodZone" &&
        key != "isPropertyManufacturedHome" &&
        key != "hasPropertyOwnedForAtLeast91Days" &&
        key != "isMonthlyHOAPaymentUpdatedOn1003" &&
        key != "isAppraisedValueLowerThanSalesPrice"
      ) {
        if (value.mode == "No" && value.revisionRequestedOn) {
          show = true;
          this.appraisalInfo.appraisalReport.revisedDoc = true;
        }
      }
    }
    return show;
  }

  public async openModal() {
    await this.$refs.refrence["openModal"]({
      taskId: this.taskIdForServer,
      messgae: "Send Notification to LO",
      data: this.appraisalInfo,
    });
  }

  public async openManufacturedHomeModal(data) {
    await this.$refs.refrence["openManufacturedHomeModal"]({
      loanId: this.$route.query.id,
      isIbtsRequired: data.isIbtsRequired,
      taskId: this.taskIdForServer,
      isEngineerRequired: data.isEngineerRequired,
    });
  }

  public unSetHoldCondition() {
    this.appraisalInfo.hasPropertyOwnedForAtLeast91Days.mode == "No"
      ? (this.appraisalInfo.hasPropertyOwnedForAtLeast91Days.mode = "Yes")
      : null;
  }

  public async allMountFunction(
    count,
    secondAppraisalCount,
    taskId,
    appraisalRefCount
  ) {
    this.secondAppraisalCountForServer = secondAppraisalCount;
    this.taskIdForServer = taskId;
    this.countToSendOnServer = count;
    this.appraisalRefCountForServer = appraisalRefCount;
    await this.getAppraisalReceivedTask();
    await this.compare();
    if (
      this.appraisalInfo.isOwnerOwnPropertLessThan180Days.mode == "Yes" &&
      this.appraisalInfo.hasPropertyOwnedForAtLeast91Days.mode == "No" &&
      !this.isComplete &&
      !this.isLoanInactive
    ) {
      await this.unSetHoldCondition();
     // await this.saveAppraisalReceivedTask();
    }
  }

  public openStatusModal() {
    this.$modal.show("status-modal");
  }

  async mounted() {
    await this.allMountFunction(
      this.reOrderCount,
      this.secondAppraisalCount,
      this.taskId,
      this.appraisalRefCount
    );
  }

  /* eslint-disable */
}
