var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-2 white-card loan-process-height-249 d-flex flex-column"},[_c('div',{staticClass:"loan-process-heading"},[_c('div',{staticClass:"col-lg-12 d-flex justify-content-between"},[_c('p',{staticClass:"heading mb-0"},[_vm._v("Master Insurance Certificate Received")]),_c('div',{staticClass:"d-flex align-items-center"},[_vm._m(0),_c('div',{staticClass:"d-flex align-items-center border_solid_radius"},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],class:[
            _vm.isCompleted
            ? 'btn-upload cursor-pointer cursor-not-allow'
            : 'btn-upload cursor-pointer' ],attrs:{"type":"button","title":"Send Follow Up Mail to Agent"},on:{"click":function($event){_vm.isCompleted ? '' : _vm.sendFollowUpMailToCondoAgent()}}},[_c('i',{staticClass:"fa fa-plus font__size12 text__blue mx-2 cursor-pointer",class:[
            _vm.isCompleted
            ? 'btn-upload cursor-pointer cursor-not-allow'
            : 'btn-upload cursor-pointer' ]})]),_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"fa fa-eye text__blue mx-2 font__size12 cursor-pointer",attrs:{"title":"View"},on:{"click":function($event){return _vm.viewFollowUpHistory()}}})])])])]),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.saveMasterInsuranceCertificateRecievedTask)}}},[_c('div',{staticClass:"col-lg-12 pt-3 pb-3 flex-1 height_100"},[_c('div',{staticClass:"row m-0 flex-column min_293 col-12 px-1"},[_c('div',{staticClass:"flex-1"},[_c('div',{staticClass:"col-lg-12 p-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 col-xl-6 col-md-12 col-sm-12 mb-2"},[_c('p',{staticClass:"content-text m-0"},[_c('b',[_vm._v("Reports")])]),_c('hr',{staticClass:"loan-process-border-top mb-2 mt-1"}),_c('div',{staticClass:"pt-1"},[_c('div',{staticClass:"custom-checkbox d-flex align-items-center mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                      _vm.masterInsuranceCertificateReceived.isMasterInsuranceCertificateRecieved
                      ),expression:"\n                      masterInsuranceCertificateReceived.isMasterInsuranceCertificateRecieved\n                      "}],staticClass:"custom-checkbox-input",attrs:{"type":"checkbox","id":"a","disabled":_vm.isCompleted},domProps:{"checked":Array.isArray(
                      _vm.masterInsuranceCertificateReceived.isMasterInsuranceCertificateRecieved
                      )?_vm._i(
                      _vm.masterInsuranceCertificateReceived.isMasterInsuranceCertificateRecieved
                      ,null)>-1:(
                      _vm.masterInsuranceCertificateReceived.isMasterInsuranceCertificateRecieved
                      )},on:{"change":[function($event){var $$a=
                      _vm.masterInsuranceCertificateReceived.isMasterInsuranceCertificateRecieved
                      ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.masterInsuranceCertificateReceived, "isMasterInsuranceCertificateRecieved", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.masterInsuranceCertificateReceived, "isMasterInsuranceCertificateRecieved", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.masterInsuranceCertificateReceived, "isMasterInsuranceCertificateRecieved", $$c)}},function($event){_vm.error = { show: false, msg: null }; _vm.isComplete = false; 
                      _vm.handleChange(_vm.masterInsuranceCertificateReceived.isMasterInsuranceCertificateRecieved, _vm.masterInsuranceCertificateReceivedData.isMasterInsuranceCertificateRecieved, 'Master Insurance Certificate Received');_vm.saveMasterInsuranceCertificateRecievedTask()}]}}),_c('label',{staticClass:"label",attrs:{"for":"a"}},[_vm._v("Master Insurance Certificate Received")]),_c('div',{staticClass:"indicator"})]),_c('div',[_c('div',{staticClass:"col-lg-12 d_flex_704 align-items-center p-0"},[_c('div',{staticClass:"form-group d-flex align-items-center"},[(!_vm.masterInsuranceCertificateReceived.loanChecklistData)?_c('div',{staticClass:"file-upload mt-1 overflow_hidden"},[_c('label',{staticClass:"file-upload-label mb-0",attrs:{"for":"upload"}},[_c('i',{staticClass:"fa fa-upload mr-1"}),_vm._v(" "+_vm._s(_vm.docLoader ? "Uploading..." : "Upload Master Insurance Report"))]),_c('input',{staticClass:"file-upload-input",class:[
                              _vm.masterInsuranceCertificateReceived.isCompleted || _vm.masterInsuranceCertificateReceived.loanChecklistData
                              ? 'btn-upload cursor-pointer cursor-not-allow'
                              : 'btn-upload cursor-pointer' ],attrs:{"id":"upload","type":"file","name":"file-upload","disabled":_vm.isCompleted || _vm.masterInsuranceCertificateReceived.loanChecklistData,"multiple":""},on:{"change":function($event){_vm.uploadReport($event);
                              _vm.error = { show: false, msg: null };}}})]):_c('div',[_c('label',{staticClass:"label",attrs:{"for":"a"}},[_vm._v("Uploaded Master Insurance Certificate Report")])]),(_vm.masterInsuranceCertificateReceived.loanChecklistData)?_c('p',{staticClass:"mb-0 card-content-title ml-2 app-count"},[_c('b',[_vm._v(_vm._s(_vm.masterInsuranceCertificateReceived.loanChecklistData ? _vm.masterInsuranceCertificateReceived.loanChecklistData.docLength:''))])]):_vm._e(),(_vm.masterInsuranceCertificateReceived.loanChecklistData && _vm.masterInsuranceCertificateReceived.loanChecklistData.docLength>0)?_c('i',{staticClass:"ri-file-text-line ml-2 mr-2 text__blue cursor-pointer",on:{"click":function($event){return _vm.viewDocument(_vm.masterInsuranceCertificateReceived.loanChecklistData)}}}):_vm._e()]),(_vm.masterInsuranceCertificateReceived.loanChecklistData && _vm.showUploadRevisedReportDiv())?_c('div',{staticClass:"d-flex top-2"},[_c('label',{staticClass:"file-upload-label mb-0",attrs:{"for":"upload1"}},[_vm._v(" Upload Updated Report ")]),_c('div',{staticClass:"file-upload mt-1 overflow_hidden"},[_c('input',{staticClass:"file-upload-input",attrs:{"id":"upload1","disabled":_vm.isCompleted,"type":"file","name":"file-upload","multiple":""},on:{"change":function($event){return _vm.uploadReport($event);}}})])]):_vm._e()])])])]),_c('div',{staticClass:"col-lg-6 col-xl-6 col-md-12 col-sm-12 border_left_991"},[_c('p',{staticClass:"content-text m-0"},[_c('b',[_vm._v("Checklist")])]),_c('hr',{staticClass:"loan-process-border-top mb-2 mt-1"}),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 p-0"},[_c('p',{staticClass:"content-text-black mt-1 mb-1"},[_c('span',[_vm._v("1.")]),_vm._v(" Is the name of the Condo accurately reflected? ")]),_c('div',{staticClass:"mb-2"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0 ml-3"},[_c('span',{staticClass:"radio-text"},[_vm._v("Yes")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.masterInsuranceCertificateReceived.isCondoNameAccuratelyReflected.mode),expression:"masterInsuranceCertificateReceived.isCondoNameAccuratelyReflected.mode"}],attrs:{"type":"radio","value":"Yes","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.masterInsuranceCertificateReceived.isCondoNameAccuratelyReflected.mode,"Yes")},on:{"change":[function($event){return _vm.$set(_vm.masterInsuranceCertificateReceived.isCondoNameAccuratelyReflected, "mode", "Yes")},function($event){_vm.isComplete = false;
                              _vm.handleChange(_vm.masterInsuranceCertificateReceived.isCondoNameAccuratelyReflected.mode, _vm.masterInsuranceCertificateReceivedData.isCondoNameAccuratelyReflected.mode, 'Is the name of the Condo accurately reflected?'); _vm.saveMasterInsuranceCertificateRecievedTask()}],"click":function($event){_vm.masterInsuranceCertificateReceived.isCondoNameAccuratelyReflected.revisionRequestedOn = null; 
                              _vm.masterInsuranceCertificateReceived.isCondoNameAccuratelyReflected.revisionCompletedOn = null; _vm.masterInsuranceCertificateReceived.isCondoNameAccuratelyReflected.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("No")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.masterInsuranceCertificateReceived.isCondoNameAccuratelyReflected.mode),expression:"masterInsuranceCertificateReceived.isCondoNameAccuratelyReflected.mode"}],attrs:{"type":"radio","value":"No","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.masterInsuranceCertificateReceived.isCondoNameAccuratelyReflected.mode,"No")},on:{"change":[function($event){return _vm.$set(_vm.masterInsuranceCertificateReceived.isCondoNameAccuratelyReflected, "mode", "No")},function($event){_vm.isComplete = false;
                              _vm.handleChange(_vm.masterInsuranceCertificateReceived.isCondoNameAccuratelyReflected.mode, _vm.masterInsuranceCertificateReceivedData.isCondoNameAccuratelyReflected.mode, 'Is the name of the Condo accurately reflected?'); _vm.saveMasterInsuranceCertificateRecievedTask()}],"click":function($event){_vm.masterInsuranceCertificateReceived.isCondoNameAccuratelyReflected.revisionRequestedOn = null; 
                              _vm.masterInsuranceCertificateReceived.isCondoNameAccuratelyReflected.revisionCompletedOn = null; _vm.masterInsuranceCertificateReceived.isCondoNameAccuratelyReflected.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("NA")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.masterInsuranceCertificateReceived.isCondoNameAccuratelyReflected.mode),expression:"masterInsuranceCertificateReceived.isCondoNameAccuratelyReflected.mode"}],attrs:{"type":"radio","value":"NA","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.masterInsuranceCertificateReceived.isCondoNameAccuratelyReflected.mode,"NA")},on:{"change":[function($event){return _vm.$set(_vm.masterInsuranceCertificateReceived.isCondoNameAccuratelyReflected, "mode", "NA")},function($event){_vm.isComplete = false;
                              _vm.handleChange(_vm.masterInsuranceCertificateReceived.isCondoNameAccuratelyReflected.mode, _vm.masterInsuranceCertificateReceivedData.isCondoNameAccuratelyReflected.mode, 'Is the name of the Condo accurately reflected?'); _vm.saveMasterInsuranceCertificateRecievedTask()}],"click":function($event){_vm.masterInsuranceCertificateReceived.isCondoNameAccuratelyReflected.revisionRequestedOn = null; 
                              _vm.masterInsuranceCertificateReceived.isCondoNameAccuratelyReflected.revisionCompletedOn = null; _vm.masterInsuranceCertificateReceived.isCondoNameAccuratelyReflected.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)})],1),_c('div',{staticClass:"row m-0"},[(_vm.masterInsuranceCertificateReceived.isCondoNameAccuratelyReflected.mode == 'No')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('label',{staticClass:"label ml-3",attrs:{"for":"initial"}},[_vm._v("Revision Requested")]),_c('div',{staticClass:"d-flex align-items-center ml-3"},[_c('datepicker',{class:[errors.length>0?'mx-input-error':''],attrs:{"disabled":_vm.isCompleted,"valueType":"format"},on:{"change":function($event){_vm.isComplete = false;
                                _vm.handleChange(_vm.masterInsuranceCertificateReceived.isCondoNameAccuratelyReflected.revisionRequestedOn, _vm.masterInsuranceCertificateReceivedData.isCondoNameAccuratelyReflected.revisionRequestedOn, 'Is the name of the Condo accurately reflected? Revision Requested'); _vm.saveMasterInsuranceCertificateRecievedTask()}},model:{value:(_vm.masterInsuranceCertificateReceived.isCondoNameAccuratelyReflected.revisionRequestedOn),callback:function ($$v) {_vm.$set(_vm.masterInsuranceCertificateReceived.isCondoNameAccuratelyReflected, "revisionRequestedOn", $$v)},expression:"masterInsuranceCertificateReceived.isCondoNameAccuratelyReflected.revisionRequestedOn"}})],1)]}}],null,true)})],1):_vm._e(),(_vm.masterInsuranceCertificateReceived.isCondoNameAccuratelyReflected.mode == 'No')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('label',{staticClass:"label",attrs:{"for":"initial"}},[_vm._v("Revision Completed")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('datepicker',{class:[errors.length>0?'mx-input-error':''],attrs:{"disabled-date":function (date) { return date <= _vm.$moment(new Date(_vm.masterInsuranceCertificateReceived.isCondoNameAccuratelyReflected.revisionRequestedOn).setDate(new Date(_vm.masterInsuranceCertificateReceived.isCondoNameAccuratelyReflected.revisionRequestedOn).getDate() -1 )); },"disabled":_vm.isCompleted,"valueType":"format"},on:{"change":function($event){_vm.isComplete = false;
                              _vm.handleChange(_vm.masterInsuranceCertificateReceived.isCondoNameAccuratelyReflected.revisionCompletedOn, _vm.masterInsuranceCertificateReceivedData.isCondoNameAccuratelyReflected.revisionCompletedOn, 'Is the name of the Condo accurately reflected? Revision Completed'); _vm.saveMasterInsuranceCertificateRecievedTask()}},model:{value:(_vm.masterInsuranceCertificateReceived.isCondoNameAccuratelyReflected.revisionCompletedOn),callback:function ($$v) {_vm.$set(_vm.masterInsuranceCertificateReceived.isCondoNameAccuratelyReflected, "revisionCompletedOn", $$v)},expression:"masterInsuranceCertificateReceived.isCondoNameAccuratelyReflected.revisionCompletedOn"}})],1)]}}],null,true)})],1):_vm._e()]),( _vm.masterInsuranceCertificateReceived.isCondoNameAccuratelyReflected.mode == 'NA')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 p-0"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label ml-3",attrs:{"for":"creditorName"}},[_vm._v("Add remarks")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.masterInsuranceCertificateReceived.isCondoNameAccuratelyReflected.nARemark),expression:"masterInsuranceCertificateReceived.isCondoNameAccuratelyReflected.nARemark"}],staticClass:"login-input line_height_40 ml-3",attrs:{"type":"text","disabled":_vm.isCompleted,"name":"Add remarks"},domProps:{"value":(_vm.masterInsuranceCertificateReceived.isCondoNameAccuratelyReflected.nARemark)},on:{"change":function($event){_vm.isComplete = false;
                            _vm.handleChange(_vm.masterInsuranceCertificateReceived.isCondoNameAccuratelyReflected.nARemark, _vm.masterInsuranceCertificateReceivedData.isCondoNameAccuratelyReflected.nARemark, 'Is the name of the Condo accurately reflected? Remarks'); _vm.saveMasterInsuranceCertificateRecievedTask()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.masterInsuranceCertificateReceived.isCondoNameAccuratelyReflected, "nARemark", $event.target.value)}}})])]):_vm._e()]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 p-0"},[_c('p',{staticClass:"content-text-black mt-1 mb-1"},[_c('span',[_vm._v("2.")]),_vm._v(" Are the Borrowers name accurately reflected? ")]),_c('div',{staticClass:"mb-2"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0 ml-3"},[_c('span',{staticClass:"radio-text"},[_vm._v("Yes")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.masterInsuranceCertificateReceived.isBorrowerNameAccuratelyReflected.mode),expression:"masterInsuranceCertificateReceived.isBorrowerNameAccuratelyReflected.mode"}],attrs:{"type":"radio","value":"Yes","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.masterInsuranceCertificateReceived.isBorrowerNameAccuratelyReflected.mode,"Yes")},on:{"change":[function($event){return _vm.$set(_vm.masterInsuranceCertificateReceived.isBorrowerNameAccuratelyReflected, "mode", "Yes")},function($event){_vm.isComplete = false;
                            _vm.handleChange(_vm.masterInsuranceCertificateReceived.isBorrowerNameAccuratelyReflected.mode, _vm.masterInsuranceCertificateReceivedData.isBorrowerNameAccuratelyReflected.mode, 'Are the Borrowers name accurately reflected?'); _vm.saveMasterInsuranceCertificateRecievedTask()}],"click":function($event){_vm.masterInsuranceCertificateReceived.isBorrowerNameAccuratelyReflected.revisionRequestedOn = null;
                            _vm.masterInsuranceCertificateReceived.isBorrowerNameAccuratelyReflected.revisionCompletedOn= null; _vm.masterInsuranceCertificateReceived.isBorrowerNameAccuratelyReflected.nARemark=null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("No")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.masterInsuranceCertificateReceived.isBorrowerNameAccuratelyReflected.mode),expression:"masterInsuranceCertificateReceived.isBorrowerNameAccuratelyReflected.mode"}],attrs:{"type":"radio","value":"No","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.masterInsuranceCertificateReceived.isBorrowerNameAccuratelyReflected.mode,"No")},on:{"change":[function($event){return _vm.$set(_vm.masterInsuranceCertificateReceived.isBorrowerNameAccuratelyReflected, "mode", "No")},function($event){_vm.isComplete = false;
                            _vm.handleChange(_vm.masterInsuranceCertificateReceived.isBorrowerNameAccuratelyReflected.mode, _vm.masterInsuranceCertificateReceivedData.isBorrowerNameAccuratelyReflected.mode, 'Are the Borrowers name accurately reflected?'); _vm.saveMasterInsuranceCertificateRecievedTask()}],"click":function($event){_vm.masterInsuranceCertificateReceived.isBorrowerNameAccuratelyReflected.revisionRequestedOn = null;
                            _vm.masterInsuranceCertificateReceived.isBorrowerNameAccuratelyReflected.revisionCompletedOn= null; _vm.masterInsuranceCertificateReceived.isBorrowerNameAccuratelyReflected.nARemark=null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("NA")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.masterInsuranceCertificateReceived.isBorrowerNameAccuratelyReflected.mode),expression:"masterInsuranceCertificateReceived.isBorrowerNameAccuratelyReflected.mode"}],attrs:{"type":"radio","value":"NA","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.masterInsuranceCertificateReceived.isBorrowerNameAccuratelyReflected.mode,"NA")},on:{"change":[function($event){return _vm.$set(_vm.masterInsuranceCertificateReceived.isBorrowerNameAccuratelyReflected, "mode", "NA")},function($event){_vm.isComplete = false;
                            _vm.handleChange(_vm.masterInsuranceCertificateReceived.isBorrowerNameAccuratelyReflected.mode, _vm.masterInsuranceCertificateReceivedData.isBorrowerNameAccuratelyReflected.mode, 'Are the Borrowers name accurately reflected?'); _vm.saveMasterInsuranceCertificateRecievedTask()}],"click":function($event){_vm.masterInsuranceCertificateReceived.isBorrowerNameAccuratelyReflected.revisionRequestedOn = null;
                            _vm.masterInsuranceCertificateReceived.isBorrowerNameAccuratelyReflected.revisionCompletedOn= null; _vm.masterInsuranceCertificateReceived.isBorrowerNameAccuratelyReflected.nARemark=null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)})],1),_c('div',{staticClass:"row m-0"},[(_vm.masterInsuranceCertificateReceived.isBorrowerNameAccuratelyReflected.mode == 'No')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('label',{staticClass:"label ml-3",attrs:{"for":"initial"}},[_vm._v("Revision Requested")]),_c('div',{staticClass:"d-flex align-items-center ml-3"},[_c('datepicker',{class:[errors.length>0?'mx-input-error':''],attrs:{"disabled":_vm.isCompleted,"valueType":"format"},on:{"change":function($event){_vm.isComplete = false;
                              _vm.handleChange(_vm.masterInsuranceCertificateReceived.isBorrowerNameAccuratelyReflected.revisionRequestedOn, _vm.masterInsuranceCertificateReceivedData.isBorrowerNameAccuratelyReflected.revisionRequestedOn, 'Are the Borrowers name accurately reflected? Revision Requested'); _vm.saveMasterInsuranceCertificateRecievedTask()}},model:{value:(_vm.masterInsuranceCertificateReceived.isBorrowerNameAccuratelyReflected.revisionRequestedOn),callback:function ($$v) {_vm.$set(_vm.masterInsuranceCertificateReceived.isBorrowerNameAccuratelyReflected, "revisionRequestedOn", $$v)},expression:"masterInsuranceCertificateReceived.isBorrowerNameAccuratelyReflected.revisionRequestedOn"}})],1)]}}],null,true)})],1):_vm._e(),(_vm.masterInsuranceCertificateReceived.isBorrowerNameAccuratelyReflected.mode == 'No')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('label',{staticClass:"label",attrs:{"for":"initial"}},[_vm._v("Revision Completed")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('datepicker',{class:[errors.length>0?'mx-input-error':''],attrs:{"disabled":_vm.isCompleted,"valueType":"format","disabled-date":function (date) { return date <= _vm.$moment(new Date(_vm.masterInsuranceCertificateReceived.isBorrowerNameAccuratelyReflected.revisionRequestedOn).setDate(new Date(_vm.masterInsuranceCertificateReceived.isBorrowerNameAccuratelyReflected.revisionRequestedOn).getDate() -1 )); }},on:{"change":function($event){_vm.isComplete = false;
                              _vm.handleChange(_vm.masterInsuranceCertificateReceived.isBorrowerNameAccuratelyReflected.revisionCompletedOn, _vm.masterInsuranceCertificateReceivedData.isBorrowerNameAccuratelyReflected.revisionCompletedOn, 'Are the Borrowers name accurately reflected? Revision Completed'); _vm.saveMasterInsuranceCertificateRecievedTask()}},model:{value:(_vm.masterInsuranceCertificateReceived.isBorrowerNameAccuratelyReflected.revisionCompletedOn ),callback:function ($$v) {_vm.$set(_vm.masterInsuranceCertificateReceived.isBorrowerNameAccuratelyReflected, "revisionCompletedOn", $$v)},expression:"masterInsuranceCertificateReceived.isBorrowerNameAccuratelyReflected.revisionCompletedOn "}})],1)]}}],null,true)})],1):_vm._e()]),( _vm.masterInsuranceCertificateReceived.isBorrowerNameAccuratelyReflected.mode == 'NA')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 p-0"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label ml-3",attrs:{"for":"creditorName"}},[_vm._v("Add remarks")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.masterInsuranceCertificateReceived.isBorrowerNameAccuratelyReflected.nARemark),expression:"masterInsuranceCertificateReceived.isBorrowerNameAccuratelyReflected.nARemark"}],staticClass:"login-input line_height_40 ml-3",attrs:{"type":"text","disabled":_vm.isCompleted,"name":"Add remarks"},domProps:{"value":(_vm.masterInsuranceCertificateReceived.isBorrowerNameAccuratelyReflected.nARemark)},on:{"change":function($event){_vm.isComplete = false;
                            _vm.handleChange(_vm.masterInsuranceCertificateReceived.isBorrowerNameAccuratelyReflected.nARemark, _vm.masterInsuranceCertificateReceivedData.isBorrowerNameAccuratelyReflected.nARemark, 'Are the Borrowers name accurately reflected? Remarks'); _vm.saveMasterInsuranceCertificateRecievedTask()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.masterInsuranceCertificateReceived.isBorrowerNameAccuratelyReflected, "nARemark", $event.target.value)}}})])]):_vm._e()]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 p-0"},[_c('p',{staticClass:"content-text-black mt-1 mb-1"},[_c('span',[_vm._v("3.")]),_vm._v(" Is the property address accurately reflected? ")]),_c('div',{staticClass:"mb-2"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0 ml-3"},[_c('span',{staticClass:"radio-text"},[_vm._v("Yes")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.masterInsuranceCertificateReceived.isPropertyAddressAccuratelyReflected.mode),expression:"masterInsuranceCertificateReceived.isPropertyAddressAccuratelyReflected.mode"}],attrs:{"type":"radio","value":"Yes","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.masterInsuranceCertificateReceived.isPropertyAddressAccuratelyReflected.mode,"Yes")},on:{"change":[function($event){return _vm.$set(_vm.masterInsuranceCertificateReceived.isPropertyAddressAccuratelyReflected, "mode", "Yes")},function($event){_vm.isComplete = false;
                            _vm.handleChange(_vm.masterInsuranceCertificateReceived.isPropertyAddressAccuratelyReflected.mode, _vm.masterInsuranceCertificateReceivedData.isPropertyAddressAccuratelyReflected.mode, 'Is the property address accurately reflected?'); _vm.saveMasterInsuranceCertificateRecievedTask()}],"click":function($event){_vm.masterInsuranceCertificateReceived.isPropertyAddressAccuratelyReflected.revisionRequestedOn = null ;
                            _vm.masterInsuranceCertificateReceived.isPropertyAddressAccuratelyReflected.revisionCompletedOn = null; _vm.masterInsuranceCertificateReceived.isPropertyAddressAccuratelyReflected.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("No")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.masterInsuranceCertificateReceived.isPropertyAddressAccuratelyReflected.mode),expression:"masterInsuranceCertificateReceived.isPropertyAddressAccuratelyReflected.mode"}],attrs:{"type":"radio","value":"No","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.masterInsuranceCertificateReceived.isPropertyAddressAccuratelyReflected.mode,"No")},on:{"change":[function($event){return _vm.$set(_vm.masterInsuranceCertificateReceived.isPropertyAddressAccuratelyReflected, "mode", "No")},function($event){_vm.isComplete = false;
                            _vm.handleChange(_vm.masterInsuranceCertificateReceived.isPropertyAddressAccuratelyReflected.mode, _vm.masterInsuranceCertificateReceivedData.isPropertyAddressAccuratelyReflected.mode, 'Is the property address accurately reflected?'); _vm.saveMasterInsuranceCertificateRecievedTask()}],"click":function($event){_vm.masterInsuranceCertificateReceived.isPropertyAddressAccuratelyReflected.revisionRequestedOn = null ;
                            _vm.masterInsuranceCertificateReceived.isPropertyAddressAccuratelyReflected.revisionCompletedOn = null; _vm.masterInsuranceCertificateReceived.isPropertyAddressAccuratelyReflected.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("NA")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.masterInsuranceCertificateReceived.isPropertyAddressAccuratelyReflected.mode),expression:"masterInsuranceCertificateReceived.isPropertyAddressAccuratelyReflected.mode"}],attrs:{"type":"radio","value":"NA","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.masterInsuranceCertificateReceived.isPropertyAddressAccuratelyReflected.mode,"NA")},on:{"change":[function($event){return _vm.$set(_vm.masterInsuranceCertificateReceived.isPropertyAddressAccuratelyReflected, "mode", "NA")},function($event){_vm.isComplete = false;
                            _vm.handleChange(_vm.masterInsuranceCertificateReceived.isPropertyAddressAccuratelyReflected.mode, _vm.masterInsuranceCertificateReceivedData.isPropertyAddressAccuratelyReflected.mode, 'Is the property address accurately reflected?'); _vm.saveMasterInsuranceCertificateRecievedTask()}],"click":function($event){_vm.masterInsuranceCertificateReceived.isPropertyAddressAccuratelyReflected.revisionRequestedOn = null ;
                            _vm.masterInsuranceCertificateReceived.isPropertyAddressAccuratelyReflected.revisionCompletedOn = null; _vm.masterInsuranceCertificateReceived.isPropertyAddressAccuratelyReflected.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)})],1),_c('div',{staticClass:"row m-0"},[(_vm.masterInsuranceCertificateReceived.isPropertyAddressAccuratelyReflected.mode == 'No')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('label',{staticClass:"label ml-3",attrs:{"for":"initial"}},[_vm._v("Revision Requested")]),_c('div',{staticClass:"d-flex align-items-center ml-3"},[_c('datepicker',{class:[errors.length>0?'mx-input-error':''],attrs:{"disabled":_vm.isCompleted,"valueType":"format"},on:{"change":function($event){_vm.isComplete = false;
                              _vm.handleChange(_vm.masterInsuranceCertificateReceived.isPropertyAddressAccuratelyReflected.revisionRequestedOn, _vm.masterInsuranceCertificateReceivedData.isPropertyAddressAccuratelyReflected.revisionRequestedOn, 'Is the property address accurately reflected? Revision Requested'); _vm.saveMasterInsuranceCertificateRecievedTask()}},model:{value:(_vm.masterInsuranceCertificateReceived.isPropertyAddressAccuratelyReflected.revisionRequestedOn),callback:function ($$v) {_vm.$set(_vm.masterInsuranceCertificateReceived.isPropertyAddressAccuratelyReflected, "revisionRequestedOn", $$v)},expression:"masterInsuranceCertificateReceived.isPropertyAddressAccuratelyReflected.revisionRequestedOn"}})],1)]}}],null,true)})],1):_vm._e(),(_vm.masterInsuranceCertificateReceived.isPropertyAddressAccuratelyReflected.mode == 'No')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('label',{staticClass:"label",attrs:{"for":"initial"}},[_vm._v("Revision Completed")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('datepicker',{class:[errors.length>0?'mx-input-error':''],attrs:{"disabled":_vm.isCompleted,"valueType":"format","disabled-date":function (date) { return date <= _vm.$moment(new Date(_vm.masterInsuranceCertificateReceived.isPropertyAddressAccuratelyReflected.revisionRequestedOn).setDate(new Date(_vm.masterInsuranceCertificateReceived.isPropertyAddressAccuratelyReflected.revisionRequestedOn).getDate() -1)); }},on:{"change":function($event){_vm.isComplete = false;
                              _vm.handleChange(_vm.masterInsuranceCertificateReceived.isPropertyAddressAccuratelyReflected.revisionCompletedOn, _vm.masterInsuranceCertificateReceivedData.isPropertyAddressAccuratelyReflected.revisionCompletedOn, 'Is the property address accurately reflected? Revision Completed'); _vm.saveMasterInsuranceCertificateRecievedTask()}},model:{value:(_vm.masterInsuranceCertificateReceived.isPropertyAddressAccuratelyReflected.revisionCompletedOn),callback:function ($$v) {_vm.$set(_vm.masterInsuranceCertificateReceived.isPropertyAddressAccuratelyReflected, "revisionCompletedOn", $$v)},expression:"masterInsuranceCertificateReceived.isPropertyAddressAccuratelyReflected.revisionCompletedOn"}})],1)]}}],null,true)})],1):_vm._e()]),(_vm.masterInsuranceCertificateReceived.isPropertyAddressAccuratelyReflected.mode == 'NA')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 p-0"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label ml-3",attrs:{"for":"creditorName"}},[_vm._v("Add remarks")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.masterInsuranceCertificateReceived.isPropertyAddressAccuratelyReflected.nARemark),expression:"masterInsuranceCertificateReceived.isPropertyAddressAccuratelyReflected.nARemark"}],staticClass:"login-input line_height_40 ml-3",attrs:{"type":"text","disabled":_vm.isCompleted,"name":"Add remarks"},domProps:{"value":(_vm.masterInsuranceCertificateReceived.isPropertyAddressAccuratelyReflected.nARemark)},on:{"change":function($event){_vm.isComplete = false;
                            _vm.handleChange(_vm.masterInsuranceCertificateReceived.isPropertyAddressAccuratelyReflected.nARemark, _vm.masterInsuranceCertificateReceivedData.isPropertyAddressAccuratelyReflected.nARemark, 'Is the property address accurately reflected? Remarks'); _vm.saveMasterInsuranceCertificateRecievedTask()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.masterInsuranceCertificateReceived.isPropertyAddressAccuratelyReflected, "nARemark", $event.target.value)}}})])]):_vm._e()]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 p-0"},[_c('p',{staticClass:"content-text-black mt-1 mb-1"},[_c('span',[_vm._v("4.")]),_vm._v(" Is the Lender’s Mortgagee Clause and Loan Number accurately reflected? ")]),_c('div',{staticClass:"mb-2"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0 ml-3"},[_c('span',{staticClass:"radio-text"},[_vm._v("Yes")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.masterInsuranceCertificateReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.mode),expression:"masterInsuranceCertificateReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.mode"}],attrs:{"type":"radio","value":"Yes","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.masterInsuranceCertificateReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.mode,"Yes")},on:{"change":[function($event){return _vm.$set(_vm.masterInsuranceCertificateReceived.isMortgageClauseAndLoanNumberAccuratelyReflected, "mode", "Yes")},function($event){_vm.isComplete = false;
                            _vm.handleChange(_vm.masterInsuranceCertificateReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.mode, _vm.masterInsuranceCertificateReceivedData.isMortgageClauseAndLoanNumberAccuratelyReflected.mode, 'Is the Lender’s Mortgagee Clause and Loan Number accurately reflected?'); _vm.saveMasterInsuranceCertificateRecievedTask()}],"click":function($event){_vm.masterInsuranceCertificateReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionRequestedOn = null;
                            _vm.masterInsuranceCertificateReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionCompletedOn = null; _vm.masterInsuranceCertificateReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.nARemark= null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("No")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.masterInsuranceCertificateReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.mode),expression:"masterInsuranceCertificateReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.mode"}],attrs:{"type":"radio","value":"No","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.masterInsuranceCertificateReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.mode,"No")},on:{"change":[function($event){return _vm.$set(_vm.masterInsuranceCertificateReceived.isMortgageClauseAndLoanNumberAccuratelyReflected, "mode", "No")},function($event){_vm.isComplete = false;
                            _vm.handleChange(_vm.masterInsuranceCertificateReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.mode, _vm.masterInsuranceCertificateReceivedData.isMortgageClauseAndLoanNumberAccuratelyReflected.mode, 'Is the Lender’s Mortgagee Clause and Loan Number accurately reflected?'); _vm.saveMasterInsuranceCertificateRecievedTask()}],"click":function($event){_vm.masterInsuranceCertificateReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionRequestedOn = null;
                            _vm.masterInsuranceCertificateReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionCompletedOn = null; _vm.masterInsuranceCertificateReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.nARemark= null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("NA")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.masterInsuranceCertificateReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.mode),expression:"masterInsuranceCertificateReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.mode"}],attrs:{"type":"radio","value":"NA","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.masterInsuranceCertificateReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.mode,"NA")},on:{"change":[function($event){return _vm.$set(_vm.masterInsuranceCertificateReceived.isMortgageClauseAndLoanNumberAccuratelyReflected, "mode", "NA")},function($event){_vm.isComplete = false;
                            _vm.handleChange(_vm.masterInsuranceCertificateReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.mode, _vm.masterInsuranceCertificateReceivedData.isMortgageClauseAndLoanNumberAccuratelyReflected.mode, 'Is the Lender’s Mortgagee Clause and Loan Number accurately reflected?'); _vm.saveMasterInsuranceCertificateRecievedTask()}],"click":function($event){_vm.masterInsuranceCertificateReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionRequestedOn = null;
                            _vm.masterInsuranceCertificateReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionCompletedOn = null; _vm.masterInsuranceCertificateReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.nARemark= null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)})],1),_c('div',{staticClass:"row m-0"},[(_vm.masterInsuranceCertificateReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.mode == 'No')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('label',{staticClass:"label ml-3",attrs:{"for":"initial"}},[_vm._v("Revision Requested")]),_c('div',{staticClass:"d-flex align-items-center ml-3"},[_c('datepicker',{class:[errors.length>0?'mx-input-error':''],attrs:{"disabled":_vm.isCompleted,"valueType":"format"},on:{"change":function($event){_vm.isComplete = false;
                              _vm.handleChange(_vm.masterInsuranceCertificateReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionRequestedOn, _vm.masterInsuranceCertificateReceivedData.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionRequestedOn, 'Is the Lender’s Mortgagee Clause and Loan Number accurately reflected? Revision Requested'); _vm.saveMasterInsuranceCertificateRecievedTask()}},model:{value:(_vm.masterInsuranceCertificateReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionRequestedOn),callback:function ($$v) {_vm.$set(_vm.masterInsuranceCertificateReceived.isMortgageClauseAndLoanNumberAccuratelyReflected, "revisionRequestedOn", $$v)},expression:"masterInsuranceCertificateReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionRequestedOn"}})],1)]}}],null,true)})],1):_vm._e(),(_vm.masterInsuranceCertificateReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.mode == 'No')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('label',{staticClass:"label",attrs:{"for":"initial"}},[_vm._v("Revision Completed")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('datepicker',{class:[errors.length>0?'mx-input-error':''],attrs:{"disabled":_vm.isCompleted,"valueType":"format","disabled-date":function (date) { return date <= _vm.$moment(new Date(_vm.masterInsuranceCertificateReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionRequestedOn).setDate(new Date(_vm.masterInsuranceCertificateReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionRequestedOn).getDate() -1 )); }},on:{"change":function($event){_vm.isComplete = false;
                              _vm.handleChange(_vm.masterInsuranceCertificateReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionCompletedOn, _vm.masterInsuranceCertificateReceivedData.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionCompletedOn, 'Is the Lender’s Mortgagee Clause and Loan Number accurately reflected? Revision Completed'); _vm.saveMasterInsuranceCertificateRecievedTask()}},model:{value:(_vm.masterInsuranceCertificateReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionCompletedOn),callback:function ($$v) {_vm.$set(_vm.masterInsuranceCertificateReceived.isMortgageClauseAndLoanNumberAccuratelyReflected, "revisionCompletedOn", $$v)},expression:"masterInsuranceCertificateReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionCompletedOn"}})],1)]}}],null,true)})],1):_vm._e()]),( _vm.masterInsuranceCertificateReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.mode == 'NA')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 p-0"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label ml-3",attrs:{"for":"creditorName"}},[_vm._v("Add remarks")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.masterInsuranceCertificateReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.nARemark),expression:"masterInsuranceCertificateReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.nARemark"}],staticClass:"login-input line_height_40 ml-3",attrs:{"type":"text","disabled":_vm.isCompleted,"name":"Add remarks"},domProps:{"value":(_vm.masterInsuranceCertificateReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.nARemark)},on:{"change":function($event){_vm.isComplete = false;
                            _vm.handleChange(_vm.masterInsuranceCertificateReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.nARemark, _vm.masterInsuranceCertificateReceivedData.isMortgageClauseAndLoanNumberAccuratelyReflected.nARemark, 'Is the Lender’s Mortgagee Clause and Loan Number accurately reflected? Remarks'); _vm.saveMasterInsuranceCertificateRecievedTask()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.masterInsuranceCertificateReceived.isMortgageClauseAndLoanNumberAccuratelyReflected, "nARemark", $event.target.value)}}})])]):_vm._e()]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 p-0"},[_c('p',{staticClass:"content-text-black mt-1 mb-1"},[_c('span',[_vm._v("5.")]),_vm._v(" Is the Certificate/Policy valid thru Closing?")]),_c('div',{staticClass:"mb-2"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0 ml-3"},[_c('span',{staticClass:"radio-text"},[_vm._v("Yes")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.masterInsuranceCertificateReceived.isPolicyValid.mode),expression:"masterInsuranceCertificateReceived.isPolicyValid.mode"}],attrs:{"type":"radio","value":"Yes","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.masterInsuranceCertificateReceived.isPolicyValid.mode,"Yes")},on:{"change":[function($event){return _vm.$set(_vm.masterInsuranceCertificateReceived.isPolicyValid, "mode", "Yes")},function($event){_vm.isComplete = false;
                            _vm.handleChange(_vm.masterInsuranceCertificateReceived.isPolicyValid.mode, _vm.masterInsuranceCertificateReceivedData.isPolicyValid.mode, 'Is the Certificate/Policy valid thru Closing?'); _vm.saveMasterInsuranceCertificateRecievedTask()}],"click":function($event){_vm.masterInsuranceCertificateReceived.isPolicyValid.revisionRequestedOn = null ;
                            _vm.masterInsuranceCertificateReceived.isPolicyValid.revisionCompletedOn = null; _vm.masterInsuranceCertificateReceived.isPolicyValid.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("No")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.masterInsuranceCertificateReceived.isPolicyValid.mode),expression:"masterInsuranceCertificateReceived.isPolicyValid.mode"}],attrs:{"type":"radio","value":"No","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.masterInsuranceCertificateReceived.isPolicyValid.mode,"No")},on:{"change":[function($event){return _vm.$set(_vm.masterInsuranceCertificateReceived.isPolicyValid, "mode", "No")},function($event){_vm.isComplete = false;
                            _vm.handleChange(_vm.masterInsuranceCertificateReceived.isPolicyValid.mode, _vm.masterInsuranceCertificateReceivedData.isPolicyValid.mode, 'Is the Certificate/Policy valid thru Closing?'); _vm.saveMasterInsuranceCertificateRecievedTask()}],"click":function($event){_vm.masterInsuranceCertificateReceived.isPolicyValid.revisionRequestedOn = null ;
                            _vm.masterInsuranceCertificateReceived.isPolicyValid.revisionCompletedOn = null; _vm.masterInsuranceCertificateReceived.isPolicyValid.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("NA")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.masterInsuranceCertificateReceived.isPolicyValid.mode),expression:"masterInsuranceCertificateReceived.isPolicyValid.mode"}],attrs:{"type":"radio","value":"NA","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.masterInsuranceCertificateReceived.isPolicyValid.mode,"NA")},on:{"change":[function($event){return _vm.$set(_vm.masterInsuranceCertificateReceived.isPolicyValid, "mode", "NA")},function($event){_vm.isComplete = false;
                            _vm.handleChange(_vm.masterInsuranceCertificateReceived.isPolicyValid.mode, _vm.masterInsuranceCertificateReceivedData.isPolicyValid.mode, 'Is the Certificate/Policy valid thru Closing?'); _vm.saveMasterInsuranceCertificateRecievedTask()}],"click":function($event){_vm.masterInsuranceCertificateReceived.isPolicyValid.revisionRequestedOn = null ;
                            _vm.masterInsuranceCertificateReceived.isPolicyValid.revisionCompletedOn = null; _vm.masterInsuranceCertificateReceived.isPolicyValid.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)})],1),_c('div',{staticClass:"row m-0"},[(_vm.masterInsuranceCertificateReceived.isPolicyValid.mode == 'No')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('label',{staticClass:"label ml-3",attrs:{"for":"initial"}},[_vm._v("Revision Requested")]),_c('div',{staticClass:"d-flex align-items-center ml-3"},[_c('datepicker',{class:[errors.length>0?'mx-input-error':''],attrs:{"disabled":_vm.isCompleted,"valueType":"format"},on:{"change":function($event){_vm.isComplete = false;
                              _vm.handleChange(_vm.masterInsuranceCertificateReceived.isPolicyValid.revisionRequestedOn, _vm.masterInsuranceCertificateReceivedData.isPolicyValid.revisionRequestedOn, 'Is the Certificate/Policy valid thru Closing? Revision Requested'); _vm.saveMasterInsuranceCertificateRecievedTask()}},model:{value:(_vm.masterInsuranceCertificateReceived.isPolicyValid.revisionRequestedOn),callback:function ($$v) {_vm.$set(_vm.masterInsuranceCertificateReceived.isPolicyValid, "revisionRequestedOn", $$v)},expression:"masterInsuranceCertificateReceived.isPolicyValid.revisionRequestedOn"}})],1)]}}],null,true)})],1):_vm._e(),(_vm.masterInsuranceCertificateReceived.isPolicyValid.mode == 'No')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('label',{staticClass:"label",attrs:{"for":"initial"}},[_vm._v("Revision Completed")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('datepicker',{class:[errors.length>0?'mx-input-error':''],attrs:{"disabled":_vm.isCompleted,"valueType":"format","disabled-date":function (date) { return date <= _vm.$moment(new Date(_vm.masterInsuranceCertificateReceived.isPolicyValid.revisionRequestedOn).setDate(new Date(_vm.masterInsuranceCertificateReceived.isPolicyValid.revisionRequestedOn).getDate() -1)); }},on:{"change":function($event){_vm.isComplete = false;
                            _vm.handleChange(_vm.masterInsuranceCertificateReceived.isPolicyValid.revisionCompletedOn, _vm.masterInsuranceCertificateReceivedData.isPolicyValid.revisionCompletedOn, 'Is the Certificate/Policy valid thru Closing? Revision Completed'); _vm.saveMasterInsuranceCertificateRecievedTask()}},model:{value:(_vm.masterInsuranceCertificateReceived.isPolicyValid.revisionCompletedOn),callback:function ($$v) {_vm.$set(_vm.masterInsuranceCertificateReceived.isPolicyValid, "revisionCompletedOn", $$v)},expression:"masterInsuranceCertificateReceived.isPolicyValid.revisionCompletedOn"}})],1)]}}],null,true)})],1):_vm._e()]),(_vm.masterInsuranceCertificateReceived.isPolicyValid.mode == 'NA')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 p-0"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label ml-3",attrs:{"for":"creditorName"}},[_vm._v("Add remarks")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.masterInsuranceCertificateReceived.isPolicyValid.nARemark),expression:"masterInsuranceCertificateReceived.isPolicyValid.nARemark"}],staticClass:"login-input line_height_40 ml-3",attrs:{"type":"text","disabled":_vm.isCompleted,"name":"Add remarks"},domProps:{"value":(_vm.masterInsuranceCertificateReceived.isPolicyValid.nARemark)},on:{"change":function($event){_vm.isComplete = false;
                          _vm.handleChange(_vm.masterInsuranceCertificateReceived.isPolicyValid.nARemark, _vm.masterInsuranceCertificateReceivedData.isPolicyValid.nARemark, 'Is the Certificate/Policy valid thru Closing? Remarks'); _vm.saveMasterInsuranceCertificateRecievedTask()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.masterInsuranceCertificateReceived.isPolicyValid, "nARemark", $event.target.value)}}})])]):_vm._e()]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 p-0"},[_c('p',{staticClass:"content-text-black mt-1 mb-1"},[_c('span',[_vm._v("6.")]),_vm._v(" Is the Certificate/Policy signed by the Authorized Representative?")]),_c('div',{staticClass:"mb-2"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0 ml-3"},[_c('span',{staticClass:"radio-text"},[_vm._v("Yes")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.masterInsuranceCertificateReceived.isPolicySigned.mode),expression:"masterInsuranceCertificateReceived.isPolicySigned.mode"}],attrs:{"type":"radio","value":"Yes","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.masterInsuranceCertificateReceived.isPolicySigned.mode,"Yes")},on:{"change":[function($event){return _vm.$set(_vm.masterInsuranceCertificateReceived.isPolicySigned, "mode", "Yes")},function($event){_vm.isComplete = false;
                          _vm.handleChange(_vm.masterInsuranceCertificateReceived.isPolicySigned.mode, _vm.masterInsuranceCertificateReceivedData.isPolicySigned.mode, 'Is the Certificate/Policy signed by the Authorized Representative?'); _vm.saveMasterInsuranceCertificateRecievedTask()}],"click":function($event){_vm.masterInsuranceCertificateReceived.isPolicySigned.revisionRequestedOn = null;
                          _vm.masterInsuranceCertificateReceived.isPolicySigned.revisionCompletedOn = null; _vm.masterInsuranceCertificateReceived.isPolicySigned.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("No")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.masterInsuranceCertificateReceived.isPolicySigned.mode),expression:"masterInsuranceCertificateReceived.isPolicySigned.mode"}],attrs:{"type":"radio","value":"No","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.masterInsuranceCertificateReceived.isPolicySigned.mode,"No")},on:{"change":[function($event){return _vm.$set(_vm.masterInsuranceCertificateReceived.isPolicySigned, "mode", "No")},function($event){_vm.isComplete = false;
                          _vm.handleChange(_vm.masterInsuranceCertificateReceived.isPolicySigned.mode, _vm.masterInsuranceCertificateReceivedData.isPolicySigned.mode, 'Is the Certificate/Policy signed by the Authorized Representative?'); _vm.saveMasterInsuranceCertificateRecievedTask()}],"click":function($event){_vm.masterInsuranceCertificateReceived.isPolicySigned.revisionRequestedOn = null;
                          _vm.masterInsuranceCertificateReceived.isPolicySigned.revisionCompletedOn = null; _vm.masterInsuranceCertificateReceived.isPolicySigned.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("NA")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.masterInsuranceCertificateReceived.isPolicySigned.mode),expression:"masterInsuranceCertificateReceived.isPolicySigned.mode"}],attrs:{"type":"radio","value":"NA","disabled":_vm.isCompleted},domProps:{"checked":_vm._q(_vm.masterInsuranceCertificateReceived.isPolicySigned.mode,"NA")},on:{"change":[function($event){return _vm.$set(_vm.masterInsuranceCertificateReceived.isPolicySigned, "mode", "NA")},function($event){_vm.isComplete = false;
                          _vm.handleChange(_vm.masterInsuranceCertificateReceived.isPolicySigned.mode, _vm.masterInsuranceCertificateReceivedData.isPolicySigned.mode, 'Is the Certificate/Policy signed by the Authorized Representative?'); _vm.saveMasterInsuranceCertificateRecievedTask()}],"click":function($event){_vm.masterInsuranceCertificateReceived.isPolicySigned.revisionRequestedOn = null;
                          _vm.masterInsuranceCertificateReceived.isPolicySigned.revisionCompletedOn = null; _vm.masterInsuranceCertificateReceived.isPolicySigned.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)})],1),_c('div',{staticClass:"row m-0"},[(_vm.masterInsuranceCertificateReceived.isPolicySigned.mode == 'No')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('label',{staticClass:"label ml-3",attrs:{"for":"initial"}},[_vm._v("Revision Requested")]),_c('div',{staticClass:"d-flex align-items-center ml-3"},[_c('datepicker',{class:[errors.length>0?'mx-input-error':''],attrs:{"disabled":_vm.isCompleted,"valueType":"format"},on:{"change":function($event){_vm.isComplete = false;
                            _vm.handleChange(_vm.masterInsuranceCertificateReceived.isPolicySigned.revisionRequestedOn, _vm.masterInsuranceCertificateReceivedData.isPolicySigned.revisionRequestedOn, 'Is the Certificate/Policy signed by the Authorized Representative? Revision Requested'); _vm.saveMasterInsuranceCertificateRecievedTask()}},model:{value:(_vm.masterInsuranceCertificateReceived.isPolicySigned.revisionRequestedOn),callback:function ($$v) {_vm.$set(_vm.masterInsuranceCertificateReceived.isPolicySigned, "revisionRequestedOn", $$v)},expression:"masterInsuranceCertificateReceived.isPolicySigned.revisionRequestedOn"}})],1)]}}],null,true)})],1):_vm._e(),(_vm.masterInsuranceCertificateReceived.isPolicySigned.mode == 'No')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('label',{staticClass:"label",attrs:{"for":"initial"}},[_vm._v("Revision Completed")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('datepicker',{class:[errors.length>0?'mx-input-error':''],attrs:{"disabled":_vm.isCompleted,"valueType":"format","disabled-date":function (date) { return date <= _vm.$moment(new Date(_vm.masterInsuranceCertificateReceived.isPolicySigned.revisionRequestedOn).setDate(new Date(_vm.masterInsuranceCertificateReceived.isPolicySigned.revisionRequestedOn).getDate() -1 )); }},on:{"change":function($event){_vm.isComplete = false;
                            _vm.handleChange(_vm.masterInsuranceCertificateReceived.isPolicySigned.revisionCompletedOn, _vm.masterInsuranceCertificateReceivedData.isPolicySigned.revisionCompletedOn, 'Is the Certificate/Policy signed by the Authorized Representative? Revision Completed'); _vm.saveMasterInsuranceCertificateRecievedTask()}},model:{value:(_vm.masterInsuranceCertificateReceived.isPolicySigned.revisionCompletedOn),callback:function ($$v) {_vm.$set(_vm.masterInsuranceCertificateReceived.isPolicySigned, "revisionCompletedOn", $$v)},expression:"masterInsuranceCertificateReceived.isPolicySigned.revisionCompletedOn"}})],1)]}}],null,true)})],1):_vm._e()]),( _vm.masterInsuranceCertificateReceived.isPolicySigned.mode == 'NA')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 p-0"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label ml-3",attrs:{"for":"creditorName"}},[_vm._v("Add remarks")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.masterInsuranceCertificateReceived.isPolicySigned.nARemark),expression:"masterInsuranceCertificateReceived.isPolicySigned.nARemark"}],staticClass:"login-input line_height_40 ml-3",attrs:{"type":"text","disabled":_vm.isCompleted,"name":"Add remarks"},domProps:{"value":(_vm.masterInsuranceCertificateReceived.isPolicySigned.nARemark)},on:{"change":function($event){_vm.isComplete = false;
                          _vm.handleChange(_vm.masterInsuranceCertificateReceived.isPolicySigned.nARemark, _vm.masterInsuranceCertificateReceivedData.isPolicySigned.nARemark, 'Is the Certificate/Policy signed by the Authorized Representative? Remarks'); _vm.saveMasterInsuranceCertificateRecievedTask()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.masterInsuranceCertificateReceived.isPolicySigned, "nARemark", $event.target.value)}}})])]):_vm._e()])])])])]),_c('hr',{staticClass:"loan-process-border-top"}),_c('div',{staticClass:"d-flex justify-content-end align-items-center form-group"},[(_vm.error.show)?_c('span',{staticClass:"login-error show mr-2"},[_vm._v(_vm._s(_vm.error.msg))]):_vm._e(),_c('button',{staticClass:"tmpco-btn tmpco-btn__theme",class:[
            _vm.isCompleted
            ? 'btn-upload cursor-pointer cursor-not-allow'
            : 'btn-upload cursor-pointer' ],attrs:{"type":"button","disabled":_vm.isCompleted},on:{"click":function($event){return _vm.$modal.show('masterReceivedConfirmationModal');}}},[_vm._v(" "+_vm._s(_vm.isCompleted ? "Completed" : "Complete")+" ")])]),_c('modal',{staticClass:"modal",attrs:{"centered":"","name":"masterReceivedConfirmationModal","hide-footer":true}},[_c('div',{staticClass:"modal--md-regular"},[_c('div',{staticClass:"tmpco-modal__content"},[_c('div',{staticClass:"tmpco-modal__header"},[_c('p',{staticClass:"tmpco-modal__title"},[_vm._v("Master Insurance Certificate Received")]),_c('button',{staticClass:"tmpco-modal__close",attrs:{"type":"button","data-dismiss":"modal"},on:{"click":function($event){return _vm.$modal.hide('masterReceivedConfirmationModal');}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"tmpco-modal__body"},[_c('div',{staticClass:"col-lg-12 col-xl-12"},[_c('p',{staticClass:"mt-3 mb-3 content-modal"},[_vm._v(" Are you sure you want to complete? ")])])]),_c('div',{staticClass:"tmpco-modal__footer"},[_c('button',{staticClass:"tmpco-btn tmpco-btn__sm-default mr-2",on:{"click":function($event){return _vm.$modal.hide('masterReceivedConfirmationModal');}}},[_vm._v("No")]),_c('button',{staticClass:"tmpco-btn tmpco-btn__sm-theme",attrs:{"type":"submit"},on:{"click":function($event){_vm.isComplete = true;_vm.$modal.hide('masterReceivedConfirmationModal')}}},[_vm._v("Yes")])])])])])],1)])])]}}])}),_c('ViewDocument',{ref:"ref",on:{"call-mount-funtion":function($event){return _vm.getMasterInsuranceCertificatRecieved()}}}),_c('follow-up-task',{ref:"refFollowUp",on:{"refresh-task-after-sening-mail":function($event){return _vm.getMasterInsuranceCertificatRecieved()}}}),_c('ReOrderTask',{ref:"refReOrder",on:{"refresh-task-after-reorder":function($event){_vm.getMasterInsuranceCertificatRecieved();
_vm.isOrderPlacedOnce = false;
_vm.$emit('refresh-task-list', true);}}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"card-content-title mr-2 mb-0"},[_c('b',[_vm._v("Follow Up")])])}]

export { render, staticRenderFns }