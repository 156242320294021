

































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "../../../config";
import { authHeader } from "../../../services/auth";
import CommonDeleteModal from "../../../common-components/CommonDeleteModal.vue";
import CommonNotifyOnRejection from "../../../common-components/CommonNotifyOnRejection.vue";
import ViewDocument from "../../../common-components/ViewDocument.vue";
import FollowUpTask from "../../../common-components/FollowUpTask.vue";
import { TitleReceivedChecklist } from "../../../models/workflow/titleReceived.model";
import ReOrderTask from "../../../common-components/ReOrderTask.vue";
import datepicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";

@Component({
  components: {
    ViewDocument,
    CommonDeleteModal,
    FollowUpTask,
    datepicker,
    ReOrderTask,
    CommonNotifyOnRejection,
  },
})
export default class TitleReceived extends Vue {
  /* eslint-disable */
  public allDocs: any = [];
  public titleReceived: any = new TitleReceivedChecklist();
  public titleReceivedData: any = new TitleReceivedChecklist();
  @Prop()
  public taskId: any;
  public isComplete = false;
  public error: any = {
    show: false,
    msg: null,
  };
  public isCompleted = false;
  public documentName = null;
  public isUploading = false;
  @Prop()
  public isLoanInactive: any;
  @Prop()
  public reOrderCount: any;
  public countToSendOnServer: any = null;
  public showHistoryBtn = false;
  public changedValues: any = [];
  @Prop()
  public isTaskDisabled: any;
  public inProcess: any = false;
  public loanPurpose: any = null;
  public agentContactId = null;
  public statusDetail: any = {};
  public isRejected = false;
  public isAccepted = false;
  public isChecklist = true;
  public isAgentNotified: any = false;
  public loanId: any = this.$route.query.id;
  // public :loanTxnId="loanId"
  public step: any = 4;
  public docId = null;
  public isOrderPlacedOnce = false;
  public isDisabled = false;

  public ConfirmationPopup() {
    this.$modal.show("titleConfirmationModal");
  }

  public ConfirmationForNA() {
    this.isComplete = true;
    this.saveTitleReceived();
    this.$modal.hide("requestDocs");
  }

  public async getListOfDocs() {
    this.changedValues = [];
    this.callLoader(true, null);
    try {
      let response = await Axios.get(
        BASE_API_URL +
          "processor/getListOfDocsForTitleReceived/" +
          this.$route.query.id +
          "/" +
          this.taskId +
          "/" +
          this.countToSendOnServer,
        {
          headers: authHeader(),
        }
      );

      if (response.status === 201) {
        console.log("------", response.data);
        this.statusDetail = response.data.statusDetails;
        this.agentContactId = response.data.agentContactId;
        this.titleReceived = response.data.taskData
          ? JSON.parse(JSON.stringify(response.data.taskData))
          : new TitleReceivedChecklist();
        this.allDocs = response.data.allDocs;
        this.showHistoryBtn = response.data.showHistoryBtn;
        if (response.data.appraisalData) {
          this.titleReceived.hasPropertyOwnedForAtLeast91Days =
            response.data.appraisalData.hasPropertyOwnedForAtLeast91Days;
          this.titleReceived.isOwnerOwnPropertLessThan180Days =
            response.data.appraisalData.isOwnerOwnPropertLessThan180Days;
        }
        this.isCompleted = response.data.isCompleted;
        this.titleReceivedData = JSON.parse(JSON.stringify(this.titleReceived));
        this.loanPurpose = response.data.loanPurpose.name;

        this.allDocs = this.allDocs.map((element) => {
          let msg = "";
          let status = false;
          let notifyed = false;
          if (
            element.allRejectedComments &&
            element.allRejectedComments.length > 0
          ) {
            for (let i = 0; i < element.allRejectedComments.length; i++) {
              const e = element.allRejectedComments[i];
              msg += `${e.comment} ${
                e.acceptedOrRejectedOn
                  ? moment(e.acceptedOrRejectedOn).format(
                      "MM/DD/YYYY, h:mm:ss a"
                    )
                  : ""
              } -${e.name ? e.name : ""} *** `;
            }
          }
          if (element.docFiles && element.docFiles.length > 0) {
            for (let j = 0; j < element.docFiles.length; j++) {
              const e = element.docFiles[j];
              status = e.status == "Accept" ? true : false;

              notifyed = e.files[e.files.length - 1]
                ? e.files[e.files.length - 1].isNotified
                : false;
            }
          }
          element["msgString"] = msg;
          let msg2 = "";

          if (
            element.allAcceptedComments &&
            element.allAcceptedComments.length > 0
          ) {
            for (let j = 0; j < element.allAcceptedComments.length; j++) {
              const e = element.allAcceptedComments[j];
              msg2 += `${
                e.acceptedOrRejectedOn
                  ? moment(e.acceptedOrRejectedOn).format(
                      "MM/DD/YYYY, h:mm:ss a"
                    )
                  : ""
              } -${e.name ? e.name : ""} *** `;
            }
          }
          element["msgString1"] = msg2;

          element["Status"] = status;
          element["isNotified"] = notifyed;
          return element;
        });
        this.isBrokerNotifiedForRejectedDocs();
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  // public async checkAllDocsUploaded() {
  //   let notUploaded: any = null;
  //   // if(this.loanPurpose === 'Purchase') {
  //     notUploaded = this.allDocs.filter(
  //       (E) =>
  //       E.docStatus != "Uploaded" &&
  //       E.docName != "Payoff Demand" &&
  //       E.docName != "Survey/Survey Affidavit"
  //       ).length;
  //   // }
  //   //   else {
  //   //  notUploaded = this.allDocs.filter(
  //   //   (E) => E.docStatus != "Uploaded"
  //   //   ).length;
  //   //   }
  //   notUploaded = notUploaded > 0 ? true : false;
  //   return notUploaded;
  // }
  public async checkAllDocsUploaded() {
    let notDocVerify: any = null;
    notDocVerify = this.allDocs.filter(
      (E) =>
        E.Status == false &&
        E.docName != "Payoff Demand" &&
        E.docName != "Survey/Survey Affidavit"
    ).length;

    notDocVerify = notDocVerify > 0 ? true : false;
    return notDocVerify;
  }

  public async checkAllRevisedDocsUploaded() {
    let notUploaded = this.allDocs.filter(
      (E) => E.revisedDoc && E.docLength == 1
    ).length;
    notUploaded = notUploaded > 0 ? true : false;
    return notUploaded;
  }

  public async saveTitleReceived() {
   // if (this.isOrderPlacedOnce == true) return;
    this.callLoader(true, null);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getListOfDocs();
        return;
      }
      if (
        this.isComplete &&
        // this.statusDetail.statusDetails && this.statusDetail.statusDetails.status == 1
        this.titleReceived.hasPropertyOwnedForAtLeast91Days.mode == "No"
      ) {
        this.error.show = true;
        this.error.msg = `Loan has been put on Hold `;
        this.isDisabled = false;

        this.callLoader(false, null);
        return;
      }
      if (this.isComplete && !this.titleReceived.isNA) {
        if (await this.checkAllDocsUploaded()) {
          this.error.show = true;
          this.error.msg = "Please Approve all documents.";
          this.callLoader(false, null);
          return;
        }
        if (await this.checkAllRevisedDocsUploaded()) {
          this.error.show = true;
          this.error.msg = "Please Upload all Revised documents.";
          this.callLoader(false, null);
          return;
        }
        if (this.titleReceived.isTaxesOn1003AsMonthlyFigure.mode == "No") {
          this.error.show = true;
          this.error.msg = "Please select yes for the Question No. 13";
          this.callLoader(false, null);
          return;
        }
      }
      if (this.isComplete && !this.titleReceived.isNA)
        this.isOrderPlacedOnce = true;
      else if (
        this.isComplete &&
        !this.titleReceived.remark &&
        this.titleReceived.isNA
      ) {
        this.error.show = true;
        this.error.msg = "Please Add Remark";
        this.callLoader(false, null);
        return;
      }
      let response = await Axios.post(
        BASE_API_URL + "processor/saveTitleReceived",
        {
          loanId: this.$route.query.id,
          taskId: this.taskId,
          isComplete: this.isComplete,
          titleReceived: this.titleReceived,
          reOrderCount: this.countToSendOnServer,
          changedValues: this.changedValues,
        },
        {
          headers: authHeader(),
        }
      );

      if (response.status === 201 && this.isComplete)
        this.$snotify.success("Title Received Completed successfully.");
      await this.getListOfDocs();
      

      this.error.show = false;
      this.error.msg = null;
      this.titleReceived.hasPropertyOwnedForAtLeast91Days.mode == "No" &&
      this.statusDetail.statusDetails.status != 1
        ? this.$snotify.success("Loan has been put on Hold")
        : null;
      if (this.titleReceived.hasPropertyOwnedForAtLeast91Days.mode == "No")
        this.$modal.hide("status-modal");
      this.isDisabled = false;
      if (
        this.isComplete ||
        this.titleReceived.hasPropertyOwnedForAtLeast91Days.mode == "No"
      )
        await this.$emit("refresh-task-list", true);
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async handleChange(currentVal, previousVal, fieldName) {
    let index = this.changedValues.findIndex((c) => c.name === fieldName);
    if (index !== -1) {
      if (currentVal === previousVal) {
        this.changedValues.splice(index, 1);
      } else {
        let data = this.changedValues.find((v) => v.name === fieldName);
        data.from = previousVal;
        data.to = currentVal;
      }
    } else {
      this.changedValues.push({
        name: fieldName,
        from: previousVal,
        to: currentVal,
      });
    }
  }

  public async viewDocument(document) {
    try {
      await this.$refs.ref["showFile"]({
        documentId: document.documentId,
        loanId: document.loanId,
        docName: document.docName,
        docCategory: document.docCategory,
        isRejected: this.isRejected,
        isAccepted: this.isAccepted,
        isChecklist: this.isChecklist,
      });
    } catch (error) {
      console.log(error);
    }
  }

  public async uploadFile(event, doc, status) {
    status != "Revised"
      ? (doc.isUploading = true)
      : (doc.revisedUploading = true);
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        status != "Revised"
          ? (doc.isUploading = false)
          : (doc.revisedUploading = false);
      }
      if (event.target.files.length <= 0) {
        status != "Revised"
          ? (doc.isUploading = false)
          : (doc.revisedUploading = false);
        return;
      }

      const formData: any = new FormData();
      const loanId: any = this.$route.query.id;

      Array.from(event.target.files).forEach((element) => {
        formData.append("file", element);
      });

      formData.append("loanId", loanId);
      formData.append("reOrderCount", this.countToSendOnServer);
      formData.append("docId", doc.documentId);
      formData.append("taskId", this.taskId);

      const response = await Axios.post(
        BASE_API_URL + "processor/uploadTitleDocs",
        formData,
        {
          headers: authHeader(),
        }
      );

      if (response.status === 201) {
        doc.docLength = response.data.hasDoc.docLength;
        doc.showEye = response.data.hasDoc.showEye;
        doc.docStatus = response.data.hasDoc.docStatus;
        this.$snotify.success("Document has been uploaded");
      }
      // this.isComplete = false;
      // await this.saveTitleReceived()
      await this.$emit("refresh-task-list", true);
    } catch (error) {
      console.log(error);
    }
    status != "Revised"
      ? (doc.isUploading = false)
      : (doc.revisedUploading = false);
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  public async addSpecificDocument() {
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        return;
      }
      this.inProcess = true;
      let res = await Axios.post(
        BASE_API_URL + "loanChecklist/addSpecificDocument",
        {
          loanId: this.$route.query.id,
          name: this.documentName,
          taskId: this.taskId,
          reOrderCount: this.countToSendOnServer,
        },
        {
          headers: authHeader(),
        }
      );
      if ((res.status = 201)) {
        this.$snotify.success("Document has been Added.");
        this.$modal.hide("addDocument");
        await this.getListOfDocs();
        this.documentName = null;
      }
    } catch (error) {
      console.log(error);
    }
    this.inProcess = false;
  }

  public async deleteDoc(documentId) {
    try {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        return;
      }
      await this.$refs.refs["delConfirmationBox"]({
        msg: `Are you sure want to delete this Document ?`,
        data: {
          loanId: this.$route.query.id,
          documentId: documentId,
        },
        apiPath: "processor/deleteDoc",
      });
    } catch (error) {
      console.log(error);
    }
  }

  public async sendFollowUpMailToTitleAgent() {
    this.callLoader(true, 1);
    try {
      this.$refs.refFollowUp["openFollowUpModal"]({
        label: "Follow Up Email",
        message: "Follow Up",
        apiPath: `processor/sendFollowUpMailToTitleAgent`,
        data: {
          loanId: this.$route.query.id,
          taskId: this.taskId,
          reOrderCount: this.countToSendOnServer,
          agentContactId: this.agentContactId,
        },
      });
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async viewFollowUpHistory() {
    try {
      this.$refs.refFollowUp["openHistoryModal"]({
        label: "Select method to send",
        message: "Follow Up",
        apiPath: `processor/getFollowUpHistoryOfTitle`,
        data: {
          loanId: this.$route.query.id,
          taskId: this.taskId,
          reOrderCount: this.countToSendOnServer,
        },
      });
    } catch (error) {
      console.log(error);
    }
  }

  public async showHistory() {
    this.callLoader(true, 1);
    try {
      this.$refs.refReOrder["showHistory"](this.taskId);
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public openStatusModal() {
    this.$modal.show("status-modal");
  }

  public showUploadRevisedReportDiv(doc) {
    let show = false;
    let value;
    let key;
    for ([key, value] of Object.entries(this.titleReceived)) {
      if (
        value &&
        Object.keys(value).length > 0 &&
        key != "createdBy" &&
        key != "createdOn" &&
        key != "doc" &&
        key != "updatedBy" &&
        key != "updatedOn"
      ) {
        if (
          doc.docName == "Tax Cert" &&
          (key == "isTaxCertReflectSubjectProperty" ||
            key == "isTaxCertReflectCorrectOwner")
        ) {
          if (value.mode == "No" && value.revisionRequestedOn) {
            show = true;
            doc.revisedDoc = true;
          }
        }
        if (
          doc.docName == "E&O" &&
          (key == "EOValidThroughClosingDate" ||
            key == "titleCompanyOnEO" ||
            key == "EOSignedByAuthorizedRepresentative")
        ) {
          if (value.mode == "No" && value.revisionRequestedOn) {
            show = true;
            doc.revisedDoc = true;
          }
        }
        if (
          doc.docName == "Chain of Title" &&
          key == "deedProvidedAsRefInchainOfTitle"
        ) {
          if (value.mode == "No" && value.revisionRequestedOn) {
            show = true;
          }
        }
        if (
          doc.docName == "Title commitment" &&
          (key == "isCommitmentReflectAuthorizedSign" ||
            key == "isCommitmentDateIsWithin60Days")
        ) {
          if (value.mode == "No" && value.revisionRequestedOn) {
            show = true;
            doc.revisedDoc = true;
          }
        }
        if (
          doc.docName == "Wiring Instructions" &&
          key == "titleCompanyOnWiringInstruction"
        ) {
          if (value.mode == "No" && value.revisionRequestedOn) {
            show = true;
            doc.revisedDoc = true;
          }
        }
        if (
          doc.docName == "Preliminary CD" &&
          (key == "borrowerNameOnPrelimCD" ||
            key == "propertyAddressOnPrelimCD" ||
            key == "lenderLoanNumberOnPrelimCD" ||
            key == "salesPriceOnPrelimCD" ||
            key == "loanAmountOnPrelimCD")
        ) {
          if (value.mode == "No" && value.revisionRequestedOn) {
            show = true;
            doc.revisedDoc = true;
          }
        }
        if (
          doc.docName == "CPL" &&
          (key == "borrowerNameOnCPL" ||
            key == "mortgageClauseOnCPL" ||
            key == "propertyAddressOnCPL" ||
            key == "lenderLoanNumberOnCPL" ||
            key == "cPLDatedWithin30Days" ||
            key == "cPLSignedByAuthorizedSignatory")
        ) {
          if (value.mode == "No" && value.revisionRequestedOn) {
            show = true;
            doc.revisedDoc = true;
          }
        }
      }
    }
    return show;
  }

  public unSetHoldCondition() {
    this.titleReceived.hasPropertyOwnedForAtLeast91Days.mode == "No"
      ? (this.titleReceived.hasPropertyOwnedForAtLeast91Days.mode = "Yes")
      : null;
  }

  public async allMountFunction(count) {
    this.countToSendOnServer = count;
    await this.getListOfDocs();
    if (
      this.titleReceived.isOwnerOwnPropertLessThan180Days.mode == "Yes" &&
      this.titleReceived.hasPropertyOwnedForAtLeast91Days.mode == "No" &&
      !this.isComplete &&
      !this.isLoanInactive
    ) {
      await this.unSetHoldCondition();
      await this.saveTitleReceived();
    }
  }

  public async isBrokerNotifiedForRejectedDocs() {
    try {
      let res = await Axios.get(
        BASE_API_URL +
          "loanChecklist/isBrokerNotifiedForRejectedDocs/" +
          this.loanId +
          "/" +
          6 +
          "/" +
          this.step,
        {
          headers: authHeader(),
        }
      );
      if (res.status == 201) {
        this.isAgentNotified = res.data.notifyBorker;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public mounted() {
 // this.isOrderPlacedOnce = false;
    this.allMountFunction(this.reOrderCount);
    this.isBrokerNotifiedForRejectedDocs();
  }
}
