var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-2 white-card loan-process-height-249 d-flex flex-column"},[_c('div',{staticClass:"loan-process-heading"},[_c('div',{staticClass:"col-lg-12 d-flex justify-content-between"},[_c('p',{staticClass:"heading mb-0"},[_vm._v("Flood Binder Received")]),_c('div',{staticClass:"d-flex align-items-center"},[_vm._m(0),_c('div',{staticClass:"d-flex align-items-center border_solid_radius"},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"border_solid_right",class:[_vm.isTaskCompleted
            ? 'btn-upload cursor-pointer cursor-not-allow'
            : 'btn-upload cursor-pointer' ],attrs:{"type":"button","title":"Send Follow Up Mail to Agent"},on:{"click":function($event){_vm.isTaskCompleted ? '' : _vm.sendFollowUpMailToFloodAgent()}}},[_c('i',{staticClass:"fa fa-plus font__size12 text__blue mx-2 cursor-pointer",class:[
            _vm.isTaskCompleted
            ? 'btn-upload cursor-pointer cursor-not-allow'
            : 'btn-upload cursor-pointer' ]})]),_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"fa fa-eye text__blue mx-2 font__size12 cursor-pointer",attrs:{"aria-hidden":"true","title":"View"},on:{"click":function($event){return _vm.viewFollowUpHistory()}}})])])])]),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.saveFloodBinderReceivedTask)}}},[_c('div',{staticClass:"col-lg-12 pt-3 pb-3 flex-1 height_100"},[_c('div',{staticClass:"form-row m-0 flex-1 flex-column min_293 col-12 px-1"},[_c('div',{staticClass:"flex-1"},[_c('div',{staticClass:"col-lg-12 p-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 col-xl-6 col-md-12 col-sm-12 mb-2"},[_c('p',{staticClass:"content-text m-0"},[_c('b',[_vm._v("Reports")])]),_c('hr',{staticClass:"loan-process-border-top mb-2 mt-1"}),_c('div',{staticClass:"pt-1"},[_c('div',{staticClass:"custom-checkbox d-flex align-items-center mb-0"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.floodBinderReceived.isFloodBinderRecieved),expression:"floodBinderReceived.isFloodBinderRecieved"}],staticClass:"custom-checkbox-input",attrs:{"type":"checkbox","id":"a","disabled":_vm.isTaskCompleted},domProps:{"checked":Array.isArray(_vm.floodBinderReceived.isFloodBinderRecieved)?_vm._i(_vm.floodBinderReceived.isFloodBinderRecieved,null)>-1:(_vm.floodBinderReceived.isFloodBinderRecieved)},on:{"change":[function($event){var $$a=_vm.floodBinderReceived.isFloodBinderRecieved,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.floodBinderReceived, "isFloodBinderRecieved", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.floodBinderReceived, "isFloodBinderRecieved", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.floodBinderReceived, "isFloodBinderRecieved", $$c)}},function($event){_vm.error = { show: false, msg: null };_vm.isComplete = false;
                      _vm.handleChange(_vm.floodBinderReceived.isFloodBinderRecieved, _vm.floodBinderReceivedData.isFloodBinderRecieved, 'Flood Binder Received');_vm.saveFloodBinderReceivedTask()}]}}),_c('label',{staticClass:"label",attrs:{"for":"a"}},[_vm._v("Flood Binder Received")]),_c('div',{staticClass:"indicator"})]),_c('div',{staticClass:"col-lg-12 d_flex_704 align-items-center p-0"},[_c('div',{staticClass:"form-group d-flex align-items-center"},[(!_vm.floodBinderReceived.loanChecklistData)?_c('div',{staticClass:"file-upload mt-1 overflow_hidden"},[_c('label',{staticClass:"file-upload-label mb-0",attrs:{"for":"upload"}},[_c('i',{staticClass:"fa fa-upload mr-1",attrs:{"aria-hidden":"true"}}),_vm._v(_vm._s(_vm.docLoader ? "Uploading..." : "Upload Flood Binder Report")+" ")]),_c('input',{staticClass:"file-upload-input",class:[
                            _vm.floodBinderReceived.isCompleted || _vm.floodBinderReceived.loanChecklistData
                            ? 'btn-upload cursor-pointer cursor-not-allow'
                            : 'btn-upload cursor-pointer' ],attrs:{"id":"upload","type":"file","name":"file-upload","multiple":"","disabled":_vm.isTaskCompleted || _vm.floodBinderReceived.loanChecklistData},on:{"change":function($event){_vm.uploadReport($event,null);
                            _vm.error = { show: false, msg: null };}}})]):_c('div',[_c('label',{staticClass:"label",attrs:{"for":"a"}},[_vm._v("Uploaded Flood Binder Report")])]),(_vm.floodBinderReceived.loanChecklistData)?_c('div',{staticClass:"d-flex align-items-center"},[_c('p',{staticClass:"mb-0 card-content-title ml-2  app-count"},[_c('b',[_vm._v(_vm._s(_vm.floodBinderReceived.loanChecklistData.docLength))])]),_c('i',{staticClass:"ri-file-text-line ml-2 mr-2 text__blue cursor-pointer",on:{"click":function($event){return _vm.viewDocument(_vm.floodBinderReceived.loanChecklistData)}}})]):_vm._e()]),(_vm.floodBinderReceived.loanChecklistData && _vm.showUploadRevisedReportDiv())?_c('div',{staticClass:"d-flex top-2"},[_c('label',{staticClass:"file-upload-label mb-0",attrs:{"for":"upload1"}},[_vm._v(" "+_vm._s(_vm.revisedUploading ? 'Uploading..' : 'Upload Updated Report')+" ")]),_c('div',{staticClass:"file-upload mt-1 overflow_hidden"},[_c('input',{staticClass:"file-upload-input",attrs:{"id":"upload1","disabled":_vm.isTaskCompleted,"type":"file","name":"file-upload","multiple":""},on:{"change":function($event){return _vm.uploadReport($event, 'Revised');}}})])]):_vm._e()]),_c('div')])]),_c('div',{staticClass:"col-lg-6 col-xl-6 col-md-12 col-sm-12 border_left_991"},[_c('p',{staticClass:"content-text m-0"},[_c('b',[_vm._v("Checklist")])]),_c('hr',{staticClass:"loan-process-border-top mb-2 mt-1"}),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 p-0"},[_c('p',{staticClass:"content-text-black mt-1 mb-1"},[_c('span',[_vm._v("1.")]),_vm._v(" Are the Borrowers name accurately reflected? ")]),_c('div',{staticClass:"mb-2"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0 ml-3"},[_c('span',{staticClass:"radio-text"},[_vm._v("Yes")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.floodBinderReceived.isBorrowerNameAccuratelyReflected.mode),expression:"floodBinderReceived.isBorrowerNameAccuratelyReflected.mode"}],attrs:{"type":"radio","value":"Yes","disabled":_vm.isTaskCompleted},domProps:{"checked":_vm._q(_vm.floodBinderReceived.isBorrowerNameAccuratelyReflected.mode,"Yes")},on:{"change":[function($event){return _vm.$set(_vm.floodBinderReceived.isBorrowerNameAccuratelyReflected, "mode", "Yes")},function($event){_vm.isComplete = false; 
                              _vm.handleChange(_vm.floodBinderReceived.isBorrowerNameAccuratelyReflected.mode, _vm.floodBinderReceivedData.isBorrowerNameAccuratelyReflected.mode, 'Are the Borrowers name accurately reflected?');_vm.saveFloodBinderReceivedTask()}],"click":function($event){_vm.floodBinderReceived.isBorrowerNameAccuratelyReflected.revisionRequestedOn = null;
                              _vm.floodBinderReceived.isBorrowerNameAccuratelyReflected.revisionCompletedOn = null; _vm.floodBinderReceived.isBorrowerNameAccuratelyReflected.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("No")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.floodBinderReceived.isBorrowerNameAccuratelyReflected.mode),expression:"floodBinderReceived.isBorrowerNameAccuratelyReflected.mode"}],attrs:{"type":"radio","value":"No","disabled":_vm.isTaskCompleted},domProps:{"checked":_vm._q(_vm.floodBinderReceived.isBorrowerNameAccuratelyReflected.mode,"No")},on:{"change":[function($event){return _vm.$set(_vm.floodBinderReceived.isBorrowerNameAccuratelyReflected, "mode", "No")},function($event){_vm.isComplete = false; 
                              _vm.handleChange(_vm.floodBinderReceived.isBorrowerNameAccuratelyReflected.mode, _vm.floodBinderReceivedData.isBorrowerNameAccuratelyReflected.mode, 'Are the Borrowers name accurately reflected?');_vm.saveFloodBinderReceivedTask()}],"click":function($event){_vm.floodBinderReceived.isBorrowerNameAccuratelyReflected.revisionRequestedOn = null;
                              _vm.floodBinderReceived.isBorrowerNameAccuratelyReflected.revisionCompletedOn = null;_vm.floodBinderReceived.isBorrowerNameAccuratelyReflected.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("NA")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.floodBinderReceived.isBorrowerNameAccuratelyReflected.mode),expression:"floodBinderReceived.isBorrowerNameAccuratelyReflected.mode"}],attrs:{"type":"radio","value":"NA","disabled":_vm.isTaskCompleted},domProps:{"checked":_vm._q(_vm.floodBinderReceived.isBorrowerNameAccuratelyReflected.mode,"NA")},on:{"change":[function($event){return _vm.$set(_vm.floodBinderReceived.isBorrowerNameAccuratelyReflected, "mode", "NA")},function($event){_vm.isComplete = false; 
                              _vm.handleChange(_vm.floodBinderReceived.isBorrowerNameAccuratelyReflected.mode, _vm.floodBinderReceivedData.isBorrowerNameAccuratelyReflected.mode, 'Are the Borrowers name accurately reflected?');_vm.saveFloodBinderReceivedTask()}],"click":function($event){_vm.floodBinderReceived.isBorrowerNameAccuratelyReflected.revisionRequestedOn = null;
                              _vm.floodBinderReceived.isBorrowerNameAccuratelyReflected.revisionCompletedOn = null; _vm.floodBinderReceived.isBorrowerNameAccuratelyReflected.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)})],1),_c('div',{staticClass:"row m-0"},[(_vm.floodBinderReceived.isBorrowerNameAccuratelyReflected.mode == 'No')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('label',{staticClass:"label ml-3",attrs:{"for":"initial"}},[_vm._v("Revision Requested")]),_c('div',{staticClass:"d-flex align-items-center ml-3"},[_c('datepicker',{class:[errors.length>0?'mx-input-error':''],attrs:{"disabled":_vm.isTaskCompleted,"valueType":"format"},on:{"change":function($event){_vm.isComplete = false; 
                            _vm.handleChange(_vm.floodBinderReceived.isBorrowerNameAccuratelyReflected.revisionRequestedOn, _vm.floodBinderReceivedData.isBorrowerNameAccuratelyReflected.revisionRequestedOn, 'Are the Borrowers name accurately reflected? Revision Requested');_vm.saveFloodBinderReceivedTask()}},model:{value:(_vm.floodBinderReceived.isBorrowerNameAccuratelyReflected.revisionRequestedOn),callback:function ($$v) {_vm.$set(_vm.floodBinderReceived.isBorrowerNameAccuratelyReflected, "revisionRequestedOn", $$v)},expression:"floodBinderReceived.isBorrowerNameAccuratelyReflected.revisionRequestedOn"}})],1)]}}],null,true)})],1):_vm._e(),(_vm.floodBinderReceived.isBorrowerNameAccuratelyReflected.mode == 'No')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('label',{staticClass:"label",attrs:{"for":"initial"}},[_vm._v("Revision Completed")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('datepicker',{class:[errors.length>0?'mx-input-error':''],attrs:{"disabled":_vm.isTaskCompleted,"valueType":"format","disabled-date":function (date) { return date <= _vm.$moment(new Date(_vm.floodBinderReceived.isBorrowerNameAccuratelyReflected.revisionRequestedOn).setDate(new Date(_vm.floodBinderReceived.isBorrowerNameAccuratelyReflected.revisionRequestedOn).getDate() -1 )); }},on:{"change":function($event){_vm.isComplete = false; 
                          _vm.handleChange(_vm.floodBinderReceived.isBorrowerNameAccuratelyReflected.revisionCompletedOn, _vm.floodBinderReceivedData.isBorrowerNameAccuratelyReflected.revisionCompletedOn, 'Are the Borrowers name accurately reflected? Revision Completed');_vm.saveFloodBinderReceivedTask()}},model:{value:(_vm.floodBinderReceived.isBorrowerNameAccuratelyReflected.revisionCompletedOn),callback:function ($$v) {_vm.$set(_vm.floodBinderReceived.isBorrowerNameAccuratelyReflected, "revisionCompletedOn", $$v)},expression:"floodBinderReceived.isBorrowerNameAccuratelyReflected.revisionCompletedOn"}})],1)]}}],null,true)})],1):_vm._e()]),(_vm.floodBinderReceived.isBorrowerNameAccuratelyReflected.mode == 'NA')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 p-0"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label ml-3",attrs:{"for":"creditorName"}},[_vm._v("Add remarks")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.floodBinderReceived.isBorrowerNameAccuratelyReflected.nARemark),expression:"floodBinderReceived.isBorrowerNameAccuratelyReflected.nARemark"}],staticClass:"login-input line_height_40 ml-3",attrs:{"type":"text","disabled":_vm.isTaskCompleted,"name":"Add remarks"},domProps:{"value":(_vm.floodBinderReceived.isBorrowerNameAccuratelyReflected.nARemark)},on:{"change":function($event){_vm.isComplete = false; 
                        _vm.handleChange(_vm.floodBinderReceived.isBorrowerNameAccuratelyReflected.nARemark, _vm.floodBinderReceivedData.isBorrowerNameAccuratelyReflected.nARemark, 'Are the Borrowers name accurately reflected? Remarks');_vm.saveFloodBinderReceivedTask()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.floodBinderReceived.isBorrowerNameAccuratelyReflected, "nARemark", $event.target.value)}}})])]):_vm._e()]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 p-0"},[_c('p',{staticClass:"content-text-black mt-1 mb-1"},[_c('span',[_vm._v("2.")]),_vm._v(" Is the property address accurately reflected? ")]),_c('div',{staticClass:"mb-2"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0 ml-3"},[_c('span',{staticClass:"radio-text"},[_vm._v("Yes")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.floodBinderReceived.isPropertyAddressAccuratelyReflected.mode),expression:"floodBinderReceived.isPropertyAddressAccuratelyReflected.mode"}],attrs:{"type":"radio","value":"Yes","disabled":_vm.isTaskCompleted},domProps:{"checked":_vm._q(_vm.floodBinderReceived.isPropertyAddressAccuratelyReflected.mode,"Yes")},on:{"change":[function($event){return _vm.$set(_vm.floodBinderReceived.isPropertyAddressAccuratelyReflected, "mode", "Yes")},function($event){_vm.isComplete = false; 
                        _vm.handleChange(_vm.floodBinderReceived.isPropertyAddressAccuratelyReflected.mode, _vm.floodBinderReceivedData.isPropertyAddressAccuratelyReflected.mode, 'Is the property address accurately reflected?');_vm.saveFloodBinderReceivedTask()}],"click":function($event){_vm.floodBinderReceived.isPropertyAddressAccuratelyReflected.revisionRequestedOn = null;
                        _vm.floodBinderReceived.isPropertyAddressAccuratelyReflected.revisionCompletedOn = null; _vm.floodBinderReceived.isPropertyAddressAccuratelyReflected.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("No")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.floodBinderReceived.isPropertyAddressAccuratelyReflected.mode),expression:"floodBinderReceived.isPropertyAddressAccuratelyReflected.mode"}],attrs:{"type":"radio","value":"No","disabled":_vm.isTaskCompleted},domProps:{"checked":_vm._q(_vm.floodBinderReceived.isPropertyAddressAccuratelyReflected.mode,"No")},on:{"change":[function($event){return _vm.$set(_vm.floodBinderReceived.isPropertyAddressAccuratelyReflected, "mode", "No")},function($event){_vm.isComplete = false; 
                        _vm.handleChange(_vm.floodBinderReceived.isPropertyAddressAccuratelyReflected.mode, _vm.floodBinderReceivedData.isPropertyAddressAccuratelyReflected.mode, 'Is the property address accurately reflected?');_vm.saveFloodBinderReceivedTask()}],"click":function($event){_vm.floodBinderReceived.isPropertyAddressAccuratelyReflected.revisionRequestedOn = null;
                        _vm.floodBinderReceived.isPropertyAddressAccuratelyReflected.revisionCompletedOn = null;_vm.floodBinderReceived.isPropertyAddressAccuratelyReflected.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("NA")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.floodBinderReceived.isPropertyAddressAccuratelyReflected.mode),expression:"floodBinderReceived.isPropertyAddressAccuratelyReflected.mode"}],attrs:{"type":"radio","value":"NA","disabled":_vm.isTaskCompleted},domProps:{"checked":_vm._q(_vm.floodBinderReceived.isPropertyAddressAccuratelyReflected.mode,"NA")},on:{"change":[function($event){return _vm.$set(_vm.floodBinderReceived.isPropertyAddressAccuratelyReflected, "mode", "NA")},function($event){_vm.isComplete = false; 
                        _vm.handleChange(_vm.floodBinderReceived.isPropertyAddressAccuratelyReflected.mode, _vm.floodBinderReceivedData.isPropertyAddressAccuratelyReflected.mode, 'Is the property address accurately reflected?');_vm.saveFloodBinderReceivedTask()}],"click":function($event){_vm.floodBinderReceived.isPropertyAddressAccuratelyReflected.revisionRequestedOn = null;
                        _vm.floodBinderReceived.isPropertyAddressAccuratelyReflected.revisionCompletedOn = null; _vm.floodBinderReceived.isPropertyAddressAccuratelyReflected.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)})],1),_c('div',{staticClass:"row m-0"},[(_vm.floodBinderReceived.isPropertyAddressAccuratelyReflected.mode == 'No')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('label',{staticClass:"label ml-3",attrs:{"for":"initial"}},[_vm._v("Revision Requested")]),_c('div',{staticClass:"d-flex align-items-center ml-3"},[_c('datepicker',{class:[errors.length>0?'mx-input-error':''],attrs:{"disabled":_vm.isTaskCompleted,"valueType":"format"},on:{"change":function($event){_vm.isComplete = false; 
                        _vm.handleChange(_vm.floodBinderReceived.isPropertyAddressAccuratelyReflected.revisionRequestedOn, _vm.floodBinderReceivedData.isPropertyAddressAccuratelyReflected.revisionRequestedOn, 'Is the property address accurately reflected? Revision Requested');_vm.saveFloodBinderReceivedTask()}},model:{value:(_vm.floodBinderReceived.isPropertyAddressAccuratelyReflected.revisionRequestedOn),callback:function ($$v) {_vm.$set(_vm.floodBinderReceived.isPropertyAddressAccuratelyReflected, "revisionRequestedOn", $$v)},expression:"floodBinderReceived.isPropertyAddressAccuratelyReflected.revisionRequestedOn"}})],1)]}}],null,true)})],1):_vm._e(),(_vm.floodBinderReceived.isPropertyAddressAccuratelyReflected.mode == 'No')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('label',{staticClass:"label",attrs:{"for":"initial"}},[_vm._v("Revision Completed")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('datepicker',{class:[errors.length>0?'mx-input-error':''],attrs:{"disabled":_vm.isTaskCompleted,"valueType":"format","disabled-date":function (date) { return date <= _vm.$moment(new Date(_vm.floodBinderReceived.isPropertyAddressAccuratelyReflected.revisionRequestedOn).setDate(new Date(_vm.floodBinderReceived.isPropertyAddressAccuratelyReflected.revisionRequestedOn).getDate() - 1)); }},on:{"change":function($event){_vm.isComplete = false; 
                      _vm.handleChange(_vm.floodBinderReceived.isPropertyAddressAccuratelyReflected.revisionCompletedOn, _vm.floodBinderReceivedData.isPropertyAddressAccuratelyReflected.revisionCompletedOn, 'Is the property address accurately reflected? Revision Completed');_vm.saveFloodBinderReceivedTask()}},model:{value:(_vm.floodBinderReceived.isPropertyAddressAccuratelyReflected.revisionCompletedOn),callback:function ($$v) {_vm.$set(_vm.floodBinderReceived.isPropertyAddressAccuratelyReflected, "revisionCompletedOn", $$v)},expression:"floodBinderReceived.isPropertyAddressAccuratelyReflected.revisionCompletedOn"}})],1)]}}],null,true)})],1):_vm._e()]),(_vm.floodBinderReceived.isPropertyAddressAccuratelyReflected.mode == 'NA')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 p-0"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label ml-3",attrs:{"for":"creditorName"}},[_vm._v("Add remarks")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.floodBinderReceived.isPropertyAddressAccuratelyReflected.nARemark),expression:"floodBinderReceived.isPropertyAddressAccuratelyReflected.nARemark"}],staticClass:"login-input line_height_40 ml-3",attrs:{"type":"text","disabled":_vm.isTaskCompleted,"name":"Add remarks"},domProps:{"value":(_vm.floodBinderReceived.isPropertyAddressAccuratelyReflected.nARemark)},on:{"change":function($event){_vm.isComplete = false; 
                    _vm.handleChange(_vm.floodBinderReceived.isPropertyAddressAccuratelyReflected.nARemark, _vm.floodBinderReceivedData.isPropertyAddressAccuratelyReflected.nARemark, 'Is the property address accurately reflected? Remarks');_vm.saveFloodBinderReceivedTask()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.floodBinderReceived.isPropertyAddressAccuratelyReflected, "nARemark", $event.target.value)}}})])]):_vm._e()]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 p-0"},[_c('p',{staticClass:"content-text-black mt-1 mb-1"},[_c('span',[_vm._v("3.")]),_vm._v(" Is the Effective Date on or no more than 7 days after the Closing Date (Purchase)? ")]),_c('div',{staticClass:"mb-2"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0 ml-3"},[_c('span',{staticClass:"radio-text"},[_vm._v("Yes")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.floodBinderReceived.isEffectiveDateOnOrNoMoreThan7Days.mode),expression:"floodBinderReceived.isEffectiveDateOnOrNoMoreThan7Days.mode"}],attrs:{"type":"radio","value":"Yes","disabled":_vm.isTaskCompleted},domProps:{"checked":_vm._q(_vm.floodBinderReceived.isEffectiveDateOnOrNoMoreThan7Days.mode,"Yes")},on:{"change":[function($event){return _vm.$set(_vm.floodBinderReceived.isEffectiveDateOnOrNoMoreThan7Days, "mode", "Yes")},function($event){_vm.isComplete = false; 
                    _vm.handleChange(_vm.floodBinderReceived.isEffectiveDateOnOrNoMoreThan7Days.mode, _vm.floodBinderReceivedData.isEffectiveDateOnOrNoMoreThan7Days.mode, 'Is the Effective Date on or no more than 7 days after the Closing Date (Purchase)?');_vm.saveFloodBinderReceivedTask()}],"click":function($event){_vm.floodBinderReceived.isEffectiveDateOnOrNoMoreThan7Days.revisionRequestedOn = null;
                    _vm.floodBinderReceived.isEffectiveDateOnOrNoMoreThan7Days.revisionCompletedOn = null; _vm.floodBinderReceived.isEffectiveDateOnOrNoMoreThan7Days.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("No")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.floodBinderReceived.isEffectiveDateOnOrNoMoreThan7Days.mode),expression:"floodBinderReceived.isEffectiveDateOnOrNoMoreThan7Days.mode"}],attrs:{"type":"radio","value":"No","disabled":_vm.isTaskCompleted},domProps:{"checked":_vm._q(_vm.floodBinderReceived.isEffectiveDateOnOrNoMoreThan7Days.mode,"No")},on:{"change":[function($event){return _vm.$set(_vm.floodBinderReceived.isEffectiveDateOnOrNoMoreThan7Days, "mode", "No")},function($event){_vm.isComplete = false; 
                    _vm.handleChange(_vm.floodBinderReceived.isEffectiveDateOnOrNoMoreThan7Days.mode, _vm.floodBinderReceivedData.isEffectiveDateOnOrNoMoreThan7Days.mode, 'Is the Effective Date on or no more than 7 days after the Closing Date (Purchase)?');_vm.saveFloodBinderReceivedTask()}],"click":function($event){_vm.floodBinderReceived.isEffectiveDateOnOrNoMoreThan7Days.revisionRequestedOn = null;
                    _vm.floodBinderReceived.isEffectiveDateOnOrNoMoreThan7Days.revisionCompletedOn = null;_vm.floodBinderReceived.isEffectiveDateOnOrNoMoreThan7Days.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("NA")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.floodBinderReceived.isEffectiveDateOnOrNoMoreThan7Days.mode),expression:"floodBinderReceived.isEffectiveDateOnOrNoMoreThan7Days.mode"}],attrs:{"type":"radio","value":"NA","disabled":_vm.isTaskCompleted},domProps:{"checked":_vm._q(_vm.floodBinderReceived.isEffectiveDateOnOrNoMoreThan7Days.mode,"NA")},on:{"change":[function($event){return _vm.$set(_vm.floodBinderReceived.isEffectiveDateOnOrNoMoreThan7Days, "mode", "NA")},function($event){_vm.isComplete = false; 
                    _vm.handleChange(_vm.floodBinderReceived.isEffectiveDateOnOrNoMoreThan7Days.mode, _vm.floodBinderReceivedData.isEffectiveDateOnOrNoMoreThan7Days.mode, 'Is the Effective Date on or no more than 7 days after the Closing Date (Purchase)?');_vm.saveFloodBinderReceivedTask()}],"click":function($event){_vm.floodBinderReceived.isEffectiveDateOnOrNoMoreThan7Days.revisionRequestedOn = null;
                    _vm.floodBinderReceived.isEffectiveDateOnOrNoMoreThan7Days.revisionCompletedOn = null; _vm.floodBinderReceived.isEffectiveDateOnOrNoMoreThan7Days.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)})],1),_c('div',{staticClass:"row m-0"},[(_vm.floodBinderReceived.isEffectiveDateOnOrNoMoreThan7Days.mode == 'No')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('label',{staticClass:"label ml-3",attrs:{"for":"initial"}},[_vm._v("Revision Requested")]),_c('div',{staticClass:"d-flex align-items-center ml-3"},[_c('datepicker',{class:[errors.length>0?'mx-input-error':''],attrs:{"disabled":_vm.isTaskCompleted,"valueType":"format"},on:{"change":function($event){_vm.isComplete = false; 
                    _vm.handleChange(_vm.floodBinderReceived.isEffectiveDateOnOrNoMoreThan7Days.revisionRequestedOn, _vm.floodBinderReceivedData.isEffectiveDateOnOrNoMoreThan7Days.revisionRequestedOn, 'Is the Effective Date on or no more than 7 days after the Closing Date (Purchase)? Revision Requested');_vm.saveFloodBinderReceivedTask()}},model:{value:(_vm.floodBinderReceived.isEffectiveDateOnOrNoMoreThan7Days.revisionRequestedOn),callback:function ($$v) {_vm.$set(_vm.floodBinderReceived.isEffectiveDateOnOrNoMoreThan7Days, "revisionRequestedOn", $$v)},expression:"floodBinderReceived.isEffectiveDateOnOrNoMoreThan7Days.revisionRequestedOn"}})],1)]}}],null,true)})],1):_vm._e(),(_vm.floodBinderReceived.isEffectiveDateOnOrNoMoreThan7Days.mode == 'No')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('label',{staticClass:"label",attrs:{"for":"initial"}},[_vm._v("Revision Completed")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('datepicker',{class:[errors.length>0?'mx-input-error':''],attrs:{"disabled":_vm.isTaskCompleted,"valueType":"format","disabled-date":function (date) { return date <= _vm.$moment(new Date (_vm.floodBinderReceived.isEffectiveDateOnOrNoMoreThan7Days.revisionRequestedOn).setDate( new Date(_vm.floodBinderReceived.isEffectiveDateOnOrNoMoreThan7Days.revisionRequestedOn).getDate() -1 )); }},on:{"change":function($event){_vm.isComplete = false; 
                  _vm.handleChange(_vm.floodBinderReceived.isEffectiveDateOnOrNoMoreThan7Days.revisionCompletedOn, _vm.floodBinderReceivedData.isEffectiveDateOnOrNoMoreThan7Days.revisionCompletedOn, 'Is the Effective Date on or no more than 7 days after the Closing Date (Purchase)? Revision Completed');_vm.saveFloodBinderReceivedTask()}},model:{value:(_vm.floodBinderReceived.isEffectiveDateOnOrNoMoreThan7Days.revisionCompletedOn),callback:function ($$v) {_vm.$set(_vm.floodBinderReceived.isEffectiveDateOnOrNoMoreThan7Days, "revisionCompletedOn", $$v)},expression:"floodBinderReceived.isEffectiveDateOnOrNoMoreThan7Days.revisionCompletedOn"}})],1)]}}],null,true)})],1):_vm._e()]),(_vm.floodBinderReceived.isEffectiveDateOnOrNoMoreThan7Days.mode == 'NA')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 p-0"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label ml-3",attrs:{"for":"creditorName"}},[_vm._v("Add remarks")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.floodBinderReceived.isEffectiveDateOnOrNoMoreThan7Days.nARemark),expression:"floodBinderReceived.isEffectiveDateOnOrNoMoreThan7Days.nARemark"}],staticClass:"login-input line_height_40 ml-3",attrs:{"type":"text","disabled":_vm.isTaskCompleted,"name":"Add remarks"},domProps:{"value":(_vm.floodBinderReceived.isEffectiveDateOnOrNoMoreThan7Days.nARemark)},on:{"change":function($event){_vm.isComplete = false; 
                _vm.handleChange(_vm.floodBinderReceived.isEffectiveDateOnOrNoMoreThan7Days.nARemark, _vm.floodBinderReceivedData.isEffectiveDateOnOrNoMoreThan7Days.nARemark, 'Is the Effective Date on or no more than 7 days after the Closing Date (Purchase)? Remarks');_vm.saveFloodBinderReceivedTask()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.floodBinderReceived.isEffectiveDateOnOrNoMoreThan7Days, "nARemark", $event.target.value)}}})])]):_vm._e()]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 p-0"},[_c('p',{staticClass:"content-text-black mt-1 mb-1"},[_c('span',[_vm._v("4.")]),_vm._v(" Is there at least 12 months of coverage reflected for Purchases? ")]),_c('div',{staticClass:"mb-2"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0 ml-3"},[_c('span',{staticClass:"radio-text"},[_vm._v("Yes")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.floodBinderReceived.hasAtLeast12MonthsOfCoverageReflectedForPurchase.mode),expression:"floodBinderReceived.hasAtLeast12MonthsOfCoverageReflectedForPurchase.mode"}],attrs:{"type":"radio","value":"Yes","disabled":_vm.isTaskCompleted},domProps:{"checked":_vm._q(_vm.floodBinderReceived.hasAtLeast12MonthsOfCoverageReflectedForPurchase.mode,"Yes")},on:{"change":[function($event){return _vm.$set(_vm.floodBinderReceived.hasAtLeast12MonthsOfCoverageReflectedForPurchase, "mode", "Yes")},function($event){_vm.isComplete = false; 
                _vm.handleChange(_vm.floodBinderReceived.hasAtLeast12MonthsOfCoverageReflectedForPurchase.mode, _vm.floodBinderReceivedData.hasAtLeast12MonthsOfCoverageReflectedForPurchase.mode, 'Is there at least 12 months of coverage reflected for Purchases?');_vm.saveFloodBinderReceivedTask()}],"click":function($event){_vm.floodBinderReceived.hasAtLeast12MonthsOfCoverageReflectedForPurchase.nARemark = null;_vm.floodBinderReceived.hasAtLeast12MonthsOfCoverageReflectedForPurchase.revisionRequestedOn = null;
                _vm.floodBinderReceived.hasAtLeast12MonthsOfCoverageReflectedForPurchase.revisionCompletedOn = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("No")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.floodBinderReceived.hasAtLeast12MonthsOfCoverageReflectedForPurchase.mode),expression:"floodBinderReceived.hasAtLeast12MonthsOfCoverageReflectedForPurchase.mode"}],attrs:{"type":"radio","value":"No","disabled":_vm.isTaskCompleted},domProps:{"checked":_vm._q(_vm.floodBinderReceived.hasAtLeast12MonthsOfCoverageReflectedForPurchase.mode,"No")},on:{"change":[function($event){return _vm.$set(_vm.floodBinderReceived.hasAtLeast12MonthsOfCoverageReflectedForPurchase, "mode", "No")},function($event){_vm.isComplete = false; 
                _vm.handleChange(_vm.floodBinderReceived.hasAtLeast12MonthsOfCoverageReflectedForPurchase.mode, _vm.floodBinderReceivedData.hasAtLeast12MonthsOfCoverageReflectedForPurchase.mode, 'Is there at least 12 months of coverage reflected for Purchases?');_vm.saveFloodBinderReceivedTask()}],"click":function($event){_vm.floodBinderReceived.hasAtLeast12MonthsOfCoverageReflectedForPurchase.revisionRequestedOn = null;
                _vm.floodBinderReceived.hasAtLeast12MonthsOfCoverageReflectedForPurchase.revisionCompletedOn = null; _vm.floodBinderReceived.hasAtLeast12MonthsOfCoverageReflectedForPurchase.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("NA")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.floodBinderReceived.hasAtLeast12MonthsOfCoverageReflectedForPurchase.mode),expression:"floodBinderReceived.hasAtLeast12MonthsOfCoverageReflectedForPurchase.mode"}],attrs:{"type":"radio","value":"NA","disabled":_vm.isTaskCompleted},domProps:{"checked":_vm._q(_vm.floodBinderReceived.hasAtLeast12MonthsOfCoverageReflectedForPurchase.mode,"NA")},on:{"change":[function($event){return _vm.$set(_vm.floodBinderReceived.hasAtLeast12MonthsOfCoverageReflectedForPurchase, "mode", "NA")},function($event){_vm.isComplete = false; 
                _vm.handleChange(_vm.floodBinderReceived.hasAtLeast12MonthsOfCoverageReflectedForPurchase.mode, _vm.floodBinderReceivedData.hasAtLeast12MonthsOfCoverageReflectedForPurchase.mode, 'Is there at least 12 months of coverage reflected for Purchases?');_vm.saveFloodBinderReceivedTask()}],"click":function($event){_vm.floodBinderReceived.hasAtLeast12MonthsOfCoverageReflectedForPurchase.nARemark = null;_vm.floodBinderReceived.hasAtLeast12MonthsOfCoverageReflectedForPurchase.revisionRequestedOn = null;
                _vm.floodBinderReceived.hasAtLeast12MonthsOfCoverageReflectedForPurchase.revisionCompletedOn = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)})],1),_c('div',{staticClass:"row m-0"},[(_vm.floodBinderReceived.hasAtLeast12MonthsOfCoverageReflectedForPurchase.mode == 'No')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('label',{staticClass:"label ml-3",attrs:{"for":"initial"}},[_vm._v("Revision Requested")]),_c('div',{staticClass:"d-flex align-items-center ml-3"},[_c('datepicker',{class:[errors.length>0?'mx-input-error':''],attrs:{"disabled":_vm.isTaskCompleted,"valueType":"format"},on:{"change":function($event){_vm.isComplete = false; 
                _vm.handleChange(_vm.floodBinderReceived.hasAtLeast12MonthsOfCoverageReflectedForPurchase.revisionRequestedOn, _vm.floodBinderReceivedData.hasAtLeast12MonthsOfCoverageReflectedForPurchase.revisionRequestedOn, 'Is there at least 12 months of coverage reflected for Purchases? Revision Requested');_vm.saveFloodBinderReceivedTask()}},model:{value:(_vm.floodBinderReceived.hasAtLeast12MonthsOfCoverageReflectedForPurchase.revisionRequestedOn),callback:function ($$v) {_vm.$set(_vm.floodBinderReceived.hasAtLeast12MonthsOfCoverageReflectedForPurchase, "revisionRequestedOn", $$v)},expression:"floodBinderReceived.hasAtLeast12MonthsOfCoverageReflectedForPurchase.revisionRequestedOn"}})],1)]}}],null,true)})],1):_vm._e(),(_vm.floodBinderReceived.hasAtLeast12MonthsOfCoverageReflectedForPurchase.mode == 'No')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('label',{staticClass:"label",attrs:{"for":"initial"}},[_vm._v("Revision Completed")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('datepicker',{class:[errors.length>0?'mx-input-error':''],attrs:{"disabled":_vm.isTaskCompleted,"valueType":"format","disabled-date":function (date) { return date <= _vm.$moment(new Date(_vm.floodBinderReceived.hasAtLeast12MonthsOfCoverageReflectedForPurchase.revisionRequestedOn).setDate( new Date(_vm.floodBinderReceived.hasAtLeast12MonthsOfCoverageReflectedForPurchase.revisionRequestedOn).getDate() -1 )); }},on:{"change":function($event){_vm.isComplete = false; 
              _vm.handleChange(_vm.floodBinderReceived.hasAtLeast12MonthsOfCoverageReflectedForPurchase.revisionCompletedOn, _vm.floodBinderReceivedData.hasAtLeast12MonthsOfCoverageReflectedForPurchase.revisionCompletedOn, 'Is there at least 12 months of coverage reflected for Purchases? Revision Completed');_vm.saveFloodBinderReceivedTask()}},model:{value:(_vm.floodBinderReceived.hasAtLeast12MonthsOfCoverageReflectedForPurchase.revisionCompletedOn),callback:function ($$v) {_vm.$set(_vm.floodBinderReceived.hasAtLeast12MonthsOfCoverageReflectedForPurchase, "revisionCompletedOn", $$v)},expression:"floodBinderReceived.hasAtLeast12MonthsOfCoverageReflectedForPurchase.revisionCompletedOn"}})],1)]}}],null,true)})],1):_vm._e()]),(_vm.floodBinderReceived.hasAtLeast12MonthsOfCoverageReflectedForPurchase.mode == 'NA')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 p-0"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label ml-3",attrs:{"for":"creditorName"}},[_vm._v("Add remarks")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.floodBinderReceived.hasAtLeast12MonthsOfCoverageReflectedForPurchase.nARemark),expression:"floodBinderReceived.hasAtLeast12MonthsOfCoverageReflectedForPurchase.nARemark"}],staticClass:"login-input line_height_40 ml-3",attrs:{"type":"text","disabled":_vm.isTaskCompleted,"name":"Add remarks"},domProps:{"value":(_vm.floodBinderReceived.hasAtLeast12MonthsOfCoverageReflectedForPurchase.nARemark)},on:{"change":function($event){_vm.isComplete = false; 
            _vm.handleChange(_vm.floodBinderReceived.hasAtLeast12MonthsOfCoverageReflectedForPurchase.nARemark, _vm.floodBinderReceivedData.hasAtLeast12MonthsOfCoverageReflectedForPurchase.nARemark, 'Is there at least 12 months of coverage reflected for Purchases? Remarks');_vm.saveFloodBinderReceivedTask()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.floodBinderReceived.hasAtLeast12MonthsOfCoverageReflectedForPurchase, "nARemark", $event.target.value)}}})])]):_vm._e()]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 p-0"},[_c('p',{staticClass:"content-text-black mt-1 mb-1"},[_c('span',[_vm._v("5.")]),_vm._v(" Is there at least 60 days of coverage remaining for Refinances? ")]),_c('div',{staticClass:"mb-2"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0 ml-3"},[_c('span',{staticClass:"radio-text"},[_vm._v("Yes")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.floodBinderReceived.atLeast60DaysOfCoverageRemainingForRefinance.mode),expression:"floodBinderReceived.atLeast60DaysOfCoverageRemainingForRefinance.mode"}],attrs:{"type":"radio","value":"yes","disabled":_vm.isTaskCompleted},domProps:{"checked":_vm._q(_vm.floodBinderReceived.atLeast60DaysOfCoverageRemainingForRefinance.mode,"yes")},on:{"change":[function($event){return _vm.$set(_vm.floodBinderReceived.atLeast60DaysOfCoverageRemainingForRefinance, "mode", "yes")},function($event){_vm.isComplete = false; 
            _vm.handleChange(_vm.floodBinderReceived.atLeast60DaysOfCoverageRemainingForRefinance.mode, _vm.floodBinderReceivedData.atLeast60DaysOfCoverageRemainingForRefinance.mode, 'Is there at least 60 days of coverage remaining for Refinances?');_vm.saveFloodBinderReceivedTask()}],"click":function($event){_vm.floodBinderReceived.atLeast60DaysOfCoverageRemainingForRefinance.revisionRequestedOn = null;
            _vm.floodBinderReceived.atLeast60DaysOfCoverageRemainingForRefinance.revisionCompletedOn = null; _vm.floodBinderReceived.atLeast60DaysOfCoverageRemainingForRefinance.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("No")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.floodBinderReceived.atLeast60DaysOfCoverageRemainingForRefinance.mode),expression:"floodBinderReceived.atLeast60DaysOfCoverageRemainingForRefinance.mode"}],attrs:{"type":"radio","value":"No","disabled":_vm.isTaskCompleted},domProps:{"checked":_vm._q(_vm.floodBinderReceived.atLeast60DaysOfCoverageRemainingForRefinance.mode,"No")},on:{"change":[function($event){return _vm.$set(_vm.floodBinderReceived.atLeast60DaysOfCoverageRemainingForRefinance, "mode", "No")},function($event){_vm.isComplete = false; 
            _vm.handleChange(_vm.floodBinderReceived.atLeast60DaysOfCoverageRemainingForRefinance.mode, _vm.floodBinderReceivedData.atLeast60DaysOfCoverageRemainingForRefinance.mode, 'Is there at least 60 days of coverage remaining for Refinances?');_vm.saveFloodBinderReceivedTask()}],"click":function($event){_vm.floodBinderReceived.atLeast60DaysOfCoverageRemainingForRefinance.revisionRequestedOn = null;
            _vm.floodBinderReceived.atLeast60DaysOfCoverageRemainingForRefinance.revisionCompletedOn = null;_vm.floodBinderReceived.atLeast60DaysOfCoverageRemainingForRefinance.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("NA")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.floodBinderReceived.atLeast60DaysOfCoverageRemainingForRefinance.mode),expression:"floodBinderReceived.atLeast60DaysOfCoverageRemainingForRefinance.mode"}],attrs:{"type":"radio","value":"NA","disabled":_vm.isTaskCompleted},domProps:{"checked":_vm._q(_vm.floodBinderReceived.atLeast60DaysOfCoverageRemainingForRefinance.mode,"NA")},on:{"change":[function($event){return _vm.$set(_vm.floodBinderReceived.atLeast60DaysOfCoverageRemainingForRefinance, "mode", "NA")},function($event){_vm.isComplete = false; 
            _vm.handleChange(_vm.floodBinderReceived.atLeast60DaysOfCoverageRemainingForRefinance.mode, _vm.floodBinderReceivedData.atLeast60DaysOfCoverageRemainingForRefinance.mode, 'Is there at least 60 days of coverage remaining for Refinances?');_vm.saveFloodBinderReceivedTask()}],"click":function($event){_vm.floodBinderReceived.atLeast60DaysOfCoverageRemainingForRefinance.revisionRequestedOn = null;
            _vm.floodBinderReceived.atLeast60DaysOfCoverageRemainingForRefinance.revisionCompletedOn = null; _vm.floodBinderReceived.atLeast60DaysOfCoverageRemainingForRefinance.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)})],1),_c('div',{staticClass:"row m-0"},[(_vm.floodBinderReceived.atLeast60DaysOfCoverageRemainingForRefinance.mode == 'No')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('label',{staticClass:"label ml-3",attrs:{"for":"initial"}},[_vm._v("Revision Requested")]),_c('div',{staticClass:"d-flex align-items-center ml-3"},[_c('datepicker',{class:[errors.length>0?'mx-input-error':''],attrs:{"disabled":_vm.isTaskCompleted,"valueType":"format"},on:{"change":function($event){_vm.isComplete = false; 
            _vm.handleChange(_vm.floodBinderReceived.atLeast60DaysOfCoverageRemainingForRefinance.revisionRequestedOn, _vm.floodBinderReceivedData.atLeast60DaysOfCoverageRemainingForRefinance.revisionRequestedOn, 'Is there at least 60 days of coverage remaining for Refinances? Revision Requested');_vm.saveFloodBinderReceivedTask()}},model:{value:(_vm.floodBinderReceived.atLeast60DaysOfCoverageRemainingForRefinance.revisionRequestedOn),callback:function ($$v) {_vm.$set(_vm.floodBinderReceived.atLeast60DaysOfCoverageRemainingForRefinance, "revisionRequestedOn", $$v)},expression:"floodBinderReceived.atLeast60DaysOfCoverageRemainingForRefinance.revisionRequestedOn"}})],1)]}}],null,true)})],1):_vm._e(),(_vm.floodBinderReceived.atLeast60DaysOfCoverageRemainingForRefinance.mode == 'No')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('label',{staticClass:"label ml-3",attrs:{"for":"initial"}},[_vm._v("Revision Completed")]),_c('div',{staticClass:"d-flex align-items-center ml-3"},[_c('datepicker',{class:[errors.length>0?'mx-input-error':''],attrs:{"disabled":_vm.isTaskCompleted,"valueType":"format","disabled-date":function (date) { return date <= _vm.$moment(new Date(_vm.floodBinderReceived.atLeast60DaysOfCoverageRemainingForRefinance.revisionRequestedOn).setDate(new Date(_vm.floodBinderReceived.atLeast60DaysOfCoverageRemainingForRefinance.revisionRequestedOn).getDate() -1)); }},on:{"change":function($event){_vm.isComplete = false; 
          _vm.handleChange(_vm.floodBinderReceived.atLeast60DaysOfCoverageRemainingForRefinance.revisionCompletedOn, _vm.floodBinderReceivedData.atLeast60DaysOfCoverageRemainingForRefinance.revisionCompletedOn, 'Is there at least 60 days of coverage remaining for Refinances? Revision Completed');_vm.saveFloodBinderReceivedTask()}},model:{value:(_vm.floodBinderReceived.atLeast60DaysOfCoverageRemainingForRefinance.revisionCompletedOn),callback:function ($$v) {_vm.$set(_vm.floodBinderReceived.atLeast60DaysOfCoverageRemainingForRefinance, "revisionCompletedOn", $$v)},expression:"floodBinderReceived.atLeast60DaysOfCoverageRemainingForRefinance.revisionCompletedOn"}})],1)]}}],null,true)})],1):_vm._e()]),(_vm.floodBinderReceived.atLeast60DaysOfCoverageRemainingForRefinance.mode == 'NA')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 p-0"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label ml-3",attrs:{"for":"creditorName"}},[_vm._v("Add remarks")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.floodBinderReceived.atLeast60DaysOfCoverageRemainingForRefinance.nARemark),expression:"floodBinderReceived.atLeast60DaysOfCoverageRemainingForRefinance.nARemark"}],staticClass:"login-input line_height_40 ml-3",attrs:{"type":"text","disabled":_vm.isTaskCompleted,"name":"Add remarks"},domProps:{"value":(_vm.floodBinderReceived.atLeast60DaysOfCoverageRemainingForRefinance.nARemark)},on:{"change":function($event){_vm.isComplete = false; 
        _vm.handleChange(_vm.floodBinderReceived.atLeast60DaysOfCoverageRemainingForRefinance.nARemark, _vm.floodBinderReceivedData.atLeast60DaysOfCoverageRemainingForRefinance.nARemark, 'Is there at least 60 days of coverage remaining for Refinances? Remarks');_vm.saveFloodBinderReceivedTask()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.floodBinderReceived.atLeast60DaysOfCoverageRemainingForRefinance, "nARemark", $event.target.value)}}})])]):_vm._e()]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 p-0"},[_c('p',{staticClass:"content-text-black mt-1 mb-1"},[_c('span',[_vm._v("6.")]),_vm._v(" Is the Lender’s Mortgagee Clause and Loan Number accurately reflected? ")]),_c('div',{staticClass:"mb-2"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0 ml-3"},[_c('span',{staticClass:"radio-text"},[_vm._v("Yes")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.floodBinderReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.mode),expression:"floodBinderReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.mode"}],attrs:{"type":"radio","value":"Yes","disabled":_vm.isTaskCompleted},domProps:{"checked":_vm._q(_vm.floodBinderReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.mode,"Yes")},on:{"change":[function($event){return _vm.$set(_vm.floodBinderReceived.isMortgageClauseAndLoanNumberAccuratelyReflected, "mode", "Yes")},function($event){_vm.isComplete = false; 
        _vm.handleChange(_vm.floodBinderReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.mode, _vm.floodBinderReceivedData.isMortgageClauseAndLoanNumberAccuratelyReflected.mode, 'Is the Lender’s Mortgagee Clause and Loan Number accurately reflected?');_vm.saveFloodBinderReceivedTask()}],"click":function($event){_vm.floodBinderReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionRequestedOn = null;
        _vm.floodBinderReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionCompletedOn = null;_vm.floodBinderReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("No")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.floodBinderReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.mode),expression:"floodBinderReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.mode"}],attrs:{"type":"radio","value":"No","disabled":_vm.isTaskCompleted},domProps:{"checked":_vm._q(_vm.floodBinderReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.mode,"No")},on:{"change":[function($event){return _vm.$set(_vm.floodBinderReceived.isMortgageClauseAndLoanNumberAccuratelyReflected, "mode", "No")},function($event){_vm.isComplete = false; 
        _vm.handleChange(_vm.floodBinderReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.mode, _vm.floodBinderReceivedData.isMortgageClauseAndLoanNumberAccuratelyReflected.mode, 'Is the Lender’s Mortgagee Clause and Loan Number accurately reflected?');_vm.saveFloodBinderReceivedTask()}],"click":function($event){_vm.floodBinderReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionRequestedOn = null;
        _vm.floodBinderReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionCompletedOn = null; _vm.floodBinderReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("NA")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.floodBinderReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.mode),expression:"floodBinderReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.mode"}],attrs:{"type":"radio","value":"NA","disabled":_vm.isTaskCompleted},domProps:{"checked":_vm._q(_vm.floodBinderReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.mode,"NA")},on:{"change":[function($event){return _vm.$set(_vm.floodBinderReceived.isMortgageClauseAndLoanNumberAccuratelyReflected, "mode", "NA")},function($event){_vm.isComplete = false; 
        _vm.handleChange(_vm.floodBinderReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.mode, _vm.floodBinderReceivedData.isMortgageClauseAndLoanNumberAccuratelyReflected.mode, 'Is the Lender’s Mortgagee Clause and Loan Number accurately reflected?');_vm.saveFloodBinderReceivedTask()}],"click":function($event){_vm.floodBinderReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionRequestedOn = null;
        _vm.floodBinderReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionCompletedOn = null;_vm.floodBinderReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)})],1),_c('div',{staticClass:"row m-0"},[(_vm.floodBinderReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.mode == 'No')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('label',{staticClass:"label ml-3",attrs:{"for":"initial"}},[_vm._v("Revision Requested")]),_c('div',{staticClass:"d-flex align-items-center ml-3"},[_c('datepicker',{class:[errors.length>0?'mx-input-error':''],attrs:{"disabled":_vm.isTaskCompleted,"valueType":"format"},on:{"change":function($event){_vm.isComplete = false; 
        _vm.handleChange(_vm.floodBinderReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionRequestedOn, _vm.floodBinderReceivedData.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionRequestedOn, 'Is the Lender’s Mortgagee Clause and Loan Number accurately reflected? Revision Requested');_vm.saveFloodBinderReceivedTask()}},model:{value:(_vm.floodBinderReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionRequestedOn),callback:function ($$v) {_vm.$set(_vm.floodBinderReceived.isMortgageClauseAndLoanNumberAccuratelyReflected, "revisionRequestedOn", $$v)},expression:"floodBinderReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionRequestedOn"}})],1)]}}],null,true)})],1):_vm._e(),(_vm.floodBinderReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.mode == 'No')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('label',{staticClass:"label",attrs:{"for":"initial"}},[_vm._v("Revision Completed")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('datepicker',{class:[errors.length>0?'mx-input-error':''],attrs:{"disabled":_vm.isTaskCompleted,"valueType":"format","disabled-date":function (date) { return date <= _vm.$moment(new Date(_vm.floodBinderReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionRequestedOn).setDate(new Date(_vm.floodBinderReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionRequestedOn).getDate() -1)); }},on:{"change":function($event){_vm.isComplete = false; 
      _vm.handleChange(_vm.floodBinderReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionCompletedOn, _vm.floodBinderReceivedData.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionCompletedOn, 'Is the Lender’s Mortgagee Clause and Loan Number accurately reflected? Revision Completed');_vm.saveFloodBinderReceivedTask()}},model:{value:(_vm.floodBinderReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionCompletedOn),callback:function ($$v) {_vm.$set(_vm.floodBinderReceived.isMortgageClauseAndLoanNumberAccuratelyReflected, "revisionCompletedOn", $$v)},expression:"floodBinderReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.revisionCompletedOn"}})],1)]}}],null,true)})],1):_vm._e()]),(_vm.floodBinderReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.mode == 'NA')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 p-0"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label ml-3",attrs:{"for":"creditorName"}},[_vm._v("Add remarks")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.floodBinderReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.nARemark),expression:"floodBinderReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.nARemark"}],staticClass:"login-input line_height_40 ml-3",attrs:{"type":"text","disabled":_vm.isTaskCompleted,"name":"Add remarks"},domProps:{"value":(_vm.floodBinderReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.nARemark)},on:{"change":function($event){_vm.isComplete = false; 
      _vm.handleChange(_vm.floodBinderReceived.isMortgageClauseAndLoanNumberAccuratelyReflected.nARemark, _vm.floodBinderReceivedData.isMortgageClauseAndLoanNumberAccuratelyReflected.nARemark, 'Is the Lender’s Mortgagee Clause and Loan Number accurately reflected? Remarks');_vm.saveFloodBinderReceivedTask()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.floodBinderReceived.isMortgageClauseAndLoanNumberAccuratelyReflected, "nARemark", $event.target.value)}}})])]):_vm._e()]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 p-0"},[_c('p',{staticClass:"content-text-black mt-1 mb-1"},[_c('span',[_vm._v("7.")]),_vm._v(" Is the Dwelling Coverage sufficient to cover the Loan Amount or Coverage is at least $250000? ")]),_c('div',{staticClass:"mb-2"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0 ml-3"},[_c('span',{staticClass:"radio-text"},[_vm._v("Yes")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.floodBinderReceived.isDwellingCoverageSufficientToCover250000.mode),expression:"floodBinderReceived.isDwellingCoverageSufficientToCover250000.mode"}],attrs:{"type":"radio","value":"Yes","disabled":_vm.isTaskCompleted},domProps:{"checked":_vm._q(_vm.floodBinderReceived.isDwellingCoverageSufficientToCover250000.mode,"Yes")},on:{"change":[function($event){return _vm.$set(_vm.floodBinderReceived.isDwellingCoverageSufficientToCover250000, "mode", "Yes")},function($event){_vm.isComplete = false; 
        _vm.handleChange(_vm.floodBinderReceived.isDwellingCoverageSufficientToCover250000.mode, _vm.floodBinderReceivedData.isDwellingCoverageSufficientToCover250000.mode, 'Is the Dwelling Coverage sufficient to cover the Loan Amount or Coverage is at least $250000?');_vm.saveFloodBinderReceivedTask()}],"click":function($event){_vm.floodBinderReceived.isDwellingCoverageSufficientToCover250000.revisionRequestedOn = null;
        _vm.floodBinderReceived.isDwellingCoverageSufficientToCover250000.revisionCompletedOn = null; _vm.floodBinderReceived.isDwellingCoverageSufficientToCover250000.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("No")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.floodBinderReceived.isDwellingCoverageSufficientToCover250000.mode),expression:"floodBinderReceived.isDwellingCoverageSufficientToCover250000.mode"}],attrs:{"type":"radio","value":"No","disabled":_vm.isTaskCompleted},domProps:{"checked":_vm._q(_vm.floodBinderReceived.isDwellingCoverageSufficientToCover250000.mode,"No")},on:{"change":[function($event){return _vm.$set(_vm.floodBinderReceived.isDwellingCoverageSufficientToCover250000, "mode", "No")},function($event){_vm.isComplete = false; 
        _vm.handleChange(_vm.floodBinderReceived.isDwellingCoverageSufficientToCover250000.mode, _vm.floodBinderReceivedData.isDwellingCoverageSufficientToCover250000.mode, 'Is the Dwelling Coverage sufficient to cover the Loan Amount or Coverage is at least $250000?');_vm.saveFloodBinderReceivedTask()}],"click":function($event){_vm.floodBinderReceived.isDwellingCoverageSufficientToCover250000.revisionRequestedOn = null;
        _vm.floodBinderReceived.isDwellingCoverageSufficientToCover250000.revisionCompletedOn = null;_vm.floodBinderReceived.isDwellingCoverageSufficientToCover250000.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("NA")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.floodBinderReceived.isDwellingCoverageSufficientToCover250000.mode),expression:"floodBinderReceived.isDwellingCoverageSufficientToCover250000.mode"}],attrs:{"type":"radio","value":"NA","disabled":_vm.isTaskCompleted},domProps:{"checked":_vm._q(_vm.floodBinderReceived.isDwellingCoverageSufficientToCover250000.mode,"NA")},on:{"change":[function($event){return _vm.$set(_vm.floodBinderReceived.isDwellingCoverageSufficientToCover250000, "mode", "NA")},function($event){_vm.isComplete = false; 
        _vm.handleChange(_vm.floodBinderReceived.isDwellingCoverageSufficientToCover250000.mode, _vm.floodBinderReceivedData.isDwellingCoverageSufficientToCover250000.mode, 'Is the Dwelling Coverage sufficient to cover the Loan Amount or Coverage is at least $250000?');_vm.saveFloodBinderReceivedTask()}],"click":function($event){_vm.floodBinderReceived.isDwellingCoverageSufficientToCover250000.revisionRequestedOn = null;
        _vm.floodBinderReceived.isDwellingCoverageSufficientToCover250000.revisionCompletedOn = null; _vm.floodBinderReceived.isDwellingCoverageSufficientToCover250000.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)})],1),_c('div',{staticClass:"row m-0"},[(_vm.floodBinderReceived.isDwellingCoverageSufficientToCover250000.mode == 'No')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('label',{staticClass:"label ml-3",attrs:{"for":"initial"}},[_vm._v("Revision Requested")]),_c('div',{staticClass:"d-flex align-items-center ml-3"},[_c('datepicker',{class:[errors.length>0?'mx-input-error':''],attrs:{"disabled":_vm.isTaskCompleted,"valueType":"format"},on:{"change":function($event){_vm.isComplete = false; 
          _vm.handleChange(_vm.floodBinderReceived.isDwellingCoverageSufficientToCover250000.revisionRequestedOn, _vm.floodBinderReceivedData.isDwellingCoverageSufficientToCover250000.revisionRequestedOn, 'Is the Dwelling Coverage sufficient to cover the Loan Amount or Coverage is at least $250000? Revision Requested');_vm.saveFloodBinderReceivedTask()}},model:{value:(_vm.floodBinderReceived.isDwellingCoverageSufficientToCover250000.revisionRequestedOn),callback:function ($$v) {_vm.$set(_vm.floodBinderReceived.isDwellingCoverageSufficientToCover250000, "revisionRequestedOn", $$v)},expression:"floodBinderReceived.isDwellingCoverageSufficientToCover250000.revisionRequestedOn"}})],1)]}}],null,true)})],1):_vm._e(),(_vm.floodBinderReceived.isDwellingCoverageSufficientToCover250000.mode == 'No')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('label',{staticClass:"label",attrs:{"for":"initial"}},[_vm._v("Revision Completed")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('datepicker',{class:[errors.length>0?'mx-input-error':''],attrs:{"disabled":_vm.isTaskCompleted,"valueType":"format","disabled-date":function (date) { return date <= _vm.$moment(new Date(_vm.floodBinderReceived.isDwellingCoverageSufficientToCover250000.revisionRequestedOn).setDate(new Date(_vm.floodBinderReceived.isDwellingCoverageSufficientToCover250000.revisionRequestedOn).getDate() -1 )); }},on:{"change":function($event){_vm.isComplete = false; 
          _vm.handleChange(_vm.floodBinderReceived.isDwellingCoverageSufficientToCover250000.revisionCompletedOn, _vm.floodBinderReceivedData.isDwellingCoverageSufficientToCover250000.revisionCompletedOn, 'Is the Dwelling Coverage sufficient to cover the Loan Amount or Coverage is at least $250000? Revision Completed');_vm.saveFloodBinderReceivedTask()}},model:{value:(_vm.floodBinderReceived.isDwellingCoverageSufficientToCover250000.revisionCompletedOn),callback:function ($$v) {_vm.$set(_vm.floodBinderReceived.isDwellingCoverageSufficientToCover250000, "revisionCompletedOn", $$v)},expression:"floodBinderReceived.isDwellingCoverageSufficientToCover250000.revisionCompletedOn"}})],1)]}}],null,true)})],1):_vm._e()]),(_vm.floodBinderReceived.isDwellingCoverageSufficientToCover250000.mode == 'NA')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 p-0"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label ml-3",attrs:{"for":"creditorName"}},[_vm._v("Add remarks")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.floodBinderReceived.isDwellingCoverageSufficientToCover250000.nARemark),expression:"floodBinderReceived.isDwellingCoverageSufficientToCover250000.nARemark"}],staticClass:"login-input line_height_40 ml-3",attrs:{"type":"text","disabled":_vm.isTaskCompleted,"name":"Add remarks"},domProps:{"value":(_vm.floodBinderReceived.isDwellingCoverageSufficientToCover250000.nARemark)},on:{"change":function($event){_vm.isComplete = false; 
        _vm.handleChange(_vm.floodBinderReceived.isDwellingCoverageSufficientToCover250000.nARemark, _vm.floodBinderReceivedData.isDwellingCoverageSufficientToCover250000.nARemark, 'Is the Dwelling Coverage sufficient to cover the Loan Amount or Coverage is at least $250000? Remarks');_vm.saveFloodBinderReceivedTask()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.floodBinderReceived.isDwellingCoverageSufficientToCover250000, "nARemark", $event.target.value)}}})])]):_vm._e()]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 p-0"},[_c('p',{staticClass:"content-text-black mt-1 mb-1"},[_c('span',[_vm._v("8.")]),_vm._v(" Is the monthly payment correctly reflected on the 1003?")]),_c('div',{staticClass:"mb-2"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0 ml-3"},[_c('span',{staticClass:"radio-text"},[_vm._v("Yes")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.floodBinderReceived.monthlyPaymentOn1003CorrectlyReflected.mode),expression:"floodBinderReceived.monthlyPaymentOn1003CorrectlyReflected.mode"}],attrs:{"type":"radio","value":"Yes","disabled":_vm.isTaskCompleted},domProps:{"checked":_vm._q(_vm.floodBinderReceived.monthlyPaymentOn1003CorrectlyReflected.mode,"Yes")},on:{"change":[function($event){return _vm.$set(_vm.floodBinderReceived.monthlyPaymentOn1003CorrectlyReflected, "mode", "Yes")},function($event){_vm.isComplete = false; 
        _vm.handleChange(_vm.floodBinderReceived.monthlyPaymentOn1003CorrectlyReflected.mode, _vm.floodBinderReceivedData.monthlyPaymentOn1003CorrectlyReflected.mode, 'Is the monthly payment correctly reflected on the 1003?');_vm.saveFloodBinderReceivedTask()}],"click":function($event){_vm.floodBinderReceived.monthlyPaymentOn1003CorrectlyReflected.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("No")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.floodBinderReceived.monthlyPaymentOn1003CorrectlyReflected.mode),expression:"floodBinderReceived.monthlyPaymentOn1003CorrectlyReflected.mode"}],attrs:{"type":"radio","value":"No","disabled":_vm.isTaskCompleted},domProps:{"checked":_vm._q(_vm.floodBinderReceived.monthlyPaymentOn1003CorrectlyReflected.mode,"No")},on:{"change":[function($event){return _vm.$set(_vm.floodBinderReceived.monthlyPaymentOn1003CorrectlyReflected, "mode", "No")},function($event){_vm.isComplete = false; 
        _vm.handleChange(_vm.floodBinderReceived.monthlyPaymentOn1003CorrectlyReflected.mode, _vm.floodBinderReceivedData.monthlyPaymentOn1003CorrectlyReflected.mode, 'Is the monthly payment correctly reflected on the 1003?');_vm.saveFloodBinderReceivedTask()}],"click":function($event){_vm.floodBinderReceived.monthlyPaymentOn1003CorrectlyReflected.nARemark = null;}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("NA")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.floodBinderReceived.monthlyPaymentOn1003CorrectlyReflected.mode),expression:"floodBinderReceived.monthlyPaymentOn1003CorrectlyReflected.mode"}],attrs:{"type":"radio","value":"NA","disabled":_vm.isTaskCompleted},domProps:{"checked":_vm._q(_vm.floodBinderReceived.monthlyPaymentOn1003CorrectlyReflected.mode,"NA")},on:{"change":[function($event){return _vm.$set(_vm.floodBinderReceived.monthlyPaymentOn1003CorrectlyReflected, "mode", "NA")},function($event){_vm.isComplete = false; 
        _vm.handleChange(_vm.floodBinderReceived.monthlyPaymentOn1003CorrectlyReflected.mode, _vm.floodBinderReceivedData.monthlyPaymentOn1003CorrectlyReflected.mode, 'Is the monthly payment correctly reflected on the 1003?');_vm.saveFloodBinderReceivedTask()}],"click":function($event){_vm.floodBinderReceived.monthlyPaymentOn1003CorrectlyReflected.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)})],1),(_vm.floodBinderReceived.monthlyPaymentOn1003CorrectlyReflected.mode == 'NA')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 p-0"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label ml-3",attrs:{"for":"creditorName"}},[_vm._v("Add remarks")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.floodBinderReceived.monthlyPaymentOn1003CorrectlyReflected.nARemark),expression:"floodBinderReceived.monthlyPaymentOn1003CorrectlyReflected.nARemark"}],staticClass:"login-input line_height_40 ml-3",attrs:{"type":"text","disabled":_vm.isTaskCompleted,"name":"Add remarks"},domProps:{"value":(_vm.floodBinderReceived.monthlyPaymentOn1003CorrectlyReflected.nARemark)},on:{"change":function($event){_vm.isComplete = false; 
        _vm.handleChange(_vm.floodBinderReceived.monthlyPaymentOn1003CorrectlyReflected.nARemark, _vm.floodBinderReceivedData.monthlyPaymentOn1003CorrectlyReflected.nARemark, 'Is the monthly payment correctly reflected on the 1003? Remarks');_vm.saveFloodBinderReceivedTask()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.floodBinderReceived.monthlyPaymentOn1003CorrectlyReflected, "nARemark", $event.target.value)}}})])]):_vm._e()]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 p-0"},[_c('p',{staticClass:"content-text-black mt-1 mb-1"},[_c('span',[_vm._v("9.")]),_vm._v(" Has the Invoice been provided? ")]),_c('div',{staticClass:"mb-2"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0 ml-3"},[_c('span',{staticClass:"radio-text"},[_vm._v("Yes")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.floodBinderReceived.hasInvoiceBeenProvided.mode),expression:"floodBinderReceived.hasInvoiceBeenProvided.mode"}],attrs:{"type":"radio","value":"Yes","disabled":_vm.isTaskCompleted},domProps:{"checked":_vm._q(_vm.floodBinderReceived.hasInvoiceBeenProvided.mode,"Yes")},on:{"change":[function($event){return _vm.$set(_vm.floodBinderReceived.hasInvoiceBeenProvided, "mode", "Yes")},function($event){_vm.isComplete = false; 
        _vm.handleChange(_vm.floodBinderReceived.hasInvoiceBeenProvided.mode, _vm.floodBinderReceivedData.hasInvoiceBeenProvided.mode, 'Has the Invoice been provided?');_vm.saveFloodBinderReceivedTask()}],"click":function($event){_vm.floodBinderReceived.hasInvoiceBeenProvided.revisionRequestedOn = null;
        _vm.floodBinderReceived.hasInvoiceBeenProvided.revisionCompletedOn = null; _vm.floodBinderReceived.hasInvoiceBeenProvided.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("No")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.floodBinderReceived.hasInvoiceBeenProvided.mode),expression:"floodBinderReceived.hasInvoiceBeenProvided.mode"}],attrs:{"type":"radio","value":"No","disabled":_vm.isTaskCompleted},domProps:{"checked":_vm._q(_vm.floodBinderReceived.hasInvoiceBeenProvided.mode,"No")},on:{"change":[function($event){return _vm.$set(_vm.floodBinderReceived.hasInvoiceBeenProvided, "mode", "No")},function($event){_vm.isComplete = false; 
        _vm.handleChange(_vm.floodBinderReceived.hasInvoiceBeenProvided.mode, _vm.floodBinderReceivedData.hasInvoiceBeenProvided.mode, 'Has the Invoice been provided?');_vm.saveFloodBinderReceivedTask()}],"click":function($event){_vm.floodBinderReceived.hasInvoiceBeenProvided.revisionRequestedOn = null;
        _vm.floodBinderReceived.hasInvoiceBeenProvided.revisionCompletedOn = null; _vm.floodBinderReceived.hasInvoiceBeenProvided.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('label',{staticClass:"radio mr-3 mb-0"},[_c('span',{staticClass:"radio-text"},[_vm._v("NA")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.floodBinderReceived.hasInvoiceBeenProvided.mode),expression:"floodBinderReceived.hasInvoiceBeenProvided.mode"}],attrs:{"type":"radio","value":"NA","disabled":_vm.isTaskCompleted},domProps:{"checked":_vm._q(_vm.floodBinderReceived.hasInvoiceBeenProvided.mode,"NA")},on:{"change":[function($event){return _vm.$set(_vm.floodBinderReceived.hasInvoiceBeenProvided, "mode", "NA")},function($event){_vm.isComplete = false; 
        _vm.handleChange(_vm.floodBinderReceived.hasInvoiceBeenProvided.mode, _vm.floodBinderReceivedData.hasInvoiceBeenProvided.mode, 'Has the Invoice been provided?');_vm.saveFloodBinderReceivedTask()}],"click":function($event){_vm.floodBinderReceived.hasInvoiceBeenProvided.revisionRequestedOn = null;
        _vm.floodBinderReceived.hasInvoiceBeenProvided.revisionCompletedOn = null; _vm.floodBinderReceived.hasInvoiceBeenProvided.nARemark = null}}}),_c('span',{class:[errors.length>0?'checkround checkround1':'checkround']})])]}}],null,true)})],1),_c('div',{staticClass:"row m-0"},[(_vm.floodBinderReceived.hasInvoiceBeenProvided.mode == 'No')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('label',{staticClass:"label ml-3",attrs:{"for":"initial"}},[_vm._v("Revision Requested")]),_c('div',{staticClass:"d-flex align-items-center ml-3"},[_c('datepicker',{class:[errors.length>0?'mx-input-error':''],attrs:{"disabled":_vm.isTaskCompleted,"valueType":"format"},on:{"change":function($event){_vm.isComplete = false; 
          _vm.handleChange(_vm.floodBinderReceived.hasInvoiceBeenProvided.revisionRequestedOn, _vm.floodBinderReceivedData.hasInvoiceBeenProvided.revisionRequestedOn, 'Has the Invoice been provided? Revision Requested');_vm.saveFloodBinderReceivedTask()}},model:{value:(_vm.floodBinderReceived.hasInvoiceBeenProvided.revisionRequestedOn),callback:function ($$v) {_vm.$set(_vm.floodBinderReceived.hasInvoiceBeenProvided, "revisionRequestedOn", $$v)},expression:"floodBinderReceived.hasInvoiceBeenProvided.revisionRequestedOn"}})],1)]}}],null,true)})],1):_vm._e(),(_vm.floodBinderReceived.hasInvoiceBeenProvided.mode == 'No')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 px-1 form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('label',{staticClass:"label",attrs:{"for":"initial"}},[_vm._v("Revision Completed")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('datepicker',{class:[errors.length>0?'mx-input-error':''],attrs:{"disabled":_vm.isTaskCompleted,"valueType":"format","disabled-date":function (date) { return date <= _vm.$moment(new Date(_vm.floodBinderReceived.hasInvoiceBeenProvided.revisionRequestedOn).setDate(new Date(_vm.floodBinderReceived.hasInvoiceBeenProvided.revisionRequestedOn).getDate() -1)); }},on:{"change":function($event){_vm.isComplete = false; 
          _vm.handleChange(_vm.floodBinderReceived.hasInvoiceBeenProvided.revisionCompletedOn, _vm.floodBinderReceivedData.hasInvoiceBeenProvided.revisionCompletedOn, 'Has the Invoice been provided? Revision Completed');_vm.saveFloodBinderReceivedTask()}},model:{value:(_vm.floodBinderReceived.hasInvoiceBeenProvided.revisionCompletedOn),callback:function ($$v) {_vm.$set(_vm.floodBinderReceived.hasInvoiceBeenProvided, "revisionCompletedOn", $$v)},expression:"floodBinderReceived.hasInvoiceBeenProvided.revisionCompletedOn"}})],1)]}}],null,true)})],1):_vm._e()]),(_vm.floodBinderReceived.hasInvoiceBeenProvided.mode == 'NA')?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 p-0"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label ml-3",attrs:{"for":"creditorName"}},[_vm._v("Add remarks")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.floodBinderReceived.hasInvoiceBeenProvided.nARemark),expression:"floodBinderReceived.hasInvoiceBeenProvided.nARemark"}],staticClass:"login-input line_height_40 ml-3",attrs:{"type":"text","disabled":_vm.isTaskCompleted,"name":"Add remarks"},domProps:{"value":(_vm.floodBinderReceived.hasInvoiceBeenProvided.nARemark)},on:{"change":function($event){_vm.isComplete = false; 
        _vm.handleChange(_vm.floodBinderReceived.hasInvoiceBeenProvided.nARemark, _vm.floodBinderReceivedData.hasInvoiceBeenProvided.nARemark, 'Has the Invoice been provided? Remarks');_vm.saveFloodBinderReceivedTask()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.floodBinderReceived.hasInvoiceBeenProvided, "nARemark", $event.target.value)}}})])]):_vm._e()])])])])]),_c('hr',{staticClass:"loan-process-border-top"}),_c('div',{staticClass:"d-flex align-items-center justify-content-end form-group"},[(_vm.error.show)?_c('span',{staticClass:"login-error show mr-2"},[_vm._v(_vm._s(_vm.error.msg))]):_vm._e(),_c('button',{staticClass:"tmpco-btn tmpco-btn__theme",class:[
  _vm.isTaskCompleted 
  ? 'btn-upload cursor-pointer cursor-not-allow'
  : 'btn-upload cursor-pointer' ],attrs:{"type":"submit","disabled":_vm.isTaskCompleted},on:{"click":function($event){return _vm.$modal.show('floodReceivedConfirmationModal')}}},[_vm._v(" "+_vm._s(_vm.isTaskCompleted ? "Completed" : "Complete")+" ")])]),_c('modal',{staticClass:"modal",attrs:{"centered":"","name":"floodReceivedConfirmationModal","hide-footer":true}},[_c('div',{staticClass:"modal--md-regular"},[_c('div',{staticClass:"tmpco-modal__content"},[_c('div',{staticClass:"tmpco-modal__header"},[_c('p',{staticClass:"tmpco-modal__title"},[_vm._v(" Flood Binder Received")]),_c('button',{staticClass:"tmpco-modal__close",attrs:{"type":"button","data-dismiss":"modal"},on:{"click":function($event){return _vm.$modal.hide('floodReceivedConfirmationModal');}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"tmpco-modal__body"},[_c('div',{staticClass:"col-lg-12 col-xl-12"},[_c('p',{staticClass:"mt-3 mb-3 content-modal"},[_vm._v(" Are you sure you want to complete? ")])])]),_c('div',{staticClass:"tmpco-modal__footer"},[_c('button',{staticClass:"tmpco-btn tmpco-btn__sm-default mr-2",on:{"click":function($event){return _vm.$modal.hide('floodReceivedConfirmationModal');}}},[_vm._v("No")]),_c('button',{staticClass:"tmpco-btn tmpco-btn__sm-theme",attrs:{"type":"submit"},on:{"click":function($event){_vm.isComplete = true;_vm.$modal.hide('floodReceivedConfirmationModal')}}},[_vm._v("Yes")])])])])])],1)])])]}}])}),_c('view-document',{ref:"ref",on:{"call-mount-funtion":function($event){return _vm.getFloodBinderReceived()}}}),_c('follow-up-task',{ref:"refFollowUp",on:{"refresh-task-after-sening-mail":function($event){return _vm.getFloodBinderReceived()}}}),_c('ReOrderTask',{ref:"refReOrder",on:{"refresh-task-after-reorder":function($event){_vm.getFloodBinderReceived();
_vm.isOrderPlacedOnce = false;
_vm.$emit('refresh-task-list', true);}}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"card-content-title mr-2 mb-0"},[_c('b',[_vm._v("Follow Up")])])}]

export { render, staticRenderFns }