
export class IsPayOffForLenderPaidOff {
    constructor() {
        this.revisionRequestedOn = new Date();
        this.revisionCompletedOn =  new Date() ;
        this.nARemark =  null;
        this.mode = null;
    }
    public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}

export class IsPayOffGoodThruuFunding {
    constructor() {
        this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    this.mode = null;
    }
    public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}
export class IsPayOffDemandReflectSubjectPropertyAddress{
    constructor() {
        this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    this.mode = null;
    }
    public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}
export class IsPayOffDemandForBorrower{
    constructor() {
        this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    this.mode = null;
    }
    public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}
export class IsPayOffUpdateOn1003 {
    constructor() {
    this.nARemark =  null;
    this.mode = null;
    }
public nARemark: any
public mode: any
}
export class AreThereLateFees{
    constructor() {
    this.nARemark =  null;
    this.mode = null;
    }
public nARemark: any
public mode: any
}
export class IsSecondUnpaidPrincipalBalanceReflectedOnPayOff {
    constructor() {
    this.nARemark =  null;
    this.mode = null;
    }
public nARemark: any
public mode: any
}


export class PayoffReceivedChecklist {
    constructor() {
        this.isPayOffForLenderPaidOff = new IsPayOffForLenderPaidOff();
        this.isPayOffGoodThruuFunding = new IsPayOffGoodThruuFunding();
        this.isPayOffDemandReflectSubjectPropertyAddress = new IsPayOffDemandReflectSubjectPropertyAddress();
        this.isPayOffDemandForBorrower= new IsPayOffDemandForBorrower();
        this.isPayOffUpdateOn1003 = new IsPayOffUpdateOn1003();
        this.areThereLateFees = new AreThereLateFees();
        this.isSecondUnpaidPrincipalBalanceReflectedOnPayOff  = new IsSecondUnpaidPrincipalBalanceReflectedOnPayOff ();
        this.isPayOffReceived = null;

     }
     public isPayOffForLenderPaidOff = new IsPayOffForLenderPaidOff();
     public isPayOffGoodThruuFunding = new IsPayOffGoodThruuFunding();
     public isPayOffDemandReflectSubjectPropertyAddress = new IsPayOffDemandReflectSubjectPropertyAddress();
     public isPayOffDemandForBorrower= new IsPayOffDemandForBorrower();
     public isPayOffUpdateOn1003 = new IsPayOffUpdateOn1003();
     public areThereLateFees = new AreThereLateFees();
     public isSecondUnpaidPrincipalBalanceReflectedOnPayOff  = new IsSecondUnpaidPrincipalBalanceReflectedOnPayOff ();
     public isPayOffReceived: any;
     
}