/* eslint-disable  @typescript-eslint/no-explicit-any */
import Address from '../common/address.model';

export class Income {
    constructor() {
        this.base = 0;
        this.overTime = 0;
        this.bonus = 0;
        this.commissions = 0;
        this.militaryEntitlements = 0;
        this.other = 0;
    }
    public base: number;
    public overTime: number;
    public bonus: number;
    public commissions: number;
    public militaryEntitlements: number;
    public other: number;
}

export class WorkingPeriod {
    constructor() {
     this.year = 0;
     this.month = 0;
    }
    public year: number;
    public month: number;
}

export class EmploymentInfo {
    constructor() {
       this.hasSelfEmployed = false;
       this.isCurrentEmployment = false;
       this.employerName = null;
       this.phone = null;
       this.address = new Address();
       this.position = null;
       this.startDate = null;
       this.endDate = null;
       this.workingPeriod = new WorkingPeriod();
       this.ownershipPercentage = null;
       this.isEmployedByFamilyMember = false;
       this.monthlyIncome = 0;
       this.previousGrossMonthlyIncome = 0;
       this.income = new Income();
       this.isPrimaryEmployment = false;

    }
    public hasSelfEmployed: boolean;
    public isCurrentEmployment: boolean;
    public employerName: any;
    public phone: any;
    public address: Address;
    public position: any;
    public startDate: any;
    public endDate: any;
    public workingPeriod: WorkingPeriod; 
    public ownershipPercentage: null;
    public isEmployedByFamilyMember: boolean;
    public monthlyIncome: number;
    public previousGrossMonthlyIncome: number;
    public income: Income;
    public isPrimaryEmployment: boolean;
    
}

export class OtherIncome {
    constructor() {
    this.incomeSource = null;
    this.monthlyIncome = 0;
    }
    public incomeSource: any;
    public monthlyIncome: number;
}

export class BorrowerInfo {
    constructor() {
    this.borrowerId = null;
    this.otherIncome = [new OtherIncome()];
    this.employments = [new EmploymentInfo()]
    this.doesNotApplyForOtherIncome = false
    }
    public borrowerId: any;
    public otherIncome: Array<OtherIncome>;
    public employments: Array<EmploymentInfo>;
    public doesNotApplyForOtherIncome: any;
}


export class Employment {
    constructor() {
     this.loanId = null;
     this.borrowerInfo = [new BorrowerInfo()];
    }
    public loanId: any;
    public borrowerInfo: Array<BorrowerInfo>;
}