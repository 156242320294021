/* eslint-disable  @typescript-eslint/no-explicit-any */
export class VendorData {
  constructor() {
    this.vendorTypeId = 6;
    this.companyName = null;
    this.companyUrl = null;
    this.userName = "";
    this.password = "";
    this.createdOn = new Date();
  }

  public vendorTypeId: number;
  public companyName: any;
  public companyUrl: any;
  public userName: any;
  public password: any;
  public createdOn: Date;


}





