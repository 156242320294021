















































































































































































import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "../config";
import { authHeader } from "../services/auth";
import suneditor from "suneditor";
import plugins from "suneditor/src/plugins";

@Component
export default class FollowUpTask extends Vue {
  /* eslint-disable */
  public reasonOfResending = null;
  public aPIPath = null;
  public aPIData: any = {};
  public label = "";

  public followUpData: any = {
    subject: null,
    option: null,
    email: null,
    fax: null,
  };
  public editor: any = null;

  public errorMessage: any = null;
  public errorSubject: any = null;
  public errorOption: any = null;
  public errorEmail: any = null;
  public errorFax: any = null;
  public followUpHistory: any = [];

  public cancel() {
    try {
      this.$modal.hide("folloupModalForVerification");
      this.$modal.hide("folloupModal");
      this.reasonOfResending = null;
      this.aPIPath = null;
      this.aPIData = null;
      this.errorMessage = null;
      this.errorSubject = null;
      this.errorOption = null;
      this.errorEmail = null;
      this.errorFax = null;
      this.followUpData = {
        subject: null,
        option: null,
        email: null,
        fax: null,
      };
    } catch (error) {
      console.log(error);
    }
  }

  public async openFollowUpModal(body) {
    try {
      console.log('BODY--', body)
      this.label = body.label;
      this.aPIPath = body.apiPath;
      this.aPIData = body.data;
      if (this.aPIData.extraInfo) {
        this.followUpData.email = this.aPIData.extraInfo.email
        this.followUpData.fax = this.aPIData.extraInfo.fax
        this.followUpData.option = this.aPIData.extraInfo.option
      }
      if (this.aPIData.taskId == 8)
        this.$modal.show("folloupModalForVerification");
      else this.$modal.show("folloupModal");
      setTimeout(async () => {
        this.editor = await suneditor.create("pageEditor", {
          plugins: {
            ...plugins,
          },
          width: "auto",
          height: "100px",
          buttonList: [["bold"], ["list"]],
        });
      }, 1);
    } catch (error) {
      console.log(error);
    }
  }

  public async openHistoryModal(body) {
    let response = await Axios.post(
      BASE_API_URL + "processor/getFollowUpHistoryOfTitle",
      {
        taskId: body.data.taskId,
        loanId: this.$route.query.id,
        reOrderCount: body.data.reOrderCount,
        secondAppraisalCount: body.data.secondAppraisalCount ? body.data.secondAppraisalCount : null
      },
      {
        headers: authHeader(),
      }
    );
    this.followUpHistory =
      response.data.followUpDet.length > 0 ? response.data.followUpDet : [];
    this.$modal.show("historyModal");
  }

  public async sendFollowUpMail() {
    try {
            this.aPIData.message = this.reasonOfResending;

         this.$modal.hide("folloupModal");
           this.callLoader(true, null)

      let response = await Axios.post(
        BASE_API_URL + this.aPIPath,
        this.aPIData,
        { headers: authHeader() }
      );
      if (response.status === 201) {
        await this.$snotify.success("Follow-Up Mail Sent");
        await this.cancel();
        await this.$emit("refresh-task-after-sening-mail");
        this.reasonOfResending = null;
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null)

  }

  public async sendFollowUpMailForVerification() {
    try {
      delete this.aPIData.extraInfo;
      this.followUpData.message = this.editor.getContents();
      this.aPIData.followUpData = this.followUpData;
      if (
        this.followUpData.message
          .replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, "")
          .trim() != ""
      ) {
        this.errorMessage = null;
      } else {
        this.errorMessage = "Please add a Message";
        this.errorSubject = "Please add a Subject";
        this.errorOption = "Please Select a method";
        this.errorEmail = "Please add a Email";
        this.errorFax = "Please add a fax";
      }
      if (
        this.followUpData.message
          .replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, "")
          .trim() != "" &&
        this.followUpData.subject &&
        this.followUpData.option &&
        (this.followUpData.email || this.followUpData.fax)
      ) {
        let response = await Axios.post(
          BASE_API_URL + this.aPIPath,
          this.aPIData,
          { headers: authHeader() }
        );

        if (response.status === 201) {
          await this.$snotify.success("Follow-Up Mail Sent");
          await this.cancel();
          await this.$emit("refresh-task-after-sening-mail");
        }
      }
    } catch (error) {
      console.log(error);
    }
  }


  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

}
