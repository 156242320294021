var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"white-card loan-process-height-249 mb-2 d-flex flex-column"},[_c('div',{staticClass:"loan-process-heading"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('p',{staticClass:"heading mb-0"},[_vm._v("Initial Disclosure")]),(_vm.notifyBroker)?_c('div',[_c('CommonNotifyOnRejection',{ref:"refNotify",attrs:{"loanTxnId":_vm.$route.query.id},on:{"refresh-task":function($event){return _vm.isBrokerNotifiedForRejectedDocs()}}})],1):_vm._e()])])]),_c('div',{staticClass:"col-lg-12 pt-3 pb-3 flex-1 height_100"},[_c('div',{staticClass:"form-row min_293 flex-column"},[_c('div',{staticClass:"flex-1"},[_c('div',{staticClass:"row m-0"},[((_vm.disclosureData.feesWorkSheet && !_vm.disclosureData.feesWorkSheet.docApproved) ||
                (_vm.disclosureData.pricingSheet && !_vm.disclosureData.pricingSheet.docApproved))?_c('div',{staticClass:"font-10 label pb-3 col-12"},[_c('b',[_vm._v("Note: Please approve documents to complete the task. ")])]):_vm._e(),_c('div',{staticClass:"col-lg-3 col-md-6 col-12"},[_c('div',{staticClass:"custom-checkbox mb-1 d-flex align-items-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.disclosureData.wsUploaded),expression:"disclosureData.wsUploaded"}],staticClass:"custom-checkbox-input",attrs:{"type":"checkbox","id":"feesWorksheet","disabled":""},domProps:{"checked":Array.isArray(_vm.disclosureData.wsUploaded)?_vm._i(_vm.disclosureData.wsUploaded,null)>-1:(_vm.disclosureData.wsUploaded)},on:{"change":function($event){var $$a=_vm.disclosureData.wsUploaded,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.disclosureData, "wsUploaded", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.disclosureData, "wsUploaded", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.disclosureData, "wsUploaded", $$c)}}}}),_c('label',{staticClass:"label d-flex align-items-center mr-1",attrs:{"for":"a"}},[_vm._v("Fees Worksheet")]),(_vm.disclosureData.feesWorkSheet &&
                    !_vm.disclosureData.feesWorkSheet.docApproved && !_vm.disclosureData.feesWorkSheet.docStatus)?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"fa fa-exclamation-circle color-red ml-2",attrs:{"title":"Not Approved"}}):_vm._e(),(_vm.disclosureData.feesWorkSheet &&
                    _vm.disclosureData.feesWorkSheet.docApproved)?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"fa fa-check-circle-o text__blue",attrs:{"title":"Approved"}}):_vm._e(),(_vm.disclosureData.feesWorkSheet)?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"fa fa-file-text cursor-pointer font-14 ml-2 text__blue",attrs:{"title":"View Document"},on:{"click":function($event){_vm.viewDocument(_vm.disclosureData.feesWorkSheet); _vm.error = { show: false, messgae: '' };}}}):_vm._e(),(
                    _vm.disclosureData.feesWorkSheet && _vm.disclosureData.feesWorkSheet.docStatus )?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"fa fa-exclamation-circle ml-2",staticStyle:{"color":"orange"},attrs:{"title":_vm.disclosureData.feesWorkSheet.comment}}):_vm._e(),_c('div',{staticClass:"indicator"})])]),_c('div',{staticClass:"col-lg-3 col-md-6 col-12"},[_c('div',{staticClass:"custom-checkbox mb-1 d-flex align-items-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.disclosureData.psUploaded),expression:"disclosureData.psUploaded"}],staticClass:"custom-checkbox-input",attrs:{"type":"checkbox","id":"pricintSheet","disabled":""},domProps:{"checked":Array.isArray(_vm.disclosureData.psUploaded)?_vm._i(_vm.disclosureData.psUploaded,null)>-1:(_vm.disclosureData.psUploaded)},on:{"change":function($event){var $$a=_vm.disclosureData.psUploaded,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.disclosureData, "psUploaded", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.disclosureData, "psUploaded", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.disclosureData, "psUploaded", $$c)}}}}),_c('label',{staticClass:"label d-flex align-items-center mr-1",attrs:{"for":"a"}},[_vm._v("Pricing Sheet")]),(
                    _vm.disclosureData.pricingSheet && !_vm.disclosureData.pricingSheet.docApproved &&
                    !_vm.disclosureData.pricingSheet.docStatus )?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"fa fa-exclamation-circle color-red ml-2",attrs:{"title":"Not Approved"}}):_vm._e(),( _vm.disclosureData.pricingSheet &&
                    _vm.disclosureData.pricingSheet.docApproved )?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"fa fa-check-circle-o text__blue",attrs:{"title":"Approved"}}):_vm._e(),(_vm.disclosureData.pricingSheet)?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"fa fa-file-text cursor-pointer font-14 ml-2 text__blue",attrs:{"title":"View Document"},on:{"click":function($event){_vm.viewDocument(_vm.disclosureData.pricingSheet); _vm.error = { show: false, messgae: '' };}}}):_vm._e(),(
                    _vm.disclosureData.pricingSheet && _vm.disclosureData.pricingSheet.docStatus )?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"fa fa-exclamation-circle ml-2",staticStyle:{"color":"orange"},attrs:{"title":_vm.disclosureData.pricingSheet.comment}}):_vm._e(),_c('div',{staticClass:"indicator"})])]),_c('div',{staticClass:"col-lg-3 col-md-6 col-12"},[_c('div',{staticClass:"custom-checkbox mb-1 d-flex align-items-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.disclosureData.isDisclosureSent),expression:"disclosureData.isDisclosureSent"}],staticClass:"custom-checkbox-input",attrs:{"type":"checkbox","id":"disclosureSent","disabled":_vm.disclosureData.isCompleted},domProps:{"checked":Array.isArray(_vm.disclosureData.isDisclosureSent)?_vm._i(_vm.disclosureData.isDisclosureSent,null)>-1:(_vm.disclosureData.isDisclosureSent)},on:{"change":[function($event){var $$a=_vm.disclosureData.isDisclosureSent,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.disclosureData, "isDisclosureSent", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.disclosureData, "isDisclosureSent", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.disclosureData, "isDisclosureSent", $$c)}},function($event){_vm.error = { show: false, messgae: null };}]}}),_c('label',{staticClass:"label",attrs:{"for":"a"}},[_vm._v("Disclosures Sent")]),_c('div',{staticClass:"indicator"})])])])]),_c('hr',{staticClass:"loan-process-border-top"}),_c('div',{staticClass:"d-flex justify-content-end align-items-center form-group"},[(_vm.error.show)?_c('span',{staticClass:"login-error show mr-2"},[_vm._v(_vm._s(_vm.error.message))]):_vm._e(),_c('button',{staticClass:"tmpco-btn tmpco-btn__default-blue mr-2",class:[
              _vm.disclosureData.isCompleted
                ? 'btn-upload cursor-pointer cursor-not-allow'
                : 'btn-upload cursor-pointer' ],attrs:{"type":"button","disabled":_vm.disclosureData.isCompleted},on:{"click":function($event){return _vm.saveDisclosureDetails(false)}}},[_vm._v(" Save ")]),_c('button',{staticClass:"tmpco-btn tmpco-btn__theme",class:[
              _vm.disclosureData.isCompleted
                ? 'btn-upload cursor-pointer cursor-not-allow'
                : 'btn-upload cursor-pointer' ],attrs:{"type":"button","disabled":_vm.disclosureData.isCompleted},on:{"click":function($event){return _vm.$modal.show('confirmationModal')}}},[_vm._v(" "+_vm._s(_vm.disclosureData.isCompleted ? "Completed" : "Complete")+" ")])])])]),_c('modal',{staticClass:"modal",attrs:{"centered":"","name":"confirmationModal","hide-footer":true}},[_c('div',{staticClass:"modal--md-regular"},[_c('div',{staticClass:"tmpco-modal__content"},[_c('div',{staticClass:"tmpco-modal__header"},[_c('p',{staticClass:"tmpco-modal__title"},[_vm._v(" Initial Disclosure")]),_c('button',{staticClass:"tmpco-modal__close",attrs:{"type":"button","data-dismiss":"modal"},on:{"click":function($event){return _vm.$modal.hide('confirmationModal');}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"tmpco-modal__body"},[_c('div',{staticClass:"col-lg-12 col-xl-12"},[_c('p',{staticClass:"mt-3 mb-3 content-modal"},[_vm._v(" Are you sure you want to complete? ")])])]),_c('div',{staticClass:"tmpco-modal__footer"},[_c('button',{staticClass:"tmpco-btn tmpco-btn__sm-default mr-2",on:{"click":function($event){return _vm.$modal.hide('confirmationModal');}}},[_vm._v("No")]),_c('button',{staticClass:"tmpco-btn tmpco-btn__sm-theme",attrs:{"type":"button"},on:{"click":function($event){_vm.saveDisclosureDetails(true);_vm.$modal.hide('confirmationModal')}}},[_vm._v("Yes")])])])])]),_c('ViewDocument',{ref:"ref",on:{"call-mount-funtion":function($event){_vm.getDisclosureInfo();
        _vm.isBrokerNotifiedForRejectedDocs();}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }