export class RevisionAndRemarksObj{
    constructor() {
    this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
}
public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}
export class  IsCommitmentReflectAuthorizedSign{
    constructor() {
        this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    }
    public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}
export class  IsCommitmentDateIsWithin60Days{
    constructor() {
        this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    }
    public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}
export class  IsProposedInsuredPolicyAmountForOwnerPolicyIsSameAsSalesPrice{
    constructor() {
        this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    }
    public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}
export class  IsProposedInsuredPolicyAmountForLoanPolicyisSameAsLoanAmount{
    constructor() {
        this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    }
    public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}
export class IsBorrowerNameCorrectlyReflected{
    constructor() {
        this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    }
    public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}
export class  IsLenderNameCorrectlyReflected{
    constructor() {
        this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    }
    public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}
export class  IsTaxCertReflectSubjectProperty{
    constructor() {
        this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    }
    public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}
export class  IsTaxCertReflectCorrectOwner{
    constructor() {
        this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    }
    public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}
export class  IsTaxesOn1003AsMonthlyFigure{
    constructor() {
        this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    }
    public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}
export class  BorrowerNameOnCPL{
    constructor() {
        this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    }
    public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}
export class  MortgageClauseOnCPL{
    constructor() {
        this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    }
    public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}
export class  PropertyAddressOnCPL{
    constructor() {
        this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    }
    public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}
export class  LenderLoanNumberOnCPL{
    constructor() {
        this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    }
    public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}
export class  CPLDatedWithin30Days{
    constructor() {
        this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    }
    public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}
export class  CPLSignedByAuthorizedSignatory{
    constructor() {
        this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    }
    public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}
export class  BorrowerNameOnPrelimCD{
    constructor() {
        this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    }
    public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}
export class  PropertyAddressOnPrelimCD{
    constructor() {
        this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    }
    public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}
export class  LenderLoanNumberOnPrelimCD{
    constructor() {
        this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    }
    public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}
export class SalesPriceOnPrelimCD{
    constructor() {
        this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    }
    public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}
export class  LoanAmountOnPrelimCD{
    constructor() {
        this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    }
    public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}
export class  TitleCompanyOnEO{
    constructor() {
        this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    }
    public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}
export class  EOValidThroughClosingDate{
    constructor() {
        this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
        this.isValid = null
    }
    public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
    public isValid: any;
}
export class  EOSignedByAuthorizedRepresentative{
    constructor() {
        this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    }
    public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}
export class  TitleCompanyOnWiringInstruction{
    constructor() {
        this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    }
    public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}
export class  IsInterestInLandHeldInFeeSimple{
    constructor() {
        this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    }
    public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}
export class  DeedProvidedAsRefInchainOfTitle{
    constructor() {
        this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    }
    public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}
export class IstheChainOfTitleWithinTheCommitment{
    constructor() {
        this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    }
    public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}
export class  IsOwnerOwnPropertLessThan180Days{
    constructor() {
        this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    }
    public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}
export class HasPropertyOwnedForAtLeast91Days {
    constructor() {
        this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    }
    public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}

export class  PropertyVestedInTheSellerName{
    constructor() {
        this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    }
    public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any
public mode: any
}
export class TitleReceivedChecklist {
    constructor() {
        this.isCommitmentReflectAuthorizedSign = new IsCommitmentReflectAuthorizedSign();
        this.isCommitmentDateIsWithin60Days= new IsCommitmentDateIsWithin60Days();
        this.isProposedInsuredPolicyAmountForOwnerPolicyIsSameAsSalesPrice = new IsProposedInsuredPolicyAmountForOwnerPolicyIsSameAsSalesPrice();
        this.isProposedInsuredPolicyAmountForLoanPolicyisSameAsLoanAmount = new IsProposedInsuredPolicyAmountForLoanPolicyisSameAsLoanAmount();
        this.isBorrowerNameCorrectlyReflected = new IsBorrowerNameCorrectlyReflected();
        this.isLenderNameCorrectlyReflected = new IsLenderNameCorrectlyReflected();
        this.isTaxCertReflectSubjectProperty= new IsTaxCertReflectSubjectProperty();
        this.isTaxCertReflectCorrectOwner = new IsTaxCertReflectCorrectOwner();
        this.isTaxesOn1003AsMonthlyFigure = new IsTaxesOn1003AsMonthlyFigure();
        this.borrowerNameOnCPL = new BorrowerNameOnCPL();
        this.mortgageClauseOnCPL = new MortgageClauseOnCPL();
        this.propertyAddressOnCPL = new PropertyAddressOnCPL();
        this.lenderLoanNumberOnCPL = new LenderLoanNumberOnCPL();
        this.cPLDatedWithin30Days = new CPLDatedWithin30Days();
        this.cPLSignedByAuthorizedSignatory = new CPLSignedByAuthorizedSignatory();
        this.borrowerNameOnPrelimCD = new BorrowerNameOnPrelimCD();
        this.propertyAddressOnPrelimCD = new PropertyAddressOnPrelimCD();
        this.lenderLoanNumberOnPrelimCD = new LenderLoanNumberOnPrelimCD();
        this.salesPriceOnPrelimCD = new SalesPriceOnPrelimCD();
        this.loanAmountOnPrelimCD = new LoanAmountOnPrelimCD();
        this.titleCompanyOnEO = new TitleCompanyOnEO();
        this.EOValidThroughClosingDate = new EOValidThroughClosingDate();
        this.EOSignedByAuthorizedRepresentative = new EOSignedByAuthorizedRepresentative();
        this.titleCompanyOnWiringInstruction = new TitleCompanyOnWiringInstruction();
        this.isInterestInLandHeldInFeeSimple = new IsInterestInLandHeldInFeeSimple();
        this.deedProvidedAsRefInchainOfTitle = new DeedProvidedAsRefInchainOfTitle();
        this.istheChainOfTitleWithinTheCommitment = new IstheChainOfTitleWithinTheCommitment();
        this.isOwnerOwnPropertLessThan180Days = new IsOwnerOwnPropertLessThan180Days();
        this.hasPropertyOwnedForAtLeast91Days = new HasPropertyOwnedForAtLeast91Days();
        this.propertyVestedInTheSellerName = new PropertyVestedInTheSellerName();

        this.isTitleReceived = null;

     }
     public isCommitmentReflectAuthorizedSign: IsCommitmentReflectAuthorizedSign;
     public isCommitmentDateIsWithin60Days: IsCommitmentDateIsWithin60Days;
     public isProposedInsuredPolicyAmountForOwnerPolicyIsSameAsSalesPrice: IsProposedInsuredPolicyAmountForOwnerPolicyIsSameAsSalesPrice;
     public isProposedInsuredPolicyAmountForLoanPolicyisSameAsLoanAmount: IsProposedInsuredPolicyAmountForLoanPolicyisSameAsLoanAmount;
     public isBorrowerNameCorrectlyReflected: IsBorrowerNameCorrectlyReflected;
     public isLenderNameCorrectlyReflected: IsLenderNameCorrectlyReflected;
     public isTaxCertReflectSubjectProperty: IsTaxCertReflectSubjectProperty;
     public isTaxCertReflectCorrectOwner: IsTaxCertReflectCorrectOwner;
     public isTaxesOn1003AsMonthlyFigure: IsTaxesOn1003AsMonthlyFigure;
     public borrowerNameOnCPL: BorrowerNameOnCPL;
     public mortgageClauseOnCPL: MortgageClauseOnCPL;
     public propertyAddressOnCPL: PropertyAddressOnCPL;
     public lenderLoanNumberOnCPL: LenderLoanNumberOnCPL;
     public cPLDatedWithin30Days: CPLDatedWithin30Days;
     public cPLSignedByAuthorizedSignatory: CPLSignedByAuthorizedSignatory;
     public borrowerNameOnPrelimCD: BorrowerNameOnPrelimCD;
     public propertyAddressOnPrelimCD: PropertyAddressOnPrelimCD;
     public lenderLoanNumberOnPrelimCD: LenderLoanNumberOnPrelimCD;
     public salesPriceOnPrelimCD: SalesPriceOnPrelimCD;
     public loanAmountOnPrelimCD: LoanAmountOnPrelimCD;
     public titleCompanyOnEO: TitleCompanyOnEO;
     public EOValidThroughClosingDate: EOValidThroughClosingDate;
     public EOSignedByAuthorizedRepresentative: EOSignedByAuthorizedRepresentative;
     public titleCompanyOnWiringInstruction: TitleCompanyOnWiringInstruction;
     public isInterestInLandHeldInFeeSimple: IsInterestInLandHeldInFeeSimple;
     public deedProvidedAsRefInchainOfTitle: DeedProvidedAsRefInchainOfTitle;
     public istheChainOfTitleWithinTheCommitment: IstheChainOfTitleWithinTheCommitment;
     public isOwnerOwnPropertLessThan180Days: IsOwnerOwnPropertLessThan180Days;
     public hasPropertyOwnedForAtLeast91Days: HasPropertyOwnedForAtLeast91Days;
     public propertyVestedInTheSellerName: PropertyVestedInTheSellerName;
     public isTitleReceived: any;
     
}