export class IsBorrowerNameAccuratelyReflected{
    constructor() {
    this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    }
public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any;
public mode: any;
}

export class IsPropertyAddressAccuratelyReflected {
    constructor() {
    this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    }
public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any;
public mode: any;
}
export class HasInvoiceBeenProvided {
    constructor() {
    this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    }
public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any;
public mode: any;
    
}
export class IsDwellingCoverageSufficientToCover250000{
    constructor() {
    this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    }
public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any;
public mode: any;
}
export class IsMortgageClauseAndLoanNumberAccuratelyReflected {
    constructor() {
    this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    }
public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any;
public mode: any;
}
export class AtLeast60DaysOfCoverageRemainingForRefinance {
    constructor() {
    this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    }
public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any;
public mode: any;
}
export class HasAtLeast12MonthsOfCoverageReflectedForPurchase{
    constructor() {
    this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    }
public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any;
public mode: any;
}
export class MonthlyPaymentOn1003CorrectlyReflected {
    constructor() {
    this.mode = null;
    this.nARemark =  null;
    }
public nARemark: any;
public mode: any;
}

export class IsEffectiveDateOnOrNoMoreThan7Days {
    constructor() {
    this.mode = null;
    this.revisionRequestedOn = new Date();
    this.revisionCompletedOn =  new Date() ;
    this.nARemark =  null;
    }
public revisionRequestedOn:  any;
public revisionCompletedOn: any;
public nARemark: any;
public mode: any;
}

export class FloodBinderReceivedChecklist {
    constructor() {
        this.isBorrowerNameAccuratelyReflected = new IsBorrowerNameAccuratelyReflected();
        this.isPropertyAddressAccuratelyReflected  = new IsPropertyAddressAccuratelyReflected ();
        this.hasInvoiceBeenProvided = new HasInvoiceBeenProvided();
        this.isDwellingCoverageSufficientToCover250000 = new IsDwellingCoverageSufficientToCover250000();
        this.isMortgageClauseAndLoanNumberAccuratelyReflected = new IsMortgageClauseAndLoanNumberAccuratelyReflected();
        this.atLeast60DaysOfCoverageRemainingForRefinance = new AtLeast60DaysOfCoverageRemainingForRefinance();
        this.hasAtLeast12MonthsOfCoverageReflectedForPurchase = new HasAtLeast12MonthsOfCoverageReflectedForPurchase();
        this.monthlyPaymentOn1003CorrectlyReflected = new MonthlyPaymentOn1003CorrectlyReflected();
        this.isEffectiveDateOnOrNoMoreThan7Days = new IsEffectiveDateOnOrNoMoreThan7Days();
        this.isFloodBinderRecieved = null;

     }
     public isBorrowerNameAccuratelyReflected: IsBorrowerNameAccuratelyReflected;
     public isPropertyAddressAccuratelyReflected: IsPropertyAddressAccuratelyReflected;
     public hasInvoiceBeenProvided: HasInvoiceBeenProvided;
     public isDwellingCoverageSufficientToCover250000: IsDwellingCoverageSufficientToCover250000;
     public isMortgageClauseAndLoanNumberAccuratelyReflected: IsMortgageClauseAndLoanNumberAccuratelyReflected;
     public atLeast60DaysOfCoverageRemainingForRefinance: AtLeast60DaysOfCoverageRemainingForRefinance;
     public hasAtLeast12MonthsOfCoverageReflectedForPurchase: HasAtLeast12MonthsOfCoverageReflectedForPurchase;
     public monthlyPaymentOn1003CorrectlyReflected: MonthlyPaymentOn1003CorrectlyReflected;
     public isEffectiveDateOnOrNoMoreThan7Days: IsEffectiveDateOnOrNoMoreThan7Days;
     public isFloodBinderRecieved: any;
     
}