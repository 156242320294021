/* eslint-disable  @typescript-eslint/no-explicit-any */
import Address from '../common/address.model';
export class Name {
    constructor() {
      this.firstName = null;
      this.middleName = null;
      this.lastName = null;
      this.suffix = null;
    }
    public firstName: any;
    public middleName: any;
    public lastName: any;
    public suffix: any;
}

export class AlternateName {
    constructor() {
        this.firstName = null;
        this.middleName = null;
        this.lastName = null;
        this.suffix = null;
      }
      public firstName: any;
      public middleName: any;
      public lastName: any;
      public suffix: any;
  }

export class ContactInfo {
    constructor() {
        this.email = null;
        this.homePhone = null;
        this.cellPhone = null;
        this.workPhone = null;
        this.workPhoneExt = 0;
      }
      public email: any;
      public homePhone: any;
      public cellPhone: any;
      public workPhone: any;
      public workPhoneExt: number;
  }

export class Status {
      constructor() {
        this.type = null;
        this.status = null;
        this.typeOfRelation = null;
        this.isRelationType = null;
      }
      public type: any;
      public status: any;
      public typeOfRelation: any;
      public isRelationType: any;
  }

export class Dependent {
      constructor() {
        this.age = 0;
      }
      public age: number;
  }


export class Period {
    constructor() {
      this.year = 0;
      this.month = 0;
    }
    public year: any;
    public month: any;
}

export class Housing {
    constructor() {
      this.type = null;
      this.rentAmount = null;
    }
    public type: any;
    public rentAmount: any;
}

export class CurrentAddress {
    constructor() {
       this.address = new Address();
       this.period = new Period();
       this.housing = new Housing();
    }
    public address: Address;
    public period: Period;
    public housing: Housing;
}  

export class FormerAddress {
    constructor() {
       this.address = new Address();
       this.period = new Period();
       this.housing = new Housing();
    }
    public address: Address;
    public period: Period;
    public housing: Housing;
}

export class MillitaryService {
    constructor() {
        this.servedArmedForces = null;
        this.hasServingActiveDuty = null; 
        this.serviceExpirationDate = null;
        this.hasRetired = null; 
        this.hasNonActivatedForPeriod = null; 
        this.isServingSpouse = null;
    }
    public  servedArmedForces: any;
    public  hasServingActiveDuty: any; 
    public  serviceExpirationDate: any;
    public  hasRetired: any; 
    public  hasNonActivatedForPeriod: any; 
    public  isServingSpouse: any; 
}

export class BorrowerInfo {
    constructor() {
      this.userId = null;
      this.ssn = null;
      this.DOB = null;
      this.creditType = null;
      this.name = new Name();
      this.alternateNames = [new AlternateName()];
      this.contactInfo = new ContactInfo();
      this.citizenship = null;
      this.maritalStatus = new Status();
      this.dependents = [new Dependent()];
      this.currentAddress = new CurrentAddress();
      this.formerAddress = [new FormerAddress()];
      this.mailingAddress = new Address();
      this.millitaryService = new MillitaryService();
      this.ismailingAddressSame = null;
      this.number = 0;
      // this.relationType = new RelationType();
     
    }

    public userId: any;
    public ssn: any;
    public DOB: any;
    public creditType: any;
    public name: Name;
    public alternateNames: Array<AlternateName>;
    public contactInfo: ContactInfo;
    public citizenship: any;
    public maritalStatus: Status;
    public dependents: Array<Dependent>;
    public currentAddress: CurrentAddress;
    public formerAddress: Array<FormerAddress>;
    public mailingAddress: Address;
    public millitaryService: MillitaryService;
    public ismailingAddressSame: any;
    public number: number;
    // public relationType: RelationType;
   
}

export class Borrower {
    constructor() {
    this.loanId = null;
    this.loanNumber = null;
    this.borrowerInfo = [new BorrowerInfo()];
    }
    public loanId: any;
    public loanNumber: any;
    public borrowerInfo: Array<BorrowerInfo>;
}
