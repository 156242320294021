




































































































































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL, EventBus } from "../../../config";
import { authHeader } from "../../../services/auth";
import { CONDITIONS_CATEGORY } from "../../../constants/constants";
import Multiselect from "vue-multiselect";
import ConditionModal from "../../../common-components/ConditionComponent.vue";
import ViewDocument from "../../../common-components/ViewDocument.vue";
import CommonDeleteModal from "../../../common-components/CommonDeleteModal.vue";
import CommonNotifyOnRejection from "../../../common-components/CommonNotifyOnRejection.vue";
import moment from "moment";

// import suneditor from "suneditor";
// import plugins from "suneditor/src/plugins";
@Component({
  components: {
    Multiselect,
    ConditionModal,
    ViewDocument,
    CommonDeleteModal,
    CommonNotifyOnRejection,
  },
})
export default class Suspended extends Vue {
  /* eslint-disable */
  public conditions: any = { conditionList: [] };
  public loanId = this.$route.query.id;
  @Prop()
  public taskId: any;
  @Prop()
  public lenderResponseSequence: any;
  public conditionsCategory = CONDITIONS_CATEGORY;
  public conditionChecklist: any = [];
  public sequence = null;
  public workflowTaskId = null;
  public source = "Suspended";
  public sData: any = {};
  public isError = false;
  public requiredDoc = [];
  @Prop()
  public isLoanInactive: any;
  public isTaskCompleted = false;
  public notifyBroker = false;
  @Prop()
  public isTaskDisabled: any;
  public isOrderPlacedOnce = false;
  public isRejected = false;
  public isAccepted = false;
  public isChecklist = true;

  public checkUsers(condition) {
    let returnValue = false;
    let user = condition.filter((e) =>
      e.toBeUploadedBy.find((j) => j == 2 || j == 4)
    );
    console.log(user, "user");
    if (user.length > 0) returnValue = true;
    return returnValue;
  }

  public async deleteCondition(documentId) {
    try {
      if (this.isTaskDisabled) {
        this.$snotify.info("Task is not assigned to you.");
        return;
      }
      await this.$refs.refs["delConfirmationBox"]({
        msg: `Are you sure want to delete this Condition ?`,
        data: {
          loanId: this.loanId,
          documentId: documentId,
        },
        apiPath: "processor/deleteCondition",
      });
    } catch (error) {
      console.log(error);
    }
  }

  public openConfirmationPopup() {
    this.$modal.show("confirmationModal");
  }

  public async saveSuspendedDetails(isComplete) {
    if (this.isOrderPlacedOnce) return;
    this.callLoader(true, null);
    try {
      if (this.isTaskDisabled) {
        this.$snotify.info("Task is not assigned to you.");
        this.getConditionsFromLoanChecklist();
        return;
      }
      // let checklist = this.conditionChecklist.filter((c) => !c.isComplete);
      // if (this.conditionChecklist.length > 0 && checklist.length < 1) {
      if (isComplete) this.isOrderPlacedOnce = true;
      let completeChecklist = this.conditionChecklist.filter(
        (c) => c.isComplete
      );
      if (this.conditionChecklist.length > 0 && completeChecklist.length > 0) {
        this.sData.lenderResponseSequence = this.sequence;
        let response = await Axios.post(
          BASE_API_URL + "processor/saveSuspendedDetails",
          {
            loanId: this.loanId,
            taskId: this.taskId,
            isComplete: isComplete,
            suspended: this.sData,
          },
          {
            headers: authHeader(),
          }
        );
        if (!isComplete) {
          this.$snotify.success("Suspended  Information has been saved");
        }

        if (response.status == 201 && isComplete)
          this.$snotify.success("Suspended Details Completed successfully.");
        if (isComplete) this.$emit("refresh-task-list", true);
        this.isError = false;
      } else {
        this.isError = true;
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async updateLoanChecklist(documentId, isComplete) {
    try {
      if (this.isTaskDisabled) {
        this.$snotify.info("Task is not assigned to you.");
        return;
      }
      let response = await Axios.post(
        BASE_API_URL + "loanChecklist/updateLoanChecklist",
        {
          loanId: this.loanId,
          documentId: documentId,
          isComplete: isComplete,
        },
        {
          headers: authHeader(),
        }
      );
      if (response.data == 200) {
        this.$snotify.success("Checklist has been Updated");
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async markConditionCritical(conditionId, event) {
    try {
      if (this.isTaskDisabled) {
        this.$snotify.info("Task is not assigned to you.");
        return;
      }
      let response = await Axios.post(
        BASE_API_URL + "loanChecklist/markConditionCritical",
        {
          loanId: this.loanId,
          documentId: conditionId,
          isCritical: event.target.checked,
        },
        {
          headers: authHeader(),
        }
      );
      if (response.status === 201) this.$snotify.success(response.data.message);
    } catch (error) {
      console.log(error);
    }
  }

  public async getConditionsFromLoanChecklist() {
    try {
      let res = await Axios.post(
        BASE_API_URL + "processor/getConditionsFromLoanChecklist",
        {
          loanId: this.loanId,
          taskId: this.taskId,
          lenderResponseSequence: this.sequence,
          source: this.source,
        },
        {
          headers: authHeader(),
        }
      );
      if (res.status === 201) {
        this.sData = res.data.task;
        this.conditionChecklist = res.data.conditions
          ? res.data.conditions
          : [];
        this.conditionChecklist = this.conditionChecklist.map((ele) => {
          let msg = "";
          if (ele.allRejectedComments && ele.allRejectedComments.length > 0) {
            for (let j = 0; j < ele.allRejectedComments.length; j++) {
              const e = ele.allRejectedComments[j];
              msg += `${e.comment} ${
                e.acceptedOrRejectedOn
                  ? moment(e.acceptedOrRejectedOn).format(
                      "MM/DD/YYYY, h:mm:ss a"
                    )
                  : ""
              } -${e.name ? e.name : ""} *** `;
            }
          }
          ele["msgString"] = msg;

          let msg2 = "";
          if (ele.allAcceptedData && ele.allAcceptedData.length > 0) {
            for (let j = 0; j < ele.allAcceptedData.length; j++) {
              const e = ele.allAcceptedData[j];
              msg2 += `${
                e.acceptedOrRejectedOn
                  ? moment(e.acceptedOrRejectedOn).format(
                      "MM/DD/YYYY, h:mm:ss a"
                    )
                  : ""
              } -${e.name ? e.name : ""} *** `;
            }
          }
          ele["msgString1"] = msg2;
          return ele;
        });

        this.requiredDoc = this.conditionChecklist.filter(
          (c) => c.docStatus == "Required"
        );
        this.isTaskCompleted = res.data.isCompleted;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public toBeUploadedBy(uploadedBy) {
    if (uploadedBy.includes(2)) {
      return "Broker";
    }
    if (uploadedBy.includes(4)) {
      return "Borrower";
    }
    if (uploadedBy.includes(3)) {
      return "Sr. Processor";
    }
    if (uploadedBy.includes(0)) {
      return "Jr. Processor";
    }
  }

  public async notifyUser() {
    try {
      if (this.isTaskDisabled) {
        this.$snotify.info("Task is not assigned to you.");
        return;
      }
      await this.$refs.ref["notify"]({
        loanId: this.loanId,
        lenderResponseSequence: this.sequence,
        source: this.source,
      });
    } catch (error) {
      console.log(error);
    }
  }

  public async viewDocument(document) {
    try {
      await this.$refs.doc["showFile"]({
        documentId: document._id,
        loanId: this.loanId,
        docName: document.name,
        docCategory: document.category,
        isComplete: document.isComplete,
        borrowerName: document.borrowerName,
        isRejected: this.isRejected,
        isAccepted: this.isAccepted,
        isChecklist: this.isChecklist,
        uploadedBy: document.uploadedBy,
      });
    } catch (error) {
      console.log(error);
    }
  }

  public callLoader(loader, loaderTitleId) {
  this.$store.state.tmpcoLoader = loader;
  this.$store.state.loaderTitleId = loaderTitleId;
}

  public async isBrokerNotifiedForRejectedDocs() {
    try {
      let res = await Axios.get(
        BASE_API_URL +
          "loanChecklist/isBrokerNotifiedForRejectedDocs/" +
          this.$route.query.id +
          "/" +
          this.taskId +
          "/" +
          null,
        {
          headers: authHeader(),
        }
      );
      if (res.status == 201) {
        this.notifyBroker = res.data.notifyBorker;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async allMountFunction(sequence) {
    this.workflowTaskId = this.taskId;
    this.isBrokerNotifiedForRejectedDocs();
    this.sequence = sequence;
    await this.getConditionsFromLoanChecklist();
    EventBus.$on(
      "refreshConditionList",
      async () => await this.getConditionsFromLoanChecklist()
    );
  }

  async mounted() {
    this.allMountFunction(this.lenderResponseSequence);
  }
}
