

























































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL, EventBus } from "../config";
import { authHeader } from "../services/auth";
import datepicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import ApexChart from "vue-apexcharts";
import { jsPDF } from "jspdf";
import saveAs from "save-as";
import {
  PRIOR_PROPERTY_USAGE_TYPE,
  PRIOR_PROPERTY_TITLE_TYPE,
} from "../constants/constants";
import Multiselect from "vue-multiselect";

@Component({
  components: { datepicker, ApexChart, Multiselect },
})
export default class LoanInfo extends Vue {
  /*eslint-disable*/
  public milestoneList: any = [];
  public allMilestoneList: any = [];
  public loanDetails: any = {};
  public openCalendar: any = false;
  public rateLockOpenCalendar: any = false;
  public loanList = [];
  public lockDateStatus = false;
  public closingDateStatus = false;
  public skip = 0;
  public totalLength = 0;
  public currentLengthOnScroll = 0;
  public originalFilename = null;
  public files: any = [];
  public fileToDisplay: any = null;
  public length = 0;
  public currentIndex = 0;

  public fileType: any = null;
  public sessionObject: any = null;
  public openSalesCalendar: any = false;
  public priorPropertyUsageType = PRIOR_PROPERTY_USAGE_TYPE;
  public priorPropertyTitleType = PRIOR_PROPERTY_TITLE_TYPE;
  public loanId: any = null;
  public allProcessor: any = [];
  public selectBorrower: any = {};
  public isForcedChangeEmail = false;
  public borroweInfo = [];
  public isDisable = false;

  public errors: any = {
    show: false,
    msg: null,
  };
  public emailExist = false;
  public msg = "";
  public noChange = false;
  public selectedProcesor: any = null;
  public chartOptions: any = {
    colors: ["#00bc00"],
    chart: {
      type: "radialBar",
      offsetY: -10,
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#e7e7e7",
          strokeWidth: "97%",
          margin: 5, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            color: "#999",
            opacity: 1,
            blur: 2,
          },
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: -2,
            fontSize: "14px",
            fontWeight: "bold",
          },
        },
      },
    },
    grid: {
      padding: {
        right: 25,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 53, 91],
      },
    },
    labels: ["Average Results"],
  };

  /**
   * Exporting xml file
   */
  public async exportXmlFile() {
    try {
      const response = await Axios.post(
        BASE_API_URL + "broker/exportXmlFile",
        { loanId: this.$route.query.id },
        {
          headers: authHeader(),
        }
      );
      if (response.data.xmlData) {
        const dom = document.createElement("a");
        const primaryBorrowerName = response.data.primaryBorrower;
        const filename =
          primaryBorrowerName + "-" + this.$route.query.id + ".xml";
        const blob = new Blob([response.data.xmlData], { type: "text/plain" });
        let ppp = dom.setAttribute("href", window.URL.createObjectURL(blob));
        dom.setAttribute("download", filename);
        dom.dataset.downloadurl = ["text/plain", dom.download, dom.href].join(
          ":"
        );
        dom.draggable = true;
        dom.classList.add("dragout");
        dom.click();
      }
    } catch (error) {
      console.log(error);
    }
  }

  /********************************* */

  public async exportOrignalXmlFile() {
    try {
      const response = await Axios.post(
        BASE_API_URL + "broker/exportOrignalXmlFile",
        { loanId: this.$route.query.id },
        {
          headers: authHeader(),
        }
      );
      if (response.status === 201) {
        this.files = response.data.files;
        this.fileType =
          this.files.length > 0
            ? this.files[this.currentIndex].path.split(".").pop()
            : null;
        this.fileToDisplay =
          this.files.length > 0 ? this.files[this.currentIndex].image : null;
        this.length = this.files.length - 1;
        this.originalFilename =
          this.files.length > 0
            ? this.files[this.currentIndex].originalName
            : null;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async exportPdf() {
    try {
      this.callLoader(true, null);
      const response = await Axios.post(
        BASE_API_URL + "broker/exportPdf",
        {
          loanId: this.$route.query.id,
          priorPropertyUsageType: this.priorPropertyUsageType,
          priorPropertyTitleType: this.priorPropertyTitleType,
        },
        {
          headers: authHeader(),
          responseType: "blob",
        }
      );
      saveAs(response.data, this.$route.query.id + ".zip");
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  //          response.data.html.forEach(e=>{
  //               let fileName = response.data.borr + "-" + this.$route.query.id + ".pdf";
  //          const doc = new jsPDF('p', 'pt', 'letter');

  //        doc.html(e, {
  //        callback: function (doc) {
  //      doc.save(fileName);
  //        },
  //        x: 5,
  //    y: 5
  // });

  //          })
  //          let fileName = response.data.borr + "-" + this.$route.query.id + ".pdf";
  //          const doc = new jsPDF('p', 'pt', 'letter');

  //        doc.html(response.data.html, {
  //        callback: function (doc) {
  //      doc.save(fileName);
  //        },
  //        x: 5,
  //    y: 5
  // });

  /******************************************** */
  public async getLoanDetails() {
    try {
      const res = await Axios.get(
        BASE_API_URL + "common/getLoanDetails/" + this.$route.query.id,
        {
          headers: authHeader(),
        }
      );
      this.loanDetails = res.data;

      this.calculateDtiAndHousingRatio();
    } catch (error) {
      console.log(error);
    }
  }

  public async getProgressBarList(allMiletsone) {
    
    try {
      const res = await Axios.post(
        BASE_API_URL + "common/getProgressBarList/",
        { loanId: this.$route.query.id, allMiletsone: allMiletsone },
        {
          headers: authHeader(),
        }
      );

      if (res.data)
        allMiletsone
          ? (this.allMilestoneList = res.data)
          : (this.milestoneList = res.data);
    } catch (error) {
      console.log(error);
    }
  }

  formatPrice(value) {
    let price;
    if (value) {
      price =
        value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + ".00";
    }
    return price;
  }
  public async updateClosingDate() {
    try {
      let date = this.loanDetails.closingDate;
      const res = await Axios.post(
        BASE_API_URL + "common/updateClosingDate/",
        {
          loanId: this.$route.query.id,
          closingDate: date,
        },
        {
          headers: authHeader(),
        }
      );
      if (res.data.status) {
        this.openCalendar = false;
        this.closingDateStatus = true;

        this.$snotify.success("Closing Date Added  Successfully");
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async updateSalesContractDate() {
    try {
      const res = await Axios.post(
        BASE_API_URL + "common/updateSalesContractDate/",
        {
          loanId: this.$route.query.id,
          salesContractDate: this.loanDetails.salesContractDate,
        },
        {
          headers: authHeader(),
        }
      );
      if (res.data.status) this.openSalesCalendar = false;
    } catch (error) {
      console.log(error);
    }
  }

  public async updateRateLockDate() {
    try {
      let date = new Date(this.loanDetails.rateLockDate).toISOString();

      const res = await Axios.post(
        BASE_API_URL + "common/updateRateLockDate/",
        {
          loanId: this.$route.query.id,
          rateLockDate: date,
        },
        {
          headers: authHeader(),
        }
      );
      if (res.status === 201) {
        this.lockDateStatus = true;
        this.$snotify.success("Lock Expenation Date Added  Successfully");
      }
    } catch (error) {
      console.log(error);
    }
  }

  public rateLockDateValueChange() {
    this.rateLockOpenCalendar = false;
  }

  public calculateDtiAndHousingRatio() {
    try {
      let housingRatio: any = 0;
      let dtiRatio: any = 0;
      let expenseHouseing: any = 0;
      //************* Calculate housing Ratio ***********/
      console.log("totalrentelIncome", this.loanDetails.rentalIncome);
      console.log(
        "this.loanDetails.expectedNetMonthlyRentalIncome",
        this.loanDetails.expectedNetMonthlyRentalIncome
      );
      let totalIncome: any =
        parseFloat(this.loanDetails.grossMonthlyIncomeAndOtherIncome) +
        parseFloat(
          this.loanDetails.rentalIncome > 0 ? this.loanDetails.rentalIncome : 0
        ) +
        parseFloat(
          this.loanDetails.expectedNetMonthlyRentalIncome > 0
            ? this.loanDetails.expectedNetMonthlyRentalIncome
            : 0
        );
      console.log("liabilities", this.loanDetails.totalLiabilities);

      console.log("totalIncome", totalIncome);
      // console.log("totalrentelIncome", this.loanDetails.rentalIncome);
      expenseHouseing =
        parseFloat(this.loanDetails.expectedNetMonthlyRentalIncome) < 0
          ? -1 * parseFloat(this.loanDetails.expectedNetMonthlyRentalIncome)
          : parseFloat(this.loanDetails.proposedExpense);
      if (isFinite(expenseHouseing) && isFinite(totalIncome))
        housingRatio = (expenseHouseing / totalIncome) * 100;
      housingRatio =
        !isNaN(housingRatio) && isFinite(housingRatio) && housingRatio
          ? housingRatio
          : 0;
      this.loanDetails.housingRatio = housingRatio
        .toString()
        .match(/^-?\d+(?:\.\d{0,2})?/)[0];


      //********* Calculate DTI Ratio ****************/
      let liabilities: any = 0;
      liabilities = this.loanDetails.totalLiabilities;
      let rentalIncome: any = 0;
      rentalIncome =
        parseFloat(this.loanDetails.rentalIncome) < 0
          ? -1 * parseFloat(this.loanDetails.rentalIncome)
          : 0;
      let expectedNetMonthlyRentalIncome: any = 0;
      expectedNetMonthlyRentalIncome =
        parseFloat(this.loanDetails.expectedNetMonthlyRentalIncome) < 0
          ? -1 * parseFloat(this.loanDetails.expectedNetMonthlyRentalIncome)
          : parseFloat(this.loanDetails.proposedExpense);

      let totalExpense: any =
        this.loanDetails.otherLiabilitiesAndExpense +
        +expectedNetMonthlyRentalIncome +
        rentalIncome +
        liabilities;

      //  console.log("proposedExpense", this.loanDetails.proposedExpense);
      console.log("totalExpense", totalExpense);
      // console.log("proposedExpense", this.loanDetails.proposedExpense);
      // console.log(
      //   "this.loanDetails.expectedNetMonthlyRentalIncome",
      //   this.loanDetails.expectedNetMonthlyRentalIncome
      // );

      // parseFloat(this.loanDetails.maitenanceExpense) +

      if (isFinite(totalExpense) && isFinite(totalIncome)) {
        if (totalIncome > 0) {
          dtiRatio = (totalExpense / totalIncome) * 100;
        } else {
          dtiRatio = 0;
        }
        console.log("dtiRatio1", dtiRatio);
        // dtiRatio = parseFloat(dtiRatio).toFixed(2);
        dtiRatio = dtiRatio.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];

        if (!(dtiRatio - Math.floor(dtiRatio))) {
          dtiRatio = dtiRatio;
        }
        dtiRatio =
          !isNaN(dtiRatio) && isFinite(dtiRatio) && dtiRatio ? dtiRatio : 0;
        this.loanDetails.DTIRatio = dtiRatio;
      }

      // this.updatelosHeaderData(
      //   this.loanDetails.DTIRatio,
      //   this.loanDetails.housingRatio
      // );
    } catch (error) {
      console.log(error);
    }
  }

  // public calculateDtiRatio() {
  //   try {
  //     let housingRatio: any = 0;
  //     let dtiRatio: any = 0;
  //     //************* Calculate housing Ratio ***********/
  //     let totalIncome: any = parseFloat(
  //       this.loanDetails.grossMonthlyIncomeAndOtherIncome
  //     );
  //     let proposedExpense: any = parseFloat(this.loanDetails.proposedExpense);
  //     let netMonthlyrent: any = parseFloat(this.loanDetails.proposedExpense);
  //     let liabilities = this.loanDetails.totalLiabilities;

  //     let totalExpense: any =
  //       parseFloat(this.loanDetails.proposedExpense) +
  //       parseFloat(this.loanDetails.otherLiabilitiesAndExpense) +
  //       parseFloat(this.loanDetails.maitenanceExpense) +
  //       parseFloat(liabilities);

  //     if (isFinite(totalExpense) && isFinite(totalIncome)) {
  //       dtiRatio = (totalExpense / totalIncome) * 100;
  //       dtiRatio = parseFloat(dtiRatio).toFixed(2);
  //       if (!(dtiRatio - Math.floor(dtiRatio))) {
  //         dtiRatio = Math.round(dtiRatio);
  //       }
  //       dtiRatio =
  //         !isNaN(dtiRatio) && isFinite(dtiRatio) && dtiRatio ? dtiRatio : 0;
  //       this.loanDetails.DTIRatio = dtiRatio;
  //     }
  //     // this.updatelosHeaderData(
  //     //   this.loanDetails.DTIRatio,
  //     //   this.loanDetails.housingRatio
  //     // );
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  public getUserPhone(phone) {
    try {
      let phoneNumber =
        "(" +
        phone.substring(0, 3) +
        ")" +
        "" +
        phone.substring(3, 6) +
        "-" +
        phone.substring(6, 10);
      return phoneNumber;
    } catch (error) {
      console.log(error);
    }
  }

  public async updateEmail() {
    try {
      this.isDisable = true;
      if (
        this.noChange ||
        this.selectBorrower.email.toLowerCase() ===
          this.loanDetails.primaryBorrower.email.toLowerCase()
      ) {
        this.$snotify.info("No Changes");
        this.$modal.hide("changeEmail");
        this.noChange = false;
        return;
      }
      this.selectBorrower.userType = 4;
      this.selectBorrower.loanId = this.$route.query.id;
      this.selectBorrower.isForcedChangeEmail = this.isForcedChangeEmail;

      const response = await Axios.post(
        BASE_API_URL + "common/broker/updateEmail",
        this.selectBorrower,
        { headers: authHeader() }
      );
      console.log("response", response);
      if (response.data.status == 201) {
        this.$snotify.success("Email Address updated Successfully.");
        this.isForcedChangeEmail = false;
        this.getLoanDetails();
        EventBus.$emit("refreshBorrowerTab");
        this.$modal.hide("changeEmail");
        this.selectBorrower.isVerification = false;
        this.isDisable = false;
        //this.$modal.show("confirmation");
      } else if (response.data.status === 401) {
        this.$snotify.error(response.data.message);
        this.isDisable = false;
        this.$modal.hide("changeEmail");
      } else if (response.data.status == 400) {
        this.emailExist = response.data.emailExist;
        this.borroweInfo = response.data.borroweInfo
          ? response.data.borroweInfo
          : [];
        if (response.data.userType == 4) {
          this.$modal.show("changeEmailConfirmationModal");
        }
        this.$snotify.error("Email Address already Exist.");
        this.$modal.hide("changeEmail");
        this.isDisable = false;
      } else if (response.status === 202) {
        this.msg = response.data.message;
        this.isDisable = false;
      } else {
        this.$snotify.error("Something went wrong.");
        this.isDisable = false;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async updateBorrowerEmail() {
    try {
      if (
        this.noChange ||
        this.selectBorrower.email === this.loanDetails.primaryBorrower.email
      ) {
        this.$snotify.info("No Changes");
        this.$modal.hide("changeEmail");
        this.noChange = false;
        return;
      }
      this.selectBorrower.userType = 4;
      this.selectBorrower.loanId = this.$route.query.id;
      this.selectBorrower.isForcedChangeEmail = this.isForcedChangeEmail;

      const response = await Axios.post(
        BASE_API_URL + "common/broker/updateBorrowerEmail",
        this.selectBorrower,
        { headers: authHeader() }
      );
      if (response.data.status == 201) {
        this.$snotify.success("Email Address updated Successfully.");
        this.isForcedChangeEmail = false;
        this.getLoanDetails();
        EventBus.$emit("refreshBorrowerTab");
        this.$modal.hide("changeEmail");
        this.selectBorrower.isVerification = false;
        //this.$modal.show("confirmation");
      }
    } catch (error) {
      console.log(error);
    }
  }
  public async sendVerificationLinkToBorrower(data) {
    this.$store.state.tmpcoLoader = true;
    try {
      const forServer = {
        email: data.primaryBorrower.email,
        loanId: data.loanId,
        userId: data.userId,
        fullName: data.primaryBorrower.name,
      };
      const response = await Axios.post(
        `${BASE_API_URL}super-admin/loans/sendVerificationLinkToBorrower`,
        forServer,
        { headers: authHeader() }
      );
      if (response.status === 201) {
        this.$snotify.success(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
    this.$store.state.tmpcoLoader = false;
  }

  public async openChangeProcessorModal(loanId) {
    try {
      this.loanId = loanId;
      await this.getSeniorrocessorList();
      this.$modal.show("changeProcessorModal");
    } catch (error) {
      console.log(error);
    }
  }

  public async closeChangeProcessorModal() {
    try {
      this.selectedProcesor = null;
      this.loanId = null;
      this.allProcessor = [];
      this.$modal.hide("changeProcessorModal");
    } catch (error) {
      console.log(error);
    }
  }

  public async changeProessorOfLOan() {
    try {
      if (!this.selectedProcesor) {
        this.errors.show = true;
        this.errors.msg = `Please select Processor`;
        return;
      }
      const response = await Axios.post(
        `${BASE_API_URL}super-admin/loans/changeProessorOfLOan`,
        { loanId: this.loanId, selectedProcesor: this.selectedProcesor },
        { headers: authHeader() }
      );
      this.$snotify.success("Senior processor changed successfully.");
      await this.closeChangeProcessorModal();
      await this.getLoanDetails();
    } catch (error) {
      console.log(error);
    }
  }

  public async getSeniorrocessorList() {
    try {
      const response = await Axios.post(
        `${BASE_API_URL}super-admin/loans/getSeniorrocessorList`,
        { loanId: this.loanId },
        { headers: authHeader() }
      );
      this.allProcessor = response.data;
    } catch (error) {
      console.log(error);
    }
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  mounted() {
    this.getProgressBarList(false);
    this.getLoanDetails();

    EventBus.$on("refesh-loaninfo", async () => {
      console.log("hello");
      await this.getLoanDetails();
      await this.getProgressBarList(false);
    });
  }
}
