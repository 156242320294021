






























































































































































































































































































































































































































































































































































































































































































































































































































































































































  import { Component, Vue, Prop } from "vue-property-decorator";
  import Axios from "axios";
  import { BASE_API_URL } from "../../../config";
  import { authHeader } from "../../../services/auth";
  import ViewDocument from "../../../common-components/ViewDocument.vue";
  import FollowUpTask from "../../../common-components/FollowUpTask.vue";
  import { FloodBinderReceivedChecklist } from "../../../models/workflow/floodBinderReceived.model";
  import datepicker from "vue2-datepicker";
  import "vue2-datepicker/index.css";
  import ReOrderTask from "../../../common-components/ReOrderTask.vue";

  @Component({
    components: {
      ViewDocument,
      FollowUpTask,
      datepicker,
      ReOrderTask,
    },
  })
  export default class FloodBinderReceived extends Vue {
    /* eslint-disable */

    public floodBinderReceived: any = new FloodBinderReceivedChecklist();
    public floodBinderReceivedData: any = new FloodBinderReceivedChecklist();
    @Prop()
    public taskId: any;
    public isComplete = false;
    public file: any = null;
    public followUpHistory: any = [];
    public revisedUploading = false;
    public error: any = {
      show: false,
      msg: null,
    };
    public docLoader = false;
    // @Prop()
    // public isLoanInactive: any;
    public isTaskCompleted = false;
    public countToSendOnServer: any = null;
    public showHistoryBtn = false;
    @Prop()
    public reOrderCount: any;
    public changedValues: any = [];
    @Prop()
    public isTaskDisabled: any;
  public agentContactId = null;
  public isOrderPlacedOnce = false

    public async uploadReport(event, status) {
      if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getFloodBinderReceived();
        return;
      }
      status != "Revised"
      ? (this.docLoader = true)
      : (this.revisedUploading = true);
      try {
        if (event.target.files.length <= 0) return;
        const formData: any = new FormData();
        Array.from(event.target.files).forEach((element) => {
          formData.append("file", element);
        });
        let loanId: any = this.$route.query.id;
        formData.append("loanId", loanId);
        formData.append("reOrderCount", this.countToSendOnServer);
        formData.append("taskId", this.taskId);
        const response = await Axios.post(
          BASE_API_URL + "processor/uploadFloodRecievedReport",
          formData,
          { headers: authHeader() }
          );
        if (response.status === 201) this.$snotify.success(response.data.message);
        this.floodBinderReceived.loanChecklistData =
        response.data.loanChecklistData;
        this.error.msg = null;
        this.error.show = false
      } catch (error) {
        console.log(error);
      }
      status != "Revised"
      ? (this.docLoader = false)
      : (this.revisedUploading = false);
    }

    public async saveFloodBinderReceivedTask() {
          if(this.isOrderPlacedOnce) return

      this.callLoader(true, null);
      try {
         if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getFloodBinderReceived();
        return;
      }
        if (this.isComplete && this.floodBinderReceived.monthlyPaymentOn1003CorrectlyReflected.mode == 'No') {
          this.error.show = true;
          this.error.msg = "Please select yes for the Question No. 8";
          this.callLoader(false, null);
          return;
        }
        if (this.isComplete && !this.floodBinderReceived.isFloodBinderRecieved) {
          this.error.show = true;
          this.error.msg = "All Fields are mandatory";
          this.callLoader(false, null);
          return;
        }
        if (this.isComplete && !this.floodBinderReceived.loanChecklistData) {
          this.error.show = true;
          this.error.msg = "Upload Flood Binder Report";
          this.callLoader(false, null);
          return;
        }
        if (
          this.isComplete &&
          this.floodBinderReceived.loanChecklistData.revisedDoc &&
          this.floodBinderReceived.loanChecklistData.docLength < 2
          ) {
          this.error.show = true;
        this.error.msg = "Upload Flood Binder Updated Report.";
        await this.callLoader(false, null);
        return;
      }
      let data = {
        loanId: this.$route.query.id,
        taskId: this.taskId,
        isComplete: this.isComplete,
        floodBinderReceived: this.floodBinderReceived,
        reOrderCount: this.countToSendOnServer,
        changedValues: this.changedValues
      };
      if(this.isComplete)
      this.isOrderPlacedOnce = true
      let response = await Axios.post(
        BASE_API_URL + "processor/saveFloodBinderReceivedTask",
        data,
        {
          headers: authHeader(),
        }
        );
      // if (!this.isComplete) {
      //   this.$snotify.success(
      //     "Flood Binder Received Information has been saved"
      //   );
      // }

      if (response.status == 201 && this.isComplete)
        this.$snotify.success("Flood Binder Received Completed successfully.");
        this.error.show = false;
         this.error.msg = null;
      if (this.isComplete) this.$emit("refresh-task-list", true);
      await this.getFloodBinderReceived();
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async handleChange(currentVal, previousVal, fieldName){    
    let index = this.changedValues.findIndex(c => c.name === fieldName);
    if(index !== -1){
      if(currentVal === previousVal){
        this.changedValues.splice(index, 1);
      } else {
        let data = this.changedValues.find(v => v.name === fieldName)
        data.from = previousVal; 
        data.to = currentVal; 
      }
    } else {
      this.changedValues.push({ name: fieldName, from: previousVal, to: currentVal });
    }
  }

  public async getFloodBinderReceived() {
    this.changedValues = [];
    this.callLoader(true, null);
    try {
      const response = await Axios.get(
        BASE_API_URL +
        "processor/getFloodBinderReceived/" +
        this.$route.query.id +
        "/" +
        this.taskId +
        "/" +
        this.countToSendOnServer,
        {
          headers: authHeader(),
        }
        );

      if (response.status === 201) {
        this.agentContactId = response.data.agentContactId
        this.floodBinderReceived = response.data.floodBinderReceived
        ? JSON.parse(JSON.stringify(response.data.floodBinderReceived))
        : new FloodBinderReceivedChecklist();
        this.floodBinderReceived.loanChecklistData =
        response.data.loanChecklistData;
        this.isTaskCompleted = response.data.isCompleted;
        this.showHistoryBtn = response.data.showHistoryBtn;
        this.floodBinderReceivedData = JSON.parse(JSON.stringify(this.floodBinderReceived))
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async viewDocument(document) {
    try {
       if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getFloodBinderReceived();
        return;
      }
      document.loanId = this.$route.query.id;
      this.$refs.ref["showFile"](document);
    } catch (error) {
      console.log(error);
    }
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  public async sendFollowUpMailToFloodAgent() {
    this.callLoader(true, 1);
    try {
       if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getFloodBinderReceived();
        return;
      }
      this.$refs.refFollowUp["openFollowUpModal"]({
        label: "Message To Flood Agent",
        message: "Follow Up",
        apiPath: `processor/sendFollowUpMailToFloodAgent`,
        data: { loanId: this.$route.query.id, taskId: this.taskId, agentContactId: this.agentContactId, reOrderCount: this.countToSendOnServer },
      });
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async viewFollowUpHistory() {
    try {
       if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getFloodBinderReceived();
        return;
      }
      
      this.$refs.refFollowUp["openHistoryModal"]({
        label: "Select method to send",
        message: "Follow Up",
        apiPath: `processor/getFollowUpHistoryOfTitle`,
        data: { loanId: this.$route.query.id, taskId: this.taskId, reOrderCount: this.countToSendOnServer },
      });
    } catch (error) {
      console.log(error);
    }
  }

  public async showHistory() {
    this.callLoader(true, 1);
    try {
       if (this.isTaskDisabled) {
        this.callLoader(false, null);
        this.$snotify.info("Task is not assigned to you.");
        this.getFloodBinderReceived();
        return;
      }
      this.$refs.refReOrder["showHistory"](this.taskId);
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public showUploadRevisedReportDiv() {
    let show = false;
    let value;
    let key;
    for ([key, value] of Object.entries(this.floodBinderReceived)) {
      if (
        value &&
        Object.keys(value).length > 0 &&
        key != "createdBy" &&
        key != "createdOn" &&
        key != "loanChecklistData" &&
        key != "updatedBy" &&
        key != "updatedOn"
        ) {
        if (value.mode == "No" && value.revisionRequestedOn) {
          show = true;
          this.floodBinderReceived.loanChecklistData.revisedDoc = true;
        }
      }
    }
    return show;
  }

  public async allMountFunction(count) {
    this.countToSendOnServer = count;
    await this.getFloodBinderReceived();
  }

  public async mounted() {
    await this.allMountFunction(this.reOrderCount);
  }
}
