

import Axios from "axios";
import { Component, Vue, Prop } from "vue-property-decorator";
import { BASE_API_URL, EventBus } from "../../../config";
import { authHeader } from "../../../services/auth";
import {
  ETHINICITY_TYPE,
  HISPANICORLATINO_TYPE,
  RACE_TYPE,
  RACE_ASIAN_TYPE,
  RACE_NATIVE_HAWALIAN_TYPE,
} from "../../../constants/constants";
@Component
export default class DemographicInfo extends Vue {
  /* eslint-disable */
  public demographicInfoList: any = [];
  public demographicDataList: any = [];
  public borrowerInfo = [];
  @Prop()
  public validationData: any;
  public ethnicityTypes = ETHINICITY_TYPE;
  public hispanicLatinoTypes = HISPANICORLATINO_TYPE;
  public raceTypes = RACE_TYPE;
  public raceAsianTypes = RACE_ASIAN_TYPE;
  public raceNativeHawalianTypes = RACE_NATIVE_HAWALIAN_TYPE;
  public count = 0;
  public changedValues: any = [];
  @Prop()
  public isLoanInactive: any;

  public hasAnychangedValues() {
    return this.changedValues;
  }

  public async getDemographicInfo() {
    try {
      this.changedValues = [];
      this.callLoader(true, null);
      const res = await Axios.get(
        BASE_API_URL + "urla/getDemographicInfo/" + this.$route.query.id,
        {
          headers: authHeader(),
        }
      );
      this.count = 0;
      this.demographicInfoList = res.data.demographicInfo;
      this.borrowerInfo = res.data.borrowerInfo;
      this.demographicDataList = JSON.parse(
        JSON.stringify(this.demographicInfoList)
      );
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  public async updateDemographicInfo() {
    try {
      const res = await Axios.post(
        BASE_API_URL + "urla/updateDemographicInfo",
        {
          borrowerInfo: this.demographicInfoList,
          loanId: this.$route.query.id,
          changedValues: this.changedValues,
        },
        {
          headers: authHeader(),
        }
      );
      if (res.status == 201 && this.count > 0) {
        await this.getDemographicInfo();
        this.$snotify.success("Demographic Information has been Updated.");
      }
      this.count = 0;
      this.$emit("referesh-tab", true);
      this.validationData ? this.refreshBorrowerTabs() : "";
    } catch (error) {
      console.log(error);
    }
  }

  public async handleChange(currentVal, previousVal, fieldName) {
    if (previousVal !== undefined) {
      const index = this.changedValues.findIndex((c) => c.name === fieldName);
      if (index !== -1) {
        if (JSON.stringify(currentVal) === JSON.stringify(previousVal)) {
          this.changedValues.splice(index, 1);
        } else {
          let data = this.changedValues.find((v) => v.name === fieldName);
          data.from = previousVal;
          data.to = currentVal;
        }
      } else {
        if (JSON.stringify(currentVal) !== JSON.stringify(previousVal))
          this.changedValues.push({
            name: fieldName,
            from: previousVal,
            to: currentVal,
          });
      }
    }
  }

  public async refreshBorrowerTabs() {
    try {
      const response = await Axios.post(
        BASE_API_URL + "urla/refreshBorrowerTabs",
        {
          loanId: this.$route.query.id,
          identifier: "Demographic",
        },
        {
          headers: authHeader(),
        }
      );
      const borIndex = response.data;
      this.demographicInfoList.forEach((b, i) => {
        const hasExist =
          borIndex.length > 0 ? borIndex.find((index) => index == i) : null;
        Vue.set(b, "showValidation", hasExist != null ? true : false);
      });
    } catch (error) {
      console.log(error);
    }
  }

  public makeRaceFieldEmpty(index) {
    // this.demographicInfoList[index].race.typeName.other = null;
  }

  public makeEthnicityFieldEmptyforHispanic(index) {
    this.demographicInfoList[
      index
    ].ethnicity.hispanicOrLatinoDetail.puertoRican = false;
    this.demographicInfoList[index].ethnicity.hispanicOrLatinoDetail.mexican =
      false;
    this.demographicInfoList[index].ethnicity.hispanicOrLatinoDetail.cuban =
      false;
    if (
      this.demographicInfoList[index].ethnicity.hispanicOrLatinoDetail
        .otherHispanicOrLatino
    )
      this.demographicInfoList[
        index
      ].ethnicity.hispanicOrLatinoDetail.otherHispanicOrLatino.isOtherHispanicOrLatino =
        false;
    this.demographicInfoList[
      index
    ].ethnicity.hispanicOrLatinoDetail.otherHispanicOrLatino.description = null;
  }

  public makeDescriptionFieldEmpty(index) {
    this.demographicInfoList[
      index
    ].ethnicity.hispanicOrLatinoDetail.otherHispanicOrLatino.description = null;
  }

  // public makeEthnicityFieldEmpty(index) {

  //   // this.demographicInfoList[index].ethnicity.hispanicType.other = null;
  // }

  public returnName(id) {
    const bor: any = this.borrowerInfo.find((b: any) => b._id == id);
    return bor ? bor.name.firstName + " " + bor.name.lastName : "";
  }

  public checkValidation() {
    this.demographicInfoList.forEach((d, i) => {
      let hasExist =
        this.validationData.borIndex.length > 0
          ? this.validationData.borIndex.find((index) => index == i)
          : null;
      Vue.set(d, "showValidation", hasExist != null ? true : false);
    });
    this.$emit("call-mount", true);
  }

  public hasEthinicity(i) {
    return this.validationData &&
      !this.demographicInfoList[i].ethnicity.hispanicOrLatinoDetail
        .isHispanicOrLatino &&
      !this.demographicInfoList[i].ethnicity.notHispanicOrLatino &&
      !this.demographicInfoList[i].ethnicity.isDoNotWish
      ? true
      : false;
  }

  public hasEthinicityType(i) {
    return this.validationData &&
      !this.demographicInfoList[i].ethnicity.hispanicOrLatinoDetail.mexican &&
      !this.demographicInfoList[i].ethnicity.hispanicOrLatinoDetail
        .puertoRican &&
      !this.demographicInfoList[i].ethnicity.hispanicOrLatinoDetail.cuban &&
      !this.demographicInfoList[i].ethnicity.hispanicOrLatinoDetail
        .otherHispanicOrLatino.isOtherHispanicOrLatino
      ? true
      : false;
  }

  public hasRace(i) {
    return this.validationData &&
      !this.demographicInfoList[i].race.americanIndianOrAlaskaNative
        .isAmericanIndianOrAlaskaNative &&
      !this.demographicInfoList[i].race.asianDetail.isAsian &&
      !this.demographicInfoList[i].race.blackOrAfricanAmerican &&
      !this.demographicInfoList[i].race.nativeHawaiianOrOtherDetail
        .isNativeHawaiianOrOtherPacificIslander &&
      !this.demographicInfoList[i].race.white &&
      !this.demographicInfoList[i].race.isDoNotWish
      ? true
      : false;
  }

  public hasAsianType(i) {
    return this.validationData &&
      !this.demographicInfoList[i].race.asianDetail.asianIndian &&
      !this.demographicInfoList[i].race.asianDetail.chinese &&
      !this.demographicInfoList[i].race.asianDetail.filipino &&
      !this.demographicInfoList[i].race.asianDetail.japanese &&
      !this.demographicInfoList[i].race.asianDetail.korean &&
      !this.demographicInfoList[i].race.asianDetail.vietnamese &&
      !this.demographicInfoList[i].race.asianDetail.otherAsian.isOtherAsian
      ? true
      : false;
  }

  public hasNativeHawalianType(i) {
    return this.validationData &&
      !this.demographicInfoList[i].race.nativeHawaiianOrOtherDetail
        .nativeHawaiian &&
      !this.demographicInfoList[i].race.nativeHawaiianOrOtherDetail
        .guamanianOrChamorro &&
      !this.demographicInfoList[i].race.nativeHawaiianOrOtherDetail.samoan &&
      !this.demographicInfoList[i].race.nativeHawaiianOrOtherDetail
        .otherPacificIslander.isOtherPacificIslander
      ? true
      : false;
  }

  public resetEthnicityVal(ethnicity) {
    console.log(ethnicity, "eh");
    if (ethnicity.isDoNotWish) {
      ethnicity.notHispanicOrLatino = null;
      ethnicity.hispanicOrLatinoDetail.isHispanicOrLatino = null;
      ethnicity.hispanicOrLatinoDetail.mexican = null;
      ethnicity.hispanicOrLatinoDetail.puertoRican = null;
      ethnicity.hispanicOrLatinoDetail.cuban = null;
      ethnicity.hispanicOrLatinoDetail.otherHispanicOrLatino.isOtherHispanicOrLatino =
        null;
      ethnicity.hispanicOrLatinoDetail.otherHispanicOrLatino.description = null;
    }
  }

  public checkValidationForMobile() {
    EventBus.$emit("check-validation", true);
  }

  async mounted() {
    await this.getDemographicInfo();
    this.validationData ? await this.checkValidation() : "";
  }
  /* eslint-disable */
}
