var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"white-card loan-process-height-249 mb-2 d-flex flex-column"},[_vm._m(0),_c('div',{staticClass:"col-lg-12 pt-3 pb-3 px-1 flex-1 height_100"},[_c('div',{staticClass:"form-row min_293 flex-column m-0 col-12 pl_5_media"},[_c('div',{staticClass:"flex-1"},[_c('div',{staticClass:"row m-0"},[_c('div',{staticClass:"col-lg-6 col-md-12 col-sm-12 px-1"},[_c('div',{staticClass:"d-flex-360 justify-content-between align-items-center"},[_c('p',{staticClass:"heading mb_16"},[_vm._v("Conditions")]),_c('div',{staticClass:"d_flex_right"},[_c('div',[(
                      _vm.cAData.notifiedOn &&
                      _vm.cAData.notifiedOn.length > 0 &&
                      _vm.checkUsers(_vm.conditionChecklist)
                    )?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"fa fa-exclamation-circle mr-2",attrs:{"title":'Last Notified On:' +
                      _vm.$moment(
                        new Date(
                          _vm.cAData.notifiedOn[_vm.cAData.notifiedOn.length - 1].date
                        )
                      ).format('MM/DD/YYYY, h:mm:ss a')}}):_vm._e(),(
                      _vm.conditionChecklist.length > 0 &&
                      _vm.requiredDoc.length > 0 &&
                      _vm.checkUsers(_vm.conditionChecklist)
                    )?_c('button',{staticClass:"tmpco-btn tmpco-btn__default ml-2",class:[
                      _vm.isTaskCompleted
                        ? 'btn-upload cursor-pointer cursor-not-allow'
                        : 'btn-upload cursor-pointer' ],attrs:{"type":"button","disabled":_vm.isTaskCompleted},on:{"click":function($event){$event.preventDefault();return _vm.notifyUser()}}},[_vm._v(" Notify Users ")]):_vm._e()])])]),_c('hr',{staticClass:"loan-process-border-top mt-1 mb-3"}),(_vm.conditionChecklist.length > 0)?_c('div',_vm._l((_vm.conditionChecklist),function(condition,i){return _c('div',{key:i,staticClass:"card-content-border-dark mb-2 p-2  clip-size-color1",on:{"change":function($event){return _vm.removeErrorMsgs()}}},[_c('div',{staticClass:"\n                    pl-2\n                    pr-2\n                    pt-2\n                    mb-2\n                    d-flex\n                    justify-content-between\n                    align-items-center\n                  "},[_c('div',{staticClass:"d-flex align-content-center"},[_c('h6',{staticClass:"card-content-title mb-0",domProps:{"innerHTML":_vm._s(condition.name)}},[_c('i',{staticClass:"fa fa-file-text-o mr-2 card-content-file-icon"})])])]),(
                    (condition.allRejectedComments &&
                      condition.allRejectedComments.length > 0 &&
                      condition.isRejectedDocNotified &&
                      (_vm.$store.state.sessionObj.userType === 2 ||
                        _vm.$store.state.sessionObj.userType === 4)) ||
                    (condition.allRejectedComments &&
                      condition.allRejectedComments.length > 0 &&
                      _vm.$store.state.sessionObj.userType === 3)
                  )?_c('div',{staticClass:"d-flex align-items-flex"},[_c('div',{staticClass:"d-flex align-items-flex"},[_c('i',{staticClass:"fa fa fa-window-close color-red"}),(
                        condition.allRejectedComments &&
                        condition.allRejectedComments.length > 0
                      )?_c('div',[_c('i',{staticClass:"fa fa-paperclip clip-size-color mr-2 ml-2",on:{"click":function($event){$event.preventDefault();_vm.isRejected = true;
                          _vm.isAccepted = false;
                          _vm.viewDocument(condition);}}})]):_vm._e(),_c('p',{staticClass:"rejected-text mb-2"},[_vm._v(" "+_vm._s(condition.msgString)+" ")])])]):_vm._e(),(condition.docFiles.find(function (l) { return l.status == 'Accept'; }))?_c('div',[_c('div',{staticClass:"d-flex align-items-center"},[_c('i',{staticClass:"fa fa-paperclip clip-size-color1 mr-2 ml-2",on:{"click":function($event){$event.preventDefault();_vm.isRejected = false;
                        _vm.isAccepted = true;
                        _vm.viewDocument(condition);}}}),_c('div',{staticClass:"d-flex justify-content-between w-100 pb-2"},[_c('p',{staticClass:"accepted-text ml-2"},[_vm._v(" -"+_vm._s(condition.msgString1)+" ")]),(
                          condition && condition.docFiles.length>0 && 
                          condition.docFiles[condition.docFiles.length - 1]
                            .status == 'Accept'
                        )?_c('p',{staticClass:"accepted"},[_vm._v("Accepted")]):_vm._e()])])]):_vm._e(),_c('div',{staticClass:"\n                    pl-2\n                    pr-2\n                    pb-2\n                    d-flex\n                    justify-content-between\n                    align-items-center\n                  "},[_c('div',{},[_c('p',{staticClass:"card-content-title mb-0 ml-4",domProps:{"innerHTML":_vm._s(_vm.toBeUploadedBy(condition.toBeUploadedBy))}})]),_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('div',{staticClass:"mr-2"},[_c('div',{staticClass:"custom-checkbox mb-0 d-flex align-items-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(condition.isCritical),expression:"condition.isCritical"}],staticClass:"custom-checkbox-input",attrs:{"type":"checkbox","id":"c","disabled":_vm.isTaskCompleted ||
                            (condition.docFiles.length > 0 &&
                              condition.docFiles[
                                condition.docFiles.length - 1
                              ].status == 'Accept')},domProps:{"checked":Array.isArray(condition.isCritical)?_vm._i(condition.isCritical,null)>-1:(condition.isCritical)},on:{"change":[function($event){var $$a=condition.isCritical,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(condition, "isCritical", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(condition, "isCritical", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(condition, "isCritical", $$c)}},function($event){return _vm.markConditionCritical(condition._id, $event)}]}}),_c('label',{staticClass:"label",attrs:{"for":"c"}},[_vm._v("Critical")]),_c('div',{staticClass:"indicator"})])]),(
                        condition.docFiles.length == 0 ||
                        (condition.docFiles.length > 0 &&
                          condition.docFiles[condition.docFiles.length - 1]
                            .status != 'Accept')
                      )?_c('div',[(!_vm.isTaskCompleted)?_c('i',{staticClass:"fa fa-trash mr-2 card-content-trash-icon",on:{"click":function($event){_vm.deleteCondition(condition._id);
                          _vm.removeErrorMsgs();}}}):_vm._e()]):_vm._e(),_c('div',[(
                          condition.isUploadedDocNotified &&
                          condition.docFiles[condition.docFiles.length - 1]
                            .status == null &&
                          _vm.$store.state.sessionObj.userType === 3
                        )?_c('div',{staticClass:"d-flex align-items-center"},[_c('i',{staticClass:"fa fa-paperclip clip-size mr-2",on:{"click":function($event){$event.preventDefault();_vm.isRejected = false;
                            _vm.isAccepted = false;
                            _vm.viewDocument(condition);}}})]):_vm._e(),(
                    condition.isDocument &&
                    condition.docFiles.length > 0 &&
                    condition.docStatus == 'Uploaded' &&
                    !condition.isUploadedDocNotified &&
                    _vm.$store.state.sessionObj.userType === 3
                  )?_c('div',[_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
                      title:
                        _vm.$store.state.sessionObj.userType === 3
                          ? "User is uploaded but not Notified!"
                          : 'Document is under review.',
                    }),expression:"{\n                      title:\n                        $store.state.sessionObj.userType === 3\n                          ? `User is uploaded but not Notified!`\n                          : 'Document is under review.',\n                    }"}],staticClass:"fa fa-clock-o clip-size orange_dark mr-2"})]):_vm._e()])])])])}),0):_c('div',{staticClass:"\n                text-muted text-center\n                d-flex\n                align-items-center\n                justify-content-center\n                condition_height\n              "},[_vm._v(" Please add conditions ")])]),_c('div',{staticClass:"col-lg-6 col-md-12 col-sm-12 px-1"},[_c('condition-modal',{ref:"ref",attrs:{"disableCondition":_vm.isTaskCompleted,"loanId":_vm.loanId,"workflowTaskId":_vm.workflowTaskId,"lenderResponseSequence":_vm.sequence,"source":_vm.source},on:{"refresh-list":function($event){return _vm.getConditionsFromLoanChecklist()},"remove-error-on-change":function($event){return _vm.removeErrorMsgs()}}})],1)])]),_c('hr',{staticClass:"loan-process-border-top"}),_c('div',{staticClass:"d-flex align-items-center justify-content-end form-group"},[(_vm.isError.show)?_c('span',{staticClass:"login-error show pr-2"},[_vm._v(_vm._s(_vm.isError.msg))]):_vm._e(),_c('button',{staticClass:"tmpco-btn tmpco-btn__theme",class:[
            _vm.isTaskCompleted
              ? 'btn-upload cursor-pointer cursor-not-allow'
              : 'btn-upload cursor-pointer' ],attrs:{"type":"button","disabled":_vm.isTaskCompleted},on:{"click":function($event){$event.preventDefault();return _vm.openConfirmationPopup()}}},[_vm._v(" "+_vm._s(this.sequence >= 1 ? "ReSubmit" : "Submit")+" ")])]),_c('modal',{staticClass:"modal",attrs:{"centered":"","name":"confirmationModal","hide-footer":true}},[_c('div',{staticClass:"modal--md-regular"},[_c('div',{staticClass:"tmpco-modal__content"},[_c('div',{staticClass:"tmpco-modal__header"},[_c('p',{staticClass:"tmpco-modal__title"},[_vm._v("Conditional Approval")]),_c('button',{staticClass:"tmpco-modal__close",attrs:{"type":"button","data-dismiss":"modal"},on:{"click":function($event){return _vm.$modal.hide('confirmationModal')}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"tmpco-modal__body"},[_c('div',{staticClass:"col-lg-12 col-xl-12"},[_c('p',{staticClass:"mt-3 mb-3 content-modal"},[_vm._v(" Are you sure you want to submit? ")])])]),_c('div',{staticClass:"tmpco-modal__footer"},[_c('button',{staticClass:"tmpco-btn tmpco-btn__sm-default mr-2",on:{"click":function($event){return _vm.$modal.hide('confirmationModal')}}},[_vm._v(" No ")]),_c('button',{staticClass:"tmpco-btn tmpco-btn__sm-theme",attrs:{"type":"button"},on:{"click":function($event){_vm.saveConditionalApproval(true);
                  _vm.$modal.hide('confirmationModal');}}},[_vm._v(" Yes ")])])])])]),_c('view-document',{ref:"doc",on:{"refresh-docs":function($event){_vm.getConditionsFromLoanChecklist();
          _vm.isBrokerNotifiedForRejectedDocs();}}}),_c('CommonDeleteModal',{ref:"refs",on:{"call-mount-funtion":function($event){return _vm.getConditionsFromLoanChecklist()}}})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"loan-process-heading"},[_c('div',{staticClass:"\n        col-12\n        d_flex_370\n        justify-content-between\n        align-items-center-start\n        m11b-0\n        pt-0\n        p_0_370\n        column_flex_767\n      "},[_c('p',{staticClass:"heading mb-0 pl_15_370"},[_vm._v("Conditional Approval")]),_c('div',{staticClass:"d-flex p_15_370"})])])}]

export { render, staticRenderFns }